import React from 'react'
import 'core-js/features/array/flat'
import { ApolloProvider } from '@apollo/client'
import { hydrate } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { apolloClient } from './utils'
import { unregister } from 'razzle-plugin-workbox/service-worker'
// import { loadableReady } from '@loadable/component'

declare global {
  interface Window {
    __APOLLO_STATE__: any
  }
}

unregister()

const client = apolloClient()

// loadableReady().then(() => {
hydrate(
  <ApolloProvider client={client}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)
// })

if (module.hot) {
  module.hot.accept()
}
