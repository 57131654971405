import Box from '@parishconnect/box'
import { Button, CalendarIcon, FileTextIcon, majorScale } from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { GroupQuery } from '../../graphql/generated/graphql-hooks'
import { AbilityContext } from '../../utils/abilityContext'

export default function SingleGroupActions({ group, parish }: GroupQuery) {
  const ability = useContext(AbilityContext)
  const canCreatePost = ability.can('create', { __typename: 'Post', group, parish })
  const canCreateEvent = ability.can('create', { __typename: 'Event', group, parish })

  if (!canCreateEvent && !canCreatePost) {
    return null
  }

  return (
    <Box
      display="inline-grid"
      gridGap={majorScale(1)}
      gridAutoFlow="column"
      marginBottom={majorScale(2)}
    >
      {canCreatePost && (
        <Button
          round
          is={Link}
          to={{ pathname: '/post/new', state: { group: group?.id } }}
          iconBefore={FileTextIcon}
        >
          New Post
        </Button>
      )}
      {canCreateEvent && (
        <Button
          round
          is={Link}
          to={{ pathname: '/event/new', state: { group: group?.id } }}
          iconBefore={CalendarIcon}
        >
          New Event
        </Button>
      )}
    </Box>
  )
}
