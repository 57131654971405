import Box from '@parishconnect/box'
import {
  Alert,
  Button,
  Card,
  Dialog,
  Edit2Icon,
  Heading,
  IconButton,
  majorScale,
  PlusSquareIcon,
  SaveIcon,
  SelectField,
  Text,
  Textarea,
  TextInputField,
  toaster,
  Trash2Icon,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { object, string } from 'yup'
import {
  DonationMethod,
  Donation_Methods,
  MutationAddDonationMethodArgs,
  useAddDonationMethodMutation,
  useEditDonationMethodMutation,
  useParishQuery,
  useRemoveDonationMethodMutation,
} from '../../graphql/generated/graphql-hooks'

export const DonationMethodDictionary = {
  TORONTO: {
    name: 'Archdiocese of Toronto',
  },
  CUSTOM: { name: 'Custom' },
}

export function DonationsManager() {
  const { data } = useParishQuery()

  const donationMethods = data?.parish?.donationMethods ?? []

  return (
    <Box width="100%" maxWidth={majorScale(65)}>
      <Heading marginY={[majorScale(4), majorScale(2)]} size={800}>
        Donations
      </Heading>
      {donationMethods.length > 0 ? (
        <AnimatePresence initial={false}>
          {data?.parish?.donationMethods.map((donationMethod) => (
            <DonationMethodCard key={donationMethod.id} {...donationMethod} />
          ))}
        </AnimatePresence>
      ) : (
        <>
          <Card
            appearance="solid"
            padding={majorScale(2)}
            marginTop={majorScale(3)}
            border="muted"
            maxWidth={majorScale(70)}
          >
            <Heading color="theme" marginBottom={majorScale(2)} size={500}>
              Get Started
            </Heading>
            Add a method below to setup donations through your diocese donation portal
          </Card>
        </>
      )}
      <DonationMethodForm mode="new" />
    </Box>
  )
}

function DonationMethodCard(donationMethod: DonationMethod) {
  const [removeDonationMethod] = useRemoveDonationMethodMutation({
    variables: { id: donationMethod.id },
  })
  const [deleteDialogShowing, showDeleteDialog] = useState(false)
  const [editing, setEditing] = useState(false)

  return editing ? (
    <DonationMethodForm
      mode="edit"
      donationMethod={donationMethod}
      close={() => setEditing(false)}
    />
  ) : (
    <Card
      key={donationMethod.id}
      is={motion.div}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      appearance="solid"
      padding={majorScale(2)}
      marginTop={majorScale(3)}
      border="muted"
      width="100%"
    >
      <Heading color="theme" size={400}>
        {donationMethod?.title || DonationMethodDictionary[donationMethod.method].name}
      </Heading>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap="5px">
        <Text flexBasis="85%">{donationMethod.url}</Text>
        <IconButton icon={Edit2Icon} onClick={() => setEditing(true)}>
          Delete
        </IconButton>
        <IconButton intent="danger" icon={Trash2Icon} onClick={() => showDeleteDialog(true)}>
          Delete
        </IconButton>
      </Box>
      {donationMethod.note && (
        <Alert title="Note" appearance="card" marginTop={majorScale(2)}>
          {donationMethod.note}
        </Alert>
      )}
      <Dialog
        title="Delete Donation Method"
        isShown={deleteDialogShowing}
        onCloseComplete={() => showDeleteDialog(false)}
        onConfirm={removeDonationMethod}
        intent="danger"
        confirmLabel="Delete"
      >
        Are you sure you want to delete this donation method? This action cannot be undone.
      </Dialog>
    </Card>
  )
}

const addDonationMethodSchema = object().shape<MutationAddDonationMethodArgs['donationMethod']>({
  method: string().oneOf(Object.values(Donation_Methods)).required(),
  note: string().max(512),
  title: string().max(64),
  url: string().required(),
})

function DonationMethodForm({
  mode = 'new',
  donationMethod,
  close,
}: {
  mode: 'new' | 'edit'
  donationMethod?: DonationMethod
  close?: () => void
}) {
  const [addDonationMethod] = useAddDonationMethodMutation()
  const [editDonationMethod] = useEditDonationMethodMutation()

  return (
    <Card
      appearance={mode === 'new' ? 'solid' : 'white'}
      padding={majorScale(2)}
      marginTop={majorScale(3)}
      border="muted"
      width="100%"
      elevation={mode === 'new' ? undefined : 3}
    >
      <Heading color="theme" marginBottom={majorScale(2)} size={500}>
        {mode === 'new' ? 'Add A Donation Method' : 'Edit Donation Method'}
      </Heading>
      <Formik<MutationAddDonationMethodArgs['donationMethod']>
        validationSchema={addDonationMethodSchema}
        initialValues={{
          method: Donation_Methods.Toronto,
          ...donationMethod,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (mode === 'new') {
              await addDonationMethod({ variables: { donationMethod: values } })
            } else {
              const { note, title, url } = values
              await editDonationMethod({
                variables: { donationMethod: { note, title, url }, id: donationMethod.id },
              })
              toaster.success('Donation method updated')
              close?.()
            }
          } catch (error) {
            console.log(error)
            Sentry.captureEvent(error)
          }
          setSubmitting(false)
        }}
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" width="100%">
                <SelectField
                  marginBottom={majorScale(2)}
                  name="method"
                  label="Method"
                  inputWidth="100%"
                  flexBasis="35%"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setFieldValue('method', e.currentTarget.value)
                  }}
                  value={values.method}
                >
                  {Object.values(Donation_Methods).map((method) => (
                    <option key={method} value={method}>
                      {DonationMethodDictionary[method].name}
                    </option>
                  ))}
                </SelectField>
                <TextInputField
                  flexBasis="65%"
                  marginLeft={majorScale(1)}
                  is={Field}
                  name="url"
                  label="URL"
                  placeholder="example.com/donate"
                  css={{ backgroundColor: 'rgba(67,90,111,0.09)' }}
                />
              </Box>
            </Box>
            <TextInputField
              is={Field}
              name="title"
              label="Title"
              placeholder="Add an (optional) title"
              css={{ backgroundColor: 'rgba(67,90,111,0.09)' }}
            />
            <TextInputField
              width="100%"
              is={Textarea}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setFieldValue('note', e.currentTarget.value)
              }
              value={values.note}
              name="note"
              label="Note"
              maxLength="512"
              placeholder="Add an (optional) brief note for your parishioners."
              css={{
                backgroundColor: 'rgba(67,90,111,0.09)',
                resize: 'none',
                boxShadow: 'none',
              }}
            />
            <Button
              type="Submit"
              whiteSpace="nowrap"
              iconBefore={mode === 'new' ? PlusSquareIcon : SaveIcon}
              intent="success"
              appearance="primary"
              marginLeft="auto"
            >
              {mode === 'new' ? 'Add Method' : 'Save Method'}
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
