import Box from '@parishconnect/box'
import {
  Button,
  majorScale,
  minorScale,
  Pane,
  Text,
  ThemeContext,
  Stack,
  StackingOrder,
} from '@parishconnect/react-ui'
import { motion, useAnimation, useMotionValue } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import useRouter from 'use-react-router'
import { NavProps } from './Nav'
import { MENU_ITEMS, NavMenuProps } from './NavMenu'

export function MobileNav({
  subMenu,
  root,
  firstAbout,
  firstSacrament,
  drawer = true,
}: NavProps &
  Pick<NavMenuProps, 'firstAbout' | 'firstSacrament'> & {
    drawer?: boolean
  }) {
  const theme = useContext(ThemeContext)
  const subMenuRef = useRef<HTMLDivElement>(null)
  const { location } = useRouter()
  const horizontalMargin = majorScale(2)

  useEffect(() => {
    const links = subMenuRef.current?.querySelectorAll('a')
    links?.forEach((link) => {
      if (link.getAttribute('href') === location.pathname) {
        link.scrollIntoView({ behavior: 'smooth', inline: 'center' })
      }
    })
  }, [location])

  return (
    <Stack value={StackingOrder.POSITIONER}>
      {(zIndex: number) => (
        <Box
          is="nav"
          aria-labelledby="mobile"
          position="fixed"
          bottom={0}
          left={0}
          zIndex={zIndex + 100}
          width="100%"
          pointerEvents="none"
          display={['block', null, 'none']}
        >
          <span className="sr-only" id="mobile">
            Site Navigation
          </span>
          {!!subMenu && (
            <Pane
              pointerEvents="all"
              borderTop
              appearance="solid"
              borderTopLeftRadius={majorScale(2)}
              borderTopRightRadius={majorScale(2)}
              position="relative"
              transition={{ duration: 0.25 }}
            >
              <Box>
                <Pane
                  innerRef={subMenuRef}
                  overflowY="hidden"
                  paddingX={horizontalMargin}
                  paddingY={majorScale(2)}
                  display="flex"
                  css={{
                    msOverflowStyle: 'none',
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                  }}
                >
                  {subMenu?.map(({ title, to, id, exact }, i) => {
                    return (
                      <Button
                        key={id}
                        is={NavLink}
                        to={root + '/' + to}
                        exact={exact}
                        marginLeft={i !== 0 && majorScale(1)}
                        css={{
                          '&.active': {
                            background: theme.getThemeColor(theme),
                            color: 'white',
                          },
                          whiteSpace: 'pre',
                          '&:after': i === subMenu.length - 1 && {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            right: -majorScale(2),
                            width: majorScale(2),
                            height: 1,
                          },
                        }}
                      >
                        {title}
                      </Button>
                    )
                  })}
                </Pane>
              </Box>
            </Pane>
          )}
          <Pane
            pointerEvents="all"
            borderTop="muted"
            display="flex"
            justifyContent="space-between"
            paddingX={majorScale(1)}
            appearance="white"
            paddingBottom="env(safe-area-inset-bottom)"
          >
            {MENU_ITEMS({ firstAbout, firstSacrament }).map(
              ({ exact, icon: Icon, text, to, mobile = true }) =>
                mobile ? (
                  <Box
                    key={to}
                    is={NavLink}
                    exact={exact}
                    to={to}
                    width={100 / 5 + '%'}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    paddingY={minorScale(2)}
                    css={{
                      color: theme.scales.neutral.N8,
                      '&.active': {
                        color: theme.getThemeColor(theme),
                      },
                    }}
                  >
                    <Icon size={24} />
                    <Text marginTop={minorScale(1)} size={300} fontWeight={600} color="inherit">
                      {text}
                    </Text>
                  </Box>
                ) : null,
            )}
          </Pane>
        </Box>
      )}
    </Stack>
  )
}
