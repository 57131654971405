import { sortBy } from 'lodash-es'
import { DateTime, Interval } from 'luxon'
import { ComputedMass, hydrateMasses } from '../components/Mass/utils'
import { Mass } from '../graphql/generated/graphql-hooks'

export default function getTodaysMasses(masses: Mass[], limit = 3): ComputedMass[] | null {
  const computedMasses = hydrateMasses(masses) ?? []

  const next24Hours = Interval.fromDateTimes(
    DateTime.local(),
    DateTime.local()
      .endOf('day')
      .plus({ day: 3 }),
  )

  return sortBy(
    computedMasses.filter(mass => next24Hours.contains(mass.nextDate)),
    mass => mass.nextDate.toMillis(),
  ).slice(0, limit)
}
