import Box from '@parishconnect/box'
import { User, UserQuery } from '../../graphql/generated/graphql-hooks'
import {
  Button,
  KeyIcon,
  majorScale,
  SettingsIcon,
  UserIcon,
  BriefcaseIcon,
} from '@parishconnect/react-ui'
import React from 'react'
import { NavLink } from 'react-router-dom'
import useRouter from 'use-react-router'

type UserPreferencesMenuProps = {
  user: UserQuery['user']
  parish: UserQuery['parish']
}

export default function UserPreferencesMenu({ user, parish }: UserPreferencesMenuProps) {
  const {
    location: { pathname },
  } = useRouter()

  const parishRole = user.roles.find((role) => role.id === parish.id)

  let menuItems = [
    {
      text: 'Profile',
      to: `/user/${user?.id}`,
      icon: UserIcon,
    },
    {
      text: 'Preferences',
      to: '/user/preferences',
      icon: SettingsIcon,
    },
    {
      text: 'Password',
      to: '/user/password',
      icon: KeyIcon,
    },
  ]

  if (parishRole?.staff) {
    menuItems = [
      ...menuItems,
      {
        text: 'Staff Profile',
        to: '/user/staff-profile',
        icon: BriefcaseIcon,
      },
    ]
  }

  return (
    <Box display="flex" flexDirection="column" marginTop={majorScale(5)}>
      {menuItems.map((item) => (
        <Button
          is={NavLink}
          to={item.to}
          appearance={pathname === item.to ? 'primary' : 'minimal'}
          height={majorScale(5)}
          marginBottom={majorScale(1)}
          iconBefore={item?.icon}
          key={item?.text}
        >
          {item?.text}
        </Button>
      ))}
    </Box>
  )
}
