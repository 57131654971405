import React from 'react'
import * as Sentry from '@sentry/browser'
import Box from '@parishconnect/box'
import {
  Heading,
  majorScale,
  Button,
  SaveIcon,
  minorScale,
  toaster,
  StackingOrder,
} from '@parishconnect/react-ui'
import { ParishQuery, useEditParishMutation } from '../../graphql/generated/graphql-hooks'
import { FeaturedImageUpload } from '../Image/FeaturedImageUpload'
import { Formik, Form } from 'formik'
import { COLUMN } from '../../utils'

export function HeroSelector({ image }: ParishQuery['parish']) {
  const [editParish, { loading }] = useEditParishMutation()
  return (
    <Box position="relative">
      <Heading marginY={majorScale(2)} size={500}>
        Hero Image
      </Heading>
      <Formik
        enableReinitialize
        onSubmit={async ({ image }, actions) => {
          try {
            await editParish({ variables: { parish: { image } } })
            actions.setSubmitting(false)
          } catch (error) {
            toaster.danger('Error saving Parish Image')
            Sentry.captureException(error)
          }
        }}
        initialValues={{ image: image?.id }}
      >
        {({ dirty, values, setFieldValue, submitForm }) => (
          <Form>
            <FeaturedImageUpload
              aspectRatio={0.5625}
              onRemove={() => {
                setFieldValue('image', null)
              }}
              allowReposition={false}
              width={COLUMN * 10}
              height={COLUMN * 3.5}
              initialFile={values.image}
              onUpload={({ id }) => {
                setFieldValue('image', id)
              }}
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              position="absolute"
              right={majorScale(1)}
              bottom={minorScale(3)}
              zIndex={StackingOrder.POSITIONER}
            >
              <Button
                iconBefore={SaveIcon}
                round
                appearance="primary"
                intent="success"
                disabled={!dirty}
                onClick={submitForm}
                isLoading={loading}
              >
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
