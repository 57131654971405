import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import {
  Badge,
  Button,
  Card,
  CornerUpLeftIcon,
  FormField,
  Heading,
  InlineDatePicker,
  majorScale,
  Menu,
  Pane,
  Paragraph,
  PlusIcon,
  PositionEnum,
  RepeatIcon,
  SaveIcon,
  SegmentedControl,
  Text,
  TextInputField,
  ThemeContext,
  TimePicker,
  XCircleIcon,
} from '@parishconnect/react-ui'
import { Field, Formik } from 'formik'
import { capitalize, omit } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useContext, useState } from 'react'
import {
  Exception,
  ExceptionData,
  ExceptionDateInput,
  ExceptionType,
  useAddMassExceptionMutation,
  useDeleteMassExceptionMutation,
} from '../../graphql/generated/graphql-hooks'
import { Dates } from '../../utils'
import { ComputedMass, getRelevantDateTimeParts, getRelevantPreposition } from './utils'

export function MassExceptionManager(mass: ComputedMass) {
  const [selectedItem, setSelectedItem] = useState(mass.exdates?.[0]?.id ?? 'new')
  const theme = useContext(ThemeContext)

  return (
    <Box display="flex" height="100%" zIndex={100} position="relative">
      <Pane
        height="100%"
        appearance="solid"
        flexBasis="45%"
        borderRight
        paddingBottom={majorScale(2)}
        minWidth={majorScale(25)}
      >
        <Menu css={{ height: '100%', display: 'block', paddingBottom: 'auto' }}>
          <Menu.Group title="Exceptions">
            {mass.exdates?.map((exDate) => (
              <Menu.Item
                background={
                  selectedItem === exDate.id && theme.fills.subtle[theme.themeColor].background
                }
                icon={exDate.date.data.exType === ExceptionType.Cancel ? XCircleIcon : RepeatIcon}
                key={exDate.id}
                onSelect={() => setSelectedItem(exDate.id)}
                secondaryText={getPastOfExType(exDate.date.data.exType as ExceptionType)}
              >{`${DateTime.fromObject(
                omit(exDate.date.dates[0], ['__typename', 'timezone']),
              ).toLocaleString(DateTime.DATE_MED)}`}</Menu.Item>
            ))}
            <Menu.Item
              onSelect={() => setSelectedItem('new')}
              background={selectedItem === 'new' && theme.fills.subtle[theme.themeColor].background}
              icon={PlusIcon}
            >
              New Exception
            </Menu.Item>
          </Menu.Group>
        </Menu>
      </Pane>
      <Box padding={majorScale(3)} flexBasis="55%">
        {selectedItem === 'new' ? (
          <NewException {...mass} setSelectedItem={setSelectedItem} />
        ) : (
          <EditException {...mass} setSelectedItem={setSelectedItem} exId={selectedItem} />
        )}
      </Box>
    </Box>
  )
}

function getPastOfExType(exType: ExceptionType) {
  switch (exType) {
    case ExceptionType.Cancel:
      return 'Cancelled'
    case ExceptionType.Modify:
      return 'Modified'
    default:
      return ''
  }
}

function EditException({
  exdates,
  exId,
  id,
  setSelectedItem,
}: ComputedMass & { exId: string; setSelectedItem: (id: string) => void }) {
  const [deleteMassException, { loading }] = useDeleteMassExceptionMutation({
    variables: { parent: id, id: exId },
    update: (_, { data: { deleteMassException } }) =>
      setSelectedItem(deleteMassException.exdates?.[0]?.id || 'new'),
  })
  const exception = exdates.find((exDate) => exDate.id === exId)

  let exceptionInstance
  try {
    exceptionInstance = Dates.fromJSON<ExceptionData>(exception.date as Dates.JSON)
  } catch (error) {
    Sentry.captureException(error)
  }

  if (!exceptionInstance) return <Text>Data is malformed</Text>

  const exceptionAdapter = exceptionInstance.occurrences({ take: 1 }).toArray()[0]
  const exceptionDate = exceptionAdapter.date
  const exceptionData = exceptionAdapter.generators?.[0]?.data

  let newDate
  let modifiedFormat
  try {
    newDate = DateTime.fromISO(exceptionData?.newDate).setZone(exceptionDate.zone)
    modifiedFormat = getRelevantDateTimeParts(exceptionDate, newDate)
  } catch (error) {
    Sentry.captureException(error)
  }

  return (
    <Box>
      <Heading size={700}>{exceptionDate.toLocaleString(DateTime.DATETIME_MED)}</Heading>
      <Badge color={exception.date.data.exType === ExceptionType.Cancel ? 'red' : 'orange'}>
        {getPastOfExType(exception.date.data.exType as ExceptionType)}
      </Badge>
      {exception.date.data.exType === ExceptionType.Cancel ? (
        <Box marginY={majorScale(3)}>
          <Paragraph>
            The Mass that would have been on {exceptionDate.toLocaleString(DateTime.DATETIME_MED)}{' '}
            has been cancelled
          </Paragraph>
          <Button
            intent="warning"
            marginTop={majorScale(2)}
            onClick={deleteMassException}
            isLoading={loading}
            iconBefore={CornerUpLeftIcon}
          >
            Undo Cancellation
          </Button>
        </Box>
      ) : (
        <Box marginY={majorScale(3)}>
          <Paragraph>
            The Mass that would have been {getRelevantPreposition(modifiedFormat)}{' '}
            {exceptionDate.toLocaleString(getRelevantDateTimeParts(exceptionDate, newDate))} will be{' '}
            {getRelevantPreposition(modifiedFormat)}{' '}
            {newDate instanceof DateTime
              ? newDate.toLocaleString(getRelevantDateTimeParts(exceptionDate, newDate))
              : ''}
          </Paragraph>
          <Button
            intent="warning"
            marginTop={majorScale(2)}
            onClick={deleteMassException}
            isLoading={loading}
            iconBefore={CornerUpLeftIcon}
          >
            Undo Modification
          </Button>
        </Box>
      )}
    </Box>
  )
}

type ExceptionFormValues = {
  date: DateTime
  data: Exception['date']['data']
}

function NewException({
  setSelectedItem,
  ...mass
}: ComputedMass & { setSelectedItem: (id: string) => void }) {
  const [addMassException, { loading }] = useAddMassExceptionMutation()
  const currentExceptions = mass.exdates?.map((exDate) =>
    DateTime.fromObject(omit(exDate.date.dates[0], ['__typename', 'timezone']))
      .startOf('day')
      .toMillis(),
  )
  const dateArray = mass.computedSchedule
    .occurrences({
      start: DateTime.local(),
      end: DateTime.local().endOf('month').plus({ year: 1 }),
    })
    .toArray()
    .map((val) => val.date.startOf('day').toMillis())
    .filter((date) => !currentExceptions.includes(date))

  return (
    <>
      <Heading size={700} marginBottom={majorScale(3)}>
        New Exception
      </Heading>
      <Formik<ExceptionFormValues>
        onSubmit={async (values, actions) => {
          try {
            const timezone = DateTime.local().zoneName
            await addMassException({
              variables: {
                id: mass.id,
                exception: {
                  date: new Dates({
                    timezone,
                    dates: [
                      mass.nextDate
                        .set({
                          day: values.date.day,
                          month: values.date.month,
                          year: values.date.year,
                        })
                        .setZone(timezone),
                    ],
                    data: values.data,
                  }).toJSON({ data: true }) as ExceptionDateInput,
                },
              },
              update: (
                _,
                {
                  data: {
                    addMassException: { exdates },
                  },
                },
              ) => {
                setSelectedItem(exdates[exdates.length - 1].id)
              },
            })
            actions.setSubmitting(false)
          } catch (error) {
            Sentry.captureException(error)
          }
        }}
        initialValues={{
          date: DateTime.fromMillis(dateArray?.[0]),
          data: {
            exType: ExceptionType.Cancel,
            reason: undefined,
            newDate: undefined,
          },
        }}
      >
        {({ setFieldValue, values, submitForm }) => (
          <Box maxWidth={majorScale(35)}>
            <FormField description={`Select an upcoming ${mass.nextDate.weekdayLong}`}>
              <Card appearance="solid" display="inline-block">
                <InlineDatePicker
                  shouldShowTodayButton={false}
                  shouldShowYearButtons={false}
                  disableDates={(date) =>
                    !dateArray.includes(DateTime.fromJSDate(date).startOf('day').toMillis())
                  }
                  required
                  onChange={(date: Date) =>
                    setFieldValue('date', DateTime.fromJSDate(date).startOf('minute'))
                  }
                  value={values.date.toJSDate()}
                />
              </Card>
            </FormField>
            <FormField label="Exception Type" marginTop={majorScale(3)}>
              <SegmentedControl
                marginLeft={majorScale(1)}
                marginTop={majorScale(1)}
                value={values.data.exType}
                onChange={(value: ExceptionType) => setFieldValue('data.exType', value)}
                options={Object.values(ExceptionType).map((exType) => ({
                  label: capitalize(exType),
                  value: exType,
                }))}
                name="data.exType"
                width={majorScale(32)}
              />
            </FormField>
            {values.data.exType === ExceptionType.Modify && (
              <TimePicker
                required
                onChange={(date: Date) => setFieldValue('data.newDate', date)}
                value={values.data.newDate ?? mass.nextDate.toJSDate()}
                label="New Date/Time"
                useAmPm
                //@ts-ignore because it is a real prop
                marginTop={majorScale(2)}
                position={PositionEnum.BOTTOM_LEFT}
                // disableDates={date => DateTime.fromJSDate(date).endOf('day') < mass.nextDate}
                shouldShowTodayButton={false}
                shouldShowYearButtons={false}
                showArrowButtons
                description="Pick a new date/time for this mass"
              />
            )}
            <TextInputField
              width="100%"
              marginTop={majorScale(2)}
              is={Field}
              name="data.reason"
              maxLength="28"
              label="Reason"
              placeholder="Provide a reason for the exception"
              hint="Optional"
            />
            <Box display="flex" width="100%">
              <Button
                appearance="primary"
                intent="success"
                type="submit"
                isLoading={loading}
                iconBefore={SaveIcon}
                onClick={submitForm}
                marginLeft="auto"
              >
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  )
}
