import {
  Button,
  Dialog,
  Edit2Icon,
  majorScale,
  TextInputField,
  DatePicker,
  PositionEnum,
  FormField,
} from '@parishconnect/react-ui'
import React, { useState } from 'react'
import {
  Season,
  SeasonInput,
  useUpdateSeasonMutation,
  Mass,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import { DateTime, Duration, Interval } from 'luxon'
import { omitBy, isNil, sortBy } from 'lodash-es'
import { ComputedMass } from './utils'
import produce from 'immer'

const seasonValidationSchema = new yup.object().shape({
  name: yup
    .string()
    .max(32)
    .required(),
  from: yup
    .date()
    .min(
      DateTime.local()
        .startOf('day')
        .toJSDate(),
    )
    .max(yup.ref('until')),
  until: yup.date().min(yup.ref('from')),
})

function getDateTimeEdge(
  first: DateTime,
  second = DateTime.local().startOf('day'),
  edge: 'start' | 'end' = 'start',
): DateTime {
  if (first < second) {
    return Interval.fromDateTimes(first, second)[edge]
  } else {
    return Interval.fromDateTimes(second, first)[edge]
  }
}

export function SeasonEdit({ masses, name, from, until, id }: Season & { masses: ComputedMass[] }) {
  const [isShown, setShown] = useState(false)
  const [updateSeason, { loading }] = useUpdateSeasonMutation()

  return (
    <>
      <Button
        iconBefore={Edit2Icon}
        height={majorScale(3)}
        round
        appearance="minimal"
        marginLeft={majorScale(1)}
        onClick={() => setShown(true)}
      >
        Edit
      </Button>
      {isShown && (
        <Formik<SeasonInput>
          validationSchema={seasonValidationSchema}
          onSubmit={async (values, actions) => {
            try {
              await updateSeason({ variables: { season: omitBy(values, isNil), id } })
              actions.setSubmitting(false)
              actions.resetForm()
              setShown(false)
            } catch (error) {
              actions.setErrors(error)
            }
          }}
          initialValues={{
            name,
            from: from
              ? DateTime.fromISO(from).toJSDate()
              : getDateTimeEdge(masses?.[0]?.nextDate).toJSDate(),
            until: until
              ? DateTime.fromISO(until).toJSDate()
              : masses?.[masses.length - 1]?.nextDate.toJSDate(),
          }}
        >
          {({ values, submitForm, setFieldValue }) => (
            <Dialog
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEscapePress={false}
              width={COLUMN * 6}
              isShown
              onCloseComplete={() => setShown(false)}
              confirmLabel="Save"
              intent="success"
              title={`Edit ${name} Season`}
              isConfirmLoading={loading}
              onConfirm={submitForm}
            >
              <TextInputField is={Field} name="name" label="Name" />
              <FormField
                label="Schedule Start"
                description="We'll show the schedule starting on this date."
                marginBottom={majorScale(3)}
              >
                <DatePicker
                  disableDates={date =>
                    date <
                    DateTime.local()
                      .startOf('day')
                      .toJSDate()
                  }
                  onChange={(date: Date) => setFieldValue('from', date)}
                  useAmPm
                  showArrowButtons
                  shouldShowTodayButton={false}
                  shouldShowYearButtons={false}
                  position={PositionEnum.RIGHT}
                  width="100%"
                  value={values.from}
                />
              </FormField>
              <FormField
                label="Schedule End"
                description="We'll show the schedule until this date."
              >
                <DatePicker
                  disableDates={date =>
                    date <=
                    DateTime.fromJSDate(values.from)
                      .startOf('day')
                      .toJSDate()
                  }
                  onChange={(date: Date) => setFieldValue('until', date)}
                  useAmPm
                  showArrowButtons
                  shouldShowTodayButton={false}
                  shouldShowYearButtons={false}
                  position={PositionEnum.RIGHT}
                  width="100%"
                  value={values.until}
                />
              </FormField>
            </Dialog>
          )}
        </Formik>
      )}
    </>
  )
}

function extractMassesFromSeasonMap(
  seasonMap: Map<number, Map<string, ComputedMass[]>>,
): ComputedMass[] {
  return sortBy(
    Object.values(Object.fromEntries(seasonMap))
      .reduce((acc, curr) => {
        return produce(acc, draft => {
          draft.push(Object.values(Object.fromEntries(curr)))
        })
      }, [])
      .flat(2),
    'start',
  )
}
