import Box from '@parishconnect/box'
import { Heading, majorScale, Strong } from '@parishconnect/react-ui'
import { DateTime } from 'luxon'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Post, PostQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN, COLUMNS } from '../../utils/constants'
import { PrivacyText } from '../Group/SingleGroupImage'

const SinglePostInfo = ({
  title,
  author,
  parish,
  group,
  updatedAt,
  createdAt,
  privacy,
}: PostQuery['post']) => (
  <Box paddingX={[majorScale(1), null, majorScale(3)]} paddingTop={[majorScale(2), COLUMN / 2]}>
    <Heading
      is="h1"
      maxWidth={900}
      size={900}
      fontSize={['1.7rem', null, '2.3rem']}
      lineHeight={['1.15', null, '1.3']}
      wordBreak="break-word"
      paddingBottom={majorScale(2)}
      css={{
        hyphens: 'auto',
      }}
    >
      {title}
    </Heading>

    {author?.fullName && (
      <Box paddingBottom={majorScale(1)} display="flex" flexWrap="wrap">
        {author?.fullName && (
          <Fragment>
            <Strong is={Link} to={`/user/${author?.id}`}>
              {author?.fullName}
            </Strong>
            <Strong marginX={majorScale(1)}>•</Strong>
          </Fragment>
        )}

        {(group?.name || parish?.name) && (
          <Link to={group ? `/group/${group.id}` : `/`}>
            <Strong color={parish?.theme}>{group?.name || parish?.name}</Strong>
            <Strong marginX={majorScale(1)}>•</Strong>
          </Link>
        )}

        <Strong>
          {DateTime.fromISO(createdAt ?? updatedAt).toLocaleString(DateTime.DATE_FULL)}
        </Strong>
        <Strong marginX={majorScale(1)}>•</Strong>
        <PrivacyText privacy={privacy} />
      </Box>
    )}
  </Box>
)

export default SinglePostInfo
