import Box from '@parishconnect/box'
import { ThemeContext, Card, Heading, majorScale } from '@parishconnect/react-ui'
import { truncate } from 'lodash-es'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import ProgressiveImage from 'react-progressive-image'
import { RouteComponentProps } from 'react-router'
import { AboutsQuery } from '../../graphql/generated/graphql-hooks'
import { GenericAboutBody } from './GenericAboutBody'

export const SingleAbout = ({
  match,
  abouts,
  parish,
}: AboutsQuery & RouteComponentProps<{ id: string }>) => {
  const about = abouts.find((about) => about.id === match.params.id)
  const theme = useContext(ThemeContext)

  return (
    <Box is="main">
      <Box position="relative">
        <Helmet>
          <title>{about?.title ?? 'About'}</title>
        </Helmet>
        <Box
          position="relative"
          height={0}
          paddingBottom="calc(100% / 3)"
          borderRadius={8}
          width="100%"
          overflow="hidden"
        >
          {parish?.image?.src ? (
            <ProgressiveImage src={parish?.image?.src} placeholder={parish?.image?.lqip}>
              {(src: string) => (
                <Card
                  role="presentation"
                  alt=""
                  is="img"
                  position="absolute"
                  width="100%"
                  height="100%"
                  css={{ objectFit: 'cover' }}
                  srcSet={parish?.image?.srcset}
                  src={src}
                />
              )}
            </ProgressiveImage>
          ) : (
            <Card
              appearance="solid"
              height={[majorScale(16), majorScale(26), null, majorScale(38)]}
              width="100%"
            />
          )}
        </Box>
        <Card
          appearance="solid"
          paddingX={majorScale(3)}
          paddingY={majorScale(2)}
          position={['static', 'absolute']}
          marginTop={[majorScale(1), 0]}
          borderRadius={14}
          bottom={majorScale(2)}
          left={majorScale(2)}
        >
          <Heading color="theme" size={800} is="h1">
            {truncate(about?.title, { length: 28 }) ?? 'About'}
          </Heading>
          <Heading color={theme.palette[theme.themeColor].text} size={100}>
            {parish?.name}
          </Heading>
        </Card>
      </Box>
      <Box paddingX={[majorScale(1), majorScale(3)]} marginTop={majorScale(3)} id="main-content">
        <GenericAboutBody about={about} parish={parish} />
      </Box>
    </Box>
  )
}
