import { css, Global } from '@emotion/core'
import { EDITOR_CLASS_SELECTOR } from '@remirror/core'
import { useContext } from 'react'
import React from 'react'
import { ThemeContext, ThemeType, majorScale } from '@parishconnect/react-ui'
import Color from 'color'
import { EMPTY_NODE_CLASS_NAME } from '@remirror/core-extensions'

export default function EditorStyles() {
  const theme: ThemeType = useContext(ThemeContext)
  return (
    <Global
      styles={css({
        [`.${EMPTY_NODE_CLASS_NAME}:first-of-type::before`]: {
          //Placeholder
          color: '#aaa',
          position: 'absolute',
          height: 0,
          pointerEvents: 'none',
          letterSpacing: '0.5px',
          content: 'attr(data-placeholder)',
        },
        [EDITOR_CLASS_SELECTOR]: {
          margin: '0 auto',
          marginBottom: 12,
          outline: 'none',
          color: theme.getTextColor('default', theme),
          fontFamily: theme.getFontFamily('display'),
          ...theme.getTextStyle(400),
          wordBreak: 'break-word',
          // Strong
          strong: {
            fontWeight: 'bold',
          },
          ':not(.ProseMirror-focused) .selected': {
            background: 'Highlight',
            color: 'HighlightText',
          },
          // Image
          img: {
            maxWidth: '100%',
            borderRadius: majorScale(1),
          },
          //Horizontal Rule
          hr: {
            margin: `${majorScale(4)}px auto`,
            maxWidth: majorScale(50),
            appearance: 'none',
            border: `1px solid ${theme.scales.neutral.N4}`,
            '&.ProseMirror-selectednode': {
              borderColor: Color(theme.getThemeColor(theme)).lighten(0.5).toString(),
            },
          },
          // Heading
          h1: theme.typography.headings[700],
          h2: theme.typography.headings[500],
          // Paragraph text
          'p, div': {
            ...theme.typography.text[400],
            clear: 'none',
          },
          'p > br': { lineHeight: 0 },
          'p:empty': {
            height: majorScale(1),
          },
          'ol li': {
            fontWeight: 'bold',
            '& > *:not(strong):not(u):not(em)': {
              fontWeight: 'normal',
            },
          },
          // Quotes
          blockquote: {
            maxWidth: majorScale(72),
            margin: `${majorScale(5)}px auto`,
            padding: `${majorScale(2)}px`,
            boxSizing: 'border-box',
            position: 'relative',
            border: `1px solid ${Color(theme.getThemeColor(theme)).lighten(0.5).toString()}`,
            borderWidth: `2px 0`,
            textAlign: 'center',
            'p, div': {
              margin: 0,
              color: theme.scales.neutral.N8,
              fontFamily: 'Georgia',
              fontStyle: 'italic',
              fontSize: '1.15rem',
              lineHeight: 1.5,
            },
          },
          // Links
          a: {
            cursor: 'pointer',
            color: theme.palette[theme.themeColor].text,
            textDecoration: 'underline',
            transition: '125ms',
            '&:hover': {
              color: Color(theme.getThemeColor(theme)).lighten(0.25).toString(),
            },
            '&:active': {
              color: Color(theme.getThemeColor(theme)).darken(10).toString(),
            },
            '&:focus': {
              boxShadow: `0 0 0 2px ${Color(theme.getThemeColor(theme)).alpha(0.4).toString()}`,
            },
          },
        },
      })}
    />
  )
}
