import Box from '@parishconnect/box'
import {
  Badge,
  Button,
  Card,
  CheckIcon,
  ExternalLinkIcon,
  Heading,
  IconButton,
  majorScale,
  Menu,
  MoreHorizontalIcon,
  Popover,
  Position,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Text,
  TextTableCell,
  TextTableHeaderCell,
  XIcon,
  ZapIcon,
  ZapOffIcon,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { DateTime } from 'luxon'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  GroupsQuery,
  GroupStatus,
  useEditGroupMutation,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils'
import { EmptyState } from '../shared/EmptyState'

function renderStatus(status: GroupStatus) {
  const color = {
    [GroupStatus.Enabled]: 'green',
    [GroupStatus.Disabled]: 'red',
    [GroupStatus.Pending]: 'yellow',
  }

  return <Badge color={color[status]}>{status}</Badge>
}

export function GroupsManager({ groups = [], loading }: GroupsQuery & { loading: boolean }) {
  if (groups.length <= 0 && !loading) {
    return <EmptyState>Your parish has no groups pending approval</EmptyState>
  }

  return (
    <Box>
      <Heading is="h1" marginTop={[majorScale(4), majorScale(2)]} size={800}>
        Groups
      </Heading>
      <Box marginTop={majorScale(4)}>
        <PendingGroups groups={groups.filter((group) => group.status === GroupStatus.Pending)} />
        <OtherGroups
          groups={groups.filter((group) =>
            [GroupStatus.Enabled, GroupStatus.Disabled].includes(group.status),
          )}
        />
      </Box>
    </Box>
  )
}

function OtherGroups({ groups }: Pick<GroupsQuery, 'groups'>) {
  const [editGroup] = useEditGroupMutation()
  if (groups.length <= 0) {
    return null
  }

  return (
    <Box>
      <Heading marginY={majorScale(2)} is="h3">
        Enabled
      </Heading>
      <Table width="100vw" maxWidth={COLUMN * 16}>
        <TableHead>
          <TextTableHeaderCell flexBasis="35%">Name</TextTableHeaderCell>
          <TextTableHeaderCell flexBasis="35%">Owner</TextTableHeaderCell>
          <TextTableHeaderCell flexBasis="10%">Members</TextTableHeaderCell>
          <TextTableHeaderCell flexBasis="10%" textAlign="center">
            Status
          </TextTableHeaderCell>
          <TextTableHeaderCell flexBasis="10%" textAlign="end">
            Actions
          </TextTableHeaderCell>
        </TableHead>
        <TableBody>
          {groups.map((group) => (
            <TableRow key={group.id}>
              <TextTableCell flexBasis="35%">{group.name}</TextTableCell>
              <TextTableCell flexBasis="35%">{group.owner?.fullName}</TextTableCell>
              <TextTableCell flexBasis="10%" textAlign="center">
                {group.memberCount}
              </TextTableCell>
              <TextTableCell flexBasis="10%" textAlign="end">
                {renderStatus(group.status)}
              </TextTableCell>
              <TextTableCell flexBasis="10%" textAlign="end">
                <Popover
                  position={Position.BOTTOM_RIGHT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item is={Link} to={`/group/${group.id}`} icon={ExternalLinkIcon}>
                          Go to Group
                        </Menu.Item>
                        <Menu.Divider />
                        {group.status === GroupStatus.Disabled ? (
                          <Menu.Item
                            intent="success"
                            icon={ZapIcon}
                            onSelect={async () => {
                              try {
                                await editGroup({
                                  variables: {
                                    id: group.id,
                                    group: { status: GroupStatus.Enabled },
                                  },
                                })
                              } catch (error) {
                                Sentry.captureException(error)
                              }
                            }}
                          >
                            Enable Group
                          </Menu.Item>
                        ) : (
                          <Menu.Item
                            intent="warning"
                            icon={ZapOffIcon}
                            onSelect={async () => {
                              try {
                                await editGroup({
                                  variables: {
                                    id: group.id,
                                    group: { status: GroupStatus.Disabled },
                                  },
                                })
                              } catch (error) {
                                Sentry.captureException(error)
                              }
                            }}
                          >
                            Disable Group
                          </Menu.Item>
                        )}
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <IconButton marginLeft="auto" appearance="minimal" icon={MoreHorizontalIcon} />
                </Popover>
              </TextTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

function PendingGroups({ groups }: Pick<GroupsQuery, 'groups'>) {
  const [editGroup] = useEditGroupMutation()
  if (groups.length <= 0) {
    return null
  }

  return (
    <Box>
      <Heading marginY={majorScale(2)} is="h3">
        Pending Approval
      </Heading>
      <Box display="flex">
        {groups.map((group) => (
          <Card
            border="muted"
            appearance="solid"
            key={group.id}
            minWidth={majorScale(40)}
            paddingY={majorScale(3)}
            paddingX={majorScale(3)}
          >
            <Heading size={600}>{group.name}</Heading>
            <Text size={300}>
              From {group.owner?.fullName} • {DateTime.fromISO(group.createdAt).toRelative()}
            </Text>
            <Box display="flex" width="100%" marginTop={majorScale(2)}>
              <Button
                iconBefore={CheckIcon}
                intent="success"
                marginRight={majorScale(2)}
                onClick={async () => {
                  try {
                    await editGroup({
                      variables: { id: group.id, group: { status: GroupStatus.Enabled } },
                    })
                  } catch (error) {
                    Sentry.captureException(error)
                  }
                }}
              >
                Approve
              </Button>
              <Button iconBefore={XIcon} intent="danger">
                Decline
              </Button>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  )
}
