import Box from '@parishconnect/box'
import {
  ArrowRightIcon,
  Button,
  Heading,
  majorScale,
  minorScale,
  Pane,
  Stack,
  StackingOrder,
  Strong,
  Text,
  ThemeContext,
  ThemeType,
  XIcon,
} from '@parishconnect/react-ui'
import { PaneProps } from '@parishconnect/react-ui/dist/layers'
import { HeadingProps } from '@parishconnect/react-ui/dist/typography/src/Heading'
import { AnimatePresence, AnimateSharedLayout, motion, useViewportScroll } from 'framer-motion'
import { isEmpty, truncate } from 'lodash-es'
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useClickAway, useDebounce } from 'react-use'
import { formatExceptionText, MassTable } from '.'
import { ExceptionType, useMassesQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN, getTodaysMasses } from '../../utils'
import {
  ComputedMass,
  getFerialMasses,
  getSpecialMasses,
  getSundayMasses,
  hydrateMasses,
} from './utils'

const MassModuleCard: FC<{ theme: ThemeType } & PaneProps> = ({ theme, ...rest }) => (
  <Pane
    borderRadius={[14, 28]}
    appearance="solid"
    backgroundColor={[theme.palette[theme.themeColor].light, theme.palette[theme.themeColor].dark]}
    padding={[majorScale(2), majorScale(3)]}
    style={{ originY: 1 }}
    layoutId="massmodule"
    is={motion.div}
    {...rest}
  />
)

const MassModuleHeading: FC<{ theme: ThemeType } & HeadingProps> = ({ theme, ...rest }) => (
  <Heading
    color={[theme.getThemeColor(theme), 'white']}
    size={100}
    layoutId="massmoduleheading"
    is={motion.h3}
    {...rest}
  />
)

export function MassModule({ massPageId }: { massPageId: string }) {
  const { data } = useMassesQuery()
  const [open, setOpen] = useState(false)
  const { scrollY } = useViewportScroll()
  const todaysMasses = useMemo(() => getTodaysMasses(data?.masses), [data?.masses])
  const theme: ThemeType = useContext(ThemeContext)
  const ref = useRef(null)
  const masses = useMemo(() => hydrateMasses(data?.masses, true), [data?.masses])

  useClickAway(ref, () => setOpen(false))

  // useDebounce(() => {
  //   if (scrollY)
  // }, 100, [scrollY])

  const escHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && open) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', escHandler, false)
    } else {
      document.removeEventListener('keydown', escHandler, false)
    }
  }, [open])

  if (todaysMasses.length <= 0) return null

  return (
    <Stack value={StackingOrder.OVERLAY}>
      {(zIndex: number) => (
        <Box role="region" aria-label="Next Masses" position="relative" marginTop={majorScale(4)}>
          <AnimateSharedLayout type="crossfade">
            <MassModuleCard
              zIndex={zIndex / 2}
              cursor="pointer"
              theme={theme}
              onClick={() => setOpen(true)}
              css={{
                '&:hover': {
                  boxShadow: `0 0 0 1px rgba(255,255,255,0.5)`,
                },
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={majorScale(1)}
              >
                <MassModuleHeading theme={theme}>
                  {todaysMasses.length === 1 ? 'Next Mass' : 'Next Masses'}
                </MassModuleHeading>
                <Button
                  height={14}
                  fontSize={11}
                  textTransform="uppercase"
                  appearance="minimal"
                  iconAfter={ArrowRightIcon}
                  is={motion.span}
                  layoutId="massmodulebutton"
                  color={[theme.getThemeColor(theme), 'white!important']}
                >
                  All
                </Button>
              </Box>
              <motion.div layoutId="massmodulelist">
                {todaysMasses.map((mass) => (
                  <Box
                    key={mass.id}
                    display="flex"
                    alignItems="center"
                    is={motion.div}
                    layoutId={mass.id}
                  >
                    <Strong
                      width={majorScale(5)}
                      color={[theme.getThemeColor(theme), 'white']}
                      size={500}
                    >
                      {mass.nextDate.toLocaleString({
                        weekday: 'short',
                      })}
                    </Strong>
                    <Strong
                      color={[theme.getThemeColor(theme), 'white']}
                      size={500}
                      whiteSpace="nowrap"
                      marginRight={minorScale(3)}
                      textTransform="uppercase"
                      textDecoration={mass.exType === ExceptionType.Cancel && 'line-through'}
                    >
                      {mass.nextDate.toLocaleString({
                        hour: 'numeric',
                        minute: '2-digit',
                      })}
                    </Strong>
                    <SecondaryText mass={mass} />
                  </Box>
                ))}
              </motion.div>
            </MassModuleCard>
            <AnimatePresence>
              {open && (
                <MassModuleCard
                  innerRef={ref}
                  overflowY={['scroll', 'hidden']}
                  maxHeight={['90vh', 'unset']}
                  theme={theme}
                  elevation={4}
                  padding={[0, majorScale(3)]}
                  paddingBottom={[majorScale(5), majorScale(3)]}
                  position={['fixed', 'absolute']}
                  zIndex={zIndex}
                  left={0}
                  top={['unset', 0]}
                  bottom={[0, 'unset']}
                  width={['100%', 'auto']}
                  borderBottomRightRadius={[0, 28]}
                  borderBottomLeftRadius={[0, 28]}
                  css={{
                    msOverflowStyle: 'none',
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                  }}
                  backgroundColor={theme.palette[theme.themeColor].dark}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={majorScale(1)}
                    position={['sticky', 'static']}
                    borderTopRightRadius={[14, 'unset']}
                    borderTopLeftRadius={[14, 'unset']}
                    backgroundColor={[theme.palette[theme.themeColor].dark, 'unset']}
                    top={[0, 'unset']}
                    padding={[majorScale(2), 'unset']}
                    zIndex={99}
                  >
                    <MassModuleHeading theme={theme} color="white">
                      Regular Mass Schedule
                    </MassModuleHeading>
                    <Button
                      height={14}
                      fontSize={11}
                      textTransform="uppercase"
                      appearance="minimal"
                      iconAfter={XIcon}
                      is={motion.span}
                      layoutId="massmodulebutton"
                      onClick={() => setOpen(false)}
                      color="white!important"
                    >
                      Close
                    </Button>
                  </Box>
                  <Box
                    is={motion.div}
                    layoutId="massmodulelist"
                    paddingX={[majorScale(1), 'unset']}
                    paddingTop={majorScale(2)}
                    display="grid"
                    gridTemplateColumns={[
                      '1fr',
                      `repeat(2, minmax(${majorScale(36)}px, ${majorScale(39)}px))`,
                    ]}
                    gap={COLUMN}
                  >
                    <MassTable
                      masses={getFerialMasses(masses)}
                      heading="Daily"
                      appearance="white"
                    />
                    <MassTable
                      masses={getSundayMasses(masses)}
                      heading="Sunday"
                      appearance="white"
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      marginLeft="auto"
                      gridColumn={[null, '2']}
                      is={Link}
                      to={`/sacraments/${massPageId}`}
                    >
                      {!isEmpty(getSpecialMasses(masses)) && (
                        <Text size={300} color="white" paddingRight={majorScale(1)}>
                          + {Object.values(getSpecialMasses(masses)).length} more masses
                        </Text>
                      )}
                      <Button color="white" iconAfter={ArrowRightIcon}>
                        See all masses
                      </Button>
                    </Box>
                  </Box>
                </MassModuleCard>
              )}
            </AnimatePresence>
          </AnimateSharedLayout>
        </Box>
      )}
    </Stack>
  )
}

function SecondaryText({ mass }: { mass: ComputedMass }) {
  const theme = useContext(ThemeContext)
  if (mass.exType) {
    return formatExceptionText(mass.exType, mass.oldDate, mass.newDate, true)
  }
  if (mass.title) {
    return (
      <Text
        marginLeft={2}
        color={[theme.getThemeColor(theme), theme.palette[theme.themeColor].lightest]}
      >
        {truncate(mass.title, { length: 21 })}
      </Text>
    )
  }
  if (mass.note) {
    return (
      <Text
        marginLeft={2}
        size={300}
        color={[theme.getThemeColor(theme), theme.palette[theme.themeColor].lightest]}
      >
        {truncate(mass.note, { length: 21 })}
      </Text>
    )
  }

  return null
}
