import { Card, majorScale } from '@parishconnect/react-ui'
import React, { ComponentProps } from 'react'
import { COLUMN } from '../../utils'

export function UserPaneWrapper({ children }: ComponentProps<'div'>) {
  return (
    <Card
      maxWidth={['100%', COLUMN * 11]}
      marginTop={majorScale(4)}
      appearance="white"
      paddingY={majorScale(4)}
      paddingX={[majorScale(3), majorScale(5)]}
      elevation={1}
    >
      {children}
    </Card>
  )
}
