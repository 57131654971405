import Box from '@parishconnect/box'
import {
  Button,
  Card,
  CheckCircleIcon,
  Heading,
  KeyIcon,
  LogInIcon,
  majorScale,
  Spinner,
  Text,
  TextInputField,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, RouteComponentProps, Redirect } from 'react-router-dom'
import * as yup from 'yup'
import { PageContainer } from '../components'
import {
  useResetPasswordMutation,
  useUserByCodeLazyQuery,
} from '../graphql/generated/graphql-hooks'

const passwordResetSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Required'),
  reNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Required'),
})

export default function PasswordReset({ match }: RouteComponentProps<{ code: string }>) {
  const [call, { data, called, loading: infoLoading }] = useUserByCodeLazyQuery()
  const [resetPassword, { loading }] = useResetPasswordMutation()

  if ((called && !data?.user) || !match?.params) {
    return <Redirect to="/" />
  }

  if (infoLoading) {
    return (
      <PageContainer>
        <Box marginTop={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
          <Card
            appearance="white"
            elevation={1}
            padding={[majorScale(3), majorScale(6)]}
            width={majorScale(48)}
            maxWidth={`calc(100vw - ${majorScale(2)}px)`}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Spinner />
            </Box>
          </Card>
        </Box>
      </PageContainer>
    )
  }

  if (!!match?.params?.code && !called) {
    call({ variables: { code: match?.params?.code } })
  }

  return (
    <PageContainer>
      <Helmet>
        <title>Password Reset</title>
      </Helmet>
      <Box marginTop={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
        <Card
          appearance="white"
          elevation={1}
          padding={[majorScale(3), majorScale(6)]}
          width={majorScale(48)}
          maxWidth={`calc(100vw - ${majorScale(2)}px)`}
        >
          <Formik
            validationSchema={passwordResetSchema}
            initialValues={{ newPassword: '', reNewPassword: '' }}
            onSubmit={async (values, actions) => {
              try {
                await resetPassword({
                  variables: { password: values.newPassword, passwordResetCode: match.params.code },
                })
                actions.setSubmitting(false)
              } catch (error) {
                Sentry.captureException(error)
              }
            }}
          >
            {({ errors, submitCount }) =>
              submitCount <= 0 ? (
                <>
                  <Heading size={600} marginBottom={majorScale(1)}>
                    Password Reset
                  </Heading>
                  <Text size={500}>
                    Hey {data?.user?.firstName}, let's get you back into your account.
                  </Text>
                  <Box is={Form} marginTop={majorScale(2)}>
                    <TextInputField
                      is={Field}
                      label="New Password"
                      name="newPassword"
                      type="password"
                      validationMessage={submitCount > 0 && errors?.newPassword}
                    />
                    <TextInputField
                      is={Field}
                      label="Confirm Password"
                      name="reNewPassword"
                      type="password"
                      validationMessage={submitCount > 0 && errors?.reNewPassword}
                    />
                    <Button
                      isLoading={loading}
                      appearance="primary"
                      intent="success"
                      type="submit"
                      iconBefore={KeyIcon}
                    >
                      Save Password
                    </Button>
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CheckCircleIcon
                    size={majorScale(4)}
                    color="success"
                    marginBottom={majorScale(2)}
                  />
                  <Text>Your new password has been saved.</Text>
                  <Button marginTop={majorScale(2)} iconBefore={LogInIcon} is={Link} to="/login">
                    Login
                  </Button>
                </Box>
              )
            }
          </Formik>
        </Card>
      </Box>
    </PageContainer>
  )
}
