import Box from '@parishconnect/box'
import {
  Badge,
  CalendarIcon,
  Card,
  FileTextIcon,
  Heading,
  majorScale,
  minorScale,
  SidebarIcon,
} from '@parishconnect/react-ui'
import { isEmpty, take } from 'lodash-es'
import { DateTime } from 'luxon'
import React from 'react'
import ProgressiveImage from 'react-progressive-image'
import { Link } from 'react-router-dom'
import {
  PinnedQuery,
  useLiveEventQuery,
  usePinnedQuery,
} from '../../graphql/generated/graphql-hooks'
import { cssLineClamp } from '../../utils'
import { DonationModule } from './DonationModule'
import { LiveEventModule } from './LiveEventModule'

export const PinnedList = () => {
  const { data } = usePinnedQuery()
  const { data: liveEventData } = useLiveEventQuery()

  const width = majorScale(18)
  const height = width * 1.5
  const gutter = majorScale(2)

  const posts = data?.posts || []
  const events = data?.events || []
  const forms = data?.forms || []

  const pinnedData = [
    ...posts,
    ...events,
    ...forms,
    { module: 'donate' },
    liveEventData?.liveEvent,
  ].filter(Boolean)

  if (isEmpty(pinnedData)) return null

  const modules = take(
    [...posts, ...events, ...forms].sort(
      (a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis(),
    ),
    8,
  )

  return (
    <Box
      width="100%"
      position="relative"
      display="grid"
      gridGap={`${gutter}px 0`}
      gridTemplateColumns={`${gutter}px 1fr ${gutter}px`}
      alignContent="start"
    >
      <Heading gridColumn="2 / -2" size={700}>
        Pinned
      </Heading>
      <Box
        role="list"
        display="grid"
        overflowX="scroll"
        gridTemplateColumns={`8px repeat(${pinnedData.length}, ${width}px) 8px`}
        gridColumn="1 / -1"
        gridTemplateRows={`minmax(${width}px, 1fr)`}
        gridGap={majorScale(1)}
        paddingY={majorScale(1)}
        css={{
          '&:before, &:after': {
            content: '""',
          },
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          overflowX: 'scroll',
          overflowY: 'hidden',
          '@media(pointer: fine)': {
            scrollSnapType: 'x mandatory',
            scrollPaddingLeft: gutter,
          },
        }}
      >
        <DonationModule />
        <LiveEventModule {...liveEventData?.liveEvent} />
        {modules.map((module) => (
          <PinnedModule key={module.id} {...module} />
        ))}
      </Box>
    </Box>
  )
}

function PinnedModule(
  props: PinnedQuery['posts'][0] | PinnedQuery['events'][0] | PinnedQuery['forms'][0],
) {
  const isPost = props.__typename === 'Post'
  const isEvent = props.__typename === 'Event'
  const isForm = props.__typename === 'Form'

  const PostHead = () => (
    <>
      <Badge color="overlay" css={{ backdropFilter: 'blur(20px) saturate(180%)' }}>
        {(props as PinnedQuery['posts'][0]).type}
      </Badge>
      <FileTextIcon marginLeft="auto" color="white" />
    </>
  )

  const FormHead = () => (
    <>
      <Badge color="overlay" css={{ backdropFilter: 'blur(20px) saturate(180%)' }}>
        Form
      </Badge>
      <SidebarIcon marginLeft="auto" color="white" />
    </>
  )

  const EventHead = () => (
    <>
      <Box
        borderRadius={4}
        display="flex"
        flexDirection="column"
        color="white"
        padding={majorScale(1)}
        zIndex={3}
        backgroundColor="rgba(0,0,0,0.2)"
        css={{ backdropFilter: 'blur(20px) saturate(180%)' }}
      >
        <Heading opacity={1} size={700}>
          {DateTime.fromISO((props as PinnedQuery['events'][0]).dtstart).toLocaleString({
            day: 'numeric',
          })}
        </Heading>
        <Heading opacity={1} size={100}>
          {DateTime.fromISO((props as PinnedQuery['events'][0]).dtstart).toLocaleString({
            month: 'short',
          })}
        </Heading>
      </Box>
      <CalendarIcon marginLeft="auto" color="white" />
    </>
  )

  const to = (() => {
    if (isPost) return `/post/${props.id}`
    if (isEvent) return `/event/${props.id}`
    if (isForm) return `/form/${props.id}`
    return ''
  })()

  return (
    <ProgressiveImage src={props.image?.src} placeholder={props.image?.lqip}>
      {(src: string) => (
        <Card
          role="listitem"
          is={Link}
          to={to}
          height="0"
          paddingBottom="150%"
          backgroundImage={src && `url(${src})`}
          elevation={1}
          hoverElevation={2}
          position="relative"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          appearance="gradient"
          css={{
            transition: 'box-shadow 125ms',
            scrollSnapAlign: 'start',
            '&::before': {
              content: '""',
              display: 'block',
              backgroundImage: src && `linear-gradient(transparent, rgba(0,0,0,0.5))`,
              position: 'absolute',
              borderRadius: 8,
              bottom: 0,
              left: 0,
              width: '100%',
              height: '75%',
              zIndex: 2,
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            padding={majorScale(1)}
            position="absolute"
            height="100%"
            width="100%"
            zIndex={3}
          >
            <Box display="flex">
              {isPost && <PostHead />}
              {isEvent && <EventHead />}
              {isForm && <FormHead />}
            </Box>
            <Box marginTop="auto" padding={minorScale(1)}>
              <Heading
                color="white"
                size={500}
                css={{
                  ...cssLineClamp(),
                  textShadow: src && '0 0 4px rgba(0,0,0,0.24)',
                }}
              >
                {props.title}
              </Heading>
            </Box>
          </Box>
        </Card>
      )}
    </ProgressiveImage>
  )
}
