import { EDITOR_CLASS_NAME, EMPTY_PARAGRAPH_NODE } from '@remirror/core'
import { RenderTree, RenderTreeProps } from '@remirror/react-renderer'
import jsonParse from 'easy-json-parse'
import React from 'react'
import EditorStyles from './editorStyles'
import { Doc, HeadingHandler, TextHandler, OrderedListHandler } from './handlers'

export type RendererProps = {
  document: string
}

export const MARK_MAP = {
  em: 'em',
  italic: 'em',
  strong: 'strong',
  bold: 'strong',
  code: 'code',
  link: 'a',
  underline: 'u',
}

const TYPE_MAP = {
  doc: Doc,
  bulletList: 'ul',
  orderedList: OrderedListHandler,
  listItem: 'li',
  blockquote: 'blockquote',
  horizontalRule: 'hr',
  paragraph: 'p',
  hardBreak: 'br',
  text: TextHandler,
  heading: HeadingHandler,
  image: 'img',
}

export const InnerRenderer = ({ json, ...rest }: RenderTreeProps) => (
  <RenderTree
    skipUnknownMarks={true}
    skipUnknownTypes={true}
    typeMap={TYPE_MAP}
    markMap={MARK_MAP}
    json={json}
    {...rest}
  />
)

export function Renderer({ document, ...rest }: RendererProps) {
  return (
    <div className={EDITOR_CLASS_NAME}>
      <EditorStyles />
      <InnerRenderer
        json={jsonParse(document, { initialValue: EMPTY_PARAGRAPH_NODE })[1]}
        {...rest}
      ></InnerRenderer>
    </div>
  )
}
