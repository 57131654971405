import { Global } from '@emotion/core'
import { Button, Heading, Image, LogInIcon, majorScale } from '@parishconnect/react-ui'
import React from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import maintenanceModeImage from '../../assets/maintenance-mode.svg'
import { AppQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils'
import { PageContainer } from '../shared'
import Login from '../../routes/Login'

export function MaintenanceMode({ parish }: AppQuery) {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PageContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="80vh"
      >
        <Image src={maintenanceModeImage} maxWidth={COLUMN * 4} />
        <Heading marginTop={majorScale(3)} size={700}>
          {parish?.name ?? 'This Parish'} is undergoing maintenance
        </Heading>
        <Button is={Link} to="/login" marginTop={majorScale(3)} iconBefore={LogInIcon}>
          Login
        </Button>
      </PageContainer>
    </Switch>
  )
}
