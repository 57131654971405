import Box from '@parishconnect/box'
import {
  Button,
  Heading,
  majorScale,
  RotateCcwIcon,
  SaveIcon,
  SelectField,
  TextInputField,
  toaster,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import { omit } from 'lodash-es'
import React from 'react'
import * as yup from 'yup'
import { useEditParishMutation, useParishQuery } from '../../graphql/generated/graphql-hooks'
import { ALLOWED_CITIES, ALLOWED_PROVINCES } from '../../utils'

const parishInfoValidationSchema = new yup.object().shape({
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  address: yup.object().shape({
    addressLine1: yup.string(),
    city: yup.string().oneOf(ALLOWED_CITIES),
    country: yup.string(),
    state: yup.string().oneOf(ALLOWED_PROVINCES),
    zip: yup.string(),
  }),
})

export function ParishInfo() {
  const { data } = useParishQuery({ fetchPolicy: 'cache-first' })
  const [editParish, { loading: editLoading }] = useEditParishMutation()

  return (
    <Box>
      <Heading marginTop={[majorScale(4), majorScale(2)]} size={800}>
        Parish Info
      </Heading>
      <Formik
        enableReinitialize
        onSubmit={async (parish, actions) => {
          try {
            await editParish({ variables: { parish } })
            actions.setSubmitting(false)
            toaster.success('Parish Information Saved!')
          } catch (error) {
            Sentry.captureException(error)
            actions.setErrors(error)
          }
        }}
        validationSchema={parishInfoValidationSchema}
        initialValues={{
          name: data?.parish?.name,
          phoneNumber: data?.parish?.phoneNumber,
          address: omit(data?.parish?.address, '__typename'),
        }}
      >
        {({ values, resetForm, dirty, errors }) => (
          <Box is={Form} marginTop={majorScale(4)}>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={majorScale(2)}>
              <TextInputField
                label="Parish Name"
                placeholder="Parish Name"
                is={Field}
                name="name"
                inputWidth={majorScale(38)}
                isRequired
              />
              <TextInputField
                label="Diocese"
                is={Field}
                name="name"
                inputWidth={majorScale(38)}
                disabled
                value={data?.parish?.diocese?.name}
              />
            </Box>
            <Heading is="h4" size={600} marginY={majorScale(2)}>
              Address
            </Heading>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={majorScale(2)}>
              <TextInputField
                label="Address Line 1"
                placeholder="65 Bond St."
                is={Field}
                name="address.addressLine1"
                inputWidth={majorScale(38)}
                isRequired
              />
              <Field
                as={SelectField}
                label="City"
                placeholder="Select a City"
                name="address.city"
                inputWidth={majorScale(38)}
              >
                {ALLOWED_CITIES.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Field>
              <TextInputField
                label="Postal Code"
                placeholder="A1A 1A1"
                is={Field}
                name="address.zip"
                inputWidth={majorScale(38)}
                isRequired
              />
              <Field
                as={SelectField}
                label="Province"
                placeholder="Select a Province"
                name="address.state"
                inputWidth={majorScale(38)}
              >
                {ALLOWED_PROVINCES.map((province) => (
                  <option key={province} value={province}>
                    {province}
                  </option>
                ))}
              </Field>
              <TextInputField
                label="Phone Number"
                placeholder="(123) 456-7890"
                is={Field}
                name="phoneNumber"
                inputWidth={majorScale(38)}
              />
              <TextInputField
                label="Country"
                value="Canada"
                placeholder="Canada"
                disabled
                is={Field}
                hint="ParishConnect is currently only available in Canada"
                name="city"
                inputWidth={majorScale(38)}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button
                disabled={!dirty}
                type="button"
                iconBefore={RotateCcwIcon}
                marginRight={majorScale(1)}
                onClick={resetForm}
              >
                Reset
              </Button>
              <Button
                isLoading={editLoading}
                disabled={!dirty}
                appearance="primary"
                intent="success"
                type="submit"
                iconBefore={SaveIcon}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  )
}
