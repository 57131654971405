import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import JSONparse from 'easy-json-parse'
import {
  Button,
  CornerUpLeftIcon,
  majorScale,
  SaveIcon,
  toaster,
  TextInputField,
  Card,
  Heading,
} from '@parishconnect/react-ui'
import { EMPTY_PARAGRAPH_NODE } from '@remirror/core'
import { Field, Form, Formik } from 'formik'
import { debounce, truncate } from 'lodash-es'
import React, { useContext } from 'react'
import useRouter from 'use-react-router'
import {
  AboutsDocument,
  AboutsQuery,
  useAddAboutMutation,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN, AbilityContext } from '../../utils'
import { aboutValidationSchema } from './GenericAboutBody'
import { Helmet } from 'react-helmet-async'
import ProgressiveImage from 'react-progressive-image'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { Editor } from '../Editor'

export function NewAbout({ parish }: AboutsQuery & RouteComponentProps<{ id: string }>) {
  const [addAbout] = useAddAboutMutation()
  const { history } = useRouter()
  const ability = useContext(AbilityContext)

  if (ability.cannot('update', parish)) return <Redirect to="/about" />

  return (
    <Formik
      enableReinitialize
      validationSchema={aboutValidationSchema}
      initialValues={{ ast: '', title: '' }}
      onSubmit={async (about, actions) => {
        try {
          await addAbout({
            variables: { about },
            update: (cache, { data: { addAbout } }) => {
              try {
                const { abouts, ...rest } = cache.readQuery<AboutsQuery>({ query: AboutsDocument })
                if (abouts) {
                  cache.writeQuery({
                    query: AboutsDocument,
                    data: {
                      ...rest,
                      abouts: [addAbout].concat(abouts),
                    },
                  })
                }

                history.push(`/about/${addAbout.id}`)
              } catch (error) {
                history.push(`/about`)
                Sentry.captureException(error)
              }
              toaster.success('Success!', { description: `"${about.title}" saved.` })
            },
          })
          actions.setSubmitting(false)
        } catch (error) {
          toaster.danger('Error.', { description: `"${about.title}" failed to save.` })
          Sentry.captureException(error)
        }
      }}
    >
      {({ values, setFieldValue, errors, submitCount }) => (
        <Box>
          <Box position="relative">
            <Helmet>
              <title>{values?.title ?? 'New About'}</title>
            </Helmet>
            {parish?.image?.src ? (
              <ProgressiveImage src={parish?.image?.src} placeholder={parish?.image?.lqip}>
                {(src: string) => (
                  <Card
                    width="100%"
                    css={{ objectFit: 'cover' }}
                    height={[majorScale(16), null, majorScale(26), majorScale(38)]}
                    is="img"
                    srcSet={parish?.image?.srcset}
                    src={src}
                  />
                )}
              </ProgressiveImage>
            ) : (
              <Card appearance="solid" height={majorScale(38)} width="100%" />
            )}
            <Card
              appearance="solid"
              paddingX={majorScale(3)}
              paddingY={majorScale(2)}
              position={['static', 'absolute']}
              marginTop={[majorScale(2), 0]}
              borderRadius={14}
              bottom={majorScale(2)}
              left={majorScale(2)}
            >
              <Heading color="theme" size={800}>
                {truncate(values?.title, { length: 28 }) ?? 'New About'}
              </Heading>
              <Heading color="theme" size={100}>
                {parish?.name}
              </Heading>
            </Card>
          </Box>
          <Box position="relative" paddingX={majorScale(3)} marginTop={majorScale(3)}>
            <Form>
              <TextInputField
                is={Field}
                label="Title"
                maxLength="23"
                hint={`${values?.title.length}/28`}
                size={900}
                name="title"
                placeholder="Add a title..."
                maxWidth={COLUMN * 10}
                validationMessage={submitCount > 0 && errors.title}
              />
              <Editor
                initialContent={JSONparse(values.ast, { initialValue: EMPTY_PARAGRAPH_NODE })[1]}
                placeholder="Write something..."
                onChange={debounce(
                  (value: any) => setFieldValue('ast', JSON.stringify(value.getObjectNode())),
                  350,
                )}
              />
              <Box position="absolute" right={0}>
                <Button
                  intent="danger"
                  onClick={() => history.goBack()}
                  type="button"
                  iconBefore={CornerUpLeftIcon}
                >
                  Cancel
                </Button>
                <Button
                  marginLeft={majorScale(1)}
                  intent="success"
                  type="submit"
                  appearance="primary"
                  iconBefore={SaveIcon}
                >
                  Save
                </Button>
              </Box>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
