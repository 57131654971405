import Box from '@parishconnect/box'
import {
  Card,
  Heading,
  majorScale,
  minorScale,
  ThemeContext,
  Badge,
  YoutubeIcon,
} from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { LiveEvent, LiveEventType } from '../../graphql/generated/graphql-hooks'
import { DateTime } from 'luxon'
import { cssLineClamp } from '../../utils'

export function LiveEventModule({ id, image, title, dtstart }: LiveEvent) {
  if (!id) {
    return null
  }

  const href = `//youtube.com/watch?v=${id}`
  const dt = DateTime.fromISO(dtstart)

  return (
    <Card
      is="a"
      href={href}
      role="listitem"
      height="0"
      paddingBottom="150%"
      backgroundImage={image && `url(${image})`}
      elevation={1}
      hoverElevation={2}
      position="relative"
      backgroundSize="270%"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      appearance="gradient"
      target="_blank"
      rel="noopener noreferrer"
      className="fv"
      css={{
        transition: 'box-shadow 125ms',
        scrollSnapAlign: 'start',
        '&::before': {
          content: '""',
          display: 'block',
          backgroundImage: image && `linear-gradient(transparent, rgba(0,0,0,1))`,
          position: 'absolute',
          borderRadius: 8,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '75%',
          zIndex: 2,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        padding={majorScale(1)}
        position="absolute"
        height="100%"
        width="100%"
        zIndex={3}
      >
        <Box display="flex">
          <>
            <Badge color="overlay" css={{ backdropFilter: 'blur(20px) saturate(180%)' }}>
              {dt.diffNow('days').days > 3 || dt.diffNow('hours').hours < 2
                ? dt.toRelative()
                : dt.toRelative({ unit: 'hours' })}
            </Badge>
            <YoutubeIcon color="white" marginLeft="auto" />
          </>
        </Box>
        <Box marginTop="auto" padding={minorScale(1)}>
          <Heading color="rgba(255,255,255,0.7)" size={100}>
            Live Stream
          </Heading>
          <Heading
            color="white"
            size={500}
            css={{
              ...cssLineClamp(),
              textShadow: image && '0 0 4px rgba(0,0,0,0.24)',
            }}
          >
            {title}
          </Heading>
        </Box>
      </Box>
    </Card>
  )
}
