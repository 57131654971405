import Box from '@parishconnect/box'
import {
  Button,
  Card,
  ChevronRightIcon,
  Heading,
  majorScale,
  minorScale,
  Text,
  ThemeContext,
  UserPlusIcon,
} from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Group, useGroupsQuery, useParishQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN, cssLineClamp } from '../../utils'

export function GroupsHomeList() {
  const { data: { parish = {} } = {} } = useParishQuery()
  const { data: { groups = [] } = {} } = useGroupsQuery()

  const gutter = majorScale(2)
  const width = majorScale(36)

  if (groups.length <= 0) return null

  return (
    <Box
      paddingTop={COLUMN / 2}
      width="100%"
      position="relative"
      display="grid"
      gridGap={`${gutter}px 0`}
      gridTemplateColumns={`${gutter}px 1fr ${gutter}px`}
      alignContent="start"
    >
      <Box display="flex" gridColumn="2 / -2">
        <Heading gridColumn="2 / -2" size={700}>
          Groups at {parish.name}
        </Heading>
        <Button
          is={Link}
          to="/groups"
          marginLeft={['auto', majorScale(2)]}
          iconAfter={ChevronRightIcon}
          whiteSpace="nowrap"
        >
          All Groups
        </Button>
      </Box>
      <Box
        role="list"
        display="grid"
        overflowX="scroll"
        gridTemplateColumns={`8px repeat(${groups.length}, ${width}px) 8px`}
        gridColumn="1 / -1"
        gridTemplateRows={`minmax(${width / 4}px, 1fr)`}
        gridGap={majorScale(1)}
        paddingY={majorScale(1)}
        css={{
          '&:before, &:after': {
            content: '""',
          },
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          overflowX: 'scroll',
          '@media(pointer: fine)': {
            scrollSnapType: 'x mandatory',
            scrollPaddingLeft: gutter,
          },
        }}
      >
        {groups.map((group) => (
          <GroupSmallCard key={group.id} group={group} />
        ))}
      </Box>
    </Box>
  )
}

function GroupSmallCard({ group }: { group: Group }) {
  const theme = useContext(ThemeContext)
  return (
    <Card
      role="listitem"
      is={Link}
      to={`group/${group.id}`}
      position="relative"
      appearance="solid"
      css={{
        transition: 'box-shadow 125ms',
        scrollSnapAlign: 'start',

        '&:hover': {
          boxShadow: `0 0 0 2px ${theme.palette[theme.themeColor].light}`,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        padding={majorScale(2)}
        position="absolute"
        height="100%"
        width="100%"
        zIndex={3}
      >
        <Heading color="theme" size={500} css={cssLineClamp(1)}>
          {group.name}
        </Heading>
        <Text>
          {group.memberCount} {group.memberCount > 1 ? `members` : 'member'}
        </Text>
      </Box>
    </Card>
  )
}
