import Box from '@parishconnect/box'
import { BookOpenIcon, Heading, majorScale, Pane, Paragraph } from '@parishconnect/react-ui'
import { AnimatePresence } from 'framer-motion'
import { isEmpty, sortBy } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { AddBulletin, BulletinLink, Nav } from '../components'
import { PageContainer } from '../components/shared'
import { EmptyState } from '../components/shared/EmptyState'
import {
  BulletinLink as BulletinLinkType,
  useBulletinQuery,
} from '../graphql/generated/graphql-hooks'
import { Can } from '../utils/abilityContext'
import { COLUMN } from '../utils/constants'

function maybeRenderMonth(link: BulletinLinkType, months: string[]): string {
  const bulletinMonth = DateTime.fromISO(link?.forDate).toLocaleString({
    month: 'long',
    year: 'numeric',
  })

  if (!months.includes(bulletinMonth)) {
    months.push(bulletinMonth)
    return bulletinMonth
  }
  return null
}

export default function Bulletins() {
  const { data } = useBulletinQuery()
  const months: string[] = []

  const bulletinlinks = sortBy(data?.bulletin?.links, link => link?.forDate).reverse()

  return (
    <PageContainer>
      <Helmet>
        <title>Bulletins</title>
      </Helmet>
      <Pane display={['block', 'flex']}>
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
        </Box>
        <Box
          width={['100%', COLUMN * 17.5]}
          marginLeft={['auto', COLUMN / 2]}
          marginRight={['auto', 0]}
          id="main-content"
          is="main"
        >
          <Box
            maxWidth={majorScale(40)}
            display="flex"
            alignItems="center"
            marginTop={[majorScale(2), majorScale(4)]}
            justifyContent="space-between"
          >
            <Heading is="h1" size={800}>
              Bulletins
            </Heading>
            <Can I="create" a="bulletin">
              <AddBulletin {...data?.bulletin} />
            </Can>
          </Box>
          <Box marginTop={majorScale(4)}>
            {isEmpty(bulletinlinks) ? (
              <EmptyState icon={BookOpenIcon} title="No Bulletins">
                <Paragraph size={500}>When you add bulletins they will appear here.</Paragraph>
              </EmptyState>
            ) : (
              <AnimatePresence initial={false}>
                {bulletinlinks.map((bulletin, i) => {
                  const month = maybeRenderMonth(bulletin, months)

                  return (
                    <Fragment key={bulletin.id}>
                      {month && (
                        <Heading marginBottom={majorScale(1)} marginTop={majorScale(2)}>
                          {month}
                        </Heading>
                      )}
                      <BulletinLink i={i} parish={data?.bulletin?.parish} bulletin={bulletin} />
                    </Fragment>
                  )
                })}
              </AnimatePresence>
            )}
          </Box>
        </Box>
      </Pane>
    </PageContainer>
  )
}
