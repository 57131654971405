import Box from '@parishconnect/box'
import { PlusCircleIcon, Spinner } from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import produce from 'immer'
import { difference } from 'lodash-es'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, Route, Switch } from 'react-router'
import useRouter from 'use-react-router'
import { Footer, Nav, NewSacrament, PageContainer, SingleSacrament } from '../components'
import { EmptyState } from '../components/shared/EmptyState'
import {
  SacramentQuery,
  SacramentType,
  useSacramentsQuery,
} from '../graphql/generated/graphql-hooks'
import { AbilityContext } from '../utils'
import { COLUMN } from '../utils/constants'

const createSacramentSubmenu = (sacraments: SacramentQuery['sacrament'][]) => {
  if (sacraments.length >= 1) {
    try {
      sacraments = produce(sacraments, (draft) => {
        const massIndex = draft.findIndex((s) => s.type === SacramentType.Mass)
        const mass = draft[massIndex]
        if (mass) {
          draft.splice(massIndex, 1)
          draft.splice(0, 0, mass)
        }
      })

      return sacraments.map(({ id, title, type }) => ({
        title,
        id,
        to: id,
        type,
      }))
    } catch (error) {
      Sentry.captureException(error)
      return []
    }
  }
  return []
}

const Sacraments = () => {
  const { data, loading } = useSacramentsQuery()
  const ability = useContext(AbilityContext)

  const sacraments = data?.sacraments ?? []
  const sacramentSubmenu = createSacramentSubmenu(sacraments)

  const {
    match: { url },
    history,
  } = useRouter()

  if (loading) {
    return (
      <PageContainer>
        <Box display="flex" height="100%">
          <Box width={[0, null, COLUMN * 6]}>
            <Nav menuOpen showMasses />
          </Box>
          <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
            <Spinner />
          </Box>
        </Box>
      </PageContainer>
    )
  }

  if (sacraments?.length <= 0) {
    if (ability.cannot('update', data?.parish)) {
      return (
        <PageContainer>
          <Box display="flex">
            <Box width={[0, null, COLUMN * 6]}>
              <Nav menuOpen showMasses />
            </Box>
            <Box marginLeft={[0, null, COLUMN]} width={['100%', null, COLUMN * 15]}>
              <EmptyState icon={PlusCircleIcon} title="No Sacraments" />
            </Box>
          </Box>
        </PageContainer>
      )
    }
  }

  const availableSacramentTypes = difference(
    Object.values(SacramentType),
    sacraments.map((s) => s.type),
  )
  const sacramentTypesAreExhausted = availableSacramentTypes.length <= 0

  return (
    <PageContainer>
      <Box display="flex">
        <Helmet>
          <title>Sacraments</title>
        </Helmet>
        <Box width={[0, null, COLUMN * 6]}>
          <Nav
            root="/sacraments"
            showMasses
            menuOpen={sacramentSubmenu.length <= 0}
            title="Sacraments"
            subMenu={sacramentSubmenu}
            newItem={
              !sacramentTypesAreExhausted && {
                title: 'New Sacrament',
                onClick: () => history.push('/sacraments/new'),
                isShown: ability.can('update', data?.parish),
              }
            }
          />
          <Footer />
        </Box>
        <Box width={['100%', null, COLUMN * 17.5]} marginLeft={[0, null, COLUMN / 2]}>
          <Switch>
            <Redirect from="/sacraments" exact to={`/sacraments/${sacramentSubmenu?.[0]?.id}`} />
            {!sacramentTypesAreExhausted && (
              <Route
                exact
                path="/sacraments/new"
                component={(route: any) => (
                  <NewSacrament
                    {...route}
                    {...data}
                    availableSacramentTypes={availableSacramentTypes}
                  />
                )}
              />
            )}
            <Route
              path={`/sacraments/:id`}
              component={(route: any) => (
                <SingleSacrament
                  {...route}
                  {...data}
                  availableSacramentTypes={availableSacramentTypes}
                />
              )}
            />
          </Switch>
        </Box>
      </Box>
    </PageContainer>
  )
}

export default Sacraments
