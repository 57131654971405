import Box from '@parishconnect/box'
import { Button, Heading, majorScale } from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { usePostsQuery } from '../../graphql/generated/graphql-hooks'
import { PostModule } from '../Post'

export function GroupPosts({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) {
  const limit = 10
  const { loading, error, data, fetchMore } = usePostsQuery({
    variables: {
      filters: { group: id },
      limit,
    },
  })

  const posts = data?.posts ?? []
  const [hasMore, setHasMore] = useState(posts.length >= limit)

  if (error) {
    Sentry.captureException(error)
    return null
  }

  if (!data) {
    return null
  }

  return (
    <>
      <Heading marginBottom={majorScale(3)} size={800}>
        Posts
      </Heading>
      <Box
        display="grid"
        gridAutoFlow="row"
        width="100%"
        gap={`${majorScale(3)}px ${majorScale(5)}px`}
        gridTemplateColumns={['1fr', `repeat(auto-fit, ${majorScale(38)}px)`]}
      >
        {posts.map(post => (
          <PostModule key={post.id} {...post} />
        ))}
        {hasMore && (
          <Box
            marginY={majorScale(3)}
            marginX="auto"
            gridColumn={['1/2', '1/3']}
            display="flex"
            justifyContent="center"
          >
            <Button
              isLoading={loading}
              onClick={() =>
                fetchMore({
                  variables: {
                    limit,
                    cursor: data?.posts[data?.posts.length - 1].id,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                      return previousResult
                    }

                    const posts = fetchMoreResult?.posts || []

                    if (posts.length < limit) {
                      setHasMore(false)
                    }

                    return {
                      ...previousResult,
                      posts: [...previousResult.posts, ...fetchMoreResult.posts],
                    }
                  },
                })
              }
              height={36}
            >
              Load More
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}
