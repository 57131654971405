import { Parish, Post } from '@parishconnect/abilities/dist/mocks/schema'
import Box from '@parishconnect/box'
import {
  AwardIcon,
  Button,
  Dialog,
  Edit2Icon,
  FacebookIcon,
  HeartFilledIcon,
  HeartIcon,
  LinkIcon,
  majorScale,
  Menu,
  Popover,
  Position,
  ShareIcon,
  Trash2Icon,
  TwitterIcon,
} from '@parishconnect/react-ui'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useClipboard } from 'use-clipboard-copy'
import useRouter from 'use-react-router'
import {
  PostQuery,
  useDeletePostMutation,
  useLikePostMutation,
  usePinPostMutation,
  useUnlikePostMutation,
} from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils/abilityContext'
import { getFacebookShareURL, getTwitterShareURL } from '../shared/socialShareURL'

const SinglePostActions = (post: PostQuery['post']) => {
  return (
    <Box
      marginX={[majorScale(1), majorScale(3)]}
      marginTop={majorScale(4)}
      display="flex"
      flexWrap="wrap"
      css={{
        'button, a': {
          marginBottom: majorScale(1),
        },
      }}
    >
      <LikeButton {...post} />
      <ShareButton {...post} />
      <Box marginLeft="auto">
        <Can I="update" this={post.parish as Parish}>
          <PinButton {...post} />
        </Can>
        <Can I="update" this={(post as unknown) as Post}>
          <EditButton {...post} />
        </Can>
        <Can I="delete" this={(post as unknown) as Post}>
          <DeleteButton {...post} />
        </Can>
      </Box>
    </Box>
  )
}

const ShareButton = ({ title, parish }: PostQuery['post']) => {
  const clipboard = useClipboard({ copiedTimeout: 600 })
  const url = typeof document !== 'undefined' ? document.location.href : ''

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Item onClick={() => clipboard.copy(url)} icon={LinkIcon}>
            Copy Link
          </Menu.Item>
          <Menu.Item
            is="a"
            href={getTwitterShareURL({
              url,
              text: `${title} from ${
                parish?.twitter?.username ? '@' + parish?.twitter?.username : parish?.name
              }`,
            })}
            icon={TwitterIcon}
            target="_blank"
            rel="noopener noreferer"
          >
            Share on Twitter
          </Menu.Item>
          <Menu.Item
            is="a"
            href={getFacebookShareURL(url)}
            icon={FacebookIcon}
            target="_blank"
            rel="noopener noreferer"
          >
            Share on Facebook
          </Menu.Item>
        </Menu>
      }
    >
      <Button is="a" height={32} round iconBefore={ShareIcon}>
        Share
      </Button>
    </Popover>
  )
}

const DeleteButton = ({ id, title }: PostQuery['post']) => {
  const { history } = useRouter()
  const [isShown, setIsShown] = useState(false)
  const [deletePost, { loading }] = useDeletePostMutation({
    variables: { id },
    refetchQueries: ['PostQuery', 'PinnedQuery', 'FeaturedPostsQuery'],
  })

  return (
    <Button
      isLoading={loading}
      onClick={() => setIsShown(true)}
      height={32}
      marginLeft={majorScale(1)}
      iconBefore={Trash2Icon}
      intent="danger"
      round
    >
      <Dialog
        isShown={isShown}
        intent="danger"
        title="Are you sure you want to delete this post?"
        confirmLabel="Delete"
        onConfirm={async () => {
          await deletePost({
            update: (cache, { data: { deletePost } }) => {
              cache.evict({ id: deletePost })
            },
          }).then(() => history.push('/posts'))
        }}
      >
        "{title}" will be permanently deleted
      </Dialog>
      Delete
    </Button>
  )
}

const EditButton = ({ id }: PostQuery['post']) => {
  return (
    <Button
      is={Link}
      to={`/post/${id}/edit`}
      height={32}
      marginLeft={majorScale(1)}
      iconBefore={Edit2Icon}
      round
    >
      Edit Post
    </Button>
  )
}

const PinButton = ({ id, pinned }: PostQuery['post']) => {
  const [pinPost, { loading }] = usePinPostMutation({
    variables: { id, pinned: !pinned },
    refetchQueries: ['PostQuery', 'PinnedQuery', 'FeaturedPostsQuery'],
  })

  return (
    <Button
      height={32}
      appearance={pinned ? 'primary' : 'default'}
      iconBefore={AwardIcon}
      onClick={pinPost}
      isLoading={loading}
      round
    >
      {pinned ? 'Remove Pin' : 'Pin'}
    </Button>
  )
}

const LikeButton = ({ id, viewer, likeCount }: PostQuery['post']) => {
  const { history } = useRouter()
  const [likePost] = useLikePostMutation({
    variables: { id },
    onError: (e) => {
      if (e.graphQLErrors.find((error) => error.extensions.code === 'UNAUTHENTICATED')) {
        history.push('/login')
      }
    },
  })
  const [unlikePost] = useUnlikePostMutation({
    variables: { id },
    onError: (e) => {
      if (e.graphQLErrors.find((error) => error.extensions.code === 'UNAUTHENTICATED')) {
        history.push('/login')
      }
    },
  })

  const viewerLikesPost = viewer && viewer.likesPost

  if (!id) return null

  return (
    <Button
      onClick={viewerLikesPost ? unlikePost : likePost}
      iconAfter={viewerLikesPost ? HeartFilledIcon : HeartIcon}
      round
      marginRight={majorScale(1)}
      appearance={viewerLikesPost ? 'primary' : 'default'}
      intent="danger"
      height={32}
    >
      {likeCount}
    </Button>
  )
}

export default SinglePostActions
