import { AddUserMutationResult } from '../graphql/generated/graphql-hooks'
import { Card, Heading, Link, majorScale, Paragraph, Text } from '@parishconnect/react-ui'
import React from 'react'
import { Link as RLink } from 'react-router-dom'
import useRouter from 'use-react-router'
import { PageContainer } from '../components'

export default function SignupSuccess() {
  const { location } = useRouter()
  const state: AddUserMutationResult['data']['addUser'] = location?.state || {
    fullName: 'Evan Hennessy',
  }

  return (
    <PageContainer
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={majorScale(2)}
    >
      <Card
        appearance="white"
        elevation={2}
        paddingY={majorScale(4)}
        paddingX={majorScale(8)}
        textAlign="center"
        maxWidth={majorScale(60)}
      >
        <Heading size={600} marginBottom={majorScale(1)}>
          Hi {state?.fullName} 👋
        </Heading>
        <Text color="theme" size={500}>
          Welcome to ParishConnect.
        </Text>
        <Paragraph marginY={majorScale(3)}>
          We sent a confirmation email to {state?.email || 'your email address'} and automatically
          added you {state?.primaryParish?.name || 'this parish'}.
        </Paragraph>
        <Link is={RLink} to="/">
          Click here to go home
        </Link>
      </Card>
    </PageContainer>
  )
}
