import Box from '@parishconnect/box'
import {
  Card,
  ChevronRightIcon,
  Heading,
  HeartIcon,
  majorScale,
  Paragraph,
  Strong,
  Text,
} from '@parishconnect/react-ui'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { AnalyticsQuery, LikedPost, useAnalyticsQuery } from '../../graphql/generated/graphql-hooks'
import { EmptyState } from '../shared/EmptyState'

export function Analytics() {
  const { data, loading } = useAnalyticsQuery()

  if (!data) return <EmptyAnalytics></EmptyAnalytics>

  return (
    <Box width="100%" maxWidth={majorScale(65)}>
      <Heading marginY={[majorScale(4), majorScale(2)]} size={800}>
        Analytics
      </Heading>
      <Paragraph color="muted">
        ParishConnect analytics are designed to protect your parishioners' privacy while giving you
        meaningful insights. We don't put any third-party trackers on your website.
      </Paragraph>
      {!data && !loading ? <EmptyAnalytics /> : <AnalyticsBody {...data} />}
    </Box>
  )
}

function AnalyticsBody({ analytics }: AnalyticsQuery) {
  const itemCounts = {
    groups: analytics.groupCount,
    events: analytics.eventsCounts,
    members: analytics.memberCount,
    posts: analytics.postCount,
  }

  const topItems = {
    'Top Posts': analytics.topLikedPosts,
  }

  return (
    <Fragment>
      <Heading paddingTop={majorScale(4)} paddingBottom={majorScale(2)}>
        By the numbers
      </Heading>
      <Box display="grid" gridAutoFlow="column" gridGap={majorScale(3)}>
        {Object.entries(itemCounts).map(([title, number]) => (
          <NumberAnalytic key={title} number={number} title={title} />
        ))}
      </Box>
      <Box>
        {Object.entries(topItems).map(([title, items]) => (
          <Box key={title} display="grid" gridAutoFlow="row" gridGap={majorScale(1)}>
            <Heading paddingTop={majorScale(4)} paddingBottom={majorScale(2)}>
              {title}
            </Heading>
            {items.map((item) => (
              <LikesAnalytic {...item} key={item.id} />
            ))}
          </Box>
        ))}
      </Box>
      {/* <pre>{JSON.stringify(analytics, null, 3)}</pre> */}
    </Fragment>
  )
}

function EmptyAnalytics() {
  return (
    <EmptyState title="No analytics to show">
      Sorry, we don't have any analytics to show you yet,
    </EmptyState>
  )
}

// Subcomponents
function NumberAnalytic({ number, title }: { number: number; title: string }) {
  return (
    <Card appearance="solid" paddingX={majorScale(2)} paddingY={majorScale(3)} elevation={0}>
      <Heading color="theme" size={900}>
        {number ?? 0}
      </Heading>
      <Strong size={300} textTransform="capitalize">
        {title}
      </Strong>
    </Card>
  )
}

function LikesAnalytic({ likeCount, title, id }: LikedPost) {
  return (
    <Card
      is={Link}
      to={`/post/${id}`}
      appearance="solid"
      padding={majorScale(2)}
      elevation={1}
      hoverElevation={2}
      display="flex"
      alignItems="center"
    >
      <Box>
        <Strong>{title}</Strong>
        <Text display="flex" alignItems="center" color="theme">
          <HeartIcon marginRight={majorScale(1)} size={14} /> {likeCount ?? 0} likes
        </Text>
      </Box>
      <ChevronRightIcon marginLeft="auto" />
    </Card>
  )
}
