import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import { Card, CheckIcon, Heading, majorScale, toaster } from '@parishconnect/react-ui'
import React from 'react'
import { ParishQuery, Themes, useEditParishMutation } from '../../graphql/generated/graphql-hooks'

export function ThemeSelector({ theme, ...rest }: ParishQuery['parish']) {
  const [editParish] = useEditParishMutation()

  const themes = Object.values(Themes).filter(t => t !== 'neutral')

  return (
    <>
      <Heading marginTop={majorScale(3)} size={500}>
        Theme Color
      </Heading>
      <Box
        display="inline-grid"
        gridAutoFlow="column"
        gridGap={majorScale(1)}
        marginY={majorScale(2)}
      >
        {themes.map(themeColor => (
          <Card
            key={themeColor}
            background={themeColor}
            width={[majorScale(5), majorScale(7)]}
            height={[majorScale(5), majorScale(7)]}
            display="flex"
            onClick={async () => {
              if (theme === themeColor) return
              try {
                await editParish({
                  variables: { parish: { theme: themeColor } },
                  optimisticResponse: {
                    __typename: 'Mutation',
                    editParish: {
                      ...rest,
                      theme: themeColor,
                    },
                  },
                })
                toaster.success('Theme Updated', { id: 'theme-selector' })
              } catch (error) {
                toaster.danger('Error updating theme')
                Sentry.captureException(error)
              }
            }}
            transition="box-shadow 125ms"
            cursor={theme !== themeColor && 'pointer'}
            css={{
              ...(theme === themeColor
                ? {
                    boxShadow: '0 0 0 3px #2D2D2D',
                  }
                : {
                    '&:hover': {
                      boxShadow: '0 0 0 3px #CCC',
                    },
                  }),
            }}
          >
            {theme === themeColor && (
              <CheckIcon
                color="white"
                background="#2d2d2d"
                bottom={0}
                right={0}
                padding={2}
                borderTopLeftRadius={4}
                position="absolute"
              />
            )}
          </Card>
        ))}
      </Box>
    </>
  )
}
