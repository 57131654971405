import { Ability } from '@casl/ability'
import Box from '@parishconnect/box'
import { majorScale, Spinner, WindIcon } from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, RouteComponentProps, useLocation } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import {
  GroupMembersModule,
  Nav,
  PageContainer,
  Seo,
  SingleGroupActions,
  SingleGroupDescription,
  SingleGroupEvents,
  SingleGroupFeaturedPosts,
  SingleGroupImage,
} from '../components'
import { GroupDisabledBanner } from '../components/Group/GroupDisabledBanner'
import { GroupEvents } from '../components/Group/GroupEvents'
import { GroupPosts } from '../components/Group/GroupPosts'
import { GroupSettings } from '../components/Group/GroupSettings'
import { GroupQuery, useGroupQuery } from '../graphql/generated/graphql-hooks'
import { AbilityContext } from '../utils'
import { COLUMN } from '../utils/constants'
import { EmptyState } from '../components/shared/EmptyState'

const createGroupSubmenu = (data: GroupQuery, ability: Ability) => {
  return [
    { title: 'Home', id: 'home', to: ``, root: true },
    data?.posts?.length > 0 && { title: 'Posts', id: 'posts', to: 'posts' },
    data?.events?.length > 0 && { title: 'Events', id: 'events', to: 'events' },
    ability.can('update', data.group) && {
      title: 'Settings',
      id: 'settings',
      to: 'settings',
    },
    // Filter out empty objects
  ].filter(Boolean)
}

export default function Group({ match, history }: RouteComponentProps<{ id: string }>) {
  const { id } = match.params
  const location = useLocation()
  const ability = useContext(AbilityContext)

  const { loading, data, error } = useGroupQuery({
    variables: { id },
  })
  const group = data?.group

  if (loading) {
    return <Spinner />
  }

  if (!loading && !group) {
    return <Redirect to="/groups" />
  }

  const groupSubMenu = createGroupSubmenu(data, ability)

  return (
    <PageContainer>
      <Helmet>
        <title>{group?.name}</title>
      </Helmet>
      <Seo
        title={group?.name}
        key={group?.id}
        description={group?.excerpt}
        pathname={location.pathname}
        image={group?.cover?.src}
        updatedAt={group?.updatedAt}
        createdAt={group?.createdAt}
        parish={group?.parish}
        type="group"
      />
      <Box display={['block', null, 'flex']}>
        <Box width={[0, null, COLUMN * 6]}>
          <Nav
            title={data?.group?.name}
            showMasses
            subMenu={groupSubMenu}
            root={`/group/${group.id}`}
          />
        </Box>
        <Box width={['100%', null, COLUMN * 17.5]} marginLeft={[0, null, COLUMN / 2]} is="main">
          <SingleGroupImage history={history} {...group} />
          <Box
            display="flex"
            flexDirection={['column-reverse', null, 'row']}
            marginY={COLUMN / 2}
            marginX={[majorScale(1), COLUMN / 2]}
            id="main-content"
          >
            <Box width={['100%', null, COLUMN * 12]}>
              <GroupDisabledBanner {...group} />
              <SingleGroupActions {...data} />
              <Switch>
                <Route path="/group/:id" exact render={() => <GroupHomeRoute {...data} />} />
                <Route path="/group/:id/posts" component={GroupPosts} />
                <Route path="/group/:id/events" component={GroupEvents} />
                <Route path="/group/:id/settings" render={() => <GroupSettings {...data} />} />
              </Switch>
            </Box>
            <Box
              width={['100%', null, COLUMN * 4.5]}
              paddingBottom={[majorScale(1), null, 0]}
              marginBottom={[majorScale(3), null, 0]}
              borderBottom={['1px solid #efefef', null, 0]}
            >
              <GroupMembersModule {...group} />
              <SingleGroupDescription {...group} />
            </Box>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  )
}

function GroupHomeRoute(data: GroupQuery) {
  return data?.events?.length <= 0 && data?.posts?.length <= 0 ? (
    <EmptyState icon={WindIcon} title="Empty">
      There's nothing to see here...
    </EmptyState>
  ) : (
    <Box display="flex" flexDirection="column" gap={majorScale(2)}>
      <SingleGroupEvents {...data} />
      <SingleGroupFeaturedPosts {...data} />
    </Box>
  )
}
