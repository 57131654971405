import Box from '@parishconnect/box'
import {
  Card,
  majorScale,
  minorScale,
  Pane,
  Pill,
  Strong,
  Text,
  ThemeContext,
} from '@parishconnect/react-ui'
import { groupBy, isEmpty } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useContext } from 'react'
import { Dictionary } from '../../../typings/dictionary'
import { ExceptionType } from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils/constants'
import { SpecialMassTableIcon } from './SpecialMassTableIcon'
import { ComputedMass, getRelevantDateTimeParts, SpecialMassGroup } from './utils'

const DATE = { month: 'short', day: 'numeric' }
const TIME = { hour: 'numeric', minute: '2-digit' }

type MassTableProps = {
  masses: ComputedMass[]
  heading: string
  appearance?: 'white'
}

export const MassTable = ({ masses, heading, appearance }: MassTableProps) => {
  const groupedMasses = groupBy(masses, (mass) => mass.nextDate?.weekday)

  if (isEmpty(groupedMasses)) return null

  return <MassListing masses={groupedMasses} heading={heading} appearance={appearance} />
}

type MassListingProps = {
  masses: Dictionary<ComputedMass[]>
  heading?: string
  appearance?: MassTableProps['appearance']
}

type SpecialMassTableProps = {
  masses: SpecialMassGroup
}

export const SpecialMassTable = ({ masses }: SpecialMassTableProps) => {
  if (isEmpty(masses)) return null

  return Object.entries(masses).map(([seasonName, { days, id, from }]) => {
    if (from && DateTime.fromISO(from) > DateTime.local()) {
      return null
    }

    return (
      <Card
        marginBottom={majorScale(4)}
        padding={majorScale(4)}
        key={id}
        appearance="solid"
        borderRadius={14}
        display="inline-block"
        marginRight="auto"
        width={['100%', 'auto']}
        position="relative"
        overflow="hidden"
      >
        <SpecialMassTableIcon seasonName={seasonName} />
        <Strong textTransform="uppercase" size={500} fontWeight={700} color="theme">
          {seasonName} Schedule
        </Strong>
        <Box
          marginTop={majorScale(2)}
          display="grid"
          gridTemplateColumns={[
            '1fr',
            `repeat(2, minmax(${majorScale(34)}px, ${majorScale(35)}px))`,
          ]}
          gridColumnGap={COLUMN}
        >
          {Object.entries(days).map(([day, masses], i, arr) => (
            <Pane
              key={day}
              display="flex"
              borderBottom={i < arr.length - 1 && 'muted'}
              paddingBottom={majorScale(1)}
              marginBottom={majorScale(1)}
            >
              <Strong size={500} whiteSpace="nowrap">
                {DateTime.fromMillis(Number(day)).toLocaleString(DATE)}
              </Strong>
              <Box display="flex" flexDirection="column" marginLeft="auto">
                {masses.map((mass, index) => (
                  <Pane
                    key={mass.id}
                    marginLeft="auto"
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                  >
                    {mass.title && masses[index - 1]?.title !== mass.title && (
                      <Text display="inline-block" textAlign="right">
                        {mass.title}
                      </Text>
                    )}
                    <Pane borderBottom={false}>
                      <Pane display="flex" flexDirection="column">
                        <Strong size={500} textAlign="right">
                          {DateTime.fromISO(mass.start).toLocaleString(TIME)}
                        </Strong>
                        {mass.note && (
                          <Text textAlign="right" size={300} color="theme">
                            {mass.note}
                          </Text>
                        )}
                      </Pane>
                    </Pane>
                  </Pane>
                ))}
              </Box>
            </Pane>
          ))}
        </Box>
      </Card>
    )
  })
}

function MassListing({ masses, heading = '', appearance }: MassListingProps) {
  const massArray = Object.values(masses)
  const theme = useContext(ThemeContext)

  if (massArray.length <= 0) return null

  return (
    <Box>
      <Strong
        textTransform="uppercase"
        size={500}
        fontWeight={700}
        color={appearance || theme.palette[theme.themeColor].text}
        marginBottom={majorScale(1)}
      >
        {heading}
      </Strong>
      {massArray.map((masses, i) => (
        <Pane
          key={i}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          paddingY={majorScale(2)}
          borderBottom={i !== massArray.length - 1 && 'muted'}
        >
          <Text color={appearance ?? null} size={500}>
            {masses?.[0]?.nextDate?.weekdayLong}
          </Text>
          <Pane display="grid" gridTemplateRows="auto" gridGap={minorScale(3)}>
            {masses.map((mass) => {
              return <MassEntry appearance={appearance} key={mass.id} mass={mass} />
            })}
          </Pane>
        </Pane>
      ))}
    </Box>
  )
}

export const formatExceptionText = (
  exType: string,
  oldDate?: DateTime,
  newDate?: DateTime,
  solid = false,
) => {
  switch (exType) {
    case 'CANCEL':
      return (
        <Pill whiteSpace="nowrap" isSolid={solid} color="red">{`Cancelled ${oldDate?.toLocaleString(
          {
            month: 'short',
            day: 'numeric',
          },
        )}`}</Pill>
      )
    case 'MODIFY':
      return (
        <Pill whiteSpace="nowrap" isSolid={solid} color="orange">{`${
          solid
            ? ''
            : newDate.toLocaleString({
                month: 'short',
                day: 'numeric',
              }) + ' • '
        }usually ${oldDate?.toLocaleString(
          getRelevantDateTimeParts(oldDate, newDate, { relative: true }),
        )}`}</Pill>
      )
    default:
      return null
  }
}

function MassEntry({
  mass,
  appearance,
}: {
  mass: ComputedMass
  appearance?: MassTableProps['appearance']
}) {
  const { newDate, exType, oldDate } = mass
  const theme = useContext(ThemeContext)

  return (
    <Box key={mass.id} textAlign="right">
      <Strong
        color={appearance ?? null}
        textDecoration={exType === ExceptionType.Cancel && 'line-through'}
        size={500}
      >
        {newDate ? newDate.toLocaleString(TIME) : mass.nextDate?.toLocaleString(TIME)}
      </Strong>
      {exType && (
        <Text
          cursor="help"
          size={300}
          color={appearance || 'warning'}
          display="block"
          alignItems="center"
        >
          {formatExceptionText(exType, oldDate, mass.nextDate)}
        </Text>
      )}
      {mass.note && (
        <Text size={300} display="block" color={appearance || theme.palette[theme.themeColor].text}>
          {mass.note}
        </Text>
      )}
    </Box>
  )
}
