import Box from '@parishconnect/box'
import { Card, majorScale, Strong, Text } from '@parishconnect/react-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import { getShortEventDateString } from '../../utils'
import { Event } from '../../graphql/generated/graphql-hooks'

export const FeaturedEvent = ({ title, parish, dtstart, duration, id }: Event) => {
  return (
    <Link to={`/event/${id}`}>
      <Card
        maxWidth={majorScale(38)}
        marginBottom={majorScale(1)}
        appearance="solid"
        hoverElevation={2}
        cursor="pointer"
        padding={majorScale(2)}
      >
        <Strong>{title}</Strong>
        <Box display="flex" justifyContent="space-between">
          <Text color="theme" size={300}>
            {parish?.name}
          </Text>
          <Text color="theme" size={300}>
            {getShortEventDateString(dtstart, duration)}
          </Text>
        </Box>
      </Card>
    </Link>
  )
}
