import { Parish, RoleDomain, User, Role } from '../../graphql/generated/graphql-hooks'

export function userBelongsToParish(user: Partial<User>, parish: Partial<Parish>): boolean {
  if (getUserParishes(user).includes(parish?.id)) {
    return true
  }
  return false
}

export function getUserParishes(user: User): string[] {
  return [
    ...new Set(
      user?.roles?.filter((role) => role.domain === RoleDomain.Parish).map((role) => role.id) ?? [],
    ),
  ]
}

export function getRoleForParish(user: Partial<User>, parish: Partial<Parish>): Role {
  return user?.roles?.find((role) => role?.id === parish?.id)
}
