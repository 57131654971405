import Box from '@parishconnect/box'
import {
  Card,
  HomeIcon,
  InfoIcon,
  majorScale,
  Menu,
  Pill,
  ThemeContext,
  UsersIcon,
  HeartIcon,
  AtSignIcon,
  TrendingUpIcon,
} from '@parishconnect/react-ui'
import React, { useContext, ReactNode } from 'react'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import { DashboardHome, PageContainer, GroupsManager, DonationsManager } from '../components'
import { ParishInfo } from '../components/Dashboard/ParishInfo'
import { GroupStatus, useGroupsQuery, GroupsQuery } from '../graphql/generated/graphql-hooks'
import { AbilityContext } from '../utils'
import { SocialMediaManager } from '../components/Dashboard/SocialMediaManager'
import { Analytics } from '../components/Dashboard/Analytics'
import { MobileNav } from '../components/Nav/MobileNav'
import { MenuItemProps } from '@parishconnect/react-ui/dist/menu/src/MenuItem'
import { Helmet } from 'react-helmet-async'

function dashBoardNavigation(
  data: GroupsQuery,
): {
  exact?: boolean
  icon: ReactNode
  is: ReactNode
  to: string
  title: string
  secondaryText?: JSX.Element
}[] {
  return [
    {
      exact: true,
      icon: HomeIcon,
      is: NavLink,
      to: '',
      title: 'Home',
    },
    {
      title: 'Groups',
      icon: UsersIcon,
      is: NavLink,
      to: 'groups',
      secondaryText: data?.groups.filter((g) => g.status === GroupStatus.Pending).length > 0 && (
        <Pill color="red" isSolid>
          {data?.groups.length}
        </Pill>
      ),
    },
    {
      icon: HeartIcon,
      is: NavLink,
      to: 'donations',
      title: 'Donations',
    },
    {
      icon: TrendingUpIcon,
      is: NavLink,
      to: 'analytics',
      title: 'Analytics',
    },
    {
      icon: AtSignIcon,
      is: NavLink,
      to: 'social-media',
      title: 'Social Media',
    },
    {
      icon: InfoIcon,
      is: NavLink,
      to: 'info',
      title: 'Parish Information',
    },
  ]
}

export default function Dashboard() {
  const theme = useContext(ThemeContext)
  const ability = useContext(AbilityContext)
  const { data, loading } = useGroupsQuery()

  if (ability && ability.cannot('update', 'parish')) {
    return <Redirect to="/login" />
  }

  const menu = dashBoardNavigation(data)

  return (
    <PageContainer>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box display="flex" flexDirection={['column', 'row']}>
        <Card
          display={['none', null, 'block']}
          appearance="solid"
          marginRight={majorScale(5)}
          minWidth={majorScale(32)}
          width={['100%', 'auto']}
          marginBottom="auto"
          css={{
            'a.active': {
              backgroundColor: theme.fills.subtle[theme.themeColor].backgroundColor,
              color: theme.getThemeColor(theme),
              'svg *': {
                stroke: theme.getThemeColor(theme) + '!important',
              },
              span: {
                fontWeight: 600,
              },
            },
          }}
        >
          <Menu>
            <Menu.Group title="Dashboard">
              {menu.map(({ title, to, ...item }) => (
                <Menu.Item key={to} to={`/dashboard/${to}`} {...item}>
                  {title}
                </Menu.Item>
              ))}
            </Menu.Group>
          </Menu>
        </Card>
        <MobileNav
          root="/dashboard"
          subMenu={menu.map((item) => ({
            exact: item.exact,
            id: item.to,
            title: item.title,
            to: item.to,
          }))}
        />
        <Switch>
          <Route exact path="/dashboard" component={DashboardHome} />
          <Route exact path="/dashboard/analytics" component={Analytics} />
          <Route exact path="/dashboard/info" component={ParishInfo} />
          <Route exact path="/dashboard/donations" component={DonationsManager} />
          <Route exact path="/dashboard/social-media" component={SocialMediaManager} />
          <Route
            exact
            path="/dashboard/groups"
            children={<GroupsManager {...data} loading={loading} />}
          />
        </Switch>
      </Box>
    </PageContainer>
  )
}
