import Box from '@parishconnect/box'
import { Avatar, Edit2Icon, IconButton, majorScale } from '@parishconnect/react-ui'
import React, { useState } from 'react'
import { FeaturedImageUpload } from '..'
import { useEditUserMutation, UserQuery } from '../../graphql/generated/graphql-hooks'
import { useCan } from '../../utils'

export const BigAvatar = (data: UserQuery) => {
  const canUpdate = useCan('update', data?.user)
  const [isEditing, setIsEditing] = useState(false)
  const [editUser] = useEditUserMutation()
  const size = majorScale(24)

  return isEditing ? (
    <FeaturedImageUpload
      onUpload={() => {}}
      allowReposition
      aspectRatio={1}
      round
      width={size}
      height={size}
      initialFile={data?.user?.image?.id}
      onSubmit={async ({ imageId }) => {
        try {
          await editUser({
            variables: { user: { image: imageId } },
          })
          setIsEditing(false)
        } catch (error) {}
      }}
      onRemove={async () => {
        try {
          await editUser({ variables: { user: { image: null } } })
        } catch (error) {}
      }}
    />
  ) : (
    <Box position="relative">
      <Avatar
        size={size}
        width={[size / 2, size]}
        height={[size / 2, size]}
        name={`${data?.user?.firstName} ${data?.user?.lastName}`}
        src={data?.user?.image?.src}
        srcSet={data?.user?.image?.srcset}
        position="relative"
        cursor="pointer"
      />
      {canUpdate && (
        <IconButton
          onClick={() => setIsEditing(true)}
          round
          appearance="primary"
          icon={Edit2Icon}
          position="absolute"
          bottom={majorScale(2)}
          right={majorScale(2)}
          zIndex={9999}
        />
      )}
    </Box>
  )
}
