import Box from '@parishconnect/box'
import { Avatar, Heading, majorScale, Tooltip } from '@parishconnect/react-ui'
import React from 'react'
import { Group, GroupQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils/constants'

export const GroupMembersModule = ({ members, memberCount, id }: GroupQuery['group']) => {
  return (
    <Box marginBottom={COLUMN / 2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={majorScale(2)}
      >
        <Heading size={600}>Members ({memberCount})</Heading>
      </Box>
      <Box display="flex">
        {members.map((member, i) => (
          <Tooltip key={member?.id} content={member?.fullName}>
            <Avatar
              marginLeft={i !== 0 && majorScale(1)}
              size={majorScale(5)}
              src={member?.image?.src}
              name={member?.fullName}
            />
          </Tooltip>
        ))}
      </Box>
    </Box>
  )
}
