import Box from '@parishconnect/box'
import {
  FacebookIcon,
  IconButton,
  InstagramIcon,
  Link,
  majorScale,
  minorScale,
  Text,
  TwitterIcon,
  YoutubeIcon,
} from '@parishconnect/react-ui'
import { pick } from 'lodash-es'
import React, { ReactNode } from 'react'
import { SocialMedia, useParishQuery } from '../../graphql/generated/graphql-hooks'
import { FACEBOOK_COLOR, INSTAGRAM_COLOR, TWITTER_COLOR, YOUTUBE_COLOR } from '../../utils'

const socialMediaDictionary: {
  [account: string]: { url: (username: string) => string; icon: ReactNode; color: string }
} = {
  facebook: {
    url: (username: string) => `https://facebook.com/${username}`,
    icon: FacebookIcon,
    color: FACEBOOK_COLOR,
  },
  instagram: {
    url: (username: string) => `https://instagram.com/${username}`,
    icon: InstagramIcon,
    color: INSTAGRAM_COLOR,
  },
  twitter: {
    url: (username: string) => `https://twitter.com/${username}`,
    icon: TwitterIcon,
    color: TWITTER_COLOR,
  },
  youtube: {
    url: (username: string) => `https://youtube.com/channel/${username}`,
    icon: YoutubeIcon,
    color: YOUTUBE_COLOR,
  },
}

const Footer = () => {
  const { data } = useParishQuery()
  const socialMediaAccounts: { [key: string]: SocialMedia } = pick(data?.parish, [
    'twitter',
    'facebook',
    'instagram',
    'youtube',
  ])

  return (
    <Box marginTop={majorScale(2)} display={['none', null, 'block']} is="footer">
      {Object.values(socialMediaAccounts ?? {}).length >= 1 && (
        <Box display="flex" marginX={-majorScale(1)} marginBottom={majorScale(2)}>
          {Object.entries(socialMediaAccounts ?? {})
            .filter(([_, { username }]) => !!username)
            .map(([account, { username }]) => {
              const { icon, color, url } = socialMediaDictionary[account]
              return (
                <IconButton
                  key={account}
                  round
                  color={color}
                  marginX={minorScale(1)}
                  is="a"
                  rel="noopener"
                  target="_blank"
                  appearance="minimal"
                  href={url(username)}
                  icon={icon}
                />
              )
            })}
        </Box>
      )}
      <Box paddingTop={majorScale(2)}>
        <Box display="flex" alignItems="center">
          <Link
            size={300}
            target="_blank"
            noUnderline
            href="//parishconnect.io/privacy"
            rel="noopener"
          >
            Privacy
          </Link>
          <Text paddingX={minorScale(1)}>•</Text>
          <Link
            size={300}
            target="_blank"
            noUnderline
            href="//parishconnect.io/terms-conditions"
            rel="noopener"
          >
            Terms
          </Link>
          <Text paddingX={minorScale(1)}>•</Text>
          <Link
            size={300}
            target="_blank"
            noUnderline
            href="//parishconnect.io/cookie-policy"
            rel="noopener"
          >
            Cookies
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
