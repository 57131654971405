import { Heading, majorScale } from '@parishconnect/react-ui'
import { HeadingProps } from '@parishconnect/react-ui/dist/typography/src/Heading'
import { ObjectMark } from '@remirror/core'
import React from 'react'
import { normalizeMark, TextHandlerProps } from '.'

export function HeadingHandler({ node, markMap, skipUnknownMarks }: TextHandlerProps) {
  const content = node.content
  if (!content) {
    return null
  }

  let level = node.attrs ? node.attrs['level'] : 1
  if (typeof level !== 'number') level = 1

  const size: HeadingProps['size'] = level === 1 ? 700 : 500

  const fn = (child: JSX.Element | string, mark: ObjectMark | string) => {
    const normalized = normalizeMark(mark)
    const MarkHandler = markMap[normalized.type]

    if (!MarkHandler) {
      if (!skipUnknownMarks) {
        console.warn(
          !skipUnknownMarks,
          'No handler for mark type `' + normalized.type + '` registered',
        )
      }

      return child
    }

    return <MarkHandler {...normalized.attrs}>{child}</MarkHandler>
  }

  return (
    <Heading is={`h${level}`} size={size} marginY={majorScale(1)}>
      {content.map((node, ii) => {
        if (!node.marks) return node?.text
        return node.marks.reduce(fn, node?.text)
      })}
    </Heading>
  )
}
