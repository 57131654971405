import Box from '@parishconnect/box'
import {
  Button,
  Card,
  Dialog,
  Edit2Icon,
  majorScale,
  SaveIcon,
  toaster,
  Trash2Icon,
} from '@parishconnect/react-ui'
import { EMPTY_PARAGRAPH_NODE } from '@remirror/core'
import * as Sentry from '@sentry/browser'
import JSONparse from 'easy-json-parse'
import { Form, Formik } from 'formik'
import { debounce } from 'lodash-es'
import React from 'react'
import useRouter from 'use-react-router'
import { object, string } from 'yup'
import {
  Parish,
  Sacrament,
  SacramentsDocument,
  SacramentsQuery,
  useDeleteSacramentMutation,
  useEditSacramentMutation,
} from '../../graphql/generated/graphql-hooks'
import { useCan, useToggle } from '../../utils'
import { Editor, Renderer } from '../Editor'

export const sacramentValidationSchema = object().shape({
  description: string().required(),
})

type GenericSacramentBody = {
  editable?: boolean
  sacrament: Sacrament
  parish: Parish
}

export function GenericSacramentBody({ editable = true, sacrament, parish }: GenericSacramentBody) {
  const [editSacrament] = useEditSacramentMutation()
  const [editing, toggleEditing] = useToggle(false)
  const [confirmDelete, toggleConfirmDelete] = useToggle(false)
  const { history } = useRouter()
  const [deleteSacrament] = useDeleteSacramentMutation({
    variables: { id: sacrament?.id },
    update: (cache, { data: { deleteSacrament } }) => {
      try {
        const data = cache.readQuery<SacramentsQuery>({ query: SacramentsDocument })
        if (data?.sacraments) {
          cache.writeQuery<SacramentsQuery>({
            query: SacramentsDocument,
            data: {
              ...data,
              sacraments: data.sacraments.filter((sacrament) => sacrament.id !== deleteSacrament),
            },
          })
        }
        toaster.success('Success!', { description: `${sacrament?.title} deleted` })
        history.push('/sacraments')
      } catch (error) {
        Sentry.captureException(error)
        toaster.danger('Error', { description: `${sacrament?.title} couldn't be deleted` })
      }
    },
  })
  const canUpdate = useCan('update', { ...sacrament, parish })

  return (
    <Box display="flex" flexDirection="column" marginX={[majorScale(2), 0]}>
      {editing ? (
        <Formik
          enableReinitialize
          validationSchema={sacramentValidationSchema}
          initialValues={{
            description: sacrament?.description ?? '',
          }}
          onSubmit={async ({ description }, actions) => {
            try {
              await editSacrament({
                variables: {
                  id: sacrament?.id,
                  sacrament: {
                    description,
                  },
                },
              })

              actions.setSubmitting(false)
              toggleEditing()
              toaster.success('Success!', { description: `"${sacrament.title}" saved.` })
            } catch (error) {
              toaster.danger('Error.', { description: `"${sacrament.title}" failed to save.` })
              Sentry.captureException(error)
            }
          }}
        >
          {({ values, setFieldValue, submitForm }) => (
            <Form>
              <Editor
                placeholder="Write Something..."
                initialContent={
                  JSONparse(values.description, { initialValue: EMPTY_PARAGRAPH_NODE })[1]
                }
                onChange={debounce(
                  (value: any) =>
                    setFieldValue('description', JSON.stringify(value.getObjectNode())),
                  350,
                )}
              />
              <Dialog
                isShown={confirmDelete}
                onConfirm={(close: () => void) => {
                  close()
                  deleteSacrament()
                }}
                onCloseComplete={toggleConfirmDelete}
                confirmLabel="Delete"
                title="Delete Sacrament"
              >
                Are you sure you want to delete {sacrament?.title}?
              </Dialog>
              <Card
                appearance="solid"
                width="100%"
                display="flex"
                justifyContent="space-between"
                padding={majorScale(1)}
                marginY={majorScale(3)}
              >
                <Button
                  intent="danger"
                  onClick={toggleConfirmDelete}
                  type="button"
                  appearance="primary"
                  iconBefore={Trash2Icon}
                  marginRight="auto"
                >
                  Delete
                </Button>
                <Box>
                  <Button intent="danger" onClick={toggleEditing} type="button">
                    Cancel
                  </Button>
                  <Button
                    marginLeft={majorScale(1)}
                    intent="success"
                    type="submit"
                    appearance="primary"
                    iconBefore={SaveIcon}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      ) : (
        <Box minWidth="100%">
          <Renderer document={sacrament?.description} />
        </Box>
      )}
      {!editing && editable && canUpdate && (
        <Box marginLeft="auto">
          <Button onClick={toggleEditing} type="button" iconBefore={Edit2Icon}>
            Edit
          </Button>
        </Box>
      )}
    </Box>
  )
}
