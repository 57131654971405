import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import {
  Button,
  Card,
  CornerUpLeftIcon,
  Dialog,
  Edit2Icon,
  majorScale,
  SaveIcon,
  TextInputField,
  toaster,
  Trash2Icon,
} from '@parishconnect/react-ui'
import { EMPTY_PARAGRAPH_NODE } from '@remirror/core'
import JSONparse from 'easy-json-parse'
import { Field, Form, Formik } from 'formik'
import { debounce } from 'lodash-es'
import React from 'react'
import useRouter from 'use-react-router'
import * as yup from 'yup'
import {
  About,
  AboutsDocument,
  AboutsQuery,
  Parish,
  useDeleteAboutMutation,
  useEditAboutMutation,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN, useToggle } from '../../utils'
import { Can } from '../../utils/abilityContext'
import { Renderer, Editor } from '../Editor'

export const aboutValidationSchema = new yup.object().shape({
  title: yup.string().required().max(28),
  ast: yup.string().required(),
})

type GenericAboutBody = {
  editable?: boolean
  about: About
  parish: Parish
}

export function GenericAboutBody({ editable = true, about, parish }: GenericAboutBody) {
  const [editAbout] = useEditAboutMutation()
  const { history } = useRouter()
  const [editing, toggleEditing] = useToggle(false)
  const [confirmDelete, toggleConfirmDelete] = useToggle(false)
  const [deleteAbout] = useDeleteAboutMutation({
    variables: { id: about?.id },
    update: (cache, { data: { deleteAbout } }) => {
      try {
        const data = cache.readQuery<AboutsQuery>({ query: AboutsDocument })
        if (data?.abouts) {
          cache.writeQuery<AboutsQuery>({
            query: AboutsDocument,
            data: {
              ...data,
              abouts: data.abouts.filter((about) => about.id !== deleteAbout),
            },
          })
        }
        toaster.success('Success!', { description: `${about?.title} deleted` })
        history.push('/about')
      } catch (error) {
        Sentry.captureException(error)
        toaster.danger('Error', { description: `${about?.title} couldn't be deleted` })
      }
    },
  })

  return (
    <Box position="relative">
      {editing ? (
        <Formik
          enableReinitialize
          validationSchema={aboutValidationSchema}
          initialValues={{ ast: about?.ast ?? '', title: about?.title ?? '' }}
          onSubmit={async (values, actions) => {
            try {
              await editAbout({ variables: { id: about.id, about: values } })
              actions.setSubmitting(false)
              toggleEditing()
              toaster.success('Success!', { description: `"${about.title}" saved.` })
            } catch (error) {
              Sentry.captureException(error)
              toaster.danger('Error.', { description: `"${about.title}" failed to save.` })
            }
          }}
        >
          {({ values, setFieldValue, submitCount, errors }) => (
            <Form>
              <TextInputField
                is={Field}
                label="Title"
                size={900}
                name="title"
                maxLength="23"
                maxWidth={COLUMN * 10}
                validationMessage={submitCount > 0 && errors.title}
              />
              <Editor
                initialContent={JSONparse(values.ast, { initialValue: EMPTY_PARAGRAPH_NODE })[1]}
                onChange={debounce(
                  (value: any) => setFieldValue('ast', JSON.stringify(value.getObjectNode())),
                  350,
                )}
              />
              <Dialog
                isShown={confirmDelete}
                onConfirm={(close: () => void) => {
                  close()
                  deleteAbout()
                }}
                onCloseComplete={toggleConfirmDelete}
                confirmLabel="Delete"
                title="Delete About"
              >
                Are you sure you want to delete {about?.title}?
              </Dialog>
              <Card
                appearance="solid"
                position="absolute"
                width="100%"
                display="flex"
                justifyContent="space-between"
                padding={majorScale(1)}
              >
                <Button
                  intent="danger"
                  onClick={deleteAbout}
                  type="button"
                  appearance="primary"
                  iconBefore={Trash2Icon}
                  marginRight="auto"
                >
                  Delete
                </Button>
                <Box>
                  <Button
                    intent="danger"
                    onClick={toggleEditing}
                    type="button"
                    iconBefore={CornerUpLeftIcon}
                  >
                    Cancel
                  </Button>
                  <Button
                    marginLeft={majorScale(1)}
                    intent="success"
                    type="submit"
                    appearance="primary"
                    iconBefore={SaveIcon}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      ) : (
        <Renderer document={about?.ast} />
      )}
      {!editing && editable && (
        <Can I="update" this={parish}>
          <Box position="absolute" right={0}>
            <Button onClick={toggleEditing} type="button" iconBefore={Edit2Icon}>
              Edit
            </Button>
          </Box>
        </Can>
      )}
    </Box>
  )
}
