import Box from '@parishconnect/box'
import { majorScale } from '@parishconnect/react-ui'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  CurrentBulletinLink,
  FeaturedEvents,
  FeaturedPostsList,
  Footer,
  Hero,
  MassModule,
  Nav,
  SpecialMassTable,
} from '../components'
import { HomeBar, GroupsRecentPosts, PinnedList } from '../components/Home'
import { GroupsHomeList } from '../components/Home/GroupsHomeList'
import { getSpecialMasses, hydrateMasses } from '../components/Mass/utils'
import { useMassesQuery } from '../graphql/generated/graphql-hooks'
import { useMedia } from '../utils'
import { COLUMN, COLUMNS } from '../utils/constants'

const GUTTER = majorScale(4)

export default function Home() {
  const small = useMedia('(max-width: 920px)')
  return (
    <Box
      marginX="auto"
      paddingY={[majorScale(8), majorScale(9)]}
      maxWidth={COLUMN * COLUMNS + GUTTER}
      marginTop="env(safe-area-inset-top)"
      minHeight="-webkit-fill-available"
    >
      <Helmet title="Home" />
      <Box
        display="flex"
        flexWrap={['wrap', null, 'initial']}
        flexDirection={['column-reverse', null, 'row']}
        width="100%"
      >
        <Box
          maxWidth={['100%', null, COLUMN * 6 + majorScale(4)]}
          paddingX={majorScale(2)}
          position={[null, null, 'sticky']}
          top={[majorScale(8), majorScale(9)]}
          marginBottom="auto"
          zIndex={20}
        >
          <Nav menuOpen showMasses />
          <CurrentBulletinLink />
          <Footer />
        </Box>
        <Box width="100%">
          <Box paddingX={majorScale(2)}>
            <Hero />
          </Box>
          <main id="main-content">
            <Box
              display="flex"
              flexDirection="column"
              gap={majorScale(4)}
              paddingX={[null, null, null, majorScale(2)]}
            >
              <HomeBar />
              <PinnedList />
              <HomeSpecialMasses small={small} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={[majorScale(3), null, majorScale(4)]}
              paddingX={[null, null, null, majorScale(2)]}
            >
              <GroupsHomeList />
              <FeaturedPostsList title="From the Parish" />
              <FeaturedEvents />
              <GroupsRecentPosts />
            </Box>
          </main>
        </Box>
      </Box>
    </Box>
  )
}

function HomeSpecialMasses({ small }: { small: boolean }) {
  const { data } = useMassesQuery()
  const masses = useMemo(() => hydrateMasses(data?.masses, true), [data])

  const specialMasses = useMemo(() => getSpecialMasses(masses), [masses])

  if (!specialMasses) return null

  return (
    <Box
      paddingX={majorScale(2)}
      maxWidth={400}
      display="flex"
      flexDirection="column"
      gap="15px"
      paddingTop={majorScale(5)}
    >
      {small && <MassModule massPageId={'mass'} />}
      {specialMasses && <SpecialMassTable masses={specialMasses} />}
    </Box>
  )
}