import { DateTime, Interval } from 'luxon'
import { sortBy, groupBy } from 'lodash-es'
import { Schedule } from '../utils'
import { Mass, MassesQuery, MassType, Flag } from '../graphql/generated/graphql-hooks'

const oneWeekRange = [
  DateTime.local().startOf('day'),
  DateTime.local()
    .plus({ week: 1, day: 1 })
    .startOf('day'),
]

const emptyReturn: {
  ferialMasses: Mass[]
  sundayMasses: Mass[]
  specialMasses: { [key: string]: Mass[] }
} = {
  ferialMasses: [],
  sundayMasses: [],
  specialMasses: { null: [] },
}

const emptySchedule: Schedule.JSON = {
  type: 'Schedule',
  rrules: [],
  exdates: { type: 'Dates', dates: [] },
  exrules: [],
  rdates: { type: 'Dates', dates: [] },
}

export default function splitMasses(data: MassesQuery, range: DateTime[] | false = oneWeekRange) {
  const masses = data?.masses ?? []

  if (!masses) {
    return emptyReturn
  }

  return splitMassesByDay(masses, range)
}

function splitMassesByDay(masses: Mass[], range: DateTime[] | false) {
  let ferialMasses: Mass[] = []
  let sundayMasses: Mass[] = []
  let specialMasses: Mass[] = []

  masses.forEach(mass => {
    if (!mass) return

    const date = DateTime.fromISO(mass.nextDate)
    const isRecurring = mass.type === MassType.Recurring

    if (isRecurring) {
      if (
        date.weekday === 7 ||
        (date.weekday === 6 && date.hour > 16) ||
        mass.flags === Flag.Sunday
      ) {
        sundayMasses.push(mass)
      } else {
        ferialMasses.push(mass)
      }
    } else {
      specialMasses.push(mass)
    }
  })

  ferialMasses = sortBy(ferialMasses, ['data.weekday'])
  sundayMasses = sortBy(sundayMasses, ['data.weekday'])
  const groupedSpecialMasses = groupBy(
    sortBy(specialMasses, ['data.weekday']),
    mass => mass?.season?.name || 'null',
  )

  return { ferialMasses, sundayMasses, specialMasses: groupedSpecialMasses }
}

function massIsInRange(massDate: DateTime, range: DateTime[]): boolean {
  if (range[0] && range[1]) {
    const dtRange = Interval.fromDateTimes(range[0], range[1])
    if (!dtRange.contains(massDate)) {
      return false
    }
  }
  return true
}

function massIsSunday(mass: Mass, dt: DateTime): boolean {
  if ((dt.weekday === 6 && dt.hour >= 17) || dt.weekday === 7 || mass.flags === 'SUNDAY') {
    return true
  }
  return false
}

function massIsRecurring(mass: Mass): boolean {
  if (mass.type === MassType.Recurring || mass.type === MassType.Exception) {
    return true
  }
  return false
}
