import Box from '@parishconnect/box'
import { majorScale, SearchInput, ThemeContext } from '@parishconnect/react-ui'
import React, { ChangeEvent, useContext, useState, useEffect } from 'react'
import useRouter from 'use-react-router'

export function HeaderSearch() {
  const [searchString, setSearchString] = useState('')
  const [isFocused, setFocused] = useState(false)
  const theme = useContext(ThemeContext)
  const { history, location } = useRouter()

  useEffect(() => {
    if (location.search) {
      setSearchString(new URLSearchParams(location.search).get('s'))
    }
    if (location.pathname === '/search') {
      setFocused(true)
    } else {
      setFocused(false)
      setSearchString('')
    }
  }, [location])

  return (
    <Box
      css={
        !isFocused
          ? {
              svg: { stroke: 'white !important' },
              input: {
                padding: 0,
                width: majorScale(4),
                overflow: 'hidden',
                color: 'transparent',
                '::placeholder': { opacity: 0 },
              },
            }
          : {
              '@media (max-width: 768px)': {
                position: 'absolute',
                width: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 99,
                '> div': {
                  left: '50%',
                  transform: 'translateX(-50%)',
                },
                input: {
                  width: '95vw',
                },
              },
            }
      }
    >
      <SearchInput
        role="search"
        aria-label="search"
        placeholder="Search..."
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        css={
          !isFocused && {
            backgroundColor: 'transparent',
            cursor: 'pointer',
            '&:hover': { backgroundColor: theme.palette[theme.themeColor].dark },
          }
        }
        value={searchString}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.currentTarget.value)}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            history.push(`/search?s=${searchString}`)
          }
        }}
      />
    </Box>
  )
}
