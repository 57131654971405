import { useApolloClient } from '@apollo/client'
import Box from '@parishconnect/box'
import {
  Button,
  Card,
  Dialog,
  Heading,
  InlineAlert,
  KeyIcon,
  Link,
  LogInIcon,
  majorScale,
  minorScale,
  Paragraph,
  Strong,
  Text,
  TextInputField,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, Redirect, RouteComponentProps } from 'react-router-dom'
import { object, string } from 'yup'
import { PageContainer } from '../components'
import {
  useLoginMutation,
  useSendPasswordResetMutation,
  useUserQuery,
} from '../graphql/generated/graphql-hooks'
import { useToggle } from '../utils'

const loginFormSchema = object().shape({
  email: string().email().required(),
  password: string().required(),
})

export default function Login({ history }: RouteComponentProps) {
  const client = useApolloClient()
  const [login] = useLoginMutation({
    update: () => {
      client.resetStore()
    },
  })
  const { data } = useUserQuery()
  const [dialogIsShown, toggleDialogShown] = useToggle(false)

  return (
    <PageContainer>
      {data?.user && <Redirect to={'/user'} />}
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box marginTop={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
        <Card
          appearance="white"
          elevation={1}
          padding={[majorScale(3), majorScale(6)]}
          width={majorScale(48)}
          maxWidth={`calc(100vw - ${majorScale(2)}px)`}
          id="main-content"
          is="main"
        >
          <Heading color="theme" size={600} is="h1">
            Welcome.
          </Heading>
          <Text>
            Sign in with your <Strong>ParishConnect</Strong> account
          </Text>
          <Formik
            onSubmit={async ({ email, password }, actions) => {
              try {
                await login({ variables: { email, password } })

                history.goBack()
              } catch (error) {
                Sentry.captureException(error)
                actions.setSubmitting(false)
                actions.setStatus(error)
              }
            }}
            validationSchema={loginFormSchema}
            initialValues={{ email: '', password: '' }}
          >
            {({ errors, submitCount, isSubmitting, status }) => (
              <Box is={Form} disabled={isSubmitting} marginTop={majorScale(2)}>
                {status && (
                  <InlineAlert marginBottom={majorScale(1)} hasIcon intent="danger">
                    {status.graphQLErrors?.[0]?.message}
                  </InlineAlert>
                )}
                <TextInputField
                  is={Field}
                  autoFocus
                  name="email"
                  label="Email"
                  type="email"
                  autoComplete="email"
                  placeholder="email@example.com"
                  disabled={isSubmitting}
                  isInvalid={submitCount > 0 && errors.email && errors.email.length > 0}
                  validationMessage={submitCount > 0 && errors.email && errors.email}
                />
                <TextInputField
                  is={Field}
                  name="password"
                  type="password"
                  label="Password"
                  autoComplete="current-password"
                  placeholder="Enter Password"
                  disabled={isSubmitting}
                  isInvalid={submitCount > 0 && errors.password && errors.password.length > 0}
                  validationMessage={submitCount > 0 && errors.password && errors.password}
                  hint={
                    <Text
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                      marginTop={minorScale(1)}
                    >
                      <Link size={300} onClick={toggleDialogShown} cursor="pointer">
                        Forgot Password?
                      </Link>
                    </Text>
                  }
                />
                <Button
                  type="submit"
                  iconBefore={LogInIcon}
                  isLoading={isSubmitting}
                  appearance="primary"
                >
                  Login
                </Button>
              </Box>
            )}
          </Formik>
        </Card>
        <Text marginTop={majorScale(2)} is="aside">
          Don't have an account yet?{' '}
          <Link is={RouterLink} to="/signup">
            Sign Up
          </Link>
        </Text>
      </Box>
      <PasswordResetDialog isShown={dialogIsShown} toggle={toggleDialogShown} />
    </PageContainer>
  )
}

const passwordResetSchema = object().shape({
  email: string().email().required(),
})
function PasswordResetDialog({
  isShown,
  toggle,
}: {
  isShown: boolean
  toggle: (nextValue?: boolean) => void
}) {
  const [sendPasswordReset] = useSendPasswordResetMutation()

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, actions) => {
        try {
          await sendPasswordReset({ variables: values })
          actions.setSubmitting(false)
        } catch (error) {
          Sentry.captureException(error)
        }
      }}
      validationSchema={passwordResetSchema}
    >
      {({ submitForm, submitCount, values }) => (
        <Dialog
          isShown={isShown}
          onCloseComplete={toggle}
          onConfirm={submitForm}
          confirmLabel="Send"
          isConfirmDisabled={submitCount > 0}
          title="Forgot Password"
        >
          {submitCount <= 0 ? (
            <>
              <Paragraph paddingBottom={majorScale(2)}>
                <Strong>Forgot your password?</Strong> That's okay, it happens.
                <br /> Enter your email below and we'll send you a link to get you back into your
                account.
              </Paragraph>
              <Form>
                <TextInputField
                  is={Field}
                  label="Email"
                  name="email"
                  isRequired
                  maxWidth={majorScale(40)}
                  placeholder="email@example.com"
                />
              </Form>
            </>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" marginY={majorScale(2)}>
              <Card
                borderRadius={999999}
                appearance="solid"
                padding={majorScale(2)}
                marginRight={majorScale(2)}
              >
                <KeyIcon size={majorScale(4)} />
              </Card>
              <Paragraph>
                We sent an email to {values?.email || 'your email'} with a password reset link
              </Paragraph>
            </Box>
          )}
        </Dialog>
      )}
    </Formik>
  )
}
