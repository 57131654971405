import { Helmet } from 'react-helmet-async'
import blue from '../../../public/blue.png'
import appleTouchIcon from '../../../public/apple-touch-icon.png'
import faviconBlack from '../../../public/favicon-black.png'
import faviconWhite from '../../../public/favicon-white.png'
import green from '../../../public/green.png'
import logoMask from '../../../public/logo-mask.svg'
import orange from '../../../public/orange.png'
import purple from '../../../public/purple.png'
import red from '../../../public/red.png'
import teal from '../../../public/teal.png'
import devFavicon from '../../../public/favicon-dev.png'
import { Parish } from '../../graphql/generated/graphql-hooks'
import { useMedia } from '../../utils'
import React, { useContext } from 'react'
import { ThemeContext } from '@parishconnect/react-ui'

const favicons = {
  green,
  blue,
  red,
  purple,
  orange,
  teal,
}

function getFavicon(dark: boolean, theme?: string) {
  if (theme && theme !== 'neutral') {
    return favicons[theme]
  }

  if (dark || theme === 'neutral') {
    return dark ? faviconWhite : faviconBlack
  }
}

export function Favicon({ parish }: { parish: Parish }) {
  const dark = useMedia('(prefers-color-scheme: dark)')
  const theme = useContext(ThemeContext)

  if (__DEV__) {
    return (
      <Helmet>
        <link href={devFavicon} rel="shortcut icon" />
        <link href={logoMask} rel="mask-icon" />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content={theme.getThemeColor(theme)} />
        <meta name="theme-color" content={theme.getThemeColor(theme)} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <link href={getFavicon(dark, parish?.theme)} rel="shortcut icon" />
      <link href={logoMask} rel="mask-icon" />
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content={theme.getThemeColor(theme)} />
      <meta name="theme-color" content={theme.getThemeColor(theme)} />
    </Helmet>
  )
}
