import React from 'react'
import { TransTitle, WidgetProps } from '@ui-schema/ui-schema'
import { TextInputField } from '@parishconnect/react-ui'

export function NumberWidget({
  value,
  storeKeys,
  onChange,
  required,
  schema,
  errors,
  valid,
  ...props
}: WidgetProps) {
  return (
    <TextInputField
      label={<TransTitle schema={schema} storeKeys={storeKeys} />}
      type={'number'}
      required={required}
      value={value || ''}
      onChange={(e) => {
        onChange({
          storeKeys,
          scopes: ['value'],
          type: 'set',
          data: {
            value: Number(e.target.value),
          },
          schema,
          required,
        })
      }}
    />
  )
}
