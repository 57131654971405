import React from 'react'
import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import About from './About'
import Bulletins from './Bulletins'
import SignupSuccess from './Confirmations'
import Dashboard from './Dashboard'
import Event from './Event'
import EventEditor from './EventEditor'
import Events from './Events'
import FourOhFour from './FourOhFour'
import Group from './Group'
import Groups from './Groups'
import Home from './Home'
import Login from './Login'
import Menu from './Menu'
import NewGroup from './NewGroup'
import PasswordReset from './PasswordReset'
import Post from './Post'
import PostEditor from './PostEditor'
import Posts from './Posts'
import Sacraments from './Sacraments'
import Search from './Search'
import Signup from './Signup'
import User from './User'
import Donate from './Donate'
import Forms from './Forms'
import Form from './Form'
import { FormSubmissions } from './FormSubmissions'

export default function Routes() {
  return (
    <Switch>
      {/* TOP LEVEL */}
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/sacraments" component={Sacraments} />
      <Route path="/events" component={Events} />
      <Route path="/donate" component={Donate} />
      <Route path="/groups" component={Groups} />
      <Route path="/forms" component={Forms} />
      <Route path="/login" exact component={Login} />
      <Route path="/password-reset/:code" component={PasswordReset} />
      <Redirect path="/password-reset" exact to="/" />
      <Route path="/signup" exact component={Signup} />
      <Route path="/menu" component={Menu} />
      <Route path="/user/:id" component={User} />
      <Route path="/dashboard" component={Dashboard} />
      {/* CREATE */}
      <Route path={['/post/new', '/post/:id/edit']} exact component={PostEditor} />
      <Route path={['/event/new', '/event/:id/edit']} exact component={EventEditor} />
      {/* <Route path={['/form/new', '/form/:id/edit']} exact component={FormEditor} /> */}
      <Route path="/group/new" exact component={NewGroup} />
      {/* SINGLES */}
      <Route path="/post/:id" component={Post} />
      <Route path="/group/:id" component={Group} />
      <Route path="/event/:id" component={Event} />
      <Route path="/user/:id" component={User} />
      <Route path="/form/:id/submissions" component={FormSubmissions} />
      <Route path="/form/:id" component={Form} />
      {/* LISTS */}
      <Route path="/posts" component={Posts} />
      <Route path="/bulletins" component={Bulletins} />
      <Route path="/search" component={Search} />
      {/* CONFIRMATIONS */}
      <Route path="/signup-success" exact component={SignupSuccess} />
      {/* 404 */}
      <Route path="*" component={FourOhFour} />
    </Switch>
  )
}
