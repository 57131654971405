import Box from '@parishconnect/box'
import {
  ChevronDownIcon,
  Heading,
  IconButton,
  majorScale,
  minorScale,
  Text,
  ThemeContext,
} from '@parishconnect/react-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { useContext } from 'react'
import { MassType, Season } from '../../graphql/generated/graphql-hooks'
import { useToggle } from '../../utils'
import MassCard from './MassCard'
import { SeasonEdit } from './SeasonEdit'
import { ComputedMass, SpecialMassGroup } from './utils'

type RecurringMassListingProps = {
  masses: ComputedMass[]
  heading: string
  includeExceptions?: boolean
}

export function RecurringMassListing({ masses, heading }: RecurringMassListingProps) {
  if (masses?.some(m => m.type === MassType.Recurring)) {
    return (
      <Box marginBottom={majorScale(5)}>
        <Heading
          marginBottom={minorScale(3)}
          size={400}
          color="theme"
          textTransform="uppercase"
          fontWeight={800}
        >
          {heading}
        </Heading>
        <Box
          display="grid"
          gridTemplateColumns={`repeat(auto-fill, ${majorScale(36)}px)`}
          gridGap={majorScale(2)}
        >
          <AnimatePresence initial={false}>
            {masses.map(mass => {
              return <MassCard key={mass.id} mass={mass} />
            })}
          </AnimatePresence>
        </Box>
      </Box>
    )
  }
  return null
}

type SpecialMassListingsProps = {
  masses: SpecialMassGroup
}

export function SpecialMassListings({ masses }: SpecialMassListingsProps) {
  return Object.entries(masses).map(([seasonName, data]) => (
    <SeasonContainer key={data.id} seasonName={seasonName} {...data} />
  ))
}

function SeasonContainer({
  seasonName,
  days,
  ...season
}: SpecialMassGroup[0] & { seasonName: string }) {
  const theme = useContext(ThemeContext)
  const [isOpen, toggleOpen] = useToggle(true)

  return (
    <Box
      key={season.id}
      marginBottom={majorScale(5)}
      position="relative"
      borderRadius={2}
      boxShadow={`0 0 0 12px ${theme.fills.subtle[theme.themeColor].backgroundColor}`}
      background={theme.fills.subtle[theme.themeColor].backgroundColor}
    >
      <Heading size={400} color="theme" textTransform="uppercase" fontWeight={800}>
        {seasonName}
        <SeasonEdit {...season} seasonName={seasonName} masses={Object.values(days).flat()} />
      </Heading>
      <IconButton
        icon={ChevronDownIcon}
        position="absolute"
        top={minorScale(1)}
        right={majorScale(1)}
        onClick={toggleOpen}
      />
      <SeasonSubHeading {...season} />
      <Box
        is={motion.div}
        animate={isOpen ? { height: '100%' } : { height: 0 }}
        initial={false}
        display="grid"
        overflow="hidden"
        gridTemplateColumns={`repeat(auto-fill, ${majorScale(36)}px)`}
        gridGap={majorScale(2)}
      >
        {Object.values(days).map(masses =>
          masses.map(mass => (
            <AnimatePresence key={mass.id} initial={false}>
              {isOpen ? <MassCard key={mass.id} mass={mass} /> : null}
            </AnimatePresence>
          )),
        )}
      </Box>
    </Box>
  )
}

function SeasonSubHeading(season: Season) {
  if (!season || (!season.from && !season.until)) return <Box marginBottom={majorScale(1)} />

  return (
    <Text display="block" marginBottom={majorScale(2)}>
      {`${
        season.from
          ? `Will be shown ${season.until ? 'from' : 'on'} ${DateTime.fromISO(
              season.from,
            ).toLocaleString(DateTime.DATE_FULL)}`
          : ''
      }${season.from ? (season.until ? ' to ' : '') : ''}${
        season.until
          ? `until ${DateTime.fromISO(season.until).toLocaleString(DateTime.DATE_FULL)}`
          : ''
      }`}
    </Text>
  )
}
