import { Global } from '@emotion/core'
import { defineAbilityFor } from '@parishconnect/abilities'
import Box from '@parishconnect/box'
import { CloudOffIcon, defaultTheme, ThemeProvider } from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { DateTime, Info, Settings } from 'luxon'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { EmptyParish, MaintenanceMode } from './components'
import { DevelopmentBar, Error, ErrorBoundary, NoScriptMessage } from './components/Error'
import { Header } from './components/Header'
import { Favicon } from './components/seo'
import { useAppQuery } from './graphql/generated/graphql-hooks'
import Routes from './routes'
import { AbilityContext } from './utils/abilityContext'
import globalStyles from './utils/globalStyles'

if (!__DEV__) {
  Sentry.init({
    dsn: 'https://1945cad5201a42b29f179807fdb6546e@o216805.ingest.sentry.io/1802457',
    environment: process.env.NODE_ENV,
  })
}

const App = () => {
  const { data, loading, error } = useAppQuery()

  if (error) {
    return <Error title="Network Error" icon={CloudOffIcon} />
  }

  if (loading || !data) return null

  if (!data.parish) return <EmptyParish />

  const ability = defineAbilityFor(data?.user as any)

  Settings.defaultZoneName = Info.normalizeZone(
    data.user?.timezone ?? data.parish?.timezone ?? DateTime.local().zone,
  ).name

  // This will set the user when logged in and unset when logged out
  Sentry.setUser(data?.user)

  return (
    <ErrorBoundary>
      <ThemeProvider
        value={{
          ...defaultTheme,
          themeColor: data?.parish?.theme ?? defaultTheme.themeColor,
        }}
      >
        <Helmet titleTemplate={`%s - ${data?.parish?.name}`} defaultTitle={data?.parish?.name}>
          <meta property="og:title" content={data?.parish?.name} />
          <meta property="og:url" content={`https://${data?.parish?.defaultDomain}`} />
          <meta name="apple-mobile-web-app-title" content={data?.parish?.name} />
        </Helmet>
        <Favicon parish={data?.parish} />
        <AbilityContext.Provider value={ability}>
          <Global styles={globalStyles} />
          <Box className="sr-only" is="a" href="#main-content">
            Skip to main content
          </Box>
          {__DEV__ && <DevelopmentBar />}
          <Header />
          {data?.parish?.maintenanceMode === true && ability.cannot('update', data.parish) ? (
            <MaintenanceMode {...data} />
          ) : (
            <Routes />
          )}
        </AbilityContext.Provider>
        <NoScriptMessage domain={data?.parish?.defaultDomain} />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
