import Box from '@parishconnect/box'
import { Button, ChevronRightIcon, Heading, majorScale } from '@parishconnect/react-ui'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GroupQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils'
import { EventModule } from '../Event'

export default function SingleGroupEvents({ events = [] }: GroupQuery) {
  const width = majorScale(38)
  const { pathname } = useLocation()

  return events.length > 0 ? (
    <Box marginTop={majorScale(2)}>
      <Box display="flex" gridColumn="2 / -2">
        <Heading marginBottom={majorScale(2)} size={700}>
          Upcoming Events
        </Heading>
        <Button
          is={Link}
          to={pathname + '/events'}
          marginLeft={['auto', majorScale(2)]}
          iconAfter={ChevronRightIcon}
        >
          All Group Events
        </Button>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={['1fr', `repeat(auto-fit, ${width}px)`]}
        gap={COLUMN / 2}
      >
        {events?.map((event) => (
          <EventModule {...event} />
        ))}
      </Box>
    </Box>
  ) : null
}
