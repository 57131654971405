import Box from '@parishconnect/box/dist/box'
import { Button, Heading, Pane } from '@parishconnect/react-ui'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import useRouter from 'use-react-router'
import { FormViewer, Nav, PageContainer, Seo } from '../components'
import { useFormQuery, useParishQuery } from '../graphql/generated/graphql-hooks'
import { Can, COLUMN } from '../utils'

export default function Form() {
  const { match } = useRouter<{ id: string }>()
  const { id } = match.params
  const { data: { parish } = {} } = useParishQuery()
  const { data } = useFormQuery({ variables: { id }, returnPartialData: true })

  return (
    <PageContainer>
      <Helmet>
        <title>{data?.form?.title || 'Event'}</title>
      </Helmet>
      <Seo
        pathname={location.pathname}
        type="website"
        createdAt={data?.form?.updatedAt}
        updatedAt={data?.form?.createdAt}
        title={data?.form?.title}
        parish={parish}
        description={data?.form?.description}
        image={parish?.image?.src}
      />
      <Pane display="flex">
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
        </Box>
        <Box
          maxWidth={600}
          width="100%"
          marginLeft={[0, null, COLUMN / 2]}
          is="main"
          id="main-content"
        >
          <Box css={{ paddingBlock: 24, display: 'flex', gap: 16, alignItems: 'center' }}>
            <Box display="flex" gap={8} flexDirection="column">
              <Heading size={800}>{data?.form?.title || 'Form'}</Heading>
              {data?.form?.description && (
                <Heading size={400} color="#8d8d8d">
                  {data?.form?.description}
                </Heading>
              )}
            </Box>
            <Can
              I="read"
              this={{ __typename: 'Form', parish: data?.parish?.id }}
              field="submissions"
            >
              <Button is={Link} to={`/form/${id}/submissions`}>
                View Submissions
              </Button>
            </Can>
          </Box>
          <FormViewer ast={data?.form?.ast} />
        </Box>
      </Pane>
    </PageContainer>
  )
}
