export function getLocalValue(key: string): string {
  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    return localStorage.getItem(key)
  }
  return ''
}

export function setLocalValue(key: string, value: string): void {
  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    localStorage.setItem(key, value)
  }
}

export function removeLocalValue(key: string | string[]): void {
  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    if (Array.isArray(key)) {
      return key.forEach(key => removeLocalValue(key))
    }
    localStorage.removeItem(key)
  }
}
