import Box from '@parishconnect/box'
import {
  Button,
  CalendarIcon,
  Heading,
  majorScale,
  Paragraph,
  PlusIcon,
} from '@parishconnect/react-ui'
import { groupBy } from 'lodash-es'
import { DateTime } from 'luxon'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { EventModule, Footer, Nav, PageContainer } from '../components'
import { EmptyState } from '../components/shared/EmptyState'
import { useEventsQuery } from '../graphql/generated/graphql-hooks'
import { Can } from '../utils/abilityContext'
import { COLUMN } from '../utils/constants'

const Events = () => {
  const limit = 10
  const { loading, error, data, fetchMore } = useEventsQuery({
    variables: {
      limit,
      past: true,
    },
  })

  const hasMore = data?.events?.length >= limit

  if (error || data?.events?.length <= 0) {
    return (
      <PageContainer>
        <Box display="flex">
          <Box width={[0, null, COLUMN * 6]}>
            <Nav menuOpen showMasses />
            <Footer />
          </Box>
          <Box marginLeft={[0, null, COLUMN]} width={['100%', null, COLUMN * 15]}>
            <EmptyState icon={CalendarIcon} title="No Events">
              <Can I="create" this={{ __typename: 'Group', parish: data?.parish }}>
                <Paragraph size={500}>
                  When you create events they will appear here.
                  <br />
                  <Button
                    marginTop={majorScale(1)}
                    appearance="primary"
                    iconBefore={PlusIcon}
                    is={Link}
                    to="/event/new"
                  >
                    Create Event
                  </Button>
                </Paragraph>
              </Can>
            </EmptyState>
          </Box>
        </Box>
      </PageContainer>
    )
  }

  const events = groupBy(data?.events, (event) => {
    const upcoming = DateTime.fromISO(event.dtstart).diffNow().as('days') > 0
    return upcoming ? 'upcoming' : 'past'
  })

  const upcomingEvents = events?.upcoming ?? []
  const pastEvents = events?.past ?? []

  console.log(events)

  return (
    <PageContainer>
      <Helmet>
        <title>Events</title>
      </Helmet>
      <Box display="flex">
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
        </Box>
        <Box marginLeft={[0, COLUMN]} width={COLUMN * 15} is="main" id="main-content">
          <Box
            display="flex"
            width={['100%', majorScale(81)]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading marginY={[majorScale(1), null, null, majorScale(3)]} size={800} is="h1">
              Events
            </Heading>
            <Can
              I="create"
              this={{
                __typename: 'Event',
                parish: data?.parish?.id,
              }}
            >
              <Button appearance="primary" iconBefore={CalendarIcon} is={Link} to="/event/new">
                Create Event
              </Button>
            </Can>
          </Box>
          <Box
            display="grid"
            gridAutoFlow="row"
            marginTop={majorScale(2)}
            width="100%"
            gap={`${majorScale(2)}px ${majorScale(3)}px`}
            gridTemplateColumns={['1fr', `repeat(auto-fit, ${majorScale(38)}px)`]}
          >
            {upcomingEvents.length > 0 && (
              <>
                <Heading size={600} is="h2" gridColumn="-1/1">
                  Upcoming Events
                </Heading>
                {upcomingEvents.map((event) => (
                  <EventModule key={event.id} {...event} />
                ))}
              </>
            )}
            {pastEvents.length > 0 && (
              <>
                <Heading size={600} is="h2" gridColumn="-1/1">
                  Past Events
                </Heading>
                {pastEvents.map((event) => (
                  <EventModule key={event.id} {...event} />
                ))}
              </>
            )}
          </Box>
          {hasMore && (
            <Box
              marginY={majorScale(3)}
              marginX="auto"
              gridColumn={['1/2', '1/3']}
              display="flex"
              justifyContent="center"
            >
              <Button
                isLoading={loading}
                onClick={() =>
                  fetchMore({
                    variables: {
                      limit,
                      cursor: data?.events[data?.events.length - 1].id,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return previousResult
                      }

                      return {
                        ...previousResult,
                        events: [...previousResult.events, ...fetchMoreResult.events],
                      }
                    },
                  })
                }
                height={36}
                marginBottom={majorScale(3)}
              >
                Load More
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </PageContainer>
  )
}

export default Events
