import Box from '@parishconnect/box'
import {
  Button,
  Heading,
  majorScale,
  Paragraph,
  UserPlusIcon,
  UsersIcon,
} from '@parishconnect/react-ui'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RLink } from 'react-router-dom'
import { GroupModule, Nav, PageContainer, Footer } from '../components'
import { EmptyState } from '../components/shared/EmptyState'
import { useGroupsQuery } from '../graphql/generated/graphql-hooks'
import { Can } from '../utils/abilityContext'
import { COLUMN } from '../utils/constants'

const Groups = () => {
  const limit = 10
  const { loading, error, data, fetchMore } = useGroupsQuery({
    variables: {
      limit,
    },
  })

  const [hasMore, setHasMore] = useState(data?.groups.length >= limit)

  if (loading) return null

  if (error || !data?.groups?.length) {
    return (
      <PageContainer>
        <Box display="flex">
          <Box width={[0, COLUMN * 6]}>
            <Nav menuOpen showMasses />
            <Footer />
          </Box>
          <Box marginLeft={[0, COLUMN]} width={['100%', COLUMN * 15]}>
            <EmptyState icon={UsersIcon} title="No Groups">
              <Can I="create" a="group">
                <Paragraph size={500}>
                  When you create groups they will appear here.
                  <br />
                  <Button
                    marginTop={majorScale(1)}
                    appearance="primary"
                    iconBefore={UserPlusIcon}
                    is={RLink}
                    to="/group/new"
                  >
                    Create Group
                  </Button>
                </Paragraph>
              </Can>
            </EmptyState>
          </Box>
        </Box>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Helmet>
        <title>Groups</title>
      </Helmet>
      <Box display="flex">
        <Box width={[0, COLUMN * 6]}>
          <Nav menuOpen showMasses />
          <Footer />
        </Box>
        <Box
          marginLeft={[0, null, majorScale(2), COLUMN]}
          width={['100%', COLUMN * 15]}
          id="main-content"
          is="main"
        >
          <Box
            display="flex"
            width={['100%', majorScale(89)]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading
              marginTop={[majorScale(1), majorScale(3)]}
              marginBottom={majorScale(3)}
              size={800}
              is="h1"
            >
              Groups
            </Heading>
            <Can I="create" a="group">
              <Button appearance="primary" iconBefore={UserPlusIcon} is={RLink} to="/group/new">
                Create Group
              </Button>
            </Can>
          </Box>
          <Box
            display="grid"
            gridAutoFlow="row"
            width="100%"
            gap={`${majorScale(2)}px ${majorScale(5)}px`}
            gridTemplateColumns={[
              '1fr',
              `repeat(auto-fit, minmax(${majorScale(40)}px, ${majorScale(42)}px))`,
            ]}
          >
            {data?.groups.map((group) => (
              <GroupModule key={group.id} {...group} />
            ))}
          </Box>
          {hasMore && (
            <Button
              isLoading={loading}
              onClick={() =>
                fetchMore({
                  variables: {
                    limit,
                    cursor: data?.groups[data?.groups.length - 1].id,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                      return previousResult
                    }

                    const groups = fetchMoreResult?.groups ?? []

                    if (groups.length < limit) {
                      setHasMore(false)
                    }

                    return {
                      ...previousResult,
                      groups: [...previousResult.groups, ...fetchMoreResult.groups],
                    }
                  },
                })
              }
              height={36}
              marginBottom={majorScale(3)}
            >
              Load More
            </Button>
          )}
        </Box>
      </Box>
    </PageContainer>
  )
}

export default Groups
