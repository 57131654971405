import Box from '@parishconnect/box'
import { Heading, majorScale, ThemeContext } from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { COLUMN, COLUMNS } from '../../utils/constants'
import { Event } from '../../graphql/generated/graphql-hooks'
import { getFullDateString, getFullTimeString } from '../../utils'

export const SingleEventTime = ({ title, dtstart, duration }: Event) => {
  const theme = useContext(ThemeContext)
  return (
    <Box
      padding={[majorScale(2), null, majorScale(3)]}
      marginTop={[majorScale(1), null, majorScale(2)]}
      maxWidth={COLUMN * COLUMNS}
    >
      <Heading
        is="h1"
        maxWidth={900}
        size={800}
        wordBreak="break-word"
        marginBottom={majorScale(1)}
        css={{
          hyphens: 'auto',
        }}
      >
        {title}
      </Heading>
      <Box marginBottom={majorScale(1)}>
        <Heading size={700}>{getFullDateString(dtstart, duration)}</Heading>
        <Heading color={theme.scales.neutral.N8} size={700}>
          {getFullTimeString(dtstart, duration)}
        </Heading>
      </Box>
    </Box>
  )
}
