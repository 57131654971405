import Box from '@parishconnect/box'
import {
  Button,
  CheckCircleIcon,
  Heading,
  majorScale,
  SaveIcon,
  SelectField,
  Spinner,
  Text,
  TextInputField,
  toaster,
  XCircleIcon,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import { capitalize } from 'lodash-es'
import React, { FormEvent, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import {
  Sex,
  useCheckEmailLazyQuery,
  useEditUserMutation,
  User,
} from '../../graphql/generated/graphql-hooks'
import { getParishNameById, getUserParishes, prefixes } from '../../utils'
import { FormikTextField } from '../shared'
import { UserPaneWrapper } from './UserPaneWrapper'
import { object, string } from 'yup'

type PreferencesProps = { user: User }

const userSchema = object().shape({
  prefix: string().oneOf(prefixes).nullable().label('Prefix'),
  firstName: string().required().label('First Name'),
  lastName: string().required().label('Last Name'),
  sex: string().oneOf(Object.values(Sex)).nullable().label('Sex'),
  phoneNumber: string().nullable().label('Phone Number'),
  email: string().email().required().label('Email'),
})

export default function Preferences({ user }: PreferencesProps) {
  const [editUser] = useEditUserMutation()

  if (!user) return null

  return (
    <Formik
      enableReinitialize
      onSubmit={async (values, actions) => {
        try {
          await editUser({
            variables: { user: values },
            update: (_, { data: { editUser } }) => {
              toaster.success('Saved', { description: `${editUser?.fullName} saved!` })
            },
          })
          actions.setSubmitting(false)
        } catch (error) {
          Sentry.captureException(error)
          actions.setErrors(error)
        }
      }}
      validationSchema={userSchema}
      initialValues={{
        prefix: user?.prefix ?? undefined,
        firstName: user?.firstName,
        lastName: user?.lastName,
        sex: user?.sex ?? undefined,
        phoneNumber: user?.phoneNumber ?? undefined,
        email: user?.email,
        primaryParish: user?.primaryParish?.id,
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        dirty,
        setFieldError,
        submitForm,
        initialValues,
        isSubmitting,
      }) => (
        <UserPaneWrapper>
          <Box display="flex" justifyContent="space-between">
            <Heading marginBottom={majorScale(4)} size={700} color="theme">
              Preferences
            </Heading>
            <Button
              type="submit"
              onClick={submitForm}
              appearance="primary"
              disabled={!dirty}
              iconBefore={SaveIcon}
              loading={isSubmitting}
            >
              Save
            </Button>
          </Box>
          <Form>
            <Box
              display="grid"
              gridTemplateColumns={`repeat(auto-fit, minmax(${majorScale(30)}px, 1fr))`}
              gridGap={majorScale(4)}
            >
              <SelectField
                name="prefix"
                label="Prefix"
                value={values.prefix}
                onChange={(e: FormEvent<HTMLSelectElement>) =>
                  setFieldValue('prefix', e.currentTarget.value)
                }
              >
                <option value="">Not Specified</option>
                {prefixes.map((prefix) => (
                  <option key={prefix} value={prefix}>
                    {prefix}
                  </option>
                ))}
              </SelectField>
              <SelectField
                name="sex"
                label="Sex"
                value={values.sex}
                onChange={(e: FormEvent<HTMLSelectElement>) =>
                  setFieldValue('sex', e.currentTarget.value)
                }
              >
                <option disabled value="">
                  Not Specified
                </option>
                {Object.values(Sex).map((sex) => (
                  <option key={sex} value={sex}>
                    {capitalize(sex)}
                  </option>
                ))}
              </SelectField>
              <Field component={FormikTextField} name="firstName" label="First Name" />
              <Field component={FormikTextField} name="lastName" label="Last Name" />
              <Field component={FormikTextField} name="phoneNumber" label="Phone Number" />
              <EmailField
                value={values.email}
                initialValue={initialValues.email}
                setField={(value) => setFieldValue('email', value)}
                setError={(message) => setFieldError('email', message)}
                error={errors.email}
              />
              <SelectField
                name="primaryParish"
                label="Primary Parish"
                value={values.primaryParish}
                hint="This parish will appear below your name across ParishConnect"
                onChange={(e: FormEvent<HTMLSelectElement>) =>
                  setFieldValue('primaryParish', e.currentTarget.value)
                }
              >
                {Object.values(getUserParishes(user)).map((parish) => {
                  const parishName = getParishNameById(parish, user)?.name
                  return parishName ? (
                    <option key={parish} value={parish}>
                      {parishName}
                    </option>
                  ) : null
                })}
              </SelectField>
            </Box>
          </Form>
        </UserPaneWrapper>
      )}
    </Formik>
  )
}

type EmailFieldProps = {
  setError: (message: string) => void
  setField: (value: string) => void
  value: string
  error: string
  initialValue: string
}

function EmailField({ value, initialValue, setError, setField, error }: EmailFieldProps) {
  const [checkEmail, { loading: aLoading, data }] = useCheckEmailLazyQuery()
  const [setInternalEmail] = useDebouncedCallback((value) => {
    if (value !== initialValue && !error) {
      checkEmail({ variables: { email: value } })
    }
  }, 1000)
  const [loading, setLoading] = useState(aLoading)

  useEffect(() => {
    setLoading(false)
    if (value !== initialValue && data?.checkEmail === false) {
      setError('Email is already taken.')
    }
  }, [data])

  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
      setLoading(false)
    }
  }, [error])

  const getLabel = () => {
    if (value === initialValue) {
      return ''
    }

    if (loading) {
      return <Spinner marginLeft={majorScale(1)} size={14} />
    }

    if (error || data?.checkEmail === false) {
      return <XCircleIcon marginLeft={majorScale(1)} size={14} color="danger" />
    }

    if (data?.checkEmail === true) {
      return <CheckCircleIcon marginLeft={majorScale(1)} size={14} color="success" />
    }

    return ''
  }

  return (
    <Box position="relative">
      <TextInputField
        name="email"
        is={Field}
        label="Email"
        validationMessage={error}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          setField(e.currentTarget.value)
          if (e.currentTarget.value !== initialValue) {
            setInternalEmail(e.currentTarget.value)
            setLoading(true)
          }
        }}
      />
      <Text
        size={300}
        marginTop={6}
        color="muted"
        display="flex"
        alignItems="center"
        position="absolute"
        right={majorScale(1)}
        top={27}
      >
        {getLabel()}
      </Text>
    </Box>
  )
}
