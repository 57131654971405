import { truncate } from 'lodash-es'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Article, CatholicChurch, Event, Organization, WebSite } from 'schema-dts'
import useRouter from 'use-react-router'
import { DonationMethod, Parish } from '../../graphql/generated/graphql-hooks'

interface BaseSeoProps {
  createdAt?: string
  description?: string
  facebookUsername?: string
  image?: string
  parish: Partial<Parish>
  pathname: string
  title: string
  twitterUsername?: string
  updatedAt?: string
  donationMethods?: DonationMethod[]

  author?: string
  startDate?: string
  endDate?: string
}

interface EventSeoProps extends BaseSeoProps {
  type: 'event'
  startDate: string
  endDate?: string
  author: string
}

interface WebsiteSeoProps extends BaseSeoProps {
  type: 'website'
}

interface GroupSeoProps extends BaseSeoProps {
  type: 'group'
}

interface ArticleSeoProps extends BaseSeoProps {
  type: 'article'
  author: string
}

export const Seo = ({
  createdAt,
  startDate,
  endDate,
  description,
  facebookUsername,
  image,
  parish,
  pathname,
  title,
  twitterUsername,
  type,
  updatedAt,
  donationMethods,
  author,
}: EventSeoProps | WebsiteSeoProps | ArticleSeoProps | GroupSeoProps) => {
  // @ts-ignore
  const { staticContext } = useRouter<{}, { readonly hostname: string }, any>()
  const siteLanguage = 'en'
  const copyrightYear = new Date().getFullYear()

  description = description?.length > 144 ? truncate(description, { length: 144 }) : description

  pathname = `//${process.browser ? location.hostname : staticContext?.hostname}${pathname}`

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': pathname,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let sameAs = []
  if (facebookUsername) sameAs.push(`//www.facebook.com/${facebookUsername}`)
  if (twitterUsername) sameAs.push(`//www.twitter.com/${twitterUsername}`)

  let jsonLD: Partial<Article | Event | WebSite | Organization> = {}
  switch (type) {
    case 'article':
      jsonLD = {
        '@context': 'https://schema.org',
        '@type': 'Article',
        author: {
          '@type': 'Person',
          name: author,
        },
        copyrightHolder: {
          '@type': 'Person',
          name: author,
        },
        copyrightYear,
        creator: {
          '@type': 'Person',
          name: author,
        },
        publisher: {
          '@type': 'Organization',
          name: parish?.name,
        },
        datePublished: createdAt,
        dateModified: updatedAt,
        description,
        headline: title,
        inLanguage: siteLanguage,
        url: pathname,
        name: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        mainEntityOfPage: pathname,
      } as Article

      itemListElement.push({
        '@type': 'ListItem',
        item: {
          '@id': pathname,
          name: title,
        },
        position: 2,
      })
      break
    case 'event':
      jsonLD = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        author: {
          '@type': 'Person',
          name: author,
        },
        copyrightHolder: {
          '@type': 'Person',
          name: author,
        },
        copyrightYear,
        creator: {
          '@type': 'Person',
          name: author,
        },
        publisher: {
          '@type': 'Organization',
          name: parish?.name,
        },
        datePublished: createdAt,
        dateModified: updatedAt,
        description,
        headline: title,
        inLanguage: siteLanguage,
        url: pathname,
        name: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        startDate,
        endDate,
        mainEntityOfPage: pathname,
      } as Event

      itemListElement.push({
        '@type': 'ListItem',
        item: {
          '@id': pathname,
          name: title,
        },
        position: 2,
      })
      break
    case 'group':
      jsonLD = {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        sourceOrganization: parish?.name,
        description,
        headline: title,
        inLanguage: siteLanguage,
        url: pathname,
        name: title,
      } as Organization
      break
    case 'website':
    default:
      jsonLD = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: pathname,
        headline: description,
        inLanguage: siteLanguage,
        mainEntityOfPage: pathname,
        description,
        name: title,
        author: {
          '@type': 'Organization',
          name: author,
        },
        copyrightHolder: {
          '@type': 'Organization',
          name: author,
        },
        copyrightYear,
        creator: {
          '@type': 'Organization',
          name: author,
        },
        publisher: {
          '@type': 'Organization',
          name: parish?.name,
        },
        datePublished: createdAt,
        dateModified: updatedAt,
        image: {
          '@type': 'ImageObject',
          url: `${image}`,
        },
      } as WebSite
      break
  }

  const breadcrumb = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  const mainJsonLD = {
    '@context': 'https://schema.org',
    '@type': 'CatholicChurch',
    url: pathname,
    headline: description,
    inLanguage: siteLanguage,
    mainEntityOfPage: pathname,
    description,
    name: title,
    isAccessibleForFree: true,
    publicAccess: true,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear,
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: parish?.name,
    },
    datePublished: createdAt,
    dateModified: updatedAt,
    image: {
      '@type': 'ImageObject',
      url: `${image}`,
    },
    telephone: parish?.phoneNumber,
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'Canada',
      streetAddress: parish?.address?.addressLine1,
    },
    email: parish?.email,
    potentialAction: {
      '@type': 'DonateAction',
      url: '/donate',
      recipient: {
        '@type': 'Organization',
        name: parish?.name,
      },
      priceCurrency: 'CAD',
    },
  } as CatholicChurch

  return (
    <Helmet>
      {/* GENERIC */}
      <html lang={siteLanguage} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      {/* JSON-LD */}
      <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      <script type="application/ld+json">{JSON.stringify(mainJsonLD)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>

      {/* FACEBOOK */}
      <meta property="og:locale" content={siteLanguage} />
      <meta property="og:url" content={pathname} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      {image && <meta property="og:image" content={image} />}
      {description && (
        <>
          <meta property="og:description" content={description} />
          <meta property="og:image:alt" content={description} />
        </>
      )}
      {updatedAt && <meta property="og:updated_time" content={updatedAt} />}
      {createdAt && <meta property="article:published_time" content={createdAt} />}
      {facebookUsername && (
        <meta
          property="article:publisher"
          content={`https://www.facebook.com/${facebookUsername}`}
        />
      )}

      {/* TWITTER */}
      {twitterUsername && <meta name="twitter:site" content={`@${twitterUsername}`} />}
      {author && <meta name="twitter:creator" content={author} />}
      {image ? (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={image} />
        </>
      ) : (
        <meta name="twitter:card" content="summary" />
      )}
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {description && <meta name="twitter:image:alt" content={description} />}
    </Helmet>
  )
}

export * from './Favicon'
