import {
  BoldIcon,
  Card,
  HeadingOneIcon,
  HeadingTwoIcon,
  IconButton,
  ItalicIcon,
  LinkIcon,
  majorScale,
  MinusIcon,
  Pane,
  UnderlineIcon,
  Tooltip,
  ListIcon,
  QuoteIcon,
} from '@parishconnect/react-ui'
import { AnyFunction, Attrs } from '@remirror/core'
import { useRemirrorContext } from '@remirror/react'
import React, { MouseEventHandler, ReactNode } from 'react'
import { InnerEditorProps } from './Editor'

type EditorToolbarProps = Omit<InnerEditorProps, 'contentComponents'>

export function EditorToolbar({ linkMenuActive, toggleLinkMenuActive }: EditorToolbarProps) {
  const { actions } = useRemirrorContext()
  return (
    <Card
      padding={majorScale(1)}
      elevation={1}
      marginY={majorScale(1)}
      display="flex"
      appearance="white"
      alignItems="center"
      position="sticky"
      top={majorScale(7)}
      alignSelf="flex-start"
    >
      <ToolbarButton icon={HeadingOneIcon} action="toggleHeading" attrs={{ level: 1 }} />
      <ToolbarButton icon={HeadingTwoIcon} action="toggleHeading" attrs={{ level: 2 }} />
      <Pane width={1} borderLeft marginX={majorScale(1)} height={majorScale(3)} />
      <ToolbarButton icon={BoldIcon} action="bold" />
      <ToolbarButton icon={ItalicIcon} action="italic" />
      <ToolbarButton icon={UnderlineIcon} action="underline" />
      <Pane width={1} borderLeft marginX={majorScale(1)} height={majorScale(3)} />
      <ToolbarButton icon={MinusIcon} action="horizontalRule" />
      <ToolbarButton icon={ListIcon} action="toggleBulletList" />
      <ToolbarButton icon={QuoteIcon} action="blockquote" />
      <Pane width={1} borderLeft marginX={majorScale(1)} height={majorScale(3)} />
      <IconButton
        icon={LinkIcon}
        disabled={!actions.updateLink.isEnabled()}
        onClick={toggleLinkMenuActive}
        isActive={linkMenuActive}
        appearance="minimal"
      />
    </Card>
  )
}

type ToolbarButtonProps = {
  icon: ReactNode
  action: string
  attrs?: {}
}
function ToolbarButton({ icon, action, attrs = {} }: ToolbarButtonProps) {
  const editorContext = useRemirrorContext()

  return (
    <IconButton
      icon={icon}
      onClick={runAction(editorContext.actions[action], attrs)}
      isActive={editorContext.actions[action]?.isActive(attrs)}
      disabled={!editorContext.actions[action]?.isEnabled()}
      appearance="minimal"
      marginRight={majorScale(1)}
    />
  )
}

const runAction = (method: AnyFunction, attrs?: Attrs): MouseEventHandler<HTMLElement> => (e) => {
  e.preventDefault()
  method(attrs)
}
