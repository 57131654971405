import Box from '@parishconnect/box'
import {
  Badge,
  Button,
  Card,
  ChevronRightIcon,
  EditIcon,
  FileTextIcon,
  Heading,
  majorScale,
  minorScale,
  Text,
  ThemeContext,
  HeartFilledIcon,
  HeartIcon,
} from '@parishconnect/react-ui'
import { isEmpty, take } from 'lodash-es'
import React, { useContext } from 'react'
import ProgressiveImage from 'react-progressive-image'
import { Link } from 'react-router-dom'
import {
  FeaturedPostsQuery,
  Filter,
  useFeaturedPostsQuery,
  useParishQuery,
} from '../../graphql/generated/graphql-hooks'
import { Can, cssLineClamp } from '../../utils'
import { EmptyState } from '../shared/EmptyState'
import { DateTime } from 'luxon'

// MARK: List
type FeaturedPostsListProps = {
  title?: string
  filters?: Filter
}
export function FeaturedPostsList({ title = 'Recent Posts', filters }: FeaturedPostsListProps) {
  const { data, loading } = useFeaturedPostsQuery({ variables: { filters } })
  const { data: { parish } = {} } = useParishQuery()

  const longHeight = majorScale(38)
  const longMobileHeight = majorScale(30)
  const narrowHeight = majorScale(30)
  const narrowMobileHeight = majorScale(28)
  const width = majorScale(28)
  const gutter = majorScale(2)
  const mobileGutter = majorScale(1)

  const posts = take(data?.posts, 6)

  if (!loading && isEmpty(posts))
    return (
      <EmptyState title="No Posts" icon={FileTextIcon}>
        <Can I="create" this={{ __typename: 'Post', parish }}>
          <Button is={Link} to="/post/new" appearance="primary" iconBefore={EditIcon}>
            Create Post
          </Button>
        </Can>
      </EmptyState>
    )

  return (
    <Box
      width="100%"
      position="relative"
      display="grid"
      gridGap={`${gutter}px 0`}
      gridTemplateColumns={`${gutter}px 1fr ${gutter}px`}
      alignContent="start"
    >
      <Box display="flex" gridColumn="2 / -2">
        <Heading size={700}>{title}</Heading>
        <Button
          is={Link}
          to="/posts"
          marginLeft={['auto', majorScale(2)]}
          iconAfter={ChevronRightIcon}
        >
          All Posts
        </Button>
        <Can I="create" this={{ __typename: 'Post', parish }}>
          <Button
            display={['none', 'inline-flex']}
            marginLeft="auto"
            is={Link}
            to="/post/new"
            appearance="primary"
            iconBefore={EditIcon}
          >
            Create Post
          </Button>
        </Can>
      </Box>
      <Box
        role="list"
        display="grid"
        overflowX="scroll"
        gridColumn="1 / -1"
        gridGap={majorScale(1)}
        gridTemplateColumns={`8px 1fr 1fr 8px`}
        paddingY={majorScale(1)}
        css={{
          '&:before, &:after': {
            content: '""',
          },
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          overflowX: 'scroll',
          '@media(pointer: fine)': {
            scrollSnapType: 'x mandatory',
            scrollPaddingLeft: gutter,
          },
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns={[
            `repeat(2, minmax(calc(50vw - ${gutter * 1.5}px), ${width}px))`,
            `repeat(2, ${width}px)`,
          ]}
          gridTemplateRows={[
            `${narrowMobileHeight}px ${longMobileHeight}px `,
            `${narrowHeight}px ${longHeight}px`,
          ]}
          gridGap={[mobileGutter, gutter]}
          paddingRight={[2, 4]}
        >
          {posts.slice(0, 3).map((post, i) => (
            <FeaturedPost key={post.id} double={i === 0 && posts.length >= 3} {...post} />
          ))}
        </Box>
        {posts.length >= 3 && (
          <Box
            display="grid"
            paddingLeft={[2, 4]}
            gridTemplateColumns={[
              `repeat(2, minmax(calc(50vw - ${gutter * 1.5}px), ${width}px))`,
              `repeat(2, ${width}px)`,
            ]}
            gridTemplateRows={[
              `${longMobileHeight}px ${narrowMobileHeight}px`,
              `${longHeight}px ${narrowHeight}px`,
            ]}
            gridGap={[mobileGutter, gutter]}
          >
            {posts.slice(3, 6).map((post, i) => (
              <FeaturedPost key={post.id} double={i === 2} {...post} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

// MARK: Featured Post Item
export function FeaturedPost({
  id,
  image,
  type,
  title,
  author,
  source,
  likeCount,
  excerpt,
  viewer,
  createdAt,
  double = false,
}: FeaturedPostsQuery['posts'][0] & { double?: boolean }) {
  const theme = useContext(ThemeContext)

  return (
    <ProgressiveImage
      src={double ? image?.doubleSrc : image?.src}
      placeholder={double ? image?.doubleLqip : image?.lqip}
    >
      {(src: string) => (
        <Card
          gridColumn={double && '1 / -1'}
          display="flex"
          flexDirection="column"
          role="listitem"
          is={Link}
          to={`/post/${id}`}
          backgroundImage={src && `url(${src})`}
          elevation={1}
          hoverElevation={2}
          position="relative"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          appearance="gradient"
          padding={majorScale(1)}
          css={{
            scrollSnapAlign: 'start',
            '&::before': {
              content: '""',
              display: 'block',
              backgroundImage: src && `linear-gradient(transparent, rgba(0,0,0,0.65))`,
              position: 'absolute',
              borderRadius: 8,
              bottom: 0,
              left: 0,
              width: '100%',
              height: '75%',
              zIndex: 2,
            },
          }}
        >
          <Box marginBottom="auto" zIndex={3} display="flex">
            <Badge color="overlay" css={{ backdropFilter: 'blur(20px) saturate(180%)' }}>
              {type}
            </Badge>
            <Box display="inline-flex" alignItems="center" marginLeft="auto">
              {likeCount > 0 && (
                <Text size={300} marginTop={1} marginRight={minorScale(1)} color="white">
                  {likeCount}
                </Text>
              )}
              {viewer && viewer.likesPost ? (
                <HeartFilledIcon color="danger" />
              ) : (
                <HeartIcon color="white" />
              )}
            </Box>
          </Box>
          <Box zIndex={3} marginTop="auto" padding={[minorScale(1), majorScale(1)]}>
            <Heading
              size={600}
              color="white"
              css={{
                ...cssLineClamp(),
                textShadow: src && '0 0 4px rgba(0,0,0,0.24)',
              }}
            >
              {title}
            </Heading>
            {double && (
              <Text
                size={300}
                paddingTop={minorScale(1)}
                color="white"
                css={{
                  ...cssLineClamp(),
                  textShadow: src && '0 0 2px rgba(0,0,0,0.24)',
                }}
              >
                {excerpt}
              </Text>
            )}
            <Box>
              <Box
                display="flex"
                paddingTop={majorScale(1)}
                alignItems="center"
                maxWidth="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                <Heading color="white" lineHeight="12px" size={300}>
                  {author.fullName}
                </Heading>
              </Box>
              <Box
                display="flex"
                maxWidth="100%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                <Text size={300} color="rgba(255,255,255,0.9)">
                  {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_MED)}
                </Text>
                <Text size={300} color="rgba(255,255,255,0.9)" paddingX={minorScale(1)}>
                  {` • `}
                </Text>
                <Text size={300} color={theme.palette[theme.themeColor].light}>
                  {source}
                </Text>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </ProgressiveImage>
  )
}
