import Box from '@parishconnect/box'
import { Button, Dialog, Heading, majorScale, Paragraph, Text } from '@parishconnect/react-ui'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import {
  Post,
  PostTypes,
  Reading,
  useReadingsLazyQuery,
} from '../../graphql/generated/graphql-hooks'

export function PostReadings(post: Post) {
  const [isShown, setShown] = useState(false)
  const readingDate = DateTime.fromISO(post.lectionaryDate)
  const [loadReadings, { data, called }] = useReadingsLazyQuery({
    variables: { date: readingDate.toJSDate() },
  })

  useEffect(() => {
    if (!called && isShown) {
      loadReadings()
    }
  }, [isShown])

  if (!post.lectionaryDate || post.type !== PostTypes.Homily) return null

  return (
    <Box paddingX={[majorScale(1), majorScale(3)]} paddingTop={majorScale(2)}>
      <Button appearance="primary" round onClick={() => setShown(true)}>
        See the readings
      </Button>
      <Dialog
        hasFooter={false}
        preventBodyScrolling
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        title={`Readings for ${readingDate.toLocaleString(DateTime.DATE_FULL)}`}
      >
        {data?.readings &&
          Object.values(data?.readings)
            .filter((key) => typeof key !== 'string')
            .map((reading) => (
              <ReadingSection key={(reading as Reading).type} reading={reading as Reading} />
            ))}
      </Dialog>
    </Box>
  )
}

function ReadingSection({ reading }: { reading: Reading }) {
  if (!reading.body) {
    return null
  }

  return (
    <Box paddingTop={majorScale(2)}>
      <Box display="flex" alignItems="baseline" gap={majorScale(1)}>
        <Heading size={500}>{reading.title}</Heading>
        <Text>{reading.passage}</Text>
      </Box>
      <Paragraph paddingY={majorScale(1)}>{reading.body}</Paragraph>
    </Box>
  )
}
