import Box from '@parishconnect/box'
import produce from 'immer'
import {
  Card,
  IconButton,
  majorScale,
  Menu,
  MoreHorizontalIcon,
  Popover,
  Position,
  Strong,
  Text,
  ThemeContext,
  TrashIcon,
} from '@parishconnect/react-ui'
import { motion, Variants } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { useContext } from 'react'
import {
  Bulletin,
  BulletinLink as BulletinLinkType,
  useDeleteBulletinLinkMutation,
  BulletinQuery,
  BulletinDocument,
} from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils/abilityContext'
import { ThemeSelector } from '../Dashboard'

type BulletinLinkProps = {
  bulletin: BulletinLinkType
  parish?: Bulletin['parish']
  title?: string
  i: number
}

const bulletinMotion: Variants = {
  enter: (i) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: i * 0.05,
    },
  }),
  exit: {
    opacity: 0,
    y: -10,
  },
}

export const BulletinLink = ({ bulletin, title, parish, i }: BulletinLinkProps) => {
  const theme = useContext(ThemeContext)
  const [deleteBulletinLink] = useDeleteBulletinLinkMutation()

  return (
    <Card
      is={motion.a}
      variants={bulletinMotion}
      animate="enter"
      exit="exit"
      positionTransition
      custom={i}
      maxWidth={parish?.id && majorScale(40)}
      marginBottom={majorScale(1)}
      appearance="solid"
      hoverElevation={2}
      cursor="pointer"
      padding={majorScale(2)}
      position="relative"
      href={bulletin?.url}
      rel="noopener"
      target="_blank"
      width="100%"
      display="block"
      className="fv"
    >
      <Box>
        <Strong>
          {title ||
            `${DateTime.fromISO(bulletin?.forDate).toLocaleString({
              weekday: 'long',
            })} Bulletin`}
        </Strong>
        <Box display="flex" justifyContent="space-between">
          <Text color={theme.palette[theme.themeColor].text} size={300}>
            {DateTime.fromISO(bulletin?.forDate).toLocaleString(DateTime.DATE_FULL)}
          </Text>
          {bulletin?.createdAt && (
            <Text color={theme.palette[theme.themeColor].text} size={300}>
              Uploaded {DateTime.fromISO(bulletin?.createdAt).toRelativeCalendar()}
            </Text>
          )}
        </Box>
      </Box>
      <Can I="delete" this={{ __typename: 'Bulletin', parish }}>
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={({ close }: any) => (
            <Menu>
              <Menu.Item
                intent="danger"
                icon={TrashIcon}
                onClick={(e: Event) => {
                  e.preventDefault()
                  deleteBulletinLink({
                    variables: { id: bulletin.id },
                    update: (cache, { data: deleteBulletinLink }) => {
                      const data = cache.readQuery<BulletinQuery>({ query: BulletinDocument })

                      cache.writeQuery<BulletinQuery>({
                        query: BulletinDocument,
                        data: {
                          ...data,
                          bulletin: {
                            ...data?.bulletin,
                            links: data?.bulletin?.links.filter(
                              (link) => link.id !== deleteBulletinLink.deleteBulletinLink,
                            ),
                          },
                        },
                      })
                    },
                  })
                  close()
                }}
              >
                Delete
              </Menu.Item>
            </Menu>
          )}
        >
          <IconButton
            pointerEvents=""
            position="absolute"
            top={majorScale(1)}
            right={majorScale(1)}
            icon={MoreHorizontalIcon}
            appearance="minimal"
            css={{ height: '16px!important' }}
          />
        </Popover>
      </Can>
    </Card>
  )
}
