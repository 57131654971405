import ReactSelect from 'react-select/async'
import React, { useContext } from 'react'
import { ApolloClient, useApolloClient } from '@apollo/client'
import {
  SearchUsersQuery,
  SearchUsersDocument,
  SearchUsersQueryVariables,
} from '../../graphql/generated/graphql-hooks'
import {
  ThemeContext,
  Text,
  defaultTheme,
  TextInput,
  Menu,
  minorScale,
} from '@parishconnect/react-ui'
import Box from '@parishconnect/box'

async function searchUsers(
  text: string,
  client: ApolloClient<object>,
  variables: Omit<SearchUsersQueryVariables, 'text'>,
): Promise<SearchUsersQuery['searchUsers']> {
  if (text?.trim().length < 3) {
    return []
  }
  const res = await client.query<SearchUsersQuery>({
    query: SearchUsersDocument,
    variables: { text, ...variables },
  })
  return res.data?.searchUsers ?? []
}

type UserSearchProps = {
  value: { fullName: string; id: string }
  setValue: (key: keyof UserSearchProps['value'], value: string) => void
  variables: Omit<SearchUsersQueryVariables, 'text'>
}
export function UserSearch({ value, variables, setValue }: UserSearchProps) {
  const client = useApolloClient()
  const theme: typeof defaultTheme = useContext(ThemeContext)

  return (
    <ReactSelect<{ fullName: string; id: string }>
      menuPortalTarget={
        typeof document !== 'undefined' &&
        document.querySelector('[data-parishkit-toaster-container]')
      }
      cacheOptions
      placeholder={null}
      isClearable={false}
      value={value}
      getOptionLabel={(value) => value.fullName}
      getOptionValue={(value) => value.id}
      loadOptions={(text) => searchUsers(text, client, variables)}
      noOptionsMessage={() => 'No Users'}
      onChange={({ id, fullName }) => {
        setValue('id', id)
        setValue('fullName', fullName)
      }}
      components={{
        DropdownIndicator: null,
        ClearIndicator: null,
        LoadingIndicator: null,
        Input: ({ ...props }) => (
          <TextInput
            size={300}
            {...props}
            width="100%"
            placeholder={!value.id && 'Search for a parishioner...'}
            css={{
              background: `${theme.scales.neutral.N2A}!important`,
              opacity: 0.8,
              borderWidth: '0!important',
              boxShadow: 'none!important',
            }}
          />
        ),
        Option: (props) =>
          props.children ? (
            <Menu.Item
              disabled={props.isDisabled}
              onSelect={() => props.selectOption(props.data)}
              {...props}
            />
          ) : (
            <Box />
          ),
        SingleValue: (props) => (
          <Text
            size={300}
            lineHeight="32px"
            marginLeft={minorScale(3)}
            css={theme.getTextInputCSS('default', theme.themeColor)}
            position="absolute"
            {...props}
          />
        ),
      }}
      styles={{
        control: () => ({
          position: 'relative',
          width: '100%',
        }),
        placeholder: () => null,
        valueContainer: () => ({
          ...theme.getTextInputCSS('default', theme.themeColor),
          width: '100%',
        }),
        container: () => ({ width: '100%' }),
        input: () => theme.getTextInputCSS('default', theme.themeColor),
      }}
    />
  )
}
