import Box from '@parishconnect/box'
import {
  defaultTheme,
  Dialog,
  IconButton,
  Image,
  majorScale,
  PlusIcon,
  Spinner,
  ThemeContext,
} from '@parishconnect/react-ui'
import React, { useContext, useEffect } from 'react'
import ProgressiveImage from 'react-progressive-image'
import { ImagePickerQuery, useImagePickerLazyQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN, COLUMNS } from '../../utils/constants'

type ImagePickerProps = {
  isShown?: boolean
  browse?: () => void
  onPick?: ({}: { id: string }) => void
  onClose?: () => void
}

export function ImagePicker({
  isShown = false,
  onPick = () => {},
  onClose,
  browse = () => {},
}: ImagePickerProps) {
  const [fetchImages, { data, loading, called }] = useImagePickerLazyQuery({
    variables: { limit: 30 },
  })

  useEffect(() => {
    if (!called) {
      fetchImages()
    }
  }, [isShown, called])

  const images = data?.images || []

  return (
    <Dialog
      onCloseComplete={onClose}
      width={(COLUMN * COLUMNS) / 2}
      isShown={isShown}
      hasFooter={false}
      title="Choose from your images"
      preventBodyScrolling
    >
      <Box display="flex" flexWrap="wrap">
        <IconButton
          icon={PlusIcon}
          iconSize={majorScale(8)}
          width={100}
          height={100}
          onClick={browse}
          margin={4}
        />
        {loading ? (
          <Box
            height={100}
            width={100}
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin={4}
          >
            <Spinner />
          </Box>
        ) : (
          images.map((image) => <ImageThumb onPick={onPick} key={image.id} {...image} />)
        )}
      </Box>
    </Dialog>
  )
}

const ImageThumb = ({
  onPick,
  ...image
}: ImagePickerQuery['images'][0] & {
  onPick: ({}: { id: string }) => void
}) => {
  const theme: typeof defaultTheme = useContext(ThemeContext)
  return (
    <ProgressiveImage src={image?.src} placeholder={image?.lqip}>
      {(src: string) => (
        <Image
          src={src}
          onClick={() => onPick({ id: image.id })}
          height={100}
          margin={4}
          alt={image?.title || ''}
          borderRadius={4}
          css={{
            cursor: 'pointer',
            transition: '125ms',
            ':hover': {
              boxShadow: `0 0 0 3px ${theme.palette[theme.themeColor].light}`,
            },
          }}
        />
      )}
    </ProgressiveImage>
  )
}
