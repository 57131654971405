import { useContext } from 'react'
import { AbilityContext } from '../abilityContext'
import { Actions } from '@parishconnect/abilities'

export default function useCan(action: Actions, subject: any, field?: string): boolean {
  const ability = useContext(AbilityContext)

  if (ability) {
    return ability.can(action, subject, field)
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.log('No ability context found')
    }
    return false
  }
}
