import Box from '@parishconnect/box'
import { majorScale, Pane } from '@parishconnect/react-ui'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import useRouter from 'use-react-router'
import {
  Nav,
  Renderer,
  Seo,
  SingleEventActions,
  SingleEventImage,
  SingleEventInfo,
  SingleEventTime,
} from '../components'
import { PageContainer } from '../components/shared'
import { useEventQuery } from '../graphql/generated/graphql-hooks'
import { COLUMN } from '../utils/constants'
import { DateTime, Duration } from 'luxon'

const Event = () => {
  const { match, location } = useRouter<{ id: string }>()
  const { id } = match.params
  const { data } = useEventQuery({
    variables: {
      id,
    },
  })

  const description = data?.event?.description

  return (
    <PageContainer>
      <Helmet>
        <title>{data?.event?.title || 'Event'}</title>
      </Helmet>
      <Seo
        author={data?.event?.owner?.fullName}
        pathname={location.pathname}
        type="event"
        startDate={data?.event?.dtstart}
        endDate={
          data?.event?.duration &&
          DateTime.fromISO(data?.event?.dtstart)
            .plus(Duration.fromISO(data?.event?.duration))
            .toUTC()
            .toISO()
        }
        createdAt={data?.event?.updatedAt}
        updatedAt={data?.event?.createdAt}
        title={data?.event?.title}
        parish={data?.event?.parish}
        image={data?.event?.image?.src}
        description={data?.event?.excerpt}
      />
      <Pane display="flex">
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
        </Box>
        <Box
          maxWidth={majorScale(62)}
          marginLeft={[0, null, COLUMN / 2]}
          is="main"
          id="main-content"
        >
          <SingleEventImage {...data?.event?.image} />
          <SingleEventTime {...data?.event} />
          <SingleEventActions {...data?.event} />
          <SingleEventInfo {...data?.event} />
          <Box marginX={[majorScale(2), null, majorScale(3)]}>
            <Renderer document={description} />
          </Box>
        </Box>
      </Pane>
    </PageContainer>
  )
}

export default Event
