import React from 'react'
import { Error, ErrorProps } from './Error'
import * as Sentry from '@sentry/browser'
import { Button } from '@parishconnect/react-ui'

type ErrorBoundaryState = {
  hasError?: boolean
  eventId: string
}

export default class ErrorBoundary extends React.Component<ErrorProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    eventId: null,
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error)
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <>
          <Error title="Error" {...this.props} />
          <Button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
            Report feedback
          </Button>
        </>
      )
    }

    //when there's not an error, render children untouched
    return this.props.children
  }
}
