import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import {
  BookOpenIcon,
  Button,
  DatePicker,
  Dialog,
  Label,
  majorScale,
  Pre,
  TextInputField,
} from '@parishconnect/react-ui'
import { Field, Form, Formik } from 'formik'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import * as yup from 'yup'
import { Bulletin, useAddBulletinLinkMutation } from '../../graphql/generated/graphql-hooks'
import { useToggle } from '../../utils'

const bulletinFormSchema = yup.object().shape({
  url: yup.string().url(),
  forDate: yup.date(),
})

export function AddBulletin({ id }: Bulletin) {
  const [isShown, toggleShown] = useToggle(false)
  const [addBulletinLink] = useAddBulletinLinkMutation()

  const [file, setFile] = useState<File>(null)

  return (
    <>
      <Button onClick={() => toggleShown(true)} iconBefore={BookOpenIcon}>
        Add Bulletin
      </Button>
      <Formik
        onSubmit={async (values, actions) => {
          try {
            await addBulletinLink({
              variables: { id, bulletinLink: values, file },
            })
            actions.setSubmitting(false)
            actions.resetForm()
            toggleShown(false)
          } catch (error) {
            Sentry.captureException(error)
          }
        }}
        validationSchema={bulletinFormSchema}
        initialValues={{
          url: '',
          forDate: DateTime.local().set({ weekday: 7 }).toJSDate(),
        }}
      >
        {({ values, errors, setFieldValue, submitForm }) => (
          <Dialog
            isShown={isShown}
            onCloseComplete={() => toggleShown(false)}
            onConfirm={submitForm}
            title="Add Bulletin"
          >
            <Form>
              <TextInputField
                is={Field}
                name="url"
                label="Bulletin URL"
                placeholder="https://example.com/bulletin"
                required={!file}
              />
              <TextInputField
                label="Upload Bulletin"
                type="file"
                required={!values.url}
                css={{ paddingTop: majorScale(1) }}
                onChange={(e) => e.target.files && setFile(e.target.files[0])}
              />
              <Box marginBottom={majorScale(3)}>
                <Label display="block" marginBottom={4} htmlFor="bulletin-datepicker">
                  Bulletin Date
                </Label>
                <DatePicker
                  required
                  id="bulletin-datepicker"
                  onChange={(date: Date) => setFieldValue('forDate', date)}
                  value={values.forDate}
                />
              </Box>
            </Form>
          </Dialog>
        )}
      </Formik>
    </>
  )
}
