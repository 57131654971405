import Box from '@parishconnect/box'
import { Heading, majorScale, Strong } from '@parishconnect/react-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import { EventQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN, COLUMNS } from '../../utils/constants'

export const SingleEventInfo = ({ owner, parish, group }: EventQuery['event']) => {
  return owner?.fullName || parish?.name || group?.name ? (
    <Box
      padding={[majorScale(2), null, majorScale(3)]}
      marginTop={[majorScale(1), null, majorScale(2)]}
      maxWidth={COLUMN * COLUMNS}
    >
      <Heading size={100}>Host</Heading>
      <Box marginBottom={majorScale(1)} display="flex">
        {group?.name ? (
          <>
            <Strong
              color={parish?.theme}
              is={group?.id ? Link : 'strong'}
              to={`/group/${group?.id}`}
            >
              {group?.name}
            </Strong>
            <Strong marginX={majorScale(1)}>•</Strong>
          </>
        ) : (
          owner?.fullName && (
            <>
              <Strong
                color={parish?.theme}
                is={owner?.id ? Link : 'strong'}
                to={`/user/${owner?.id}`}
              >
                {owner?.fullName}
              </Strong>
              <Strong marginX={majorScale(1)}>•</Strong>
            </>
          )
        )}
        <Strong is={Link} to="/" color={parish?.theme}>
          {parish?.name}
        </Strong>
      </Box>
    </Box>
  ) : null
}
