import Box from '@parishconnect/box'
import {
  Button,
  Card,
  FacebookIcon,
  Heading,
  InstagramIcon,
  majorScale,
  SaveIcon,
  Text,
  TextInputField,
  toaster,
  TwitterIcon,
  YoutubeIcon,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import { camelCase, capitalize } from 'lodash-es'
import React, { ReactNode } from 'react'
import { object, ObjectSchema, string } from 'yup'
import {
  Parish,
  useEditParishMutation,
  useParishQuery,
} from '../../graphql/generated/graphql-hooks'
import {
  FACEBOOK_COLOR,
  FACEBOOK_REGEX,
  INSTAGRAM_COLOR,
  TWITTER_COLOR,
  TWITTER_REGEX,
  YOUTUBE_COLOR,
  YOUTUBE_REGEX,
} from '../../utils'

export function SocialMediaManager() {
  const { data } = useParishQuery()
  return (
    <Box width="100%" maxWidth={majorScale(65)}>
      <Heading marginY={[majorScale(4), majorScale(2)]} size={800}>
        Social Media
      </Heading>
      <Text>
        Your social media accounts will be used around your site to increase engagment with your
        parishioners.
      </Text>

      <Box paddingTop={majorScale(3)} display="grid" gridAutoFlow="row" gridGap={majorScale(2)}>
        <SocialMediaFormFactory {...data?.parish} />
      </Box>
    </Box>
  )
}

const SocialMediaDictionary: {
  [account: string]: {
    color: string
    icon: ReactNode
    schema: ObjectSchema<object>
    label?: string
    placeholder?: string
  }
} = {
  facebook: {
    color: FACEBOOK_COLOR,
    schema: object().shape({
      facebook: object().shape({
        username: string().matches(
          FACEBOOK_REGEX,
          'Facebook only accepts alphanumeric characters and periods',
        ),
      }),
    }),
    icon: FacebookIcon,
  },
  instagram: {
    color: INSTAGRAM_COLOR,
    schema: object().shape({
      instagram: object().shape({
        username: string().matches(FACEBOOK_REGEX, 'Not a valid instagram username'),
      }),
    }),
    icon: InstagramIcon,
  },
  twitter: {
    color: TWITTER_COLOR,
    schema: object().shape({
      twitter: object().shape({
        username: string().matches(
          TWITTER_REGEX,
          'Twitter only accepts alphanumeric characters and underscores',
        ),
      }),
    }),
    icon: TwitterIcon,
  },
  youtube: {
    color: YOUTUBE_COLOR,
    schema: object().shape({
      youtube: object().shape({
        username: string().matches(YOUTUBE_REGEX, 'Not a valid youtube username'),
      }),
    }),
    label: 'Channel ID',
    placeholder: 'e.g. UCltp2x1uzdsHVARw',
    icon: YoutubeIcon,
  },
}

function SocialMediaFormFactory(parish: Partial<Parish>) {
  const [editParish] = useEditParishMutation()

  return Object.entries(SocialMediaDictionary).map(([account, { icon: Icon, ...settings }]) => (
    <Card
      key={account}
      background="tint1"
      border="muted"
      padding={majorScale(2)}
      maxWidth={majorScale(70)}
    >
      <Box color={settings.color} display="flex" alignItems="center">
        <Icon marginRight={majorScale(1)} />
        <Heading size={600}>{capitalize(account)}</Heading>
      </Box>
      <Box paddingTop={majorScale(2)}>
        <Formik<{ [account: string]: { username: string } }>
          initialValues={{ [account]: { username: parish?.[account]?.username } }}
          validationSchema={settings.schema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await editParish({ variables: { parish: values } })
              toaster.success(`Saved ${capitalize(account)} Settings!`)
            } catch (error) {
              Sentry.captureException(error)
              toaster.danger(error.message)
            }
            setSubmitting(false)
          }}
        >
          {({ dirty, errors, isSubmitting }) => (
            <Form autoComplete="off" disabled={isSubmitting}>
              <Box display="flex" alignItems="center">
                <TextInputField
                  is={Field}
                  name={`${account}.username`}
                  label={settings.label ?? 'Username'}
                  placeholder={settings.placeholder ?? `e.g. ${camelCase(parish?.name)}`}
                  flexGrow="1"
                  isInvalid={errors?.[account]?.username?.length > 0}
                  validationMessage={errors?.[account]?.username}
                />
                <Button
                  type="submit"
                  disabled={!dirty}
                  appearance="primary"
                  iconBefore={SaveIcon}
                  marginLeft={majorScale(1)}
                >
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Card>
  ))
}
