import Box from '@parishconnect/box'
import { Card, CloudIcon, majorScale, Strong, Text, ThemeContext } from '@parishconnect/react-ui'
import { IconProps } from '@parishconnect/react-ui/dist/icons/src/Icon'
import React, { ComponentType, ReactNode, useContext } from 'react'

type EmptyStateProps = {
  title?: string
  children?: ReactNode
  icon?: ComponentType<IconProps> | false
  css?: any
}
export function EmptyState({
  title = 'Empty',
  children,
  icon: Icon = CloudIcon,
  css,
}: EmptyStateProps) {
  const theme = useContext(ThemeContext)
  return (
    <Box
      css={{
        width: '100%',
        height: '80vh',
        maxHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...css,
      }}
    >
      {Icon !== false && (
        <Card
          appearance="solid"
          borderRadius="100vw"
          width={[majorScale(25), '25vw']}
          height={[majorScale(25), '25vw']}
          maxWidth={majorScale(25)}
          maxHeight={majorScale(25)}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Icon size={majorScale(10)} color={theme.palette[theme.themeColor].base} />
          <Strong marginTop={majorScale(1)} size={500} color="theme">
            {title}
          </Strong>
        </Card>
      )}
      {children && (
        <Text
          size={500}
          maxWidth={majorScale(40)}
          textAlign="center"
          width="80%"
          marginTop={majorScale(2)}
        >
          {children}
        </Text>
      )}
    </Box>
  )
}
