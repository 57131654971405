import Box from '@parishconnect/box'
import {
  Avatar,
  Heading,
  majorScale,
  minorScale,
  Pill,
  Strong,
  Text,
  ThemeContext,
} from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { User, Role, useParishQuery } from '../../graphql/generated/graphql-hooks'

type UserControlsProps = {
  user: User
  role?: Role
}

const UserNavModule = ({ user, role }: UserControlsProps) => {
  const theme = useContext(ThemeContext)

  if (!user) {
    return <SignupCTA />
  }

  return (
    <Box
      is={Link}
      to={`/user/${user.id}`}
      display="flex"
      alignItems="center"
      marginY={majorScale(2)}
    >
      <Box flexBasis={majorScale(7)}>
        <Avatar
          boxShadow="0 3px 6px rgba(0,0,0,0.12)"
          size={majorScale(7)}
          flexBasis={majorScale(7)}
          marginRight={minorScale(1)}
          src={user?.image?.src}
          name={user.firstName + ' ' + user.lastName}
        />
      </Box>
      <Box
        paddingY={majorScale(2)}
        paddingX={majorScale(2)}
        display="flex"
        flexDirection="column"
        borderRadius={18}
        css={{
          transition: '225ms',
          '&:hover': {
            backgroundColor: theme.palette[theme.themeColor].dark,
          },
        }}
      >
        <Strong size={500} color="white">
          {user?.fullName ?? ''}
        </Strong>
        <Box display="flex" alignItems="center" overflow="hidden" textOverflow="ellipsis">
          <Text
            color="white"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth={majorScale(14)}
          >
            {user?.primaryParish?.name}
          </Text>
          {role && (
            <Pill
              marginLeft={majorScale(1)}
              color="overlay"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {role.level}
            </Pill>
          )}
        </Box>
      </Box>
    </Box>
  )
}

function SignupCTA() {
  const theme = useContext(ThemeContext)
  const { data } = useParishQuery()

  return (
    <Box is={Link} to="/signup" display="flex" alignItems="center" marginTop={majorScale(2)}>
      <Box
        padding={majorScale(3)}
        display="flex"
        flexGrow={1}
        flexDirection="column"
        borderRadius={28}
        background={theme.palette[theme.themeColor].dark}
        css={{
          '&:hover': {
            backgroundColor: theme.palette[theme.themeColor].dark,
            boxShadow: `0 0 0 1px rgba(255,255,255,0.5)`,
          },
        }}
      >
        <Heading color="white" size={100} marginBottom={minorScale(1)}>
          Are you a parishioner at {data?.parish?.name}?
        </Heading>
        <Text size={400} color="white">
          Create an account to do more
        </Text>
      </Box>
    </Box>
  )
}

export default UserNavModule
