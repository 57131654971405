import React from 'react'
import { TextHandlerProps } from '.'
import { InnerRenderer } from '../Renderer'

export function OrderedListHandler({ node }: TextHandlerProps) {
  const content = node.content
  if (!content) {
    return null
  }

  return (
    <ol start={node.attrs.order as number}>
      {node.content.map((child, ii) => (
        <InnerRenderer key={ii} json={child} />
      ))}
    </ol>
  )
}
