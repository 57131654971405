import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Map: any;
  Upload: any;
};







export type About = {
  __typename?: 'About';
  ast?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  plain?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  parish?: Maybe<Parish>;
};

export type AboutInput = {
  ast: Scalars['String'];
  parish?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  plain?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum AboutType {
  Generic = 'GENERIC',
  Staff = 'STAFF'
}

export type Address = {
  __typename?: 'Address';
  locationName?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
};

export type Analytics = {
  __typename?: 'Analytics';
  id?: Maybe<Scalars['ID']>;
  parish?: Maybe<Parish>;
  date?: Maybe<Scalars['Date']>;
  memberCount?: Maybe<Scalars['Int']>;
  postCount?: Maybe<Scalars['Int']>;
  groupCount?: Maybe<Scalars['Int']>;
  topLikedPosts?: Maybe<Array<Maybe<LikedPost>>>;
  eventsCounts?: Maybe<Scalars['Int']>;
  visitors?: Maybe<Array<Maybe<Visitor>>>;
};

export type Bulletin = {
  __typename?: 'Bulletin';
  currentLink?: Maybe<BulletinLink>;
  id?: Maybe<Scalars['ID']>;
  lastUpdated?: Maybe<Scalars['Date']>;
  links?: Maybe<Array<Maybe<BulletinLink>>>;
  parish?: Maybe<Parish>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type BulletinLink = {
  __typename?: 'BulletinLink';
  id?: Maybe<Scalars['ID']>;
  forDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
};

export type BulletinLinkInput = {
  forDate?: Maybe<Scalars['Date']>;
  uploadedDate?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
};

export enum Channel {
  Youtube = 'YOUTUBE'
}

export enum Crop {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Faces = 'faces',
  Focalpoint = 'focalpoint',
  Edges = 'edges',
  Entropy = 'entropy'
}

/** Filters by current user abilities */
export type Can = {
  post?: Maybe<CanType>;
  event?: Maybe<CanType>;
};

export type CanType = {
  create?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
};

export enum Category {
  Mobile = 'mobile',
  Desktop = 'desktop'
}

export enum Donation_Methods {
  Custom = 'CUSTOM',
  Toronto = 'TORONTO'
}


export type DateTimeObj = {
  __typename?: 'DateTimeObj';
  timezone: Scalars['String'];
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  hour: Scalars['Int'];
  minute: Scalars['Int'];
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
};

export type DateTimeObjInput = {
  timezone: Scalars['String'];
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  hour: Scalars['Int'];
  minute: Scalars['Int'];
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
};

/** Every Parish belongs to a diocese */
export type Diocese = {
  __typename?: 'Diocese';
  about?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  /** Administrative Email */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type DioceseInput = {
  about?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** Administrative Email */
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DonationMethod = {
  __typename?: 'DonationMethod';
  id: Scalars['ID'];
  method: Donation_Methods;
  note?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DonationMethodInput = {
  method: Donation_Methods;
  note?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EditAboutInput = {
  ast?: Maybe<Scalars['String']>;
  parish?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  plain?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EditDonationMethodInput = {
  method?: Maybe<Donation_Methods>;
  note?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EditEventInput = {
  address?: Maybe<AddressInput>;
  coHosts?: Maybe<Array<Maybe<HostInput>>>;
  description?: Maybe<Scalars['String']>;
  dtend?: Maybe<Scalars['Date']>;
  dtstart?: Maybe<Scalars['Date']>;
  excerpt?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  imagePosition?: Maybe<Position2DInput>;
  host?: Maybe<HostInput>;
  location?: Maybe<Array<Maybe<Scalars['Float']>>>;
  locationID?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  privacy?: Maybe<Privacy>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
  tzid?: Maybe<Scalars['String']>;
};

export type EditFormInput = {
  ast?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EditGroupInput = {
  cover?: Maybe<Scalars['ID']>;
  coverPosition?: Maybe<Position2DInput>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  privacy?: Maybe<Privacy>;
  status?: Maybe<GroupStatus>;
};

export type EditPostInput = {
  status?: Maybe<Status>;
  createdAt?: Maybe<Scalars['Date']>;
  type?: Maybe<PostTypes>;
  ast?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  imagePosition?: Maybe<Position2DInput>;
  lectionaryDate?: Maybe<Scalars['Date']>;
  plain?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  privacy?: Maybe<Privacy>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type EditSacramentInput = {
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<SacramentType>;
};

export type EditUserInput = {
  image?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  primaryParish?: Maybe<Scalars['ID']>;
  staffEntry?: Maybe<StaffEntryInput>;
};

export type Event = {
  __typename?: 'Event';
  id?: Maybe<Scalars['ID']>;
  address?: Maybe<Address>;
  description?: Maybe<Scalars['String']>;
  dtend?: Maybe<Scalars['Date']>;
  dtstart?: Maybe<Scalars['Date']>;
  duration?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  imagePosition?: Maybe<Position2D>;
  group?: Maybe<Group>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  list?: Maybe<Array<Maybe<Scalars['Date']>>>;
  location?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  parentEvent?: Maybe<Scalars['ID']>;
  parentID?: Maybe<Scalars['ID']>;
  pinned?: Maybe<Scalars['Boolean']>;
  parish?: Maybe<Parish>;
  privacy?: Maybe<Privacy>;
  rrule?: Maybe<Rrule>;
  rruleString?: Maybe<Scalars['String']>;
  rsvpCount?: Maybe<Scalars['Int']>;
  rsvps?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status: Status;
  title?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  viewer?: Maybe<EventViewer>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type EventInput = {
  address?: Maybe<AddressInput>;
  coHosts?: Maybe<Array<Maybe<HostInput>>>;
  description?: Maybe<Scalars['String']>;
  dtend?: Maybe<Scalars['Date']>;
  dtstart: Scalars['Date'];
  excerpt?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['ID']>;
  imagePosition?: Maybe<Position2DInput>;
  host?: Maybe<HostInput>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['Float']>>>;
  locationID?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  privacy?: Maybe<Privacy>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
  tzid?: Maybe<Scalars['String']>;
};

export type EventViewer = {
  __typename?: 'EventViewer';
  isHost?: Maybe<Scalars['Boolean']>;
  hasRSVP?: Maybe<Scalars['Boolean']>;
};

export type Exception = {
  __typename?: 'Exception';
  id: Scalars['ID'];
  expires?: Maybe<Scalars['Date']>;
  date?: Maybe<ExceptionDate>;
};

export type ExceptionData = {
  __typename?: 'ExceptionData';
  exType?: Maybe<Scalars['String']>;
  newDate?: Maybe<Scalars['Date']>;
  reason?: Maybe<Scalars['String']>;
};

export type ExceptionDataInput = {
  reason?: Maybe<Scalars['String']>;
  newDate?: Maybe<Scalars['Date']>;
  exType: ExceptionType;
};

export type ExceptionDate = {
  __typename?: 'ExceptionDate';
  type?: Maybe<RDateTypes>;
  dates?: Maybe<Array<Maybe<DateTimeObj>>>;
  timezone?: Maybe<Scalars['String']>;
  data?: Maybe<ExceptionData>;
};

export type ExceptionDateInput = {
  type: RDateTypes;
  dates: Array<DateTimeObjInput>;
  data?: Maybe<ExceptionDataInput>;
  timezone: Scalars['String'];
};

export type ExceptionInput = {
  expires?: Maybe<Scalars['Date']>;
  date: ExceptionDateInput;
};

export enum ExceptionType {
  Cancel = 'CANCEL',
  Modify = 'MODIFY'
}

export enum Fit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Facearea = 'facearea',
  Fill = 'fill',
  Fillmax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale'
}

export type Feedback = {
  __typename?: 'Feedback';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  allowedContact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  parish?: Maybe<Parish>;
};

export type FeedbackInput = {
  allowedContact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  type: Scalars['String'];
};

export type Filter = {
  from?: Maybe<Scalars['Date']>;
  /** Specify multiple fields by separating with spaces */
  has?: Maybe<Scalars['String']>;
  include?: Maybe<Include>;
  status?: Maybe<Status>;
  pinned?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['ID']>;
  parish?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<FilterTypes>;
  until?: Maybe<Scalars['Date']>;
};

export enum FilterTypes {
  Exception = 'EXCEPTION',
  Recurring = 'RECURRING',
  Single = 'SINGLE'
}

/** A `Flag` is set on a mass to provide contextual for masses that may belong to specific group */
export enum Flag {
  Ferial = 'FERIAL',
  Special = 'SPECIAL',
  Sunday = 'SUNDAY'
}

export type Form = {
  __typename?: 'Form';
  id?: Maybe<Scalars['ID']>;
  ast?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  pinned?: Maybe<Scalars['Boolean']>;
  parish?: Maybe<Parish>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  submissions?: Maybe<Array<Maybe<Submission>>>;
  closedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type FormInput = {
  ast?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  cover?: Maybe<Image>;
  coverPosition?: Maybe<Position2D>;
  image?: Maybe<Image>;
  id?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<User>>>;
  memberCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  parish?: Maybe<Parish>;
  privacy?: Maybe<Privacy>;
  policies?: Maybe<Policies>;
  status?: Maybe<GroupStatus>;
  updatedAt?: Maybe<Scalars['Date']>;
  viewer?: Maybe<GroupViewer>;
};

export type GroupInput = {
  cover?: Maybe<Scalars['ID']>;
  coverPosition?: Maybe<Position2DInput>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  privacy?: Maybe<Privacy>;
  image?: Maybe<Scalars['ID']>;
};

export enum GroupStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Pending = 'PENDING'
}

export type GroupViewer = {
  __typename?: 'GroupViewer';
  isMember?: Maybe<Scalars['Boolean']>;
};

export type Host = {
  __typename?: 'Host';
  user?: Maybe<User>;
};

export type HostInput = {
  user?: Maybe<UserInput>;
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['ID']>;
  colors?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  srcset?: Maybe<Scalars['String']>;
  parish?: Maybe<Parish>;
  owner?: Maybe<User>;
  url?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  temporary?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type ImageColorsArgs = {
  colors?: Maybe<Scalars['Int']>;
};


export type ImageSrcArgs = {
  w?: Maybe<Scalars['Int']>;
  h?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['Float']>;
  facepad?: Maybe<Scalars['Int']>;
  dpr?: Maybe<Scalars['Float']>;
  maxW?: Maybe<Scalars['Int']>;
  maxH?: Maybe<Scalars['Int']>;
  minW?: Maybe<Scalars['Int']>;
  minH?: Maybe<Scalars['Int']>;
  blur?: Maybe<Scalars['Int']>;
  auto?: Maybe<Scalars['String']>;
  usePosition?: Maybe<Scalars['Boolean']>;
  fit?: Maybe<Fit>;
  crop?: Maybe<Crop>;
};


export type ImageSrcsetArgs = {
  sizes?: Maybe<Scalars['String']>;
  w?: Maybe<Scalars['Int']>;
  h?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['Float']>;
  facepad?: Maybe<Scalars['Int']>;
  dpr?: Maybe<Scalars['Float']>;
  maxW?: Maybe<Scalars['Int']>;
  maxH?: Maybe<Scalars['Int']>;
  minW?: Maybe<Scalars['Int']>;
  minH?: Maybe<Scalars['Int']>;
  blur?: Maybe<Scalars['Int']>;
  auto?: Maybe<Scalars['String']>;
  usePosition?: Maybe<Scalars['Boolean']>;
  fit?: Maybe<Fit>;
  crop?: Maybe<Crop>;
};

export type ImageFilter = {
  parish?: Maybe<Scalars['ID']>;
  owner?: Maybe<Scalars['ID']>;
};

export type ImageInputOptions = {
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  temporary?: Maybe<Scalars['Boolean']>;
  toParish?: Maybe<Scalars['Boolean']>;
};

export enum Include {
  All = 'ALL',
  Current = 'CURRENT'
}

export enum Level {
  Admin = 'ADMIN',
  Moderator = 'MODERATOR',
  Member = 'MEMBER'
}

export type LikedPost = {
  __typename?: 'LikedPost';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  likeCount?: Maybe<Scalars['Int']>;
};

export type Link = {
  __typename?: 'Link';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
};

export type LinkInput = {
  text: Scalars['String'];
  href: Scalars['String'];
};

export type LiveEvent = {
  __typename?: 'LiveEvent';
  image?: Maybe<Scalars['String']>;
  channelTitle?: Maybe<Scalars['String']>;
  dtstart?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<LiveEventType>;
  id: Scalars['ID'];
};

export enum LiveEventType {
  Live = 'Live',
  Upcoming = 'Upcoming',
  None = 'None'
}


export type Mass = {
  __typename?: 'Mass';
  createdAt?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  flag?: Maybe<Flag>;
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  parish?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  privacy?: Maybe<Privacy>;
  season?: Maybe<Season>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
  rule?: Maybe<RRule>;
  date?: Maybe<RDate>;
  exdates?: Maybe<Array<Maybe<Exception>>>;
  type?: Maybe<MassType>;
};

export type MassInput = {
  start: Scalars['Date'];
  flag?: Maybe<Flag>;
  note?: Maybe<Scalars['String']>;
  rule?: Maybe<RRuleInput>;
  season?: Maybe<Scalars['String']>;
  date?: Maybe<RDateInput>;
  title?: Maybe<Scalars['String']>;
};

export enum MassType {
  Recurring = 'RECURRING',
  Single = 'SINGLE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addAbout?: Maybe<About>;
  editAbout?: Maybe<About>;
  deleteAbout?: Maybe<Scalars['ID']>;
  addBulletinLink?: Maybe<Bulletin>;
  deleteBulletinLink?: Maybe<Scalars['ID']>;
  addEvent?: Maybe<Event>;
  editEvent?: Maybe<Event>;
  deleteEvent?: Maybe<Scalars['ID']>;
  addRSVP?: Maybe<Event>;
  deleteRSVP?: Maybe<Event>;
  addFeedback?: Maybe<Scalars['Boolean']>;
  addForm?: Maybe<Form>;
  editForm?: Maybe<Form>;
  deleteForm?: Maybe<Scalars['ID']>;
  submitForm?: Maybe<Form>;
  addGroup?: Maybe<Group>;
  editGroup?: Maybe<Group>;
  deleteGroup?: Maybe<Scalars['ID']>;
  uploadImage?: Maybe<Image>;
  revertImage?: Maybe<Scalars['ID']>;
  addMass?: Maybe<Mass>;
  addMassException?: Maybe<Mass>;
  deleteMassException?: Maybe<Mass>;
  updateMass?: Maybe<Mass>;
  deleteMass?: Maybe<Scalars['ID']>;
  /** Must be able to edit current Parish */
  editParish?: Maybe<Parish>;
  addParish?: Maybe<Parish>;
  addDonationMethod?: Maybe<Parish>;
  editDonationMethod?: Maybe<Parish>;
  removeDonationMethod?: Maybe<Parish>;
  addParishLink?: Maybe<Parish>;
  deleteParishLink?: Maybe<Parish>;
  addPost?: Maybe<Post>;
  editPost?: Maybe<Post>;
  likePost?: Maybe<Post>;
  unlikePost?: Maybe<Post>;
  deletePost?: Maybe<Scalars['ID']>;
  addSacrament?: Maybe<Sacrament>;
  editSacrament?: Maybe<Sacrament>;
  deleteSacrament?: Maybe<Scalars['ID']>;
  updateSeason?: Maybe<Season>;
  deleteSeason?: Maybe<Scalars['ID']>;
  addUser?: Maybe<User>;
  changePassword?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  editUser?: Maybe<User>;
  invalidateTokens: Scalars['Boolean'];
  login?: Maybe<User>;
  logout: Scalars['Boolean'];
  resendEmailVerification?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  sendPasswordReset?: Maybe<Scalars['Boolean']>;
  verifyEmail?: Maybe<User>;
  addToParish?: Maybe<User>;
  removeFromParish?: Maybe<User>;
  addToGroup?: Maybe<User>;
  removeFromGroup?: Maybe<User>;
  editUserRole?: Maybe<User>;
};


export type MutationAddAboutArgs = {
  about: AboutInput;
};


export type MutationEditAboutArgs = {
  id: Scalars['ID'];
  about: EditAboutInput;
};


export type MutationDeleteAboutArgs = {
  id: Scalars['ID'];
};


export type MutationAddBulletinLinkArgs = {
  id?: Maybe<Scalars['ID']>;
  bulletinLink: BulletinLinkInput;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteBulletinLinkArgs = {
  id: Scalars['ID'];
};


export type MutationAddEventArgs = {
  event: EventInput;
};


export type MutationEditEventArgs = {
  id: Scalars['ID'];
  event: EditEventInput;
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationAddRsvpArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRsvpArgs = {
  id: Scalars['ID'];
};


export type MutationAddFeedbackArgs = {
  feedback: FeedbackInput;
};


export type MutationAddFormArgs = {
  event: FormInput;
};


export type MutationEditFormArgs = {
  id: Scalars['ID'];
  event: EditFormInput;
};


export type MutationDeleteFormArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitFormArgs = {
  id: Scalars['ID'];
  data: Scalars['String'];
};


export type MutationAddGroupArgs = {
  group: GroupInput;
};


export type MutationEditGroupArgs = {
  id: Scalars['ID'];
  group: EditGroupInput;
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationUploadImageArgs = {
  image: Scalars['Upload'];
  options?: Maybe<ImageInputOptions>;
};


export type MutationRevertImageArgs = {
  id: Scalars['ID'];
};


export type MutationAddMassArgs = {
  mass: MassInput;
};


export type MutationAddMassExceptionArgs = {
  id: Scalars['ID'];
  exception: ExceptionInput;
};


export type MutationDeleteMassExceptionArgs = {
  parent: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationUpdateMassArgs = {
  id: Scalars['ID'];
  mass: MassInput;
};


export type MutationDeleteMassArgs = {
  id: Scalars['ID'];
};


export type MutationEditParishArgs = {
  parish: ParishInput;
};


export type MutationAddParishArgs = {
  parish: ParishInput;
};


export type MutationAddDonationMethodArgs = {
  donationMethod: DonationMethodInput;
};


export type MutationEditDonationMethodArgs = {
  id: Scalars['ID'];
  donationMethod: EditDonationMethodInput;
};


export type MutationRemoveDonationMethodArgs = {
  id: Scalars['ID'];
};


export type MutationAddParishLinkArgs = {
  link: LinkInput;
};


export type MutationDeleteParishLinkArgs = {
  parishId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationAddPostArgs = {
  post: PostInput;
};


export type MutationEditPostArgs = {
  id: Scalars['ID'];
  post: EditPostInput;
};


export type MutationLikePostArgs = {
  id: Scalars['ID'];
};


export type MutationUnlikePostArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};


export type MutationAddSacramentArgs = {
  sacrament: SacramentInput;
};


export type MutationEditSacramentArgs = {
  id: Scalars['ID'];
  sacrament: EditSacramentInput;
};


export type MutationDeleteSacramentArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSeasonArgs = {
  id: Scalars['ID'];
  season: SeasonInput;
};


export type MutationDeleteSeasonArgs = {
  id: Scalars['ID'];
};


export type MutationAddUserArgs = {
  user: UserInput;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationEditUserArgs = {
  user?: Maybe<EditUserInput>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  passwordResetCode: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSendPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  code: Scalars['String'];
};


export type MutationAddToParishArgs = {
  parish: Scalars['ID'];
};


export type MutationRemoveFromParishArgs = {
  parish: Scalars['ID'];
};


export type MutationAddToGroupArgs = {
  group: Scalars['ID'];
};


export type MutationRemoveFromGroupArgs = {
  group: Scalars['ID'];
};


export type MutationEditUserRoleArgs = {
  id: Scalars['ID'];
  user: Scalars['ID'];
  role: RoleInput;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Pagination = {
  __typename?: 'Pagination';
  total?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Date']>;
};

export type Parish = {
  __typename?: 'Parish';
  about?: Maybe<Array<Maybe<About>>>;
  createdAt?: Maybe<Scalars['String']>;
  diocese?: Maybe<Diocese>;
  id?: Maybe<Scalars['ID']>;
  links?: Maybe<Array<Maybe<Link>>>;
  donationMethods?: Maybe<Array<Maybe<DonationMethod>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  maintenanceMode: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** This is a virtual field that returns all pending groups for a given parish. **use sparingly** */
  pendingGroups?: Maybe<Array<Maybe<Group>>>;
  theme?: Maybe<Themes>;
  updatedAt?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  defaultDomain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  timezone?: Maybe<Scalars['String']>;
  storageUsed: Scalars['Int'];
  twitter?: Maybe<SocialMedia>;
  facebook?: Maybe<SocialMedia>;
  instagram?: Maybe<SocialMedia>;
  youtube?: Maybe<SocialMedia>;
};

export type ParishInput = {
  address?: Maybe<AddressInput>;
  city?: Maybe<Scalars['String']>;
  diocese?: Maybe<DioceseInput>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  theme?: Maybe<Themes>;
  timezone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  twitter?: Maybe<SocialMediaInput>;
  facebook?: Maybe<SocialMediaInput>;
  instagram?: Maybe<SocialMediaInput>;
  youtube?: Maybe<SocialMediaInput>;
};

export type Policies = {
  __typename?: 'Policies';
  event?: Maybe<Policy>;
  file?: Maybe<Policy>;
  post?: Maybe<Policy>;
};

export type Policy = {
  __typename?: 'Policy';
  create?: Maybe<Level>;
  delete?: Maybe<Level>;
  read?: Maybe<Level>;
  update?: Maybe<Level>;
};

export type Position2D = {
  __typename?: 'Position2D';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type Position2DInput = {
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** Post type for homilies and blogs */
export type Post = {
  __typename?: 'Post';
  author?: Maybe<User>;
  owner?: Maybe<User>;
  createdAt?: Maybe<Scalars['Date']>;
  excerpt?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  id?: Maybe<Scalars['ID']>;
  parish?: Maybe<Parish>;
  slug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status: Status;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<PostTypes>;
  updatedAt?: Maybe<Scalars['Date']>;
  image?: Maybe<Image>;
  imagePosition?: Maybe<Position2D>;
  ast?: Maybe<Scalars['String']>;
  lectionaryDate?: Maybe<Scalars['Date']>;
  likeCount?: Maybe<Scalars['Int']>;
  likes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pinned?: Maybe<Scalars['Boolean']>;
  plain?: Maybe<Scalars['String']>;
  privacy?: Maybe<Privacy>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  viewer?: Maybe<PostViewer>;
};

export type PostInput = {
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  type?: Maybe<PostTypes>;
  createdAt?: Maybe<Scalars['Date']>;
  author?: Maybe<Scalars['ID']>;
  ast?: Maybe<Scalars['String']>;
  parish?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['ID']>;
  imagePosition?: Maybe<Position2DInput>;
  lectionaryDate?: Maybe<Scalars['Date']>;
  plain?: Maybe<Scalars['String']>;
  privacy?: Maybe<Privacy>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
};

export enum PostTypes {
  Homily = 'HOMILY',
  Blog = 'BLOG',
  News = 'NEWS',
  Announcement = 'ANNOUNCEMENT'
}

export type PostViewer = {
  __typename?: 'PostViewer';
  isAuthor?: Maybe<Scalars['Boolean']>;
  likesPost?: Maybe<Scalars['Boolean']>;
  hasBookmark?: Maybe<Scalars['Boolean']>;
};

export enum Privacy {
  Hidden = 'HIDDEN',
  Info = 'INFO',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Query = {
  __typename?: 'Query';
  about?: Maybe<About>;
  abouts?: Maybe<Array<Maybe<About>>>;
  analytics?: Maybe<Analytics>;
  bulletin?: Maybe<Bulletin>;
  diocese?: Maybe<Diocese>;
  event?: Maybe<Event>;
  events?: Maybe<Array<Maybe<Event>>>;
  searchEvents?: Maybe<Array<Maybe<Event>>>;
  feedbacks?: Maybe<Array<Maybe<Feedback>>>;
  form?: Maybe<Form>;
  forms?: Maybe<Array<Maybe<Form>>>;
  group?: Maybe<Group>;
  groups?: Maybe<Array<Maybe<Group>>>;
  image?: Maybe<Image>;
  images?: Maybe<Array<Maybe<Image>>>;
  mass?: Maybe<Mass>;
  masses?: Maybe<Array<Maybe<Mass>>>;
  /** Get one parish based on the filters, defaults to the current Parish */
  parish?: Maybe<Parish>;
  parishes?: Maybe<Array<Maybe<Parish>>>;
  /** Only used for youtube right now */
  liveEvent?: Maybe<LiveEvent>;
  /** Get a single post by its ID */
  post?: Maybe<Post>;
  /** Get a list of all posts **LIMIT 25** */
  posts?: Maybe<Array<Maybe<Post>>>;
  /** Uses the search resolver */
  searchPosts?: Maybe<Array<Maybe<Post>>>;
  /** Get the readings for a specific date. Defaults to today */
  readings?: Maybe<Readings>;
  sacrament?: Maybe<Sacrament>;
  /** Returns sacraments for the current parish */
  sacraments?: Maybe<Array<Maybe<Sacrament>>>;
  search?: Maybe<Array<Maybe<SearchResult>>>;
  seasons?: Maybe<Array<Maybe<Season>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  searchUsers?: Maybe<Array<Maybe<User>>>;
  groupAdmins?: Maybe<Array<Maybe<User>>>;
  checkEmail?: Maybe<Scalars['Boolean']>;
};


export type QueryAboutArgs = {
  id: Scalars['ID'];
};


export type QueryAboutsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Order>;
};


export type QueryBulletinArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Order>;
  cursor?: Maybe<Scalars['ID']>;
};


export type QueryDioceseArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEventArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEventsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Order>;
  filters?: Maybe<Filter>;
  cursor?: Maybe<Scalars['ID']>;
  past?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchEventsArgs = {
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFormArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryFormsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Order>;
  filters?: Maybe<Filter>;
  cursor?: Maybe<Scalars['ID']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGroupsArgs = {
  filters?: Maybe<Filter>;
  sort?: Maybe<Order>;
  limit?: Maybe<Scalars['Int']>;
  can?: Maybe<Can>;
};


export type QueryImageArgs = {
  id: Scalars['ID'];
};


export type QueryImagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Order>;
  filters?: Maybe<ImageFilter>;
  cursor?: Maybe<Scalars['ID']>;
};


export type QueryMassArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryMassesArgs = {
  filters?: Maybe<Filter>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Order>;
};


export type QueryParishArgs = {
  id?: Maybe<Scalars['ID']>;
  filters?: Maybe<Filter>;
};


export type QueryParishesArgs = {
  filters?: Maybe<Filter>;
};


export type QueryLiveEventArgs = {
  channel?: Maybe<Channel>;
};


export type QueryPostArgs = {
  id?: Maybe<Scalars['ID']>;
  filters?: Maybe<Filter>;
};


export type QueryPostsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Order>;
  filters?: Maybe<Filter>;
  cursor?: Maybe<Scalars['ID']>;
};


export type QuerySearchPostsArgs = {
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryReadingsArgs = {
  date?: Maybe<Scalars['Date']>;
};


export type QuerySacramentArgs = {
  id: Scalars['ID'];
};


export type QuerySearchArgs = {
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  include?: Maybe<Array<Maybe<SearchType>>>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  passwordResetCode?: Maybe<Scalars['String']>;
  filters?: Maybe<Filter>;
};


export type QueryUsersArgs = {
  filters?: Maybe<UserFilter>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['ID']>;
  sort?: Maybe<Order>;
};


export type QuerySearchUsersArgs = {
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  filters?: Maybe<UserFilter>;
};


export type QueryGroupAdminsArgs = {
  group: Scalars['ID'];
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
};

export type RDate = {
  __typename?: 'RDate';
  type?: Maybe<RDateTypes>;
  dates?: Maybe<Array<Maybe<DateTimeObj>>>;
};

export type RDateInput = {
  type: RDateTypes;
  dates: Array<DateTimeObjInput>;
  timezone: Scalars['String'];
};

export enum RDateTypes {
  Dates = 'Dates'
}

export type RRule = {
  __typename?: 'RRule';
  type: RRuleTypes;
  config?: Maybe<RRuleConfig>;
};

export type RRuleConfig = {
  __typename?: 'RRuleConfig';
  frequency: Scalars['String'];
  byDayOfWeek?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<DateTimeObj>;
};

export type RRuleConfigInput = {
  frequency: Scalars['String'];
  byDayOfWeek: Array<Scalars['String']>;
  start: DateTimeObjInput;
};

export type RRuleInput = {
  type: RRuleTypes;
  config: RRuleConfigInput;
  timezone: Scalars['String'];
};

export enum RRuleTypes {
  Rule = 'Rule'
}

export type Reading = {
  __typename?: 'Reading';
  type?: Maybe<ReadingType>;
  date?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  passage?: Maybe<Scalars['String']>;
};

export enum ReadingType {
  Reading = 'reading',
  Reading2 = 'reading2',
  Psalm = 'psalm',
  Gospel = 'gospel'
}

export type Readings = {
  __typename?: 'Readings';
  date: Scalars['Date'];
  firstReading?: Maybe<Reading>;
  secondReading?: Maybe<Reading>;
  psalm?: Maybe<Reading>;
  gospel?: Maybe<Reading>;
};

export type Role = {
  __typename?: 'Role';
  domain?: Maybe<RoleDomain>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Level>;
  owner?: Maybe<Scalars['Boolean']>;
  staff?: Maybe<Scalars['Boolean']>;
  staffEntry?: Maybe<StaffEntry>;
};

export enum RoleDomain {
  Group = 'group',
  Parish = 'parish'
}

export type RoleInput = {
  domain?: Maybe<RoleDomain>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Level>;
  staff?: Maybe<Scalars['Boolean']>;
  staffEntry?: Maybe<StaffEntryInput>;
};

export type Rrule = {
  __typename?: 'Rrule';
  byhour?: Maybe<Array<Maybe<Scalars['Int']>>>;
  bymonth?: Maybe<Array<Maybe<Scalars['Int']>>>;
  bymonthday?: Maybe<Array<Maybe<Scalars['Int']>>>;
  bysetpos?: Maybe<Scalars['Int']>;
  byweekday?: Maybe<Scalars['Int']>;
  byweekno?: Maybe<Array<Maybe<Scalars['Int']>>>;
  byyearday?: Maybe<Array<Maybe<Scalars['Int']>>>;
  count?: Maybe<Scalars['Int']>;
  dtstart?: Maybe<Scalars['Date']>;
  freq?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['Int']>;
  tzid?: Maybe<Scalars['String']>;
  until?: Maybe<Scalars['Date']>;
  wkst?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum Scope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** The Sacrament API is most extensible here */
export type Sacrament = {
  __typename?: 'Sacrament';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  parish?: Maybe<Parish>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SacramentType>;
};

export type SacramentInput = {
  description: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: SacramentType;
};

export enum SacramentType {
  Adoration = 'ADORATION',
  AnointingOfTheSick = 'ANOINTING_OF_THE_SICK',
  Baptism = 'BAPTISM',
  Confirmation = 'CONFIRMATION',
  FirstCommunion = 'FIRST_COMMUNION',
  HolyOrders = 'HOLY_ORDERS',
  Mass = 'MASS',
  Matrimony = 'MATRIMONY',
  Reconciliation = 'RECONCILIATION'
}

export type SearchResult = Post | Event;

export enum SearchType {
  Post = 'Post',
  Event = 'Event'
}

export type Season = {
  __typename?: 'Season';
  from?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  parish?: Maybe<Scalars['ID']>;
  until?: Maybe<Scalars['Date']>;
};

export type SeasonInput = {
  from?: Maybe<Scalars['Date']>;
  until?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
};

export enum Sex {
  Male = 'MALE',
  Female = 'FEMALE'
}

export type SocialMedia = {
  __typename?: 'SocialMedia';
  username?: Maybe<Scalars['String']>;
  liveEvents?: Maybe<Scalars['Boolean']>;
};

export type SocialMediaInput = {
  username?: Maybe<Scalars['String']>;
  liveEvents?: Maybe<Scalars['Boolean']>;
};

export type StaffEntry = {
  __typename?: 'StaffEntry';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type StaffEntryInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum Status {
  Deleted = 'DELETED',
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Pending = 'PENDING'
}

export type Submission = {
  __typename?: 'Submission';
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

/** Theme Types are given in lowercase to match aluminum UI */
export enum Themes {
  Blue = 'blue',
  Red = 'red',
  Purple = 'purple',
  Green = 'green',
  Teal = 'teal',
  Orange = 'orange',
  Neutral = 'neutral'
}

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  image?: Maybe<Image>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastLogin?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  likedPosts?: Maybe<Array<Maybe<Post>>>;
  location?: Maybe<Scalars['Map']>;
  parishes?: Maybe<Array<Maybe<Parish>>>;
  groups?: Maybe<Array<Maybe<Group>>>;
  sex?: Maybe<Sex>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  emailIsConfirmed?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  primaryParish?: Maybe<Parish>;
  roles?: Maybe<Array<Maybe<Role>>>;
  token?: Maybe<Scalars['String']>;
};

export type UserFilter = {
  role?: Maybe<UserRoleFilter>;
};

export type UserInput = {
  image?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  role?: Maybe<Array<Maybe<RoleInput>>>;
  sex?: Maybe<Sex>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserRoleFilter = {
  domain: RoleDomain;
  id?: Maybe<Scalars['ID']>;
  staff?: Maybe<Scalars['Boolean']>;
  levels: Array<Level>;
};

export type Visitor = {
  __typename?: 'Visitor';
  browser?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  ip?: Maybe<Scalars['String']>;
};

export type AboutQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AboutQuery = (
  { __typename?: 'Query' }
  & { about?: Maybe<(
    { __typename?: 'About' }
    & Pick<About, 'id' | 'title' | 'ast' | 'order'>
  )> }
);

export type AboutsQueryVariables = Exact<{ [key: string]: never; }>;


export type AboutsQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src' | 'srcset'>
      & { lqip: Image['src'] }
    )> }
  )>, abouts?: Maybe<Array<Maybe<(
    { __typename?: 'About' }
    & Pick<About, 'id' | 'title' | 'ast' | 'order'>
  )>>> }
);

export type AddAboutMutationVariables = Exact<{
  about: AboutInput;
}>;


export type AddAboutMutation = (
  { __typename?: 'Mutation' }
  & { addAbout?: Maybe<(
    { __typename?: 'About' }
    & Pick<About, 'id' | 'title' | 'ast' | 'order'>
  )> }
);

export type AddBulletinLinkMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  bulletinLink: BulletinLinkInput;
  file?: Maybe<Scalars['Upload']>;
}>;


export type AddBulletinLinkMutation = (
  { __typename?: 'Mutation' }
  & { addBulletinLink?: Maybe<(
    { __typename?: 'Bulletin' }
    & Pick<Bulletin, 'id'>
    & { links?: Maybe<Array<Maybe<(
      { __typename?: 'BulletinLink' }
      & Pick<BulletinLink, 'id' | 'createdAt' | 'forDate' | 'updatedAt' | 'url'>
    )>>> }
  )> }
);

export type AddDonationMethodMutationVariables = Exact<{
  donationMethod: DonationMethodInput;
}>;


export type AddDonationMethodMutation = (
  { __typename?: 'Mutation' }
  & { addDonationMethod?: Maybe<(
    { __typename?: 'Parish' }
    & DonationMethodFragmentFragment
  )> }
);

export type DonationMethodFragmentFragment = (
  { __typename?: 'Parish' }
  & Pick<Parish, 'id'>
  & { donationMethods?: Maybe<Array<Maybe<(
    { __typename?: 'DonationMethod' }
    & Pick<DonationMethod, 'id' | 'method' | 'note' | 'url' | 'title'>
  )>>> }
);

export type AddEventMutationVariables = Exact<{
  event: EventInput;
}>;


export type AddEventMutation = (
  { __typename?: 'Mutation' }
  & { addEvent?: Maybe<(
    { __typename?: 'Event' }
    & EventsFragmentFragment
  )> }
);

export type AddGroupMutationVariables = Exact<{
  group: GroupInput;
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { addGroup?: Maybe<(
    { __typename?: 'Group' }
    & FullGroupFragmentFragment
  )> }
);

export type AddMassMutationVariables = Exact<{
  mass: MassInput;
}>;


export type AddMassMutation = (
  { __typename?: 'Mutation' }
  & { addMass?: Maybe<(
    { __typename?: 'Mass' }
    & FullMassFragmentFragment
  )> }
);

export type AddMassExceptionMutationVariables = Exact<{
  id: Scalars['ID'];
  exception: ExceptionInput;
}>;


export type AddMassExceptionMutation = (
  { __typename?: 'Mutation' }
  & { addMassException?: Maybe<(
    { __typename?: 'Mass' }
    & FullMassFragmentFragment
  )> }
);

export type AddParishLinkMutationVariables = Exact<{
  link: LinkInput;
}>;


export type AddParishLinkMutation = (
  { __typename?: 'Mutation' }
  & { addParishLink?: Maybe<(
    { __typename?: 'Parish' }
    & ParishFragmentFragment
  )> }
);

export type AddPostMutationVariables = Exact<{
  post: PostInput;
}>;


export type AddPostMutation = (
  { __typename?: 'Mutation' }
  & { addPost?: Maybe<(
    { __typename?: 'Post' }
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'title' | 'src' | 'srcset'>
      & { doubleSrc: Image['src'], lqip: Image['src'], doubleLqip: Image['src'] }
    )> }
    & SharedPostFragmentFragment
  )> }
);

export type AddRsvpMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AddRsvpMutation = (
  { __typename?: 'Mutation' }
  & { addRSVP?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'pinned' | 'title' | 'dtstart' | 'excerpt' | 'duration' | 'description' | 'privacy' | 'status' | 'rsvps' | 'rsvpCount' | 'createdAt' | 'updatedAt'>
    & { viewer?: Maybe<(
      { __typename?: 'EventViewer' }
      & Pick<EventViewer, 'isHost' | 'hasRSVP'>
    )> }
  )> }
);

export type AddSacramentMutationVariables = Exact<{
  sacrament: SacramentInput;
}>;


export type AddSacramentMutation = (
  { __typename?: 'Mutation' }
  & { addSacrament?: Maybe<(
    { __typename?: 'Sacrament' }
    & Pick<Sacrament, 'id' | 'title' | 'description' | 'type'>
    & { parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id'>
    )> }
  )> }
);

export type AddUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'fullName' | 'lastName' | 'username' | 'email'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src' | 'srcset'>
      & { lqip: Image['src'] }
    )>, primaryParish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'name'>
    )> }
  )> }
);

export type AnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsQuery = (
  { __typename?: 'Query' }
  & { analytics?: Maybe<(
    { __typename?: 'Analytics' }
    & Pick<Analytics, 'id' | 'memberCount' | 'postCount' | 'groupCount' | 'eventsCounts' | 'date'>
    & { visitors?: Maybe<Array<Maybe<(
      { __typename?: 'Visitor' }
      & Pick<Visitor, 'browser' | 'category' | 'ip'>
    )>>>, topLikedPosts?: Maybe<Array<Maybe<(
      { __typename?: 'LikedPost' }
      & Pick<LikedPost, 'id' | 'title' | 'likeCount'>
    )>>> }
  )> }
);

export type AppQueryVariables = Exact<{ [key: string]: never; }>;


export type AppQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'defaultDomain' | 'name' | 'theme' | 'timezone' | 'maintenanceMode'>
  )>, abouts?: Maybe<Array<Maybe<(
    { __typename?: 'About' }
    & Pick<About, 'id' | 'title' | 'ast' | 'order'>
  )>>>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'timezone' | 'firstName' | 'lastName' | 'fullName'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & UserRoleFragmentFragment
    )>>> }
  )> }
);

export type BulletinQueryVariables = Exact<{ [key: string]: never; }>;


export type BulletinQuery = (
  { __typename?: 'Query' }
  & { bulletin?: Maybe<(
    { __typename?: 'Bulletin' }
    & Pick<Bulletin, 'id'>
    & { parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id'>
    )>, links?: Maybe<Array<Maybe<(
      { __typename?: 'BulletinLink' }
      & Pick<BulletinLink, 'id' | 'forDate' | 'createdAt' | 'url'>
    )>>> }
  )> }
);

export type BulletinModuleQueryVariables = Exact<{ [key: string]: never; }>;


export type BulletinModuleQuery = (
  { __typename?: 'Query' }
  & { bulletin?: Maybe<(
    { __typename?: 'Bulletin' }
    & Pick<Bulletin, 'id'>
    & { currentLink?: Maybe<(
      { __typename?: 'BulletinLink' }
      & Pick<BulletinLink, 'id' | 'createdAt' | 'forDate' | 'url'>
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkEmail'>
);

export type DeleteAboutMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAboutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAbout'>
);

export type DeleteBulletinLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBulletinLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBulletinLink'>
);

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEvent'>
);

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGroup'>
);

export type DeleteMassMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMassMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMass'>
);

export type DeleteMassExceptionMutationVariables = Exact<{
  parent: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type DeleteMassExceptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteMassException?: Maybe<(
    { __typename?: 'Mass' }
    & FullMassFragmentFragment
  )> }
);

export type DeleteParishLinkMutationVariables = Exact<{
  parishId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type DeleteParishLinkMutation = (
  { __typename?: 'Mutation' }
  & { deleteParishLink?: Maybe<(
    { __typename?: 'Parish' }
    & ParishFragmentFragment
  )> }
);

export type DeletePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePost'>
);

export type DeleteRsvpMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRsvpMutation = (
  { __typename?: 'Mutation' }
  & { deleteRSVP?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'pinned' | 'title' | 'dtstart' | 'excerpt' | 'duration' | 'description' | 'privacy' | 'status' | 'rsvps' | 'rsvpCount' | 'createdAt' | 'updatedAt'>
    & { viewer?: Maybe<(
      { __typename?: 'EventViewer' }
      & Pick<EventViewer, 'isHost' | 'hasRSVP'>
    )> }
  )> }
);

export type DeleteSacramentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSacramentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSacrament'>
);

export type EditAboutMutationVariables = Exact<{
  id: Scalars['ID'];
  about: EditAboutInput;
}>;


export type EditAboutMutation = (
  { __typename?: 'Mutation' }
  & { editAbout?: Maybe<(
    { __typename?: 'About' }
    & Pick<About, 'id' | 'title' | 'ast' | 'order'>
  )> }
);

export type EditDonationMethodMutationVariables = Exact<{
  id: Scalars['ID'];
  donationMethod: EditDonationMethodInput;
}>;


export type EditDonationMethodMutation = (
  { __typename?: 'Mutation' }
  & { editDonationMethod?: Maybe<(
    { __typename?: 'Parish' }
    & DonationMethodFragmentFragment
  )> }
);

export type EditEventMutationVariables = Exact<{
  id: Scalars['ID'];
  event: EditEventInput;
}>;


export type EditEventMutation = (
  { __typename?: 'Mutation' }
  & { editEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'pinned' | 'title' | 'dtstart' | 'excerpt' | 'duration' | 'description' | 'privacy' | 'status' | 'rsvps' | 'rsvpCount' | 'createdAt' | 'updatedAt'>
    & { owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'title' | 'src' | 'srcset'>
      & { lqip: Image['src'] }
    )>, viewer?: Maybe<(
      { __typename?: 'EventViewer' }
      & Pick<EventViewer, 'isHost' | 'hasRSVP'>
    )>, parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'theme' | 'defaultDomain' | 'name'>
    )> }
  )> }
);

export type EditGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  group: EditGroupInput;
}>;


export type EditGroupMutation = (
  { __typename?: 'Mutation' }
  & { editGroup?: Maybe<(
    { __typename?: 'Group' }
    & FullGroupFragmentFragment
  )> }
);

export type EditParishMutationVariables = Exact<{
  parish: ParishInput;
}>;


export type EditParishMutation = (
  { __typename?: 'Mutation' }
  & { editParish?: Maybe<(
    { __typename?: 'Parish' }
    & ParishFragmentFragment
  )> }
);

export type EditPostMutationVariables = Exact<{
  id: Scalars['ID'];
  post: EditPostInput;
}>;


export type EditPostMutation = (
  { __typename?: 'Mutation' }
  & { editPost?: Maybe<(
    { __typename?: 'Post' }
    & SinglePostFragmentFragment
  )> }
);

export type EditSacramentMutationVariables = Exact<{
  id: Scalars['ID'];
  sacrament: EditSacramentInput;
}>;


export type EditSacramentMutation = (
  { __typename?: 'Mutation' }
  & { editSacrament?: Maybe<(
    { __typename?: 'Sacrament' }
    & Pick<Sacrament, 'id' | 'description' | 'title'>
  )> }
);

export type EditUserMutationVariables = Exact<{
  user: EditUserInput;
}>;


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser?: Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )> }
);

export type EditUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  user: Scalars['ID'];
  role: RoleInput;
}>;


export type EditUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { editUserRole?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & UserRoleFragmentFragment
    )>>> }
  )> }
);

export type EventQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type EventQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'pinned' | 'title' | 'dtstart' | 'excerpt' | 'duration' | 'description' | 'privacy' | 'status' | 'rsvps' | 'rsvpCount' | 'createdAt' | 'updatedAt'>
    & { owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'title' | 'src' | 'srcset'>
      & { lqip: Image['src'] }
    )>, imagePosition?: Maybe<(
      { __typename?: 'Position2D' }
      & Pick<Position2D, 'y'>
    )>, viewer?: Maybe<(
      { __typename?: 'EventViewer' }
      & Pick<EventViewer, 'isHost' | 'hasRSVP'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'theme' | 'defaultDomain' | 'name'>
    )> }
  )> }
);

export type EventsQueryVariables = Exact<{
  filters?: Maybe<Filter>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['ID']>;
  past?: Maybe<Scalars['Boolean']>;
}>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id'>
  )>, events?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & EventsFragmentFragment
  )>>> }
);

export type EventsFragmentFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'title' | 'dtstart' | 'duration' | 'excerpt' | 'privacy' | 'status' | 'rsvpCount' | 'rsvps' | 'createdAt'>
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )>, owner?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, viewer?: Maybe<(
    { __typename?: 'EventViewer' }
    & Pick<EventViewer, 'isHost' | 'hasRSVP'>
  )>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name' | 'theme'>
  )>, imagePosition?: Maybe<(
    { __typename?: 'Position2D' }
    & Pick<Position2D, 'y'>
  )>, image?: Maybe<(
    { __typename?: 'Image' }
    & EventModuleImageFragment
  )> }
);

export type FeaturedEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeaturedEventsQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & EventsFragmentFragment
  )>>> }
);

export type FeaturedPostsQueryVariables = Exact<{
  filters?: Maybe<Filter>;
}>;


export type FeaturedPostsQuery = (
  { __typename?: 'Query' }
  & { posts?: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src'>
      & { doubleSrc: Image['src'], lqip: Image['src'], doubleLqip: Image['src'] }
    )> }
    & SharedPostFragmentFragment
  )>>> }
);

export type FormQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type FormQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id'>
  )>, form?: Maybe<(
    { __typename?: 'Form' }
    & FormsFragmentFragment
  )> }
);

export type FormSubmissionsQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type FormSubmissionsQuery = (
  { __typename?: 'Query' }
  & { form?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'title' | 'description'>
    & { submissions?: Maybe<Array<Maybe<(
      { __typename?: 'Submission' }
      & Pick<Submission, 'id' | 'data' | 'createdAt'>
    )>>> }
  )> }
);

export type FormsQueryVariables = Exact<{
  filters?: Maybe<Filter>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['ID']>;
}>;


export type FormsQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id'>
  )>, forms?: Maybe<Array<Maybe<(
    { __typename?: 'Form' }
    & FormsFragmentFragment
  )>>> }
);

export type FormsFragmentFragment = (
  { __typename?: 'Form' }
  & Pick<Form, 'id' | 'ast' | 'pinned' | 'status' | 'title' | 'description' | 'closedAt' | 'createdAt' | 'updatedAt'>
  & { owner?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
  )>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id'>
  )> }
);

export type GetImageQueryVariables = Exact<{
  id: Scalars['ID'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
}>;


export type GetImageQuery = (
  { __typename?: 'Query' }
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'url' | 'temporary' | 'src'>
  )> }
);

export type GetTempImageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTempImageQuery = (
  { __typename?: 'Query' }
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'url' | 'path' | 'temporary'>
  )> }
);

export type GroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GroupQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id'>
  )>, group?: Maybe<(
    { __typename?: 'Group' }
    & FullGroupFragmentFragment
  )>, events?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & EventsFragmentFragment
  )>>>, posts?: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'pinned' | 'slug' | 'createdAt' | 'updatedAt' | 'title' | 'type' | 'likeCount' | 'excerpt' | 'source' | 'ast' | 'plain'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'theme' | 'defaultDomain' | 'name'>
    )>, viewer?: Maybe<(
      { __typename?: 'PostViewer' }
      & Pick<PostViewer, 'isAuthor' | 'likesPost'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'colors' | 'src' | 'title'>
      & { lqip: Image['src'] }
    )> }
  )>>> }
);

export type FullGroupFragmentFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'id' | 'description' | 'excerpt' | 'createdAt' | 'updatedAt' | 'status' | 'memberCount' | 'privacy' | 'name'>
  & { policies?: Maybe<(
    { __typename?: 'Policies' }
    & { post?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'create' | 'read' | 'update' | 'delete'>
    )>, event?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'create' | 'read' | 'update' | 'delete'>
    )> }
  )>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'theme' | 'defaultDomain' | 'name'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'path' | 'title'>
    )> }
  )>, cover?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'src' | 'srcset' | 'title'>
    & { lqip: Image['src'] }
  )>, coverPosition?: Maybe<(
    { __typename?: 'Position2D' }
    & Pick<Position2D, 'x' | 'y'>
  )>, members?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src'>
    )> }
  )>>>, owner?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src'>
    )> }
  )>, viewer?: Maybe<(
    { __typename?: 'GroupViewer' }
    & Pick<GroupViewer, 'isMember'>
  )> }
);

export type GroupAdminsQueryVariables = Exact<{
  group: Scalars['ID'];
}>;


export type GroupAdminsQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'fullName'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src'>
    )> }
  )>>> }
);

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id'>
  )>, groups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'status' | 'excerpt' | 'memberCount' | 'description' | 'createdAt'>
    & { policies?: Maybe<(
      { __typename?: 'Policies' }
      & { post?: Maybe<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'create' | 'read' | 'update' | 'delete'>
      )>, event?: Maybe<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'create' | 'read' | 'update' | 'delete'>
      )> }
    )>, cover?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src' | 'srcset' | 'title'>
      & { lqip: Image['src'] }
    )>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'src'>
      )> }
    )>>>, parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id'>
    )>, viewer?: Maybe<(
      { __typename?: 'GroupViewer' }
      & Pick<GroupViewer, 'isMember'>
    )>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName'>
    )> }
  )>>> }
);

export type HeaderQueryVariables = Exact<{ [key: string]: never; }>;


export type HeaderQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name'>
  )> }
);

export type ImagePickerQueryVariables = Exact<{
  filters?: Maybe<ImageFilter>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['ID']>;
}>;


export type ImagePickerQuery = (
  { __typename?: 'Query' }
  & { images?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'src' | 'title'>
    & { lqip: Image['src'] }
    & { owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>>> }
);

export type JoinGroupMutationVariables = Exact<{
  group: Scalars['ID'];
}>;


export type JoinGroupMutation = (
  { __typename?: 'Mutation' }
  & { addToGroup?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    )>>> }
  )> }
);

export type UnJoinGroupMutationVariables = Exact<{
  group: Scalars['ID'];
}>;


export type UnJoinGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeFromGroup?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    )>>> }
  )> }
);

export type JoinParishMutationVariables = Exact<{
  parish: Scalars['ID'];
}>;


export type JoinParishMutation = (
  { __typename?: 'Mutation' }
  & { addToParish?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { parishes?: Maybe<Array<Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id'>
    )>>>, roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & UserRoleFragmentFragment
    )>>> }
  )> }
);

export type UnJoinParishMutationVariables = Exact<{
  parish: Scalars['ID'];
}>;


export type UnJoinParishMutation = (
  { __typename?: 'Mutation' }
  & { removeFromParish?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { parishes?: Maybe<Array<Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id'>
    )>>>, roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & UserRoleFragmentFragment
    )>>> }
  )> }
);

export type LikePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LikePostMutation = (
  { __typename?: 'Mutation' }
  & { likePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'likeCount'>
    & { viewer?: Maybe<(
      { __typename?: 'PostViewer' }
      & Pick<PostViewer, 'isAuthor' | 'likesPost'>
    )> }
  )> }
);

export type LiveEventQueryVariables = Exact<{ [key: string]: never; }>;


export type LiveEventQuery = (
  { __typename?: 'Query' }
  & { liveEvent?: Maybe<(
    { __typename?: 'LiveEvent' }
    & Pick<LiveEvent, 'channelTitle' | 'description' | 'dtstart' | 'id' | 'image' | 'title' | 'type'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'fullName' | 'lastName' | 'username' | 'email'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src' | 'srcset'>
      & { lqip: Image['src'] }
    )>, primaryParish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'name'>
    )> }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MassesQueryVariables = Exact<{
  filters?: Maybe<Filter>;
}>;


export type MassesQuery = (
  { __typename?: 'Query' }
  & { masses?: Maybe<Array<Maybe<(
    { __typename?: 'Mass' }
    & FullMassFragmentFragment
  )>>> }
);

export type FullMassFragmentFragment = (
  { __typename?: 'Mass' }
  & Pick<Mass, 'id' | 'title' | 'flag' | 'start' | 'note' | 'parish' | 'type' | 'status' | 'privacy'>
  & { season?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'from' | 'until' | 'name'>
  )>, rule?: Maybe<(
    { __typename?: 'RRule' }
    & Pick<RRule, 'type'>
    & { config?: Maybe<(
      { __typename?: 'RRuleConfig' }
      & Pick<RRuleConfig, 'frequency' | 'byDayOfWeek'>
      & { start?: Maybe<(
        { __typename?: 'DateTimeObj' }
        & DateTimeObjectFragment
      )> }
    )> }
  )>, date?: Maybe<(
    { __typename?: 'RDate' }
    & Pick<RDate, 'type'>
    & { dates?: Maybe<Array<Maybe<(
      { __typename?: 'DateTimeObj' }
      & DateTimeObjectFragment
    )>>> }
  )>, exdates?: Maybe<Array<Maybe<(
    { __typename?: 'Exception' }
    & Pick<Exception, 'id' | 'expires'>
    & { date?: Maybe<(
      { __typename?: 'ExceptionDate' }
      & Pick<ExceptionDate, 'type' | 'timezone'>
      & { dates?: Maybe<Array<Maybe<(
        { __typename?: 'DateTimeObj' }
        & DateTimeObjectFragment
      )>>>, data?: Maybe<(
        { __typename?: 'ExceptionData' }
        & Pick<ExceptionData, 'exType' | 'newDate' | 'reason'>
      )> }
    )> }
  )>>> }
);

export type DateTimeObjectFragment = (
  { __typename?: 'DateTimeObj' }
  & Pick<DateTimeObj, 'timezone' | 'year' | 'month' | 'day' | 'hour' | 'minute'>
);

export type NavQueryVariables = Exact<{ [key: string]: never; }>;


export type NavQuery = (
  { __typename?: 'Query' }
  & { sacraments?: Maybe<Array<Maybe<(
    { __typename?: 'Sacrament' }
    & Pick<Sacrament, 'id' | 'type'>
  )>>>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName' | 'firstName' | 'lastName'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src'>
    )>, roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & UserRoleFragmentFragment
    )>>>, primaryParish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'name'>
    )> }
  )>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name'>
  )>, abouts?: Maybe<Array<Maybe<(
    { __typename?: 'About' }
    & Pick<About, 'id'>
  )>>> }
);

export type OwnGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type OwnGroupsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'memberCount'>
      & { cover?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'src' | 'srcset'>
        & { lqip: Image['src'] }
      )>, policies?: Maybe<(
        { __typename?: 'Policies' }
        & { post?: Maybe<(
          { __typename?: 'Policy' }
          & Pick<Policy, 'create' | 'update'>
        )>, event?: Maybe<(
          { __typename?: 'Policy' }
          & Pick<Policy, 'create' | 'update'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ParishQueryVariables = Exact<{ [key: string]: never; }>;


export type ParishQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & ParishFragmentFragment
  )> }
);

export type ParishFragmentFragment = (
  { __typename?: 'Parish' }
  & Pick<Parish, 'id' | 'theme' | 'name' | 'timezone' | 'email' | 'isEnabled' | 'phoneNumber'>
  & { donationMethods?: Maybe<Array<Maybe<(
    { __typename?: 'DonationMethod' }
    & Pick<DonationMethod, 'id' | 'method' | 'note' | 'url' | 'title'>
  )>>>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'addressLine1' | 'city' | 'zip' | 'state'>
  )>, image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'src' | 'srcset'>
    & { lqip: Image['src'] }
  )>, diocese?: Maybe<(
    { __typename?: 'Diocese' }
    & Pick<Diocese, 'id' | 'name'>
  )>, twitter?: Maybe<(
    { __typename?: 'SocialMedia' }
    & Pick<SocialMedia, 'username'>
  )>, facebook?: Maybe<(
    { __typename?: 'SocialMedia' }
    & Pick<SocialMedia, 'username'>
  )>, instagram?: Maybe<(
    { __typename?: 'SocialMedia' }
    & Pick<SocialMedia, 'username'>
  )>, youtube?: Maybe<(
    { __typename?: 'SocialMedia' }
    & Pick<SocialMedia, 'username'>
  )>, links?: Maybe<Array<Maybe<(
    { __typename?: 'Link' }
    & Pick<Link, 'href' | 'id' | 'text'>
  )>>> }
);

export type PinEventMutationVariables = Exact<{
  id: Scalars['ID'];
  pinned?: Maybe<Scalars['Boolean']>;
}>;


export type PinEventMutation = (
  { __typename?: 'Mutation' }
  & { editEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'pinned'>
  )> }
);

export type PinPostMutationVariables = Exact<{
  id: Scalars['ID'];
  pinned?: Maybe<Scalars['Boolean']>;
}>;


export type PinPostMutation = (
  { __typename?: 'Mutation' }
  & { editPost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'pinned'>
  )> }
);

export type PinnedQueryVariables = Exact<{ [key: string]: never; }>;


export type PinnedQuery = (
  { __typename?: 'Query' }
  & { forms?: Maybe<Array<Maybe<(
    { __typename?: 'Form' }
    & FormsFragmentFragment
  )>>>, posts?: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'pinned' | 'status' | 'slug' | 'createdAt' | 'updatedAt' | 'title' | 'type' | 'likeCount' | 'excerpt' | 'plain'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName'>
    )>, parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'theme' | 'defaultDomain' | 'name'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'path' | 'title'>
      )> }
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, viewer?: Maybe<(
      { __typename?: 'PostViewer' }
      & Pick<PostViewer, 'isAuthor' | 'likesPost'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src' | 'srcset' | 'title'>
      & { lqip: Image['src'] }
    )> }
  )>>>, events?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'pinned' | 'title' | 'dtstart' | 'duration' | 'excerpt' | 'privacy' | 'status' | 'rsvpCount' | 'rsvps' | 'createdAt'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, viewer?: Maybe<(
      { __typename?: 'EventViewer' }
      & Pick<EventViewer, 'isHost' | 'hasRSVP'>
    )>, parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id' | 'name' | 'theme'>
    )>, imagePosition?: Maybe<(
      { __typename?: 'Position2D' }
      & Pick<Position2D, 'y'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & EventModuleImageFragment
    )> }
  )>>> }
);

export type EventModuleImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'id' | 'src' | 'title'>
  & { lqip: Image['src'] }
);

export type PostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostQuery = (
  { __typename?: 'Query' }
  & { post?: Maybe<(
    { __typename?: 'Post' }
    & SinglePostFragmentFragment
  )> }
);

export type SinglePostFragmentFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'pinned' | 'slug' | 'createdAt' | 'updatedAt' | 'title' | 'type' | 'likeCount' | 'excerpt' | 'lectionaryDate' | 'ast' | 'privacy' | 'status' | 'plain'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
  )>, group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
    & { policies?: Maybe<(
      { __typename?: 'Policies' }
      & { post?: Maybe<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'create' | 'read' | 'update' | 'delete'>
      )>, event?: Maybe<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'create' | 'read' | 'update' | 'delete'>
      )> }
    )> }
  )>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'theme' | 'defaultDomain' | 'name'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'path' | 'title'>
    )>, twitter?: Maybe<(
      { __typename?: 'SocialMedia' }
      & Pick<SocialMedia, 'username'>
    )>, facebook?: Maybe<(
      { __typename?: 'SocialMedia' }
      & Pick<SocialMedia, 'username'>
    )> }
  )>, viewer?: Maybe<(
    { __typename?: 'PostViewer' }
    & Pick<PostViewer, 'isAuthor' | 'likesPost'>
  )>, imagePosition?: Maybe<(
    { __typename?: 'Position2D' }
    & Pick<Position2D, 'x' | 'y'>
  )>, image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'src' | 'srcset' | 'title'>
    & { lqip: Image['src'] }
  )> }
);

export type PostsQueryVariables = Exact<{
  filters?: Maybe<Filter>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['ID']>;
}>;


export type PostsQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id'>
  )>, posts?: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & PostModuleFragmentFragment
  )>>> }
);

export type SharedPostFragmentFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'pinned' | 'slug' | 'createdAt' | 'updatedAt' | 'title' | 'type' | 'likeCount' | 'plain' | 'ast' | 'source' | 'status' | 'privacy'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
  )>, group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'theme' | 'defaultDomain' | 'name'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'path' | 'title'>
    )> }
  )>, viewer?: Maybe<(
    { __typename?: 'PostViewer' }
    & Pick<PostViewer, 'isAuthor' | 'likesPost'>
  )> }
);

export type PostModuleFragmentFragment = (
  { __typename?: 'Post' }
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'src' | 'srcset' | 'title'>
    & { lqip: Image['src'] }
  )> }
  & SharedPostFragmentFragment
);

export type ReadingsQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
}>;


export type ReadingsQuery = (
  { __typename?: 'Query' }
  & { readings?: Maybe<(
    { __typename?: 'Readings' }
    & { firstReading?: Maybe<(
      { __typename?: 'Reading' }
      & Pick<Reading, 'title' | 'passage' | 'body'>
    )>, secondReading?: Maybe<(
      { __typename?: 'Reading' }
      & Pick<Reading, 'title' | 'passage' | 'body'>
    )>, psalm?: Maybe<(
      { __typename?: 'Reading' }
      & Pick<Reading, 'title' | 'passage' | 'body'>
    )>, gospel?: Maybe<(
      { __typename?: 'Reading' }
      & Pick<Reading, 'title' | 'passage' | 'body'>
    )> }
  )> }
);

export type RemoveDonationMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveDonationMethodMutation = (
  { __typename?: 'Mutation' }
  & { removeDonationMethod?: Maybe<(
    { __typename?: 'Parish' }
    & DonationMethodFragmentFragment
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  passwordResetCode: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type SendPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendPasswordReset'>
);

export type RevertTempImageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RevertTempImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revertImage'>
);

export type SacramentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SacramentQuery = (
  { __typename?: 'Query' }
  & { sacrament?: Maybe<(
    { __typename?: 'Sacrament' }
    & Pick<Sacrament, 'id' | 'title' | 'description' | 'type'>
  )> }
);

export type SacramentsQueryVariables = Exact<{ [key: string]: never; }>;


export type SacramentsQuery = (
  { __typename?: 'Query' }
  & { parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name' | 'timezone'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'src' | 'srcset'>
      & { lqip: Image['src'] }
    )> }
  )>, sacraments?: Maybe<Array<Maybe<(
    { __typename?: 'Sacrament' }
    & Pick<Sacrament, 'id' | 'title' | 'description' | 'type'>
    & { parish?: Maybe<(
      { __typename?: 'Parish' }
      & Pick<Parish, 'id'>
    )> }
  )>>> }
);

export type SearchQueryVariables = Exact<{
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & PostModuleFragmentFragment
  ) | (
    { __typename?: 'Event' }
    & EventsFragmentFragment
  )>>> }
);

export type SearchUsersQueryVariables = Exact<{
  text: Scalars['String'];
  filters?: Maybe<UserFilter>;
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { searchUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
  )>>> }
);

export type SeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type SeasonsQuery = (
  { __typename?: 'Query' }
  & { seasons?: Maybe<Array<Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'from' | 'id' | 'name' | 'until'>
  )>>> }
);

export type SubmitFormMutationVariables = Exact<{
  id: Scalars['ID'];
  data: Scalars['String'];
}>;


export type SubmitFormMutation = (
  { __typename?: 'Mutation' }
  & { submitForm?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id'>
  )> }
);

export type UnlikePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnlikePostMutation = (
  { __typename?: 'Mutation' }
  & { unlikePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'likeCount'>
    & { viewer?: Maybe<(
      { __typename?: 'PostViewer' }
      & Pick<PostViewer, 'isAuthor' | 'likesPost'>
    )> }
  )> }
);

export type UpdateMassMutationVariables = Exact<{
  id: Scalars['ID'];
  mass: MassInput;
}>;


export type UpdateMassMutation = (
  { __typename?: 'Mutation' }
  & { updateMass?: Maybe<(
    { __typename?: 'Mass' }
    & FullMassFragmentFragment
  )> }
);

export type UpdateSeasonMutationVariables = Exact<{
  id: Scalars['ID'];
  season: SeasonInput;
}>;


export type UpdateSeasonMutation = (
  { __typename?: 'Mutation' }
  & { updateSeason?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'from' | 'until' | 'name'>
  )> }
);

export type UploadEditorImageMutationVariables = Exact<{
  image: Scalars['Upload'];
}>;


export type UploadEditorImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'src' | 'path'>
  )> }
);

export type UploadTempImageMutationVariables = Exact<{
  image: Scalars['Upload'];
}>;


export type UploadTempImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'path'>
  )> }
);

export type UserQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )>, posts?: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & PostModuleFragmentFragment
  )>>>, parish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name'>
  )> }
);

export type UserByCodeQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;


export type UserByCodeQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName'>
  )> }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'prefix' | 'sex' | 'phoneNumber' | 'username' | 'firstName' | 'fullName' | 'lastName' | 'email'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'src' | 'srcset'>
    & { lqip: Image['src'] }
  )>, groups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )>>>, parishes?: Maybe<Array<Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name'>
  )>>>, roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & UserRoleFragmentFragment
  )>>>, primaryParish?: Maybe<(
    { __typename?: 'Parish' }
    & Pick<Parish, 'id' | 'name'>
  )> }
);

export type UserRoleFragmentFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'owner' | 'staff' | 'level' | 'domain'>
  & { staffEntry?: Maybe<(
    { __typename?: 'StaffEntry' }
    & Pick<StaffEntry, 'title' | 'description'>
  )> }
);

export type UsersQueryVariables = Exact<{
  filters?: Maybe<UserFilter>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & UserRoleFragmentFragment
    )>>> }
  )>>> }
);

export const DonationMethodFragmentFragmentDoc = gql`
    fragment DonationMethodFragment on Parish {
  id
  donationMethods {
    id
    method
    note
    url
    title
  }
}
    `;
export const EventModuleImageFragmentDoc = gql`
    fragment EventModuleImage on Image {
  id
  src(w: 304, h: 136, crop: entropy, fit: crop, facepad: 10, dpr: 2)
  lqip: src(w: 38, h: 17, blur: 15, crop: entropy, fit: crop, facepad: 10)
  title
}
    `;
export const EventsFragmentFragmentDoc = gql`
    fragment EventsFragment on Event {
  id
  title
  dtstart
  duration
  excerpt
  privacy
  status
  rsvpCount
  rsvps
  createdAt
  group {
    id
    name
  }
  owner {
    id
  }
  viewer {
    isHost
    hasRSVP
  }
  parish {
    id
    name
    theme
  }
  imagePosition {
    y
  }
  image {
    ...EventModuleImage
  }
}
    ${EventModuleImageFragmentDoc}`;
export const FormsFragmentFragmentDoc = gql`
    fragment FormsFragment on Form {
  id
  ast
  owner {
    id
    fullName
  }
  pinned
  parish {
    id
  }
  status
  title
  description
  closedAt
  createdAt
  updatedAt
}
    `;
export const FullGroupFragmentFragmentDoc = gql`
    fragment FullGroupFragment on Group {
  id
  description
  excerpt
  createdAt
  updatedAt
  status
  policies {
    post {
      create
      read
      update
      delete
    }
    event {
      create
      read
      update
      delete
    }
  }
  parish {
    id
    theme
    image {
      id
      path
      title
    }
    defaultDomain
    name
  }
  cover {
    id
    src(w: 990, h: 330, crop: focalpoint)
    srcset(w: 990, h: 330, crop: focalpoint)
    lqip: src(w: 60, h: 20, crop: focalpoint, blur: 10)
    title
  }
  coverPosition {
    x
    y
  }
  members {
    id
    fullName
    image {
      id
      src(w: 25, h: 25, crop: entropy, fit: crop, facepad: 10)
    }
  }
  memberCount
  privacy
  name
  owner {
    id
    fullName
    image {
      id
      src(w: 25, h: 25, crop: entropy, fit: crop, facepad: 10)
    }
  }
  viewer {
    isMember
  }
}
    `;
export const DateTimeObjectFragmentDoc = gql`
    fragment DateTimeObject on DateTimeObj {
  timezone
  year
  month
  day
  hour
  minute
}
    `;
export const FullMassFragmentFragmentDoc = gql`
    fragment FullMassFragment on Mass {
  id
  title
  flag
  start
  note
  parish
  season {
    id
    from
    until
    name
  }
  type
  status
  privacy
  rule {
    type
    config {
      frequency
      byDayOfWeek
      start {
        ...DateTimeObject
      }
    }
  }
  date {
    type
    dates {
      ...DateTimeObject
    }
  }
  exdates {
    id
    expires
    date {
      type
      dates {
        ...DateTimeObject
      }
      timezone
      data {
        exType
        newDate
        reason
      }
    }
  }
}
    ${DateTimeObjectFragmentDoc}`;
export const ParishFragmentFragmentDoc = gql`
    fragment ParishFragment on Parish {
  id
  theme
  name
  donationMethods {
    id
    method
    note
    url
    title
  }
  address {
    addressLine1
    city
    zip
    state
  }
  timezone
  email
  image {
    id
    src(w: 990, h: 330, crop: focalpoint, fit: facearea, facepad: 2)
    srcset(w: 990, h: 330, crop: focalpoint, fit: facearea, facepad: 2)
    lqip: src(w: 30, h: 10, crop: focalpoint, fit: facearea, facepad: 2, blur: 20)
  }
  diocese {
    id
    name
  }
  twitter {
    username
  }
  facebook {
    username
  }
  instagram {
    username
  }
  youtube {
    username
  }
  isEnabled
  phoneNumber
  links {
    href
    id
    text
  }
}
    `;
export const SinglePostFragmentFragmentDoc = gql`
    fragment SinglePostFragment on Post {
  id
  pinned
  slug
  createdAt
  updatedAt
  title
  type
  likeCount
  excerpt
  lectionaryDate
  ast
  author {
    id
    fullName
  }
  privacy
  status
  plain
  group {
    id
    name
    policies {
      post {
        create
        read
        update
        delete
      }
      event {
        create
        read
        update
        delete
      }
    }
  }
  parish {
    id
    theme
    image {
      id
      path
      title
    }
    twitter {
      username
    }
    facebook {
      username
    }
    defaultDomain
    name
  }
  viewer {
    isAuthor
    likesPost
  }
  imagePosition {
    x
    y
  }
  image {
    id
    src(w: 990, h: 330, usePosition: true)
    srcset(w: 990, h: 330, usePosition: true)
    lqip: src(w: 60, h: 20, usePosition: true, blur: 10)
    title
  }
}
    `;
export const SharedPostFragmentFragmentDoc = gql`
    fragment SharedPostFragment on Post {
  id
  pinned
  slug
  createdAt
  updatedAt
  title
  type
  likeCount
  plain
  ast
  source
  status
  privacy
  author {
    id
    fullName
  }
  plain
  group {
    id
    name
  }
  parish {
    id
    theme
    image {
      id
      path
      title
    }
    defaultDomain
    name
  }
  viewer {
    isAuthor
    likesPost
  }
}
    `;
export const PostModuleFragmentFragmentDoc = gql`
    fragment PostModuleFragment on Post {
  ...SharedPostFragment
  image {
    id
    src(w: 80, h: 112, usePosition: true)
    srcset(w: 80, h: 112, usePosition: true)
    lqip: src(w: 10, h: 14, blur: 20, usePosition: true)
    title
  }
}
    ${SharedPostFragmentFragmentDoc}`;
export const UserRoleFragmentFragmentDoc = gql`
    fragment UserRoleFragment on Role {
  id
  owner
  staff
  staffEntry {
    title
    description
  }
  level
  domain
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  image {
    id
    src(w: 256, h: 256, fit: crop)
    srcset(w: 256, h: 256, fit: crop)
    lqip: src(w: 15, h: 15, fit: crop)
  }
  prefix
  sex
  phoneNumber
  username
  firstName
  fullName
  lastName
  username
  email
  groups {
    id
  }
  parishes {
    id
    name
  }
  roles {
    ...UserRoleFragment
  }
  primaryParish {
    id
    name
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export const AboutDocument = gql`
    query About($id: ID!) {
  about(id: $id) {
    id
    title
    ast
    order
  }
}
    `;

/**
 * __useAboutQuery__
 *
 * To run a query within a React component, call `useAboutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAboutQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AboutQuery, AboutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AboutQuery, AboutQueryVariables>(AboutDocument, options);
      }
export function useAboutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AboutQuery, AboutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AboutQuery, AboutQueryVariables>(AboutDocument, options);
        }
export type AboutQueryHookResult = ReturnType<typeof useAboutQuery>;
export type AboutLazyQueryHookResult = ReturnType<typeof useAboutLazyQuery>;
export type AboutQueryResult = ApolloReactCommon.QueryResult<AboutQuery, AboutQueryVariables>;
export const AboutsDocument = gql`
    query Abouts {
  parish {
    id
    name
    image {
      id
      src(w: 990, h: 330, crop: focalpoint, fit: facearea, facepad: 2)
      srcset(w: 990, h: 330, crop: focalpoint, fit: facearea, facepad: 2)
      lqip: src(w: 60, h: 20, crop: focalpoint, fit: facearea, facepad: 2, blur: 15)
    }
  }
  abouts {
    id
    title
    ast
    order
  }
}
    `;

/**
 * __useAboutsQuery__
 *
 * To run a query within a React component, call `useAboutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAboutsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AboutsQuery, AboutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AboutsQuery, AboutsQueryVariables>(AboutsDocument, options);
      }
export function useAboutsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AboutsQuery, AboutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AboutsQuery, AboutsQueryVariables>(AboutsDocument, options);
        }
export type AboutsQueryHookResult = ReturnType<typeof useAboutsQuery>;
export type AboutsLazyQueryHookResult = ReturnType<typeof useAboutsLazyQuery>;
export type AboutsQueryResult = ApolloReactCommon.QueryResult<AboutsQuery, AboutsQueryVariables>;
export const AddAboutDocument = gql`
    mutation AddAbout($about: AboutInput!) {
  addAbout(about: $about) {
    id
    title
    ast
    order
  }
}
    `;
export type AddAboutMutationFn = ApolloReactCommon.MutationFunction<AddAboutMutation, AddAboutMutationVariables>;

/**
 * __useAddAboutMutation__
 *
 * To run a mutation, you first call `useAddAboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAboutMutation, { data, loading, error }] = useAddAboutMutation({
 *   variables: {
 *      about: // value for 'about'
 *   },
 * });
 */
export function useAddAboutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAboutMutation, AddAboutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddAboutMutation, AddAboutMutationVariables>(AddAboutDocument, options);
      }
export type AddAboutMutationHookResult = ReturnType<typeof useAddAboutMutation>;
export type AddAboutMutationResult = ApolloReactCommon.MutationResult<AddAboutMutation>;
export type AddAboutMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAboutMutation, AddAboutMutationVariables>;
export const AddBulletinLinkDocument = gql`
    mutation AddBulletinLink($id: ID, $bulletinLink: BulletinLinkInput!, $file: Upload) {
  addBulletinLink(id: $id, bulletinLink: $bulletinLink, file: $file) {
    id
    links {
      id
      createdAt
      forDate
      updatedAt
      url
    }
  }
}
    `;
export type AddBulletinLinkMutationFn = ApolloReactCommon.MutationFunction<AddBulletinLinkMutation, AddBulletinLinkMutationVariables>;

/**
 * __useAddBulletinLinkMutation__
 *
 * To run a mutation, you first call `useAddBulletinLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBulletinLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBulletinLinkMutation, { data, loading, error }] = useAddBulletinLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      bulletinLink: // value for 'bulletinLink'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddBulletinLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBulletinLinkMutation, AddBulletinLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddBulletinLinkMutation, AddBulletinLinkMutationVariables>(AddBulletinLinkDocument, options);
      }
export type AddBulletinLinkMutationHookResult = ReturnType<typeof useAddBulletinLinkMutation>;
export type AddBulletinLinkMutationResult = ApolloReactCommon.MutationResult<AddBulletinLinkMutation>;
export type AddBulletinLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBulletinLinkMutation, AddBulletinLinkMutationVariables>;
export const AddDonationMethodDocument = gql`
    mutation AddDonationMethod($donationMethod: DonationMethodInput!) {
  addDonationMethod(donationMethod: $donationMethod) {
    ...DonationMethodFragment
  }
}
    ${DonationMethodFragmentFragmentDoc}`;
export type AddDonationMethodMutationFn = ApolloReactCommon.MutationFunction<AddDonationMethodMutation, AddDonationMethodMutationVariables>;

/**
 * __useAddDonationMethodMutation__
 *
 * To run a mutation, you first call `useAddDonationMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDonationMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDonationMethodMutation, { data, loading, error }] = useAddDonationMethodMutation({
 *   variables: {
 *      donationMethod: // value for 'donationMethod'
 *   },
 * });
 */
export function useAddDonationMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDonationMethodMutation, AddDonationMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddDonationMethodMutation, AddDonationMethodMutationVariables>(AddDonationMethodDocument, options);
      }
export type AddDonationMethodMutationHookResult = ReturnType<typeof useAddDonationMethodMutation>;
export type AddDonationMethodMutationResult = ApolloReactCommon.MutationResult<AddDonationMethodMutation>;
export type AddDonationMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDonationMethodMutation, AddDonationMethodMutationVariables>;
export const AddEventDocument = gql`
    mutation AddEvent($event: EventInput!) {
  addEvent(event: $event) {
    ...EventsFragment
  }
}
    ${EventsFragmentFragmentDoc}`;
export type AddEventMutationFn = ApolloReactCommon.MutationFunction<AddEventMutation, AddEventMutationVariables>;

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutation, { data, loading, error }] = useAddEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useAddEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEventMutation, AddEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddEventMutation, AddEventMutationVariables>(AddEventDocument, options);
      }
export type AddEventMutationHookResult = ReturnType<typeof useAddEventMutation>;
export type AddEventMutationResult = ApolloReactCommon.MutationResult<AddEventMutation>;
export type AddEventMutationOptions = ApolloReactCommon.BaseMutationOptions<AddEventMutation, AddEventMutationVariables>;
export const AddGroupDocument = gql`
    mutation AddGroup($group: GroupInput!) {
  addGroup(group: $group) {
    ...FullGroupFragment
  }
}
    ${FullGroupFragmentFragmentDoc}`;
export type AddGroupMutationFn = ApolloReactCommon.MutationFunction<AddGroupMutation, AddGroupMutationVariables>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAddGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, options);
      }
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = ApolloReactCommon.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddGroupMutation, AddGroupMutationVariables>;
export const AddMassDocument = gql`
    mutation AddMass($mass: MassInput!) {
  addMass(mass: $mass) {
    ...FullMassFragment
  }
}
    ${FullMassFragmentFragmentDoc}`;
export type AddMassMutationFn = ApolloReactCommon.MutationFunction<AddMassMutation, AddMassMutationVariables>;

/**
 * __useAddMassMutation__
 *
 * To run a mutation, you first call `useAddMassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMassMutation, { data, loading, error }] = useAddMassMutation({
 *   variables: {
 *      mass: // value for 'mass'
 *   },
 * });
 */
export function useAddMassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMassMutation, AddMassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddMassMutation, AddMassMutationVariables>(AddMassDocument, options);
      }
export type AddMassMutationHookResult = ReturnType<typeof useAddMassMutation>;
export type AddMassMutationResult = ApolloReactCommon.MutationResult<AddMassMutation>;
export type AddMassMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMassMutation, AddMassMutationVariables>;
export const AddMassExceptionDocument = gql`
    mutation AddMassException($id: ID!, $exception: ExceptionInput!) {
  addMassException(id: $id, exception: $exception) {
    ...FullMassFragment
  }
}
    ${FullMassFragmentFragmentDoc}`;
export type AddMassExceptionMutationFn = ApolloReactCommon.MutationFunction<AddMassExceptionMutation, AddMassExceptionMutationVariables>;

/**
 * __useAddMassExceptionMutation__
 *
 * To run a mutation, you first call `useAddMassExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMassExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMassExceptionMutation, { data, loading, error }] = useAddMassExceptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exception: // value for 'exception'
 *   },
 * });
 */
export function useAddMassExceptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMassExceptionMutation, AddMassExceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddMassExceptionMutation, AddMassExceptionMutationVariables>(AddMassExceptionDocument, options);
      }
export type AddMassExceptionMutationHookResult = ReturnType<typeof useAddMassExceptionMutation>;
export type AddMassExceptionMutationResult = ApolloReactCommon.MutationResult<AddMassExceptionMutation>;
export type AddMassExceptionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMassExceptionMutation, AddMassExceptionMutationVariables>;
export const AddParishLinkDocument = gql`
    mutation AddParishLink($link: LinkInput!) {
  addParishLink(link: $link) {
    ...ParishFragment
  }
}
    ${ParishFragmentFragmentDoc}`;
export type AddParishLinkMutationFn = ApolloReactCommon.MutationFunction<AddParishLinkMutation, AddParishLinkMutationVariables>;

/**
 * __useAddParishLinkMutation__
 *
 * To run a mutation, you first call `useAddParishLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParishLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParishLinkMutation, { data, loading, error }] = useAddParishLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddParishLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddParishLinkMutation, AddParishLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddParishLinkMutation, AddParishLinkMutationVariables>(AddParishLinkDocument, options);
      }
export type AddParishLinkMutationHookResult = ReturnType<typeof useAddParishLinkMutation>;
export type AddParishLinkMutationResult = ApolloReactCommon.MutationResult<AddParishLinkMutation>;
export type AddParishLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<AddParishLinkMutation, AddParishLinkMutationVariables>;
export const AddPostDocument = gql`
    mutation AddPost($post: PostInput!) {
  addPost(post: $post) {
    ...SharedPostFragment
    image {
      id
      title
      src(w: 224, h: 304, usePosition: true, dpr: 2)
      srcset(w: 80, h: 112, usePosition: true)
      doubleSrc: src(w: 464, h: 240, usePosition: true, dpr: 2)
      lqip: src(w: 14, h: 19, blur: 20, usePosition: true)
      doubleLqip: src(w: 29, h: 15, blur: 20, usePosition: true)
    }
  }
}
    ${SharedPostFragmentFragmentDoc}`;
export type AddPostMutationFn = ApolloReactCommon.MutationFunction<AddPostMutation, AddPostMutationVariables>;

/**
 * __useAddPostMutation__
 *
 * To run a mutation, you first call `useAddPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostMutation, { data, loading, error }] = useAddPostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useAddPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPostMutation, AddPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddPostMutation, AddPostMutationVariables>(AddPostDocument, options);
      }
export type AddPostMutationHookResult = ReturnType<typeof useAddPostMutation>;
export type AddPostMutationResult = ApolloReactCommon.MutationResult<AddPostMutation>;
export type AddPostMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPostMutation, AddPostMutationVariables>;
export const AddRsvpDocument = gql`
    mutation AddRSVP($id: ID!) {
  addRSVP(id: $id) {
    id
    pinned
    title
    dtstart
    excerpt
    duration
    description
    privacy
    status
    rsvps
    rsvpCount
    createdAt
    updatedAt
    viewer {
      isHost
      hasRSVP
    }
  }
}
    `;
export type AddRsvpMutationFn = ApolloReactCommon.MutationFunction<AddRsvpMutation, AddRsvpMutationVariables>;

/**
 * __useAddRsvpMutation__
 *
 * To run a mutation, you first call `useAddRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRsvpMutation, { data, loading, error }] = useAddRsvpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddRsvpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRsvpMutation, AddRsvpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddRsvpMutation, AddRsvpMutationVariables>(AddRsvpDocument, options);
      }
export type AddRsvpMutationHookResult = ReturnType<typeof useAddRsvpMutation>;
export type AddRsvpMutationResult = ApolloReactCommon.MutationResult<AddRsvpMutation>;
export type AddRsvpMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRsvpMutation, AddRsvpMutationVariables>;
export const AddSacramentDocument = gql`
    mutation AddSacrament($sacrament: SacramentInput!) {
  addSacrament(sacrament: $sacrament) {
    id
    title
    description
    type
    parish {
      id
    }
  }
}
    `;
export type AddSacramentMutationFn = ApolloReactCommon.MutationFunction<AddSacramentMutation, AddSacramentMutationVariables>;

/**
 * __useAddSacramentMutation__
 *
 * To run a mutation, you first call `useAddSacramentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSacramentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSacramentMutation, { data, loading, error }] = useAddSacramentMutation({
 *   variables: {
 *      sacrament: // value for 'sacrament'
 *   },
 * });
 */
export function useAddSacramentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSacramentMutation, AddSacramentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddSacramentMutation, AddSacramentMutationVariables>(AddSacramentDocument, options);
      }
export type AddSacramentMutationHookResult = ReturnType<typeof useAddSacramentMutation>;
export type AddSacramentMutationResult = ApolloReactCommon.MutationResult<AddSacramentMutation>;
export type AddSacramentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSacramentMutation, AddSacramentMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($user: UserInput!) {
  addUser(user: $user) {
    id
    image {
      id
      src(w: 256, h: 256, fit: crop)
      srcset(w: 256, h: 256, fit: crop)
      lqip: src(w: 15, h: 15, fit: crop)
    }
    firstName
    fullName
    lastName
    username
    email
    primaryParish {
      id
      name
    }
  }
}
    `;
export type AddUserMutationFn = ApolloReactCommon.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = ApolloReactCommon.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const AnalyticsDocument = gql`
    query Analytics {
  analytics {
    id
    memberCount
    postCount
    groupCount
    eventsCounts
    date
    visitors {
      browser
      category
      ip
    }
    topLikedPosts {
      id
      title
      likeCount
    }
  }
}
    `;

/**
 * __useAnalyticsQuery__
 *
 * To run a query within a React component, call `useAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, options);
      }
export function useAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, options);
        }
export type AnalyticsQueryHookResult = ReturnType<typeof useAnalyticsQuery>;
export type AnalyticsLazyQueryHookResult = ReturnType<typeof useAnalyticsLazyQuery>;
export type AnalyticsQueryResult = ApolloReactCommon.QueryResult<AnalyticsQuery, AnalyticsQueryVariables>;
export const AppDocument = gql`
    query App {
  parish {
    id
    defaultDomain
    name
    theme
    timezone
    maintenanceMode
  }
  abouts {
    id
    title
    ast
    order
  }
  user {
    id
    timezone
    firstName
    lastName
    fullName
    roles {
      ...UserRoleFragment
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppQuery, AppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AppQuery, AppQueryVariables>(AppDocument, options);
      }
export function useAppLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, options);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = ApolloReactCommon.QueryResult<AppQuery, AppQueryVariables>;
export const BulletinDocument = gql`
    query Bulletin {
  bulletin {
    id
    parish {
      id
    }
    links {
      id
      forDate
      createdAt
      url
    }
  }
}
    `;

/**
 * __useBulletinQuery__
 *
 * To run a query within a React component, call `useBulletinQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulletinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulletinQuery({
 *   variables: {
 *   },
 * });
 */
export function useBulletinQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulletinQuery, BulletinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BulletinQuery, BulletinQueryVariables>(BulletinDocument, options);
      }
export function useBulletinLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulletinQuery, BulletinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BulletinQuery, BulletinQueryVariables>(BulletinDocument, options);
        }
export type BulletinQueryHookResult = ReturnType<typeof useBulletinQuery>;
export type BulletinLazyQueryHookResult = ReturnType<typeof useBulletinLazyQuery>;
export type BulletinQueryResult = ApolloReactCommon.QueryResult<BulletinQuery, BulletinQueryVariables>;
export const BulletinModuleDocument = gql`
    query BulletinModule {
  bulletin {
    id
    currentLink {
      id
      createdAt
      forDate
      url
    }
  }
}
    `;

/**
 * __useBulletinModuleQuery__
 *
 * To run a query within a React component, call `useBulletinModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulletinModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulletinModuleQuery({
 *   variables: {
 *   },
 * });
 */
export function useBulletinModuleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulletinModuleQuery, BulletinModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BulletinModuleQuery, BulletinModuleQueryVariables>(BulletinModuleDocument, options);
      }
export function useBulletinModuleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulletinModuleQuery, BulletinModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BulletinModuleQuery, BulletinModuleQueryVariables>(BulletinModuleDocument, options);
        }
export type BulletinModuleQueryHookResult = ReturnType<typeof useBulletinModuleQuery>;
export type BulletinModuleLazyQueryHookResult = ReturnType<typeof useBulletinModuleLazyQuery>;
export type BulletinModuleQueryResult = ApolloReactCommon.QueryResult<BulletinModuleQuery, BulletinModuleQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CheckEmailDocument = gql`
    query CheckEmail($email: String!) {
  checkEmail(email: $email)
}
    `;

/**
 * __useCheckEmailQuery__
 *
 * To run a query within a React component, call `useCheckEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CheckEmailQuery, CheckEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CheckEmailQuery, CheckEmailQueryVariables>(CheckEmailDocument, options);
      }
export function useCheckEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckEmailQuery, CheckEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CheckEmailQuery, CheckEmailQueryVariables>(CheckEmailDocument, options);
        }
export type CheckEmailQueryHookResult = ReturnType<typeof useCheckEmailQuery>;
export type CheckEmailLazyQueryHookResult = ReturnType<typeof useCheckEmailLazyQuery>;
export type CheckEmailQueryResult = ApolloReactCommon.QueryResult<CheckEmailQuery, CheckEmailQueryVariables>;
export const DeleteAboutDocument = gql`
    mutation DeleteAbout($id: ID!) {
  deleteAbout(id: $id)
}
    `;
export type DeleteAboutMutationFn = ApolloReactCommon.MutationFunction<DeleteAboutMutation, DeleteAboutMutationVariables>;

/**
 * __useDeleteAboutMutation__
 *
 * To run a mutation, you first call `useDeleteAboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAboutMutation, { data, loading, error }] = useDeleteAboutMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAboutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAboutMutation, DeleteAboutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteAboutMutation, DeleteAboutMutationVariables>(DeleteAboutDocument, options);
      }
export type DeleteAboutMutationHookResult = ReturnType<typeof useDeleteAboutMutation>;
export type DeleteAboutMutationResult = ApolloReactCommon.MutationResult<DeleteAboutMutation>;
export type DeleteAboutMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAboutMutation, DeleteAboutMutationVariables>;
export const DeleteBulletinLinkDocument = gql`
    mutation DeleteBulletinLink($id: ID!) {
  deleteBulletinLink(id: $id)
}
    `;
export type DeleteBulletinLinkMutationFn = ApolloReactCommon.MutationFunction<DeleteBulletinLinkMutation, DeleteBulletinLinkMutationVariables>;

/**
 * __useDeleteBulletinLinkMutation__
 *
 * To run a mutation, you first call `useDeleteBulletinLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulletinLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulletinLinkMutation, { data, loading, error }] = useDeleteBulletinLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBulletinLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBulletinLinkMutation, DeleteBulletinLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteBulletinLinkMutation, DeleteBulletinLinkMutationVariables>(DeleteBulletinLinkDocument, options);
      }
export type DeleteBulletinLinkMutationHookResult = ReturnType<typeof useDeleteBulletinLinkMutation>;
export type DeleteBulletinLinkMutationResult = ApolloReactCommon.MutationResult<DeleteBulletinLinkMutation>;
export type DeleteBulletinLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBulletinLinkMutation, DeleteBulletinLinkMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id)
}
    `;
export type DeleteEventMutationFn = ApolloReactCommon.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = ApolloReactCommon.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($id: ID!) {
  deleteGroup(id: $id)
}
    `;
export type DeleteGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = ApolloReactCommon.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const DeleteMassDocument = gql`
    mutation DeleteMass($id: ID!) {
  deleteMass(id: $id)
}
    `;
export type DeleteMassMutationFn = ApolloReactCommon.MutationFunction<DeleteMassMutation, DeleteMassMutationVariables>;

/**
 * __useDeleteMassMutation__
 *
 * To run a mutation, you first call `useDeleteMassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMassMutation, { data, loading, error }] = useDeleteMassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMassMutation, DeleteMassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteMassMutation, DeleteMassMutationVariables>(DeleteMassDocument, options);
      }
export type DeleteMassMutationHookResult = ReturnType<typeof useDeleteMassMutation>;
export type DeleteMassMutationResult = ApolloReactCommon.MutationResult<DeleteMassMutation>;
export type DeleteMassMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMassMutation, DeleteMassMutationVariables>;
export const DeleteMassExceptionDocument = gql`
    mutation DeleteMassException($parent: ID!, $id: ID!) {
  deleteMassException(id: $id, parent: $parent) {
    ...FullMassFragment
  }
}
    ${FullMassFragmentFragmentDoc}`;
export type DeleteMassExceptionMutationFn = ApolloReactCommon.MutationFunction<DeleteMassExceptionMutation, DeleteMassExceptionMutationVariables>;

/**
 * __useDeleteMassExceptionMutation__
 *
 * To run a mutation, you first call `useDeleteMassExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMassExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMassExceptionMutation, { data, loading, error }] = useDeleteMassExceptionMutation({
 *   variables: {
 *      parent: // value for 'parent'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMassExceptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMassExceptionMutation, DeleteMassExceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteMassExceptionMutation, DeleteMassExceptionMutationVariables>(DeleteMassExceptionDocument, options);
      }
export type DeleteMassExceptionMutationHookResult = ReturnType<typeof useDeleteMassExceptionMutation>;
export type DeleteMassExceptionMutationResult = ApolloReactCommon.MutationResult<DeleteMassExceptionMutation>;
export type DeleteMassExceptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMassExceptionMutation, DeleteMassExceptionMutationVariables>;
export const DeleteParishLinkDocument = gql`
    mutation DeleteParishLink($parishId: ID!, $id: ID!) {
  deleteParishLink(parishId: $parishId, id: $id) {
    ...ParishFragment
  }
}
    ${ParishFragmentFragmentDoc}`;
export type DeleteParishLinkMutationFn = ApolloReactCommon.MutationFunction<DeleteParishLinkMutation, DeleteParishLinkMutationVariables>;

/**
 * __useDeleteParishLinkMutation__
 *
 * To run a mutation, you first call `useDeleteParishLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParishLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParishLinkMutation, { data, loading, error }] = useDeleteParishLinkMutation({
 *   variables: {
 *      parishId: // value for 'parishId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteParishLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteParishLinkMutation, DeleteParishLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteParishLinkMutation, DeleteParishLinkMutationVariables>(DeleteParishLinkDocument, options);
      }
export type DeleteParishLinkMutationHookResult = ReturnType<typeof useDeleteParishLinkMutation>;
export type DeleteParishLinkMutationResult = ApolloReactCommon.MutationResult<DeleteParishLinkMutation>;
export type DeleteParishLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteParishLinkMutation, DeleteParishLinkMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($id: ID!) {
  deletePost(id: $id)
}
    `;
export type DeletePostMutationFn = ApolloReactCommon.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = ApolloReactCommon.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DeleteRsvpDocument = gql`
    mutation DeleteRSVP($id: ID!) {
  deleteRSVP(id: $id) {
    id
    pinned
    title
    dtstart
    excerpt
    duration
    description
    privacy
    status
    rsvps
    rsvpCount
    createdAt
    updatedAt
    viewer {
      isHost
      hasRSVP
    }
  }
}
    `;
export type DeleteRsvpMutationFn = ApolloReactCommon.MutationFunction<DeleteRsvpMutation, DeleteRsvpMutationVariables>;

/**
 * __useDeleteRsvpMutation__
 *
 * To run a mutation, you first call `useDeleteRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRsvpMutation, { data, loading, error }] = useDeleteRsvpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRsvpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRsvpMutation, DeleteRsvpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRsvpMutation, DeleteRsvpMutationVariables>(DeleteRsvpDocument, options);
      }
export type DeleteRsvpMutationHookResult = ReturnType<typeof useDeleteRsvpMutation>;
export type DeleteRsvpMutationResult = ApolloReactCommon.MutationResult<DeleteRsvpMutation>;
export type DeleteRsvpMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRsvpMutation, DeleteRsvpMutationVariables>;
export const DeleteSacramentDocument = gql`
    mutation DeleteSacrament($id: ID!) {
  deleteSacrament(id: $id)
}
    `;
export type DeleteSacramentMutationFn = ApolloReactCommon.MutationFunction<DeleteSacramentMutation, DeleteSacramentMutationVariables>;

/**
 * __useDeleteSacramentMutation__
 *
 * To run a mutation, you first call `useDeleteSacramentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSacramentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSacramentMutation, { data, loading, error }] = useDeleteSacramentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSacramentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSacramentMutation, DeleteSacramentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSacramentMutation, DeleteSacramentMutationVariables>(DeleteSacramentDocument, options);
      }
export type DeleteSacramentMutationHookResult = ReturnType<typeof useDeleteSacramentMutation>;
export type DeleteSacramentMutationResult = ApolloReactCommon.MutationResult<DeleteSacramentMutation>;
export type DeleteSacramentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSacramentMutation, DeleteSacramentMutationVariables>;
export const EditAboutDocument = gql`
    mutation EditAbout($id: ID!, $about: EditAboutInput!) {
  editAbout(id: $id, about: $about) {
    id
    title
    ast
    order
  }
}
    `;
export type EditAboutMutationFn = ApolloReactCommon.MutationFunction<EditAboutMutation, EditAboutMutationVariables>;

/**
 * __useEditAboutMutation__
 *
 * To run a mutation, you first call `useEditAboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAboutMutation, { data, loading, error }] = useEditAboutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      about: // value for 'about'
 *   },
 * });
 */
export function useEditAboutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAboutMutation, EditAboutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditAboutMutation, EditAboutMutationVariables>(EditAboutDocument, options);
      }
export type EditAboutMutationHookResult = ReturnType<typeof useEditAboutMutation>;
export type EditAboutMutationResult = ApolloReactCommon.MutationResult<EditAboutMutation>;
export type EditAboutMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAboutMutation, EditAboutMutationVariables>;
export const EditDonationMethodDocument = gql`
    mutation EditDonationMethod($id: ID!, $donationMethod: EditDonationMethodInput!) {
  editDonationMethod(id: $id, donationMethod: $donationMethod) {
    ...DonationMethodFragment
  }
}
    ${DonationMethodFragmentFragmentDoc}`;
export type EditDonationMethodMutationFn = ApolloReactCommon.MutationFunction<EditDonationMethodMutation, EditDonationMethodMutationVariables>;

/**
 * __useEditDonationMethodMutation__
 *
 * To run a mutation, you first call `useEditDonationMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDonationMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDonationMethodMutation, { data, loading, error }] = useEditDonationMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      donationMethod: // value for 'donationMethod'
 *   },
 * });
 */
export function useEditDonationMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDonationMethodMutation, EditDonationMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditDonationMethodMutation, EditDonationMethodMutationVariables>(EditDonationMethodDocument, options);
      }
export type EditDonationMethodMutationHookResult = ReturnType<typeof useEditDonationMethodMutation>;
export type EditDonationMethodMutationResult = ApolloReactCommon.MutationResult<EditDonationMethodMutation>;
export type EditDonationMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDonationMethodMutation, EditDonationMethodMutationVariables>;
export const EditEventDocument = gql`
    mutation EditEvent($id: ID!, $event: EditEventInput!) {
  editEvent(id: $id, event: $event) {
    id
    pinned
    title
    dtstart
    excerpt
    duration
    description
    privacy
    status
    rsvps
    rsvpCount
    createdAt
    updatedAt
    owner {
      id
      fullName
    }
    image {
      id
      title
      src(w: 952, h: 304, crop: focalpoint, fit: facearea, facepad: 2)
      srcset(w: 952, h: 304, crop: focalpoint, fit: facearea, facepad: 2)
      lqip: src(w: 119, h: 38, crop: focalpoint, fit: facearea, facepad: 2, blur: 10)
    }
    viewer {
      isHost
      hasRSVP
    }
    parish {
      id
      theme
      defaultDomain
      name
    }
  }
}
    `;
export type EditEventMutationFn = ApolloReactCommon.MutationFunction<EditEventMutation, EditEventMutationVariables>;

/**
 * __useEditEventMutation__
 *
 * To run a mutation, you first call `useEditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventMutation, { data, loading, error }] = useEditEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useEditEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditEventMutation, EditEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditEventMutation, EditEventMutationVariables>(EditEventDocument, options);
      }
export type EditEventMutationHookResult = ReturnType<typeof useEditEventMutation>;
export type EditEventMutationResult = ApolloReactCommon.MutationResult<EditEventMutation>;
export type EditEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EditEventMutation, EditEventMutationVariables>;
export const EditGroupDocument = gql`
    mutation EditGroup($id: ID!, $group: EditGroupInput!) {
  editGroup(id: $id, group: $group) {
    ...FullGroupFragment
  }
}
    ${FullGroupFragmentFragmentDoc}`;
export type EditGroupMutationFn = ApolloReactCommon.MutationFunction<EditGroupMutation, EditGroupMutationVariables>;

/**
 * __useEditGroupMutation__
 *
 * To run a mutation, you first call `useEditGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupMutation, { data, loading, error }] = useEditGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useEditGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditGroupMutation, EditGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditGroupMutation, EditGroupMutationVariables>(EditGroupDocument, options);
      }
export type EditGroupMutationHookResult = ReturnType<typeof useEditGroupMutation>;
export type EditGroupMutationResult = ApolloReactCommon.MutationResult<EditGroupMutation>;
export type EditGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditGroupMutation, EditGroupMutationVariables>;
export const EditParishDocument = gql`
    mutation EditParish($parish: ParishInput!) {
  editParish(parish: $parish) {
    ...ParishFragment
  }
}
    ${ParishFragmentFragmentDoc}`;
export type EditParishMutationFn = ApolloReactCommon.MutationFunction<EditParishMutation, EditParishMutationVariables>;

/**
 * __useEditParishMutation__
 *
 * To run a mutation, you first call `useEditParishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditParishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editParishMutation, { data, loading, error }] = useEditParishMutation({
 *   variables: {
 *      parish: // value for 'parish'
 *   },
 * });
 */
export function useEditParishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditParishMutation, EditParishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditParishMutation, EditParishMutationVariables>(EditParishDocument, options);
      }
export type EditParishMutationHookResult = ReturnType<typeof useEditParishMutation>;
export type EditParishMutationResult = ApolloReactCommon.MutationResult<EditParishMutation>;
export type EditParishMutationOptions = ApolloReactCommon.BaseMutationOptions<EditParishMutation, EditParishMutationVariables>;
export const EditPostDocument = gql`
    mutation EditPost($id: ID!, $post: EditPostInput!) {
  editPost(id: $id, post: $post) {
    ...SinglePostFragment
  }
}
    ${SinglePostFragmentFragmentDoc}`;
export type EditPostMutationFn = ApolloReactCommon.MutationFunction<EditPostMutation, EditPostMutationVariables>;

/**
 * __useEditPostMutation__
 *
 * To run a mutation, you first call `useEditPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPostMutation, { data, loading, error }] = useEditPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      post: // value for 'post'
 *   },
 * });
 */
export function useEditPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPostMutation, EditPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditPostMutation, EditPostMutationVariables>(EditPostDocument, options);
      }
export type EditPostMutationHookResult = ReturnType<typeof useEditPostMutation>;
export type EditPostMutationResult = ApolloReactCommon.MutationResult<EditPostMutation>;
export type EditPostMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPostMutation, EditPostMutationVariables>;
export const EditSacramentDocument = gql`
    mutation EditSacrament($id: ID!, $sacrament: EditSacramentInput!) {
  editSacrament(id: $id, sacrament: $sacrament) {
    id
    description
    title
  }
}
    `;
export type EditSacramentMutationFn = ApolloReactCommon.MutationFunction<EditSacramentMutation, EditSacramentMutationVariables>;

/**
 * __useEditSacramentMutation__
 *
 * To run a mutation, you first call `useEditSacramentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSacramentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSacramentMutation, { data, loading, error }] = useEditSacramentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sacrament: // value for 'sacrament'
 *   },
 * });
 */
export function useEditSacramentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSacramentMutation, EditSacramentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditSacramentMutation, EditSacramentMutationVariables>(EditSacramentDocument, options);
      }
export type EditSacramentMutationHookResult = ReturnType<typeof useEditSacramentMutation>;
export type EditSacramentMutationResult = ApolloReactCommon.MutationResult<EditSacramentMutation>;
export type EditSacramentMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSacramentMutation, EditSacramentMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($user: EditUserInput!) {
  editUser(user: $user) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type EditUserMutationFn = ApolloReactCommon.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = ApolloReactCommon.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const EditUserRoleDocument = gql`
    mutation EditUserRole($id: ID!, $user: ID!, $role: RoleInput!) {
  editUserRole(id: $id, user: $user, role: $role) {
    id
    fullName
    roles {
      ...UserRoleFragment
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export type EditUserRoleMutationFn = ApolloReactCommon.MutationFunction<EditUserRoleMutation, EditUserRoleMutationVariables>;

/**
 * __useEditUserRoleMutation__
 *
 * To run a mutation, you first call `useEditUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserRoleMutation, { data, loading, error }] = useEditUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserRoleMutation, EditUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditUserRoleMutation, EditUserRoleMutationVariables>(EditUserRoleDocument, options);
      }
export type EditUserRoleMutationHookResult = ReturnType<typeof useEditUserRoleMutation>;
export type EditUserRoleMutationResult = ApolloReactCommon.MutationResult<EditUserRoleMutation>;
export type EditUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserRoleMutation, EditUserRoleMutationVariables>;
export const EventDocument = gql`
    query Event($id: ID) {
  event(id: $id) {
    id
    pinned
    title
    dtstart
    excerpt
    duration
    description
    privacy
    status
    rsvps
    rsvpCount
    createdAt
    updatedAt
    owner {
      id
      fullName
    }
    image {
      id
      title
      src(w: 496, h: 224, crop: focalpoint, fit: facearea, facepad: 2)
      srcset(w: 496, h: 224, crop: focalpoint, fit: facearea, facepad: 2)
      lqip: src(w: 31, h: 14, crop: focalpoint, fit: facearea, facepad: 2, blur: 10)
    }
    imagePosition {
      y
    }
    viewer {
      isHost
      hasRSVP
    }
    group {
      id
      name
    }
    parish {
      id
      theme
      defaultDomain
      name
    }
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = ApolloReactCommon.QueryResult<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query Events($filters: Filter, $limit: Int, $cursor: ID, $past: Boolean) {
  parish {
    id
  }
  events(filters: $filters, limit: $limit, cursor: $cursor, past: $past) @connection(key: "Event", filter: ["past"]) {
    ...EventsFragment
  }
}
    ${EventsFragmentFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      past: // value for 'past'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const FeaturedEventsDocument = gql`
    query FeaturedEvents {
  events(limit: 8) @connection(key: "Event") {
    ...EventsFragment
  }
}
    ${EventsFragmentFragmentDoc}`;

/**
 * __useFeaturedEventsQuery__
 *
 * To run a query within a React component, call `useFeaturedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeaturedEventsQuery, FeaturedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FeaturedEventsQuery, FeaturedEventsQueryVariables>(FeaturedEventsDocument, options);
      }
export function useFeaturedEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturedEventsQuery, FeaturedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FeaturedEventsQuery, FeaturedEventsQueryVariables>(FeaturedEventsDocument, options);
        }
export type FeaturedEventsQueryHookResult = ReturnType<typeof useFeaturedEventsQuery>;
export type FeaturedEventsLazyQueryHookResult = ReturnType<typeof useFeaturedEventsLazyQuery>;
export type FeaturedEventsQueryResult = ApolloReactCommon.QueryResult<FeaturedEventsQuery, FeaturedEventsQueryVariables>;
export const FeaturedPostsDocument = gql`
    query FeaturedPosts($filters: Filter) {
  posts(limit: 6, filters: $filters) @connection(key: "Post", filter: ["filters"]) {
    ...SharedPostFragment
    image {
      id
      src(w: 224, h: 304, usePosition: true, dpr: 2)
      doubleSrc: src(w: 464, h: 240, usePosition: true, dpr: 2)
      lqip: src(w: 14, h: 19, blur: 20, usePosition: true)
      doubleLqip: src(w: 29, h: 15, blur: 20, usePosition: true)
    }
  }
}
    ${SharedPostFragmentFragmentDoc}`;

/**
 * __useFeaturedPostsQuery__
 *
 * To run a query within a React component, call `useFeaturedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedPostsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFeaturedPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeaturedPostsQuery, FeaturedPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FeaturedPostsQuery, FeaturedPostsQueryVariables>(FeaturedPostsDocument, options);
      }
export function useFeaturedPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturedPostsQuery, FeaturedPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FeaturedPostsQuery, FeaturedPostsQueryVariables>(FeaturedPostsDocument, options);
        }
export type FeaturedPostsQueryHookResult = ReturnType<typeof useFeaturedPostsQuery>;
export type FeaturedPostsLazyQueryHookResult = ReturnType<typeof useFeaturedPostsLazyQuery>;
export type FeaturedPostsQueryResult = ApolloReactCommon.QueryResult<FeaturedPostsQuery, FeaturedPostsQueryVariables>;
export const FormDocument = gql`
    query Form($id: ID) {
  parish {
    id
  }
  form(id: $id) {
    ...FormsFragment
  }
}
    ${FormsFragmentFragmentDoc}`;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormQuery, FormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormQueryResult = ApolloReactCommon.QueryResult<FormQuery, FormQueryVariables>;
export const FormSubmissionsDocument = gql`
    query FormSubmissions($id: ID) {
  form(id: $id) {
    id
    title
    description
    submissions {
      id
      data
      createdAt
    }
  }
}
    `;

/**
 * __useFormSubmissionsQuery__
 *
 * To run a query within a React component, call `useFormSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormSubmissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormSubmissionsQuery, FormSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FormSubmissionsQuery, FormSubmissionsQueryVariables>(FormSubmissionsDocument, options);
      }
export function useFormSubmissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormSubmissionsQuery, FormSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FormSubmissionsQuery, FormSubmissionsQueryVariables>(FormSubmissionsDocument, options);
        }
export type FormSubmissionsQueryHookResult = ReturnType<typeof useFormSubmissionsQuery>;
export type FormSubmissionsLazyQueryHookResult = ReturnType<typeof useFormSubmissionsLazyQuery>;
export type FormSubmissionsQueryResult = ApolloReactCommon.QueryResult<FormSubmissionsQuery, FormSubmissionsQueryVariables>;
export const FormsDocument = gql`
    query Forms($filters: Filter, $limit: Int, $cursor: ID) {
  parish {
    id
  }
  forms(filters: $filters, limit: $limit, cursor: $cursor) {
    ...FormsFragment
  }
}
    ${FormsFragmentFragmentDoc}`;

/**
 * __useFormsQuery__
 *
 * To run a query within a React component, call `useFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useFormsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormsQuery, FormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
      }
export function useFormsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormsQuery, FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export type FormsQueryResult = ApolloReactCommon.QueryResult<FormsQuery, FormsQueryVariables>;
export const GetImageDocument = gql`
    query GetImage($id: ID!, $width: Int = 990, $height: Int = 330) {
  image(id: $id) {
    id
    url
    temporary
    src(w: $width, h: $height, crop: focalpoint, fit: facearea, facepad: 2)
  }
}
    `;

/**
 * __useGetImageQuery__
 *
 * To run a query within a React component, call `useGetImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useGetImageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetImageQuery, GetImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetImageQuery, GetImageQueryVariables>(GetImageDocument, options);
      }
export function useGetImageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetImageQuery, GetImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetImageQuery, GetImageQueryVariables>(GetImageDocument, options);
        }
export type GetImageQueryHookResult = ReturnType<typeof useGetImageQuery>;
export type GetImageLazyQueryHookResult = ReturnType<typeof useGetImageLazyQuery>;
export type GetImageQueryResult = ApolloReactCommon.QueryResult<GetImageQuery, GetImageQueryVariables>;
export const GetTempImageDocument = gql`
    query GetTempImage($id: ID!) {
  image(id: $id) {
    id
    url
    path
    temporary
  }
}
    `;

/**
 * __useGetTempImageQuery__
 *
 * To run a query within a React component, call `useGetTempImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTempImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTempImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTempImageQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTempImageQuery, GetTempImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTempImageQuery, GetTempImageQueryVariables>(GetTempImageDocument, options);
      }
export function useGetTempImageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTempImageQuery, GetTempImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTempImageQuery, GetTempImageQueryVariables>(GetTempImageDocument, options);
        }
export type GetTempImageQueryHookResult = ReturnType<typeof useGetTempImageQuery>;
export type GetTempImageLazyQueryHookResult = ReturnType<typeof useGetTempImageLazyQuery>;
export type GetTempImageQueryResult = ApolloReactCommon.QueryResult<GetTempImageQuery, GetTempImageQueryVariables>;
export const GroupDocument = gql`
    query Group($id: ID!) {
  parish {
    id
  }
  group(id: $id) {
    ...FullGroupFragment
  }
  events(filters: {group: $id}, limit: 2) {
    ...EventsFragment
  }
  posts(filters: {group: $id}, limit: 2) {
    id
    pinned
    slug
    createdAt
    updatedAt
    title
    type
    likeCount
    excerpt
    source
    ast
    author {
      id
      fullName
    }
    plain
    group {
      id
      name
    }
    parish {
      id
      theme
      defaultDomain
      name
    }
    viewer {
      isAuthor
      likesPost
    }
    image {
      id
      colors
      src(w: 288, h: 224, crop: entropy, fit: crop, facepad: 10)
      lqip: src(w: 36, h: 28, blur: 20, crop: entropy, fit: crop, facepad: 10)
      title
    }
  }
}
    ${FullGroupFragmentFragmentDoc}
${EventsFragmentFragmentDoc}`;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = ApolloReactCommon.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupAdminsDocument = gql`
    query GroupAdmins($group: ID!) {
  users(filters: {role: {domain: group, id: $group, levels: [ADMIN, MODERATOR]}}) {
    id
    image {
      id
      src(w: 25, h: 25, crop: entropy, fit: crop, facepad: 10)
    }
    username
    firstName
    lastName
    fullName
  }
}
    `;

/**
 * __useGroupAdminsQuery__
 *
 * To run a query within a React component, call `useGroupAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAdminsQuery({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGroupAdminsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GroupAdminsQuery, GroupAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GroupAdminsQuery, GroupAdminsQueryVariables>(GroupAdminsDocument, options);
      }
export function useGroupAdminsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupAdminsQuery, GroupAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GroupAdminsQuery, GroupAdminsQueryVariables>(GroupAdminsDocument, options);
        }
export type GroupAdminsQueryHookResult = ReturnType<typeof useGroupAdminsQuery>;
export type GroupAdminsLazyQueryHookResult = ReturnType<typeof useGroupAdminsLazyQuery>;
export type GroupAdminsQueryResult = ApolloReactCommon.QueryResult<GroupAdminsQuery, GroupAdminsQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
  parish {
    id
  }
  groups {
    id
    policies {
      post {
        create
        read
        update
        delete
      }
      event {
        create
        read
        update
        delete
      }
    }
    cover {
      id
      src(w: 288, h: 144, crop: entropy, fit: crop)
      srcset(w: 288, h: 144, crop: entropy, fit: crop)
      lqip: src(w: 18, h: 9, blur: 20, crop: entropy, fit: crop)
      title
    }
    name
    status
    excerpt
    memberCount
    members {
      id
      fullName
      image {
        id
        src(w: 36, h: 36, crop: entropy, fit: crop)
      }
    }
    description
    createdAt
    parish {
      id
    }
    viewer {
      isMember
    }
    owner {
      id
      fullName
    }
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = ApolloReactCommon.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const HeaderDocument = gql`
    query Header {
  user {
    id
  }
  parish {
    id
    name
  }
}
    `;

/**
 * __useHeaderQuery__
 *
 * To run a query within a React component, call `useHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
      }
export function useHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
        }
export type HeaderQueryHookResult = ReturnType<typeof useHeaderQuery>;
export type HeaderLazyQueryHookResult = ReturnType<typeof useHeaderLazyQuery>;
export type HeaderQueryResult = ApolloReactCommon.QueryResult<HeaderQuery, HeaderQueryVariables>;
export const ImagePickerDocument = gql`
    query ImagePicker($filters: ImageFilter, $limit: Int, $cursor: ID) {
  images(filters: $filters, limit: $limit, cursor: $cursor) @connection(key: "Image") {
    id
    owner {
      id
    }
    src(h: 100, crop: focalpoint, fit: crop, facepad: 2)
    lqip: src(h: 10, blur: 20, crop: focalpoint, fit: crop, facepad: 2)
    title
  }
}
    `;

/**
 * __useImagePickerQuery__
 *
 * To run a query within a React component, call `useImagePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useImagePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImagePickerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useImagePickerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImagePickerQuery, ImagePickerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ImagePickerQuery, ImagePickerQueryVariables>(ImagePickerDocument, options);
      }
export function useImagePickerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImagePickerQuery, ImagePickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ImagePickerQuery, ImagePickerQueryVariables>(ImagePickerDocument, options);
        }
export type ImagePickerQueryHookResult = ReturnType<typeof useImagePickerQuery>;
export type ImagePickerLazyQueryHookResult = ReturnType<typeof useImagePickerLazyQuery>;
export type ImagePickerQueryResult = ApolloReactCommon.QueryResult<ImagePickerQuery, ImagePickerQueryVariables>;
export const JoinGroupDocument = gql`
    mutation JoinGroup($group: ID!) {
  addToGroup(group: $group) {
    id
    groups {
      id
    }
  }
}
    `;
export type JoinGroupMutationFn = ApolloReactCommon.MutationFunction<JoinGroupMutation, JoinGroupMutationVariables>;

/**
 * __useJoinGroupMutation__
 *
 * To run a mutation, you first call `useJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGroupMutation, { data, loading, error }] = useJoinGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useJoinGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinGroupMutation, JoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<JoinGroupMutation, JoinGroupMutationVariables>(JoinGroupDocument, options);
      }
export type JoinGroupMutationHookResult = ReturnType<typeof useJoinGroupMutation>;
export type JoinGroupMutationResult = ApolloReactCommon.MutationResult<JoinGroupMutation>;
export type JoinGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinGroupMutation, JoinGroupMutationVariables>;
export const UnJoinGroupDocument = gql`
    mutation UnJoinGroup($group: ID!) {
  removeFromGroup(group: $group) {
    id
    groups {
      id
    }
  }
}
    `;
export type UnJoinGroupMutationFn = ApolloReactCommon.MutationFunction<UnJoinGroupMutation, UnJoinGroupMutationVariables>;

/**
 * __useUnJoinGroupMutation__
 *
 * To run a mutation, you first call `useUnJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unJoinGroupMutation, { data, loading, error }] = useUnJoinGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useUnJoinGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnJoinGroupMutation, UnJoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnJoinGroupMutation, UnJoinGroupMutationVariables>(UnJoinGroupDocument, options);
      }
export type UnJoinGroupMutationHookResult = ReturnType<typeof useUnJoinGroupMutation>;
export type UnJoinGroupMutationResult = ApolloReactCommon.MutationResult<UnJoinGroupMutation>;
export type UnJoinGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UnJoinGroupMutation, UnJoinGroupMutationVariables>;
export const JoinParishDocument = gql`
    mutation JoinParish($parish: ID!) {
  addToParish(parish: $parish) {
    id
    parishes {
      id
    }
    roles {
      ...UserRoleFragment
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export type JoinParishMutationFn = ApolloReactCommon.MutationFunction<JoinParishMutation, JoinParishMutationVariables>;

/**
 * __useJoinParishMutation__
 *
 * To run a mutation, you first call `useJoinParishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinParishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinParishMutation, { data, loading, error }] = useJoinParishMutation({
 *   variables: {
 *      parish: // value for 'parish'
 *   },
 * });
 */
export function useJoinParishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinParishMutation, JoinParishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<JoinParishMutation, JoinParishMutationVariables>(JoinParishDocument, options);
      }
export type JoinParishMutationHookResult = ReturnType<typeof useJoinParishMutation>;
export type JoinParishMutationResult = ApolloReactCommon.MutationResult<JoinParishMutation>;
export type JoinParishMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinParishMutation, JoinParishMutationVariables>;
export const UnJoinParishDocument = gql`
    mutation UnJoinParish($parish: ID!) {
  removeFromParish(parish: $parish) {
    id
    parishes {
      id
    }
    roles {
      ...UserRoleFragment
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export type UnJoinParishMutationFn = ApolloReactCommon.MutationFunction<UnJoinParishMutation, UnJoinParishMutationVariables>;

/**
 * __useUnJoinParishMutation__
 *
 * To run a mutation, you first call `useUnJoinParishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnJoinParishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unJoinParishMutation, { data, loading, error }] = useUnJoinParishMutation({
 *   variables: {
 *      parish: // value for 'parish'
 *   },
 * });
 */
export function useUnJoinParishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnJoinParishMutation, UnJoinParishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnJoinParishMutation, UnJoinParishMutationVariables>(UnJoinParishDocument, options);
      }
export type UnJoinParishMutationHookResult = ReturnType<typeof useUnJoinParishMutation>;
export type UnJoinParishMutationResult = ApolloReactCommon.MutationResult<UnJoinParishMutation>;
export type UnJoinParishMutationOptions = ApolloReactCommon.BaseMutationOptions<UnJoinParishMutation, UnJoinParishMutationVariables>;
export const LikePostDocument = gql`
    mutation LikePost($id: ID!) {
  likePost(id: $id) {
    id
    likeCount
    viewer {
      isAuthor
      likesPost
    }
  }
}
    `;
export type LikePostMutationFn = ApolloReactCommon.MutationFunction<LikePostMutation, LikePostMutationVariables>;

/**
 * __useLikePostMutation__
 *
 * To run a mutation, you first call `useLikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePostMutation, { data, loading, error }] = useLikePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LikePostMutation, LikePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LikePostMutation, LikePostMutationVariables>(LikePostDocument, options);
      }
export type LikePostMutationHookResult = ReturnType<typeof useLikePostMutation>;
export type LikePostMutationResult = ApolloReactCommon.MutationResult<LikePostMutation>;
export type LikePostMutationOptions = ApolloReactCommon.BaseMutationOptions<LikePostMutation, LikePostMutationVariables>;
export const LiveEventDocument = gql`
    query LiveEvent {
  liveEvent {
    channelTitle
    description
    dtstart
    id
    image
    title
    type
  }
}
    `;

/**
 * __useLiveEventQuery__
 *
 * To run a query within a React component, call `useLiveEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LiveEventQuery, LiveEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LiveEventQuery, LiveEventQueryVariables>(LiveEventDocument, options);
      }
export function useLiveEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LiveEventQuery, LiveEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LiveEventQuery, LiveEventQueryVariables>(LiveEventDocument, options);
        }
export type LiveEventQueryHookResult = ReturnType<typeof useLiveEventQuery>;
export type LiveEventLazyQueryHookResult = ReturnType<typeof useLiveEventLazyQuery>;
export type LiveEventQueryResult = ApolloReactCommon.QueryResult<LiveEventQuery, LiveEventQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    id
    image {
      id
      src(w: 256, h: 256, fit: crop)
      srcset(w: 256, h: 256, fit: crop)
      lqip: src(w: 15, h: 15, fit: crop)
    }
    firstName
    fullName
    lastName
    username
    email
    primaryParish {
      id
      name
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MassesDocument = gql`
    query Masses($filters: Filter) {
  masses(filters: $filters) @connection(key: "Mass") {
    ...FullMassFragment
  }
}
    ${FullMassFragmentFragmentDoc}`;

/**
 * __useMassesQuery__
 *
 * To run a query within a React component, call `useMassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMassesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMassesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MassesQuery, MassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MassesQuery, MassesQueryVariables>(MassesDocument, options);
      }
export function useMassesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MassesQuery, MassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MassesQuery, MassesQueryVariables>(MassesDocument, options);
        }
export type MassesQueryHookResult = ReturnType<typeof useMassesQuery>;
export type MassesLazyQueryHookResult = ReturnType<typeof useMassesLazyQuery>;
export type MassesQueryResult = ApolloReactCommon.QueryResult<MassesQuery, MassesQueryVariables>;
export const NavDocument = gql`
    query Nav {
  sacraments {
    id
    type
  }
  user {
    id
    fullName
    firstName
    lastName
    image {
      id
      src(w: 200, h: 200, fit: crop, dpr: 2)
    }
    roles {
      ...UserRoleFragment
    }
    primaryParish {
      id
      name
    }
  }
  parish {
    id
    name
  }
  abouts {
    id
  }
}
    ${UserRoleFragmentFragmentDoc}`;

/**
 * __useNavQuery__
 *
 * To run a query within a React component, call `useNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NavQuery, NavQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NavQuery, NavQueryVariables>(NavDocument, options);
      }
export function useNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NavQuery, NavQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NavQuery, NavQueryVariables>(NavDocument, options);
        }
export type NavQueryHookResult = ReturnType<typeof useNavQuery>;
export type NavLazyQueryHookResult = ReturnType<typeof useNavLazyQuery>;
export type NavQueryResult = ApolloReactCommon.QueryResult<NavQuery, NavQueryVariables>;
export const OwnGroupsDocument = gql`
    query OwnGroups {
  user {
    id
    groups {
      id
      name
      memberCount
      cover {
        id
        src(w: 320, h: 320, crop: focalpoint)
        srcset(w: 320, h: 320, crop: focalpoint)
        lqip: src(w: 15, h: 15, crop: focalpoint, blur: 10)
      }
      policies {
        post {
          create
          update
        }
        event {
          create
          update
        }
      }
    }
  }
}
    `;

/**
 * __useOwnGroupsQuery__
 *
 * To run a query within a React component, call `useOwnGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OwnGroupsQuery, OwnGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OwnGroupsQuery, OwnGroupsQueryVariables>(OwnGroupsDocument, options);
      }
export function useOwnGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OwnGroupsQuery, OwnGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OwnGroupsQuery, OwnGroupsQueryVariables>(OwnGroupsDocument, options);
        }
export type OwnGroupsQueryHookResult = ReturnType<typeof useOwnGroupsQuery>;
export type OwnGroupsLazyQueryHookResult = ReturnType<typeof useOwnGroupsLazyQuery>;
export type OwnGroupsQueryResult = ApolloReactCommon.QueryResult<OwnGroupsQuery, OwnGroupsQueryVariables>;
export const ParishDocument = gql`
    query Parish {
  parish {
    ...ParishFragment
  }
}
    ${ParishFragmentFragmentDoc}`;

/**
 * __useParishQuery__
 *
 * To run a query within a React component, call `useParishQuery` and pass it any options that fit your needs.
 * When your component renders, `useParishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParishQuery({
 *   variables: {
 *   },
 * });
 */
export function useParishQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParishQuery, ParishQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ParishQuery, ParishQueryVariables>(ParishDocument, options);
      }
export function useParishLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParishQuery, ParishQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ParishQuery, ParishQueryVariables>(ParishDocument, options);
        }
export type ParishQueryHookResult = ReturnType<typeof useParishQuery>;
export type ParishLazyQueryHookResult = ReturnType<typeof useParishLazyQuery>;
export type ParishQueryResult = ApolloReactCommon.QueryResult<ParishQuery, ParishQueryVariables>;
export const PinEventDocument = gql`
    mutation PinEvent($id: ID!, $pinned: Boolean) {
  editEvent(id: $id, event: {pinned: $pinned}) {
    id
    pinned
  }
}
    `;
export type PinEventMutationFn = ApolloReactCommon.MutationFunction<PinEventMutation, PinEventMutationVariables>;

/**
 * __usePinEventMutation__
 *
 * To run a mutation, you first call `usePinEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinEventMutation, { data, loading, error }] = usePinEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pinned: // value for 'pinned'
 *   },
 * });
 */
export function usePinEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PinEventMutation, PinEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PinEventMutation, PinEventMutationVariables>(PinEventDocument, options);
      }
export type PinEventMutationHookResult = ReturnType<typeof usePinEventMutation>;
export type PinEventMutationResult = ApolloReactCommon.MutationResult<PinEventMutation>;
export type PinEventMutationOptions = ApolloReactCommon.BaseMutationOptions<PinEventMutation, PinEventMutationVariables>;
export const PinPostDocument = gql`
    mutation PinPost($id: ID!, $pinned: Boolean) {
  editPost(id: $id, post: {pinned: $pinned}) {
    id
    pinned
  }
}
    `;
export type PinPostMutationFn = ApolloReactCommon.MutationFunction<PinPostMutation, PinPostMutationVariables>;

/**
 * __usePinPostMutation__
 *
 * To run a mutation, you first call `usePinPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinPostMutation, { data, loading, error }] = usePinPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pinned: // value for 'pinned'
 *   },
 * });
 */
export function usePinPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PinPostMutation, PinPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PinPostMutation, PinPostMutationVariables>(PinPostDocument, options);
      }
export type PinPostMutationHookResult = ReturnType<typeof usePinPostMutation>;
export type PinPostMutationResult = ApolloReactCommon.MutationResult<PinPostMutation>;
export type PinPostMutationOptions = ApolloReactCommon.BaseMutationOptions<PinPostMutation, PinPostMutationVariables>;
export const PinnedDocument = gql`
    query Pinned {
  forms(filters: {pinned: true}, limit: 5) @connection(key: "PinnedForm") {
    ...FormsFragment
  }
  posts(filters: {pinned: true}, limit: 8) @connection(key: "PinnedPost") {
    id
    pinned
    status
    slug
    createdAt
    updatedAt
    title
    type
    likeCount
    excerpt
    author {
      id
      fullName
    }
    plain
    parish {
      id
      theme
      image {
        id
        path
        title
      }
      defaultDomain
      name
    }
    group {
      id
      name
    }
    viewer {
      isAuthor
      likesPost
    }
    image {
      id
      src(w: 144, h: 216, crop: entropy, fit: crop, facepad: 2, dpr: 2)
      srcset(w: 144, h: 216, crop: entropy, fit: crop, facepad: 2, dpr: 2)
      lqip: src(w: 10, h: 15, blur: 20, crop: entropy, fit: crop, facepad: 2)
      title
    }
  }
  events(filters: {pinned: true}, limit: 8) @connection(key: "PinnedEvent") {
    id
    pinned
    title
    dtstart
    duration
    excerpt
    privacy
    status
    rsvpCount
    rsvps
    createdAt
    group {
      id
      name
    }
    owner {
      id
    }
    viewer {
      isHost
      hasRSVP
    }
    parish {
      id
      name
      theme
    }
    imagePosition {
      y
    }
    image {
      ...EventModuleImage
    }
  }
}
    ${FormsFragmentFragmentDoc}
${EventModuleImageFragmentDoc}`;

/**
 * __usePinnedQuery__
 *
 * To run a query within a React component, call `usePinnedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePinnedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePinnedQuery({
 *   variables: {
 *   },
 * });
 */
export function usePinnedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PinnedQuery, PinnedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PinnedQuery, PinnedQueryVariables>(PinnedDocument, options);
      }
export function usePinnedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PinnedQuery, PinnedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PinnedQuery, PinnedQueryVariables>(PinnedDocument, options);
        }
export type PinnedQueryHookResult = ReturnType<typeof usePinnedQuery>;
export type PinnedLazyQueryHookResult = ReturnType<typeof usePinnedLazyQuery>;
export type PinnedQueryResult = ApolloReactCommon.QueryResult<PinnedQuery, PinnedQueryVariables>;
export const PostDocument = gql`
    query Post($id: ID!) {
  post(id: $id) {
    ...SinglePostFragment
  }
}
    ${SinglePostFragmentFragmentDoc}`;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PostQuery, PostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PostQuery, PostQueryVariables>(PostDocument, options);
      }
export function usePostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, options);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = ApolloReactCommon.QueryResult<PostQuery, PostQueryVariables>;
export const PostsDocument = gql`
    query Posts($filters: Filter, $limit: Int, $cursor: ID) {
  parish {
    id
  }
  posts(filters: $filters, limit: $limit, cursor: $cursor) @connection(key: "Post", filter: ["filters"]) {
    ...PostModuleFragment
  }
}
    ${PostModuleFragmentFragmentDoc}`;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
      }
export function usePostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = ApolloReactCommon.QueryResult<PostsQuery, PostsQueryVariables>;
export const ReadingsDocument = gql`
    query Readings($date: Date) {
  readings(date: $date) {
    firstReading {
      title
      passage
      body
    }
    secondReading {
      title
      passage
      body
    }
    psalm {
      title
      passage
      body
    }
    gospel {
      title
      passage
      body
    }
  }
}
    `;

/**
 * __useReadingsQuery__
 *
 * To run a query within a React component, call `useReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadingsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useReadingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReadingsQuery, ReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReadingsQuery, ReadingsQueryVariables>(ReadingsDocument, options);
      }
export function useReadingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReadingsQuery, ReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReadingsQuery, ReadingsQueryVariables>(ReadingsDocument, options);
        }
export type ReadingsQueryHookResult = ReturnType<typeof useReadingsQuery>;
export type ReadingsLazyQueryHookResult = ReturnType<typeof useReadingsLazyQuery>;
export type ReadingsQueryResult = ApolloReactCommon.QueryResult<ReadingsQuery, ReadingsQueryVariables>;
export const RemoveDonationMethodDocument = gql`
    mutation RemoveDonationMethod($id: ID!) {
  removeDonationMethod(id: $id) {
    ...DonationMethodFragment
  }
}
    ${DonationMethodFragmentFragmentDoc}`;
export type RemoveDonationMethodMutationFn = ApolloReactCommon.MutationFunction<RemoveDonationMethodMutation, RemoveDonationMethodMutationVariables>;

/**
 * __useRemoveDonationMethodMutation__
 *
 * To run a mutation, you first call `useRemoveDonationMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDonationMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDonationMethodMutation, { data, loading, error }] = useRemoveDonationMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDonationMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDonationMethodMutation, RemoveDonationMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveDonationMethodMutation, RemoveDonationMethodMutationVariables>(RemoveDonationMethodDocument, options);
      }
export type RemoveDonationMethodMutationHookResult = ReturnType<typeof useRemoveDonationMethodMutation>;
export type RemoveDonationMethodMutationResult = ApolloReactCommon.MutationResult<RemoveDonationMethodMutation>;
export type RemoveDonationMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDonationMethodMutation, RemoveDonationMethodMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($passwordResetCode: String!, $password: String!) {
  resetPassword(passwordResetCode: $passwordResetCode, password: $password)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      passwordResetCode: // value for 'passwordResetCode'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendPasswordResetDocument = gql`
    mutation SendPasswordReset($email: String!) {
  sendPasswordReset(email: $email)
}
    `;
export type SendPasswordResetMutationFn = ApolloReactCommon.MutationFunction<SendPasswordResetMutation, SendPasswordResetMutationVariables>;

/**
 * __useSendPasswordResetMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetMutation, { data, loading, error }] = useSendPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendPasswordResetMutation, SendPasswordResetMutationVariables>(SendPasswordResetDocument, options);
      }
export type SendPasswordResetMutationHookResult = ReturnType<typeof useSendPasswordResetMutation>;
export type SendPasswordResetMutationResult = ApolloReactCommon.MutationResult<SendPasswordResetMutation>;
export type SendPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>;
export const RevertTempImageDocument = gql`
    mutation RevertTempImage($id: ID!) {
  revertImage(id: $id)
}
    `;
export type RevertTempImageMutationFn = ApolloReactCommon.MutationFunction<RevertTempImageMutation, RevertTempImageMutationVariables>;

/**
 * __useRevertTempImageMutation__
 *
 * To run a mutation, you first call `useRevertTempImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertTempImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertTempImageMutation, { data, loading, error }] = useRevertTempImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevertTempImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevertTempImageMutation, RevertTempImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RevertTempImageMutation, RevertTempImageMutationVariables>(RevertTempImageDocument, options);
      }
export type RevertTempImageMutationHookResult = ReturnType<typeof useRevertTempImageMutation>;
export type RevertTempImageMutationResult = ApolloReactCommon.MutationResult<RevertTempImageMutation>;
export type RevertTempImageMutationOptions = ApolloReactCommon.BaseMutationOptions<RevertTempImageMutation, RevertTempImageMutationVariables>;
export const SacramentDocument = gql`
    query Sacrament($id: ID!) {
  sacrament(id: $id) {
    id
    title
    description
    type
  }
}
    `;

/**
 * __useSacramentQuery__
 *
 * To run a query within a React component, call `useSacramentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSacramentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSacramentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSacramentQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SacramentQuery, SacramentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SacramentQuery, SacramentQueryVariables>(SacramentDocument, options);
      }
export function useSacramentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SacramentQuery, SacramentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SacramentQuery, SacramentQueryVariables>(SacramentDocument, options);
        }
export type SacramentQueryHookResult = ReturnType<typeof useSacramentQuery>;
export type SacramentLazyQueryHookResult = ReturnType<typeof useSacramentLazyQuery>;
export type SacramentQueryResult = ApolloReactCommon.QueryResult<SacramentQuery, SacramentQueryVariables>;
export const SacramentsDocument = gql`
    query Sacraments {
  parish {
    id
    name
    image {
      id
      src(w: 990, h: 330, crop: focalpoint, fit: facearea, facepad: 2)
      srcset(w: 990, h: 330, crop: focalpoint, fit: facearea, facepad: 2)
      lqip: src(w: 60, h: 20, crop: focalpoint, fit: facearea, facepad: 2, blur: 15)
    }
    timezone
  }
  sacraments {
    id
    title
    description
    type
    parish {
      id
    }
  }
}
    `;

/**
 * __useSacramentsQuery__
 *
 * To run a query within a React component, call `useSacramentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSacramentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSacramentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSacramentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SacramentsQuery, SacramentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SacramentsQuery, SacramentsQueryVariables>(SacramentsDocument, options);
      }
export function useSacramentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SacramentsQuery, SacramentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SacramentsQuery, SacramentsQueryVariables>(SacramentsDocument, options);
        }
export type SacramentsQueryHookResult = ReturnType<typeof useSacramentsQuery>;
export type SacramentsLazyQueryHookResult = ReturnType<typeof useSacramentsLazyQuery>;
export type SacramentsQueryResult = ApolloReactCommon.QueryResult<SacramentsQuery, SacramentsQueryVariables>;
export const SearchDocument = gql`
    query Search($text: String!, $limit: Int) {
  search(text: $text, limit: $limit) {
    ... on Post {
      ...PostModuleFragment
    }
    ... on Event {
      ...EventsFragment
    }
  }
}
    ${PostModuleFragmentFragmentDoc}
${EventsFragmentFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      text: // value for 'text'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($text: String!, $filters: UserFilter) {
  searchUsers(text: $text, limit: 8, filters: $filters) {
    id
    fullName
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      text: // value for 'text'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const SeasonsDocument = gql`
    query Seasons {
  seasons {
    from
    id
    name
    until
  }
}
    `;

/**
 * __useSeasonsQuery__
 *
 * To run a query within a React component, call `useSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeasonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeasonsQuery, SeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
      }
export function useSeasonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeasonsQuery, SeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
        }
export type SeasonsQueryHookResult = ReturnType<typeof useSeasonsQuery>;
export type SeasonsLazyQueryHookResult = ReturnType<typeof useSeasonsLazyQuery>;
export type SeasonsQueryResult = ApolloReactCommon.QueryResult<SeasonsQuery, SeasonsQueryVariables>;
export const SubmitFormDocument = gql`
    mutation SubmitForm($id: ID!, $data: String!) {
  submitForm(id: $id, data: $data) {
    id
  }
}
    `;
export type SubmitFormMutationFn = ApolloReactCommon.MutationFunction<SubmitFormMutation, SubmitFormMutationVariables>;

/**
 * __useSubmitFormMutation__
 *
 * To run a mutation, you first call `useSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFormMutation, { data, loading, error }] = useSubmitFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitFormMutation, SubmitFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubmitFormMutation, SubmitFormMutationVariables>(SubmitFormDocument, options);
      }
export type SubmitFormMutationHookResult = ReturnType<typeof useSubmitFormMutation>;
export type SubmitFormMutationResult = ApolloReactCommon.MutationResult<SubmitFormMutation>;
export type SubmitFormMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitFormMutation, SubmitFormMutationVariables>;
export const UnlikePostDocument = gql`
    mutation UnlikePost($id: ID!) {
  unlikePost(id: $id) {
    id
    likeCount
    viewer {
      isAuthor
      likesPost
    }
  }
}
    `;
export type UnlikePostMutationFn = ApolloReactCommon.MutationFunction<UnlikePostMutation, UnlikePostMutationVariables>;

/**
 * __useUnlikePostMutation__
 *
 * To run a mutation, you first call `useUnlikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikePostMutation, { data, loading, error }] = useUnlikePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlikePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnlikePostMutation, UnlikePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnlikePostMutation, UnlikePostMutationVariables>(UnlikePostDocument, options);
      }
export type UnlikePostMutationHookResult = ReturnType<typeof useUnlikePostMutation>;
export type UnlikePostMutationResult = ApolloReactCommon.MutationResult<UnlikePostMutation>;
export type UnlikePostMutationOptions = ApolloReactCommon.BaseMutationOptions<UnlikePostMutation, UnlikePostMutationVariables>;
export const UpdateMassDocument = gql`
    mutation UpdateMass($id: ID!, $mass: MassInput!) {
  updateMass(id: $id, mass: $mass) {
    ...FullMassFragment
  }
}
    ${FullMassFragmentFragmentDoc}`;
export type UpdateMassMutationFn = ApolloReactCommon.MutationFunction<UpdateMassMutation, UpdateMassMutationVariables>;

/**
 * __useUpdateMassMutation__
 *
 * To run a mutation, you first call `useUpdateMassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMassMutation, { data, loading, error }] = useUpdateMassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mass: // value for 'mass'
 *   },
 * });
 */
export function useUpdateMassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMassMutation, UpdateMassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMassMutation, UpdateMassMutationVariables>(UpdateMassDocument, options);
      }
export type UpdateMassMutationHookResult = ReturnType<typeof useUpdateMassMutation>;
export type UpdateMassMutationResult = ApolloReactCommon.MutationResult<UpdateMassMutation>;
export type UpdateMassMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMassMutation, UpdateMassMutationVariables>;
export const UpdateSeasonDocument = gql`
    mutation UpdateSeason($id: ID!, $season: SeasonInput!) {
  updateSeason(id: $id, season: $season) {
    id
    from
    until
    name
  }
}
    `;
export type UpdateSeasonMutationFn = ApolloReactCommon.MutationFunction<UpdateSeasonMutation, UpdateSeasonMutationVariables>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      season: // value for 'season'
 *   },
 * });
 */
export function useUpdateSeasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(UpdateSeasonDocument, options);
      }
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = ApolloReactCommon.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>;
export const UploadEditorImageDocument = gql`
    mutation UploadEditorImage($image: Upload!) {
  uploadImage(image: $image, options: {temporary: false}) {
    id
    src(w: 990)
    path
  }
}
    `;
export type UploadEditorImageMutationFn = ApolloReactCommon.MutationFunction<UploadEditorImageMutation, UploadEditorImageMutationVariables>;

/**
 * __useUploadEditorImageMutation__
 *
 * To run a mutation, you first call `useUploadEditorImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEditorImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEditorImageMutation, { data, loading, error }] = useUploadEditorImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadEditorImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadEditorImageMutation, UploadEditorImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UploadEditorImageMutation, UploadEditorImageMutationVariables>(UploadEditorImageDocument, options);
      }
export type UploadEditorImageMutationHookResult = ReturnType<typeof useUploadEditorImageMutation>;
export type UploadEditorImageMutationResult = ApolloReactCommon.MutationResult<UploadEditorImageMutation>;
export type UploadEditorImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadEditorImageMutation, UploadEditorImageMutationVariables>;
export const UploadTempImageDocument = gql`
    mutation UploadTempImage($image: Upload!) {
  uploadImage(image: $image, options: {temporary: true}) {
    id
    path
  }
}
    `;
export type UploadTempImageMutationFn = ApolloReactCommon.MutationFunction<UploadTempImageMutation, UploadTempImageMutationVariables>;

/**
 * __useUploadTempImageMutation__
 *
 * To run a mutation, you first call `useUploadTempImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTempImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTempImageMutation, { data, loading, error }] = useUploadTempImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadTempImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadTempImageMutation, UploadTempImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UploadTempImageMutation, UploadTempImageMutationVariables>(UploadTempImageDocument, options);
      }
export type UploadTempImageMutationHookResult = ReturnType<typeof useUploadTempImageMutation>;
export type UploadTempImageMutationResult = ApolloReactCommon.MutationResult<UploadTempImageMutation>;
export type UploadTempImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadTempImageMutation, UploadTempImageMutationVariables>;
export const UserDocument = gql`
    query User($id: ID) {
  user(id: $id) {
    ...UserFragment
  }
  posts(filters: {user: $id}, limit: 4) {
    ...PostModuleFragment
  }
  parish {
    id
    name
  }
}
    ${UserFragmentFragmentDoc}
${PostModuleFragmentFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UserByCodeDocument = gql`
    query UserByCode($code: String) {
  user(passwordResetCode: $code) {
    email
    firstName
  }
}
    `;

/**
 * __useUserByCodeQuery__
 *
 * To run a query within a React component, call `useUserByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUserByCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserByCodeQuery, UserByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserByCodeQuery, UserByCodeQueryVariables>(UserByCodeDocument, options);
      }
export function useUserByCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserByCodeQuery, UserByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserByCodeQuery, UserByCodeQueryVariables>(UserByCodeDocument, options);
        }
export type UserByCodeQueryHookResult = ReturnType<typeof useUserByCodeQuery>;
export type UserByCodeLazyQueryHookResult = ReturnType<typeof useUserByCodeLazyQuery>;
export type UserByCodeQueryResult = ApolloReactCommon.QueryResult<UserByCodeQuery, UserByCodeQueryVariables>;
export const UsersDocument = gql`
    query Users($filters: UserFilter) {
  users(filters: $filters, sort: ASC) {
    id
    fullName
    roles {
      ...UserRoleFragment
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;