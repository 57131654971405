import Box from '@parishconnect/box'
import {
  Button,
  Card,
  ChevronRightIcon,
  Heading,
  majorScale,
  minorScale,
  Text,
  UserPlusIcon,
} from '@parishconnect/react-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  useOwnGroupsQuery,
  useParishQuery,
  usePostsQuery,
} from '../../graphql/generated/graphql-hooks'
import { cssLineClamp } from '../../utils'
import { PostModule } from '../Post'

export function GroupsRecentPosts() {
  const { data: { posts = [] } = {}, loading } = usePostsQuery({
    variables: { filters: { group: 'own' } },
  })
  const gutter = majorScale(2)
  const width = majorScale(24)

  if (!posts || posts?.length <= 0 || loading) return null

  return (
    <Box
      width="100%"
      position="relative"
      display="grid"
      gridGap={`${gutter}px 0`}
      gridTemplateColumns={`${gutter}px 1fr ${gutter}px`}
      alignContent="start"
    >
      <Box display="flex" gridColumn="2 / -2">
        <Heading gridColumn="2 / -2" size={700}>
          From your Groups
        </Heading>
        <Button
          is={Link}
          to="/groups"
          marginLeft={['auto', majorScale(2)]}
          iconAfter={ChevronRightIcon}
        >
          All Groups
        </Button>
      </Box>
      <Box
        role="list"
        display="grid"
        overflowX="scroll"
        gridTemplateColumns={`8px repeat(${posts?.length + 1}, 1fr) 8px`}
        gridColumn="1 / -1"
        gridTemplateRows={`minmax(${width}px, 1fr)`}
        gridGap={majorScale(1)}
        paddingY={majorScale(1)}
        css={{
          '&:before, &:after': {
            content: '""',
          },
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          overflowX: 'scroll',
          '@media(pointer: fine)': {
            scrollSnapType: 'x mandatory',
            scrollPaddingLeft: gutter,
          },
        }}
      >
        {posts.map((post) => (
          <PostModule key={post.id} {...post} />
        ))}
      </Box>
    </Box>
  )
}

function JoinAGroup() {
  const { data: { parish } = {} } = useParishQuery()

  return (
    <Card
      role="listitem"
      is={Link}
      to={`group/new`}
      height="0"
      paddingBottom="50%"
      border
      hoverElevation={2}
      position="relative"
      appearance="solid"
      css={{
        transition: 'box-shadow 125ms',
        scrollSnapAlign: 'start',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        padding={majorScale(1)}
        position="absolute"
        height="100%"
        width="100%"
        zIndex={3}
      >
        <Box display="flex">
          <UserPlusIcon marginLeft="auto" />
        </Box>
        <Box marginTop="auto" padding={minorScale(1)}>
          <Heading color="theme" size={500} css={cssLineClamp()}>
            {`You're not a part of any groups at ${parish.name}`}
          </Heading>
          <Text>Click here to see available groups and join one.</Text>
        </Box>
      </Box>
    </Card>
  )
}
