import Box from '@parishconnect/box'
import { Button, Card, majorScale, PlusIcon, ThemeContext } from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { SacramentType, useNavQuery } from '../../graphql/generated/graphql-hooks'
import { getRoleForParish } from '../../utils'
import { COLUMN } from '../../utils/constants'
import UserControls from '../User/UserControls'
import { MobileNav } from './MobileNav'
import { MassModule } from '../Mass'
import NavMenu from './NavMenu'

export type NavProps = {
  menuOpen?: boolean
  showMasses?: boolean
  title?: string
  root?: string
  newItem?: { onClick: () => void; title: string; isShown: boolean }
  subMenu?: {
    id: string
    exact?: boolean
    title: string
    to: string
    type?: string
  }[]
}

export default function Nav({
  menuOpen = false,
  showMasses = false,
  subMenu,
  title,
  root,
  newItem,
}: NavProps) {
  const { data, loading } = useNavQuery()

  const sacraments = data?.sacraments ?? []
  const firstSacrament = sacraments.find((s) => s.type === SacramentType.Mass) ?? sacraments[0]
  const user = data?.user
  const parish = data?.parish

  const MemoNavMasses = () => (
    <MassModule massPageId={sacraments.find((s) => s.type === SacramentType.Mass)?.id} />
  )

  return (
    <>
      <Card
        minWidth={['100vw', COLUMN * 6]}
        paddingX={[majorScale(2), majorScale(3)]}
        paddingY={majorScale(2)}
        paddingBottom={[0, majorScale(2)]}
        appearance="gradient"
        display={['none', null, 'block']}
        zIndex={20}
        css={{ position: 'sticky' }}
      >
        {user && !loading && <UserControls role={getRoleForParish(user, parish)} user={user} />}
        {showMasses && <MemoNavMasses />}
        <NavMenu
          firstAbout={data?.abouts?.[0]?.id}
          firstSacrament={firstSacrament?.id}
          open={menuOpen}
          subMenu={subMenu}
          title={title}
          root={root}
        />
        {newItem && <NewItemButton newItem={newItem} />}
      </Card>
      <Box display={['block', null, 'none']}>
        <MobileNav
          firstAbout={data?.abouts?.[0]?.id}
          firstSacrament={firstSacrament?.id}
          showMasses={showMasses}
          subMenu={subMenu}
          root={root}
          title={title}
        />
      </Box>
    </>
  )
}

type NewItemButtonProps = {
  newItem: NavProps['newItem']
}
function NewItemButton({ newItem }: NewItemButtonProps) {
  const theme = useContext(ThemeContext)

  if (!newItem.isShown) return null

  return (
    <Box display="flex" width="100%" justifyContent="flex-end">
      <Button
        onClick={newItem?.onClick}
        iconBefore={PlusIcon}
        borderRadius={[14, 28]}
        background={[theme.palette[theme.themeColor].light, theme.palette[theme.themeColor].dark]}
        marginTop={majorScale(4)}
        height={majorScale(5)}
        css={{ color: 'white' }}
      >
        {newItem.title}
      </Button>
    </Box>
  )
}
