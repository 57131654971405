import Box from '@parishconnect/box'
import {
  Button,
  ClockIcon,
  Dialog,
  Heading,
  majorScale,
  Pane,
  Spinner,
  Card,
} from '@parishconnect/react-ui'
import produce from 'immer'
import { isEmpty } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { Include, MassesQueryResult } from '../../graphql/generated/graphql-hooks'
import { COLUMN, COLUMNS } from '../../utils/constants'
import { RecurringMassListing, SpecialMassListings } from './MassManagementListings'
import { NewMass } from './NewMass'
import { getFerialMasses, getSpecialMasses, getSundayMasses, hydrateMasses } from './utils'

export function MassManagementDialog({
  fetchMore,
  data,
  loading,
}: Pick<MassesQueryResult, 'fetchMore' | 'data' | 'loading'>) {
  const [isShown, setShown] = useState(false)

  useEffect(() => {
    if (isShown === true) {
      fetchMore({
        variables: { filters: { include: Include.All } },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return produce(prev, (draft) => {
            const prevMasses = draft.masses
            for (const mass of fetchMoreResult.masses) {
              if (!prevMasses.find((prevMass) => prevMass.id === mass.id)) {
                draft.masses.push(mass)
              }
            }
          })
        },
      })
    }
  }, [isShown])

  const ManageButton = () => (
    <Button
      display="flex"
      marginRight="auto"
      appearance="primary"
      onClick={() => setShown(true)}
      iconBefore={ClockIcon}
      marginBottom={majorScale(2)}
    >
      Manage Schedule
    </Button>
  )
  const masses = useMemo(() => hydrateMasses(data?.masses, false), [data])
  const specialMasses = getSpecialMasses(masses)
  const sundayMasses = getSundayMasses(masses)
  const ferialMasses = getFerialMasses(masses)

  if (!isShown) return <ManageButton />

  return (
    <Box>
      <ManageButton />
      <Dialog
        shouldCloseOnEscapePress={false}
        preventBodyScrolling
        width={(COLUMNS - 1) * COLUMN}
        confirmLabel="Done"
        hasCancel={false}
        onCloseComplete={() => setShown(false)}
        isShown={isShown}
        hasHeader={false}
        containerProps={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
        contentContainerProps={{
          padding: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          background: 'tint1',
        }}
      >
        <Box position="relative">
          <Pane
            appearance="white"
            paddingY={majorScale(2)}
            paddingX={majorScale(2)}
            display="fixed"
            position="sticky"
            zIndex={999}
            top={0}
            left={0}
            borderBottom
          >
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Heading size={600}>Mass Management</Heading>
              <NewMass />
            </Box>
          </Pane>
          {isEmpty(masses) ? (
            <Card
              appearance="solid"
              textAlign="center"
              maxWidth={majorScale(40)}
              padding={majorScale(4)}
              marginY={majorScale(8)}
              marginX="auto"
              borderRadius={20}
            >
              <Heading marginBottom={majorScale(3)}>Start by adding your first mass</Heading>
              <NewMass />
            </Card>
          ) : (
            <Box paddingY={majorScale(3)} paddingX={majorScale(4)}>
              {loading ? (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner />
                </Box>
              ) : (
                <>
                  {!isEmpty(specialMasses) && (
                    <>
                      <Heading size={700} marginBottom={majorScale(3)}>
                        Single Masses
                      </Heading>
                      <SpecialMassListings masses={specialMasses} />
                    </>
                  )}
                  {(ferialMasses.length > 0 || sundayMasses.length) > 0 && (
                    <>
                      <Heading size={700} marginBottom={majorScale(2)}>
                        Recurring Masses
                      </Heading>
                      <RecurringMassListing heading="Sunday" masses={sundayMasses} />
                      <RecurringMassListing heading="Ferial" masses={ferialMasses} />
                    </>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </Dialog>
    </Box>
  )
}
