import Box from '@parishconnect/box'
import { Card, Heading, majorScale, Strong } from '@parishconnect/react-ui'
import React from 'react'
import ProgressiveImage from 'react-progressive-image'
import { Parish, useParishQuery, useUserQuery } from '../../graphql/generated/graphql-hooks'
import { Seo } from '../seo'

export function Hero() {
  const { data: { parish } = {} } = useParishQuery()
  const { data: { user } = {} } = useUserQuery()

  function getAddressText(parish: Parish) {
    const city = parish?.address?.city
    const state = parish?.address?.state

    return `${city ? city + ', ' : ''}${state ? state : ''}`
  }

  return (
    <Box
      position="relative"
      width="100%"
      role="complementary"
      aria-label={`${parish?.name} Parish`}
    >
      <Seo
        key={parish?.id}
        title={`${parish?.name} Parish`}
        facebookUsername={parish?.facebook?.username}
        twitterUsername={parish?.twitter?.username}
        pathname="/"
        type="website"
        image={parish?.image?.src}
        parish={parish}
      />
      <Box
        position="relative"
        height={0}
        paddingBottom="calc(100% / 3)"
        borderRadius={8}
        width="100%"
        overflow="hidden"
      >
        {parish?.image?.src ? (
          <ProgressiveImage
            src={parish?.image?.src}
            placeholder={parish?.image?.lqip}
            srcSetData={{ srcSet: parish?.image?.srcset }}
          >
            {(src: string, loading: boolean, srcSetData: any) => (
              <Card
                role="presentation"
                alt=""
                is="img"
                width="100%"
                srcSet={srcSetData.srcSet}
                src={src}
                css={{
                  objectFit: 'cover',
                }}
              />
            )}
          </ProgressiveImage>
        ) : (
          <Card
            appearance="solid"
            height={[majorScale(16), majorScale(26), null, majorScale(38)]}
            width="100%"
          />
        )}
      </Box>
      <Card
        bottom={majorScale(2)}
        left={majorScale(2)}
        position={['static', 'absolute']}
        marginTop={[majorScale(1), 0]}
        paddingX={majorScale(3)}
        paddingY={majorScale(2)}
        borderRadius={14}
        appearance="solid"
      >
        <Heading size={700} color="theme" is="h1">
          {parish?.name}
        </Heading>
        <Strong size={300}>{getAddressText(parish)}</Strong>
      </Card>
    </Box>
  )
}
