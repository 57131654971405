import Box from '@parishconnect/box'
import {
  Button,
  Card,
  CornerUpLeftIcon,
  Heading,
  majorScale,
  SaveIcon,
  SelectField,
  toaster,
} from '@parishconnect/react-ui'
import { EMPTY_PARAGRAPH_NODE } from '@remirror/core'
import * as Sentry from '@sentry/browser'
import JSONparse from 'easy-json-parse'
import { Field, Form, Formik } from 'formik'
import { capitalize, debounce, truncate } from 'lodash-es'
import React, { FormEvent } from 'react'
import { Helmet } from 'react-helmet-async'
import ProgressiveImage from 'react-progressive-image'
import { RouteComponentProps } from 'react-router-dom'
import useRouter from 'use-react-router'
import {
  SacramentsDocument,
  SacramentsQuery,
  SacramentType,
  useAddSacramentMutation,
} from '../../graphql/generated/graphql-hooks'
import { Editor } from '../Editor'
import { object, string } from 'yup'

export const specialSacramentTypes = [SacramentType.Mass]

export const newSacramentValidationSchema = object().shape({
  type: string().oneOf(Object.values(SacramentType)).required(),
  description: string().when('type', {
    is: (value) => specialSacramentTypes.includes(value),
    then: string(),
    otherwise: string().required(),
  }),
})

type NewSacramentProps = SacramentsQuery &
  RouteComponentProps<{ id: string }> & {
    availableSacramentTypes: SacramentType[]
  }

export function NewSacrament({ parish, availableSacramentTypes }: NewSacramentProps) {
  const [addSacrament] = useAddSacramentMutation()
  const { history } = useRouter()

  return (
    <Formik
      enableReinitialize
      validationSchema={newSacramentValidationSchema}
      initialValues={{
        description: '',
        title: capitalize(availableSacramentTypes[0]).replace(/_/g, ' '),
        type: availableSacramentTypes[0],
      }}
      onSubmit={async (sacrament, actions) => {
        try {
          sacrament.title = capitalize(sacrament.type).replace(/_/g, ' ')
          await addSacrament({
            variables: { sacrament },
            update: (cache, { data: { addSacrament } }) => {
              try {
                const { sacraments = [], ...rest } = cache.readQuery<SacramentsQuery>({
                  query: SacramentsDocument,
                })
                if (sacraments) {
                  cache.writeQuery({
                    query: SacramentsDocument,
                    data: {
                      ...rest,
                      sacraments: [addSacrament].concat(sacraments),
                    },
                  })
                }

                history.push(`/sacraments/${addSacrament.id}`)
              } catch (error) {
                history.push(`/sacraments`)
                Sentry.captureException(error)
              }
              toaster.success('Success!', { description: `"${sacrament.title}" saved.` })
            },
          })
          actions.setSubmitting(false)
        } catch (error) {
          toaster.danger('Error.', { description: `"${sacrament.title}" failed to save.` })
          Sentry.captureException(error)
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Box>
          <Box position="relative">
            <Helmet>
              <title>{values?.title ?? 'New Sacrament'}</title>
            </Helmet>
            {parish?.image?.src ? (
              <ProgressiveImage src={parish?.image?.src} placeholder={parish?.image?.lqip}>
                {(src: string) => (
                  <Card
                    width="100%"
                    css={{ objectFit: 'cover' }}
                    height={[majorScale(16), null, majorScale(26), majorScale(38)]}
                    is="img"
                    srcSet={parish?.image?.srcset}
                    src={src}
                  />
                )}
              </ProgressiveImage>
            ) : (
              <Card appearance="solid" height={majorScale(38)} width="100%" />
            )}
            <Card
              appearance="solid"
              paddingX={majorScale(3)}
              paddingY={majorScale(2)}
              position={['static', 'absolute']}
              marginTop={[majorScale(2), 0]}
              borderRadius={14}
              bottom={majorScale(2)}
              left={majorScale(2)}
            >
              <Heading color="theme" size={800}>
                {truncate(values?.title, { length: 28 }) ?? 'New About'}
              </Heading>
              <Heading color="theme" size={100}>
                {parish?.name}
              </Heading>
            </Card>
          </Box>
          <Box position="relative" paddingX={majorScale(3)} marginTop={majorScale(3)}>
            <Form>
              <Field
                as={SelectField}
                label="Sacrament Type"
                placeholder="Select a Sacrament Type"
                name="type"
                inputWidth={majorScale(38)}
                onChange={(e: FormEvent<HTMLSelectElement>) => {
                  setFieldValue('title', capitalize(e.currentTarget.value).replace(/_/g, ' '))
                  setFieldValue('type', e.currentTarget.value)
                }}
              >
                {availableSacramentTypes.map((type) => {
                  const title = capitalize(type).replace(/_/g, ' ')
                  return (
                    <option key={type} value={type} title={title}>
                      {title}
                    </option>
                  )
                })}
              </Field>
              <Editor
                initialContent={
                  JSONparse(values.description, { initialValue: EMPTY_PARAGRAPH_NODE })[1]
                }
                placeholder={
                  specialSacramentTypes.includes(values.type)
                    ? `Write something (optional)...`
                    : 'Write something...'
                }
                onChange={debounce(
                  (value: any) =>
                    setFieldValue('description', JSON.stringify(value.getObjectNode())),
                  350,
                )}
              />
              <Box position="absolute" right={0}>
                <Button
                  intent="danger"
                  onClick={() => history.goBack()}
                  type="button"
                  iconBefore={CornerUpLeftIcon}
                >
                  Cancel
                </Button>
                <Button
                  marginLeft={majorScale(1)}
                  intent="success"
                  type="submit"
                  appearance="primary"
                  iconBefore={SaveIcon}
                >
                  Save
                </Button>
              </Box>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
