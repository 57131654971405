import { UserQuery } from '../../graphql/generated/graphql-hooks'
import { Heading, majorScale, AwardIcon } from '@parishconnect/react-ui'
import React from 'react'
import Box from '@parishconnect/box'
import { PostModule } from '../Post'
import { EmptyState } from '../shared/EmptyState'

export function Profile({ posts }: UserQuery) {
  return posts.length > 0 ? (
    <>
      <Heading size={700} marginTop={[majorScale(4), 0]} marginBottom={majorScale(2)}>
        Recent Posts
      </Heading>
      <Box
        display="grid"
        gridGap={majorScale(3)}
        gridTemplateColumns={['1fr', `repeat(auto-fill, ${majorScale(38)}px)`]}
      >
        {posts?.map(post => (
          <PostModule key={post?.id} {...post} />
        ))}
      </Box>
    </>
  ) : (
    <EmptyState icon={AwardIcon} title="Nothing to Show" />
  )
}
