import { random } from 'lodash-es'

interface saintObject {
  firstName: string
  lastName: string
}

export const saintPlaceholder = (): saintObject => {
  return SAINTS[random(SAINTS.length, false)]
}

const SAINTS = [
  { firstName: 'Jude', lastName: 'Thaddaeus' },
  { firstName: 'Maria', lastName: 'Goretti' },
  { firstName: 'Elizabeth', lastName: 'Ann Seton' },
  { firstName: 'Kateri', lastName: 'Tekakwitha' },
  { firstName: 'Maximilian', lastName: 'Kolbe' },
  { firstName: 'Thomas', lastName: 'Aquinas' },
  { firstName: 'Faustina', lastName: 'Kowalska' },
  { firstName: 'Martin', lastName: 'de-Porres' },
  { firstName: 'Gerard', lastName: 'Majella' },
  { firstName: 'Katharine', lastName: 'Drexel' },
  { firstName: 'Gianna', lastName: 'Beretta-Molla' },
  { firstName: 'Ignatius', lastName: 'Loyola' },
  { firstName: 'Mary', lastName: 'Magdalene' },
  { firstName: 'John', lastName: 'Bosco' },
  { firstName: 'Thomas', lastName: 'More' },
  { firstName: 'Juan', lastName: 'Diego' },
  { firstName: 'Josephine', lastName: 'Bakhita' },
  { firstName: 'Angela', lastName: 'Merici' },
  { firstName: 'Francis', lastName: 'Xavier' },
  { firstName: 'Gemma', lastName: 'Galgani' },
  { firstName: 'Lorenzo', lastName: 'Ruiz' },
  { firstName: 'John', lastName: 'Vianney' },
  { firstName: 'Philip', lastName: 'Neri' },
  { firstName: 'Dominic', lastName: 'Savio' },
  { firstName: 'Charles', lastName: 'Borromeo' },
  { firstName: 'Mary', lastName: 'MacKillop' },
  { firstName: 'Catherine', lastName: 'Laboure' },
  { firstName: 'John', lastName: 'Neumann' },
  { firstName: 'Bernadette', lastName: 'Soubirous' },
  { firstName: 'Edith', lastName: 'Stein' },
  { firstName: 'Peregrine', lastName: 'Laziosi' },
  { firstName: 'Aloysius', lastName: 'Gonzaga' },
  { firstName: 'Andre', lastName: 'Bessette' },
  { firstName: 'Vincent', lastName: 'Ferrer' },
  { firstName: 'John', lastName: 'Chrysostom' },
  { firstName: 'Pier-Giorgio', lastName: 'Frassati' },
  { firstName: 'Robert', lastName: 'Bellarmine' },
]
