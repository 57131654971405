import { useApolloClient } from '@apollo/client'
import Box from '@parishconnect/box'
import {
  Avatar,
  Button,
  LogOutIcon,
  majorScale,
  Menu,
  Popover,
  PositionEnum,
  SettingsIcon,
  SlidersIcon,
  UserIcon,
} from '@parishconnect/react-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import { Parish, useLogoutMutation, User } from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils'

type UserHeaderMenuProps = {
  user: User
  parish: Partial<Parish>
}

export function UserHeaderMenu({ user, parish }: UserHeaderMenuProps) {
  const [logout, { loading }] = useLogoutMutation()
  const client = useApolloClient()

  return (
    <Popover
      position={PositionEnum.BOTTOM_RIGHT}
      closeOnClick
      content={
        <Menu>
          <Menu.Group title="My Account">
            <Menu.Item is={Link} to={`/user/${user.id}`} icon={UserIcon}>
              Profile
            </Menu.Item>
            <Menu.Item is={Link} to="/user/preferences" icon={SettingsIcon}>
              Preferences
            </Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Can I="update" this={parish}>
            <Menu.Group title={parish?.name || 'This Parish'}>
              <Menu.Item is={Link} to="/dashboard" icon={SlidersIcon}>
                Manage Parish
              </Menu.Item>
            </Menu.Group>
            <Menu.Divider />
          </Can>
          <Menu.Item
            intent="warning"
            icon={LogOutIcon}
            onSelect={async () => {
              const {
                data: { logout: loggedOut },
              } = await logout()

              if (loggedOut) {
                await client.resetStore()
              }
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      }
    >
      <Button
        isLoading={loading}
        iconBefore={
          <Avatar
            marginRight={[0, majorScale(1)]}
            src={user?.image?.src}
            name={`${user?.firstName} ${user?.lastName}`}
          />
        }
        appearance="minimal"
        size={400}
        strong
        color="white!important"
      >
        <Box is="span" display={['none', 'inline']}>
          {`${user?.prefix ? user?.prefix + ' ' : ''}${user?.firstName}`}
        </Box>
      </Button>
    </Popover>
  )
}
