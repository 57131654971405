import { MutationHookOptions } from '@apollo/client'
import Box from '@parishconnect/box'
import {
  Button,
  Checkbox,
  DateTimePicker,
  Dialog,
  FormField,
  InlineAlert,
  majorScale,
  PositionEnum,
  SaveIcon,
  TextInputField,
  ThemeContext,
  TimePicker,
  TrashIcon,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { FormEvent, useContext, useState } from 'react'
import {
  DeleteMassMutation,
  DeleteMassMutationVariables,
  Flag,
  MassesDocument,
  MassesQuery,
  MassType,
  RDateInput,
  RRuleInput,
  UpdateMassMutationVariables,
  useDeleteMassMutation,
  useUpdateMassMutation,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN, DateAdapter, Dates, Rule, useToggle } from '../../utils'
import { MassExceptionManager } from './MassExceptionManager'
import { ComputedMass } from './utils'
import { WeekdaySelector } from './WeekdaySelector'

export type UpdateMassProps = {
  mass: ComputedMass
  isShown: boolean
  close?: () => void
  toggleShown: (next?: boolean) => void
}

type UpdateMassFormVariables = {
  start: Date
  byDayOfWeek?: DateAdapter.Weekday
  flag?: Flag
  note?: string
  title?: string
}

export function UpdateMass(props: UpdateMassProps) {
  return props.mass.type === MassType.Recurring ? (
    <UpdateRecurringMassForm {...props} />
  ) : (
    <UpdateSingleMassForm {...props} />
  )
}

function formatUpdateMassVariables({
  values,
  mass,
}: {
  values: UpdateMassFormVariables
  mass: ComputedMass
}) {
  const mutationVariables: UpdateMassMutationVariables = {
    id: mass.id,
    mass: {
      note: values.note,
      start: values.start,
    },
  }

  const timezone = mass.nextDate.zoneName
  const dt = DateTime.fromJSDate(values.start).startOf('minute').setZone(timezone)

  try {
    if (mass.type === MassType.Recurring) {
      mutationVariables.mass.rule = new Rule({
        frequency: 'WEEKLY',
        byDayOfWeek: [values.byDayOfWeek],
        start: dt,
      }).toJSON() as RRuleInput
    } else {
      mutationVariables.mass.date = new Dates({
        timezone,
        dates: [dt],
      }).toJSON() as RDateInput
      mutationVariables.mass.title = values.title
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  return mutationVariables
}

function UpdateRecurringMassForm({ mass, toggleShown, isShown }: UpdateMassProps) {
  const [updateMass] = useUpdateMassMutation()
  const [exceptionsShown, toggleExceptionsShown] = useToggle(false)
  const theme = useContext(ThemeContext)

  return (
    <Dialog
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      title="Update Recurring Mass"
      containerProps={{
        is: motion.div,
        exit: {},
        initial: false,
        animate: {
          width: exceptionsShown ? COLUMN * 16 : COLUMN * 6,
        },
      }}
      isShown={isShown}
      hasFooter={false}
      onCloseComplete={() => {
        toggleShown(false)
        toggleExceptionsShown(false)
      }}
      contentContainerProps={{ padding: 0 }}
    >
      {({ close }: { close: () => void }) => (
        <Box display="flex" height="100%">
          <Box
            padding={majorScale(3)}
            position="relative"
            width={exceptionsShown ? COLUMN * 5 : '100%'}
            onClick={exceptionsShown && toggleExceptionsShown}
            css={
              exceptionsShown && {
                cursor: 'pointer',
                '&:hover::after': {
                  backdropFilter: 'brightness(20%)',
                },
                '&::after': {
                  transition: '225ms',
                  borderBottomLeftRadius: 8,
                  content: '"Back to Mass"',
                  color: 'white',
                  zIndex: 99,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: theme.overlayBackgroundColor,
                  backdropFilter: 'brightness(30%)',
                },
              }
            }
          >
            <Formik<UpdateMassFormVariables>
              onSubmit={async (values, actions) => {
                try {
                  await updateMass({ variables: formatUpdateMassVariables({ values, mass }) })
                  actions.setSubmitting(false)
                  close()
                } catch (error) {
                  Sentry.captureException(error)
                }
              }}
              initialValues={{
                start: DateTime.fromISO(mass.start).toJSDate(),
                byDayOfWeek: mass.rule?.config?.byDayOfWeek?.[0] as DateAdapter.Weekday,
                note: mass.note ?? undefined,
                flag: mass.flag ?? undefined,
              }}
            >
              {({
                values,
                setFieldTouched,
                setFieldValue,
                submitCount,
                errors,
                dirty,
                initialValues,
              }) => (
                <Form>
                  <WeekdaySelector
                    value={values.byDayOfWeek}
                    onChange={(v) => setFieldValue('byDayOfWeek', v)}
                    onBlur={() => setFieldTouched('byDayOfWeek', true)}
                  />
                  <TimePicker
                    value={values.start}
                    onChange={(v) => setFieldValue('start', v)}
                    onBlur={() => setFieldTouched('start', true)}
                    width="100%"
                    useAmPm
                    showArrowButtons
                    label="Time"
                  />
                  {dirty &&
                    mass.exdates?.length > 0 &&
                    (initialValues.byDayOfWeek !== values.byDayOfWeek ||
                      initialValues.start !== values.start) && (
                      <InlineAlert intent="warning" marginBottom={majorScale(2)}>
                        Changing the date or time of this mass will remove any future exceptions
                      </InlineAlert>
                    )}
                  {(values?.byDayOfWeek === 'SA' || values.start.getDay() === 6) &&
                    values.start.getHours() > 15 && (
                      <Checkbox
                        label="Saturday Vigil?"
                        checked={values.flag === Flag.Sunday}
                        onChange={(e: FormEvent<HTMLInputElement>) =>
                          setFieldValue('flag', e.currentTarget.checked ? Flag.Sunday : Flag.Ferial)
                        }
                      />
                    )}
                  <FormField
                    label="Cancellations/modifications"
                    hint={`${mass.exdates?.length <= 0 ? 'No' : mass.exdates?.length} exception${
                      mass.exdates?.length > 1 || mass.exdates?.length <= 0 ? 's' : ''
                    }`}
                    marginBottom={majorScale(3)}
                  >
                    <Button onClick={toggleExceptionsShown} type="button">
                      Edit Future Masses
                    </Button>
                  </FormField>
                  <TextInputField
                    is={Field}
                    maxLength="26"
                    maxWidth={majorScale(28)}
                    name="note"
                    label="Note (optional)"
                    isInvalid={submitCount > 0 && errors.note && errors.note.length > 0}
                    validationMessage={submitCount > 0 && errors.note && errors.note}
                  />
                  <Box width="100%" display="flex">
                    <DeleteMassButton mass={mass} close={close} />
                    <Button marginLeft="auto" type="button" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      marginLeft={majorScale(1)}
                      type="submit"
                      iconBefore={SaveIcon}
                      intent="success"
                      appearance="primary"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          {exceptionsShown && (
            <motion.div
              exit={{ width: 0, opacity: 0 }}
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: '100%', opacity: 1 }}
            >
              <MassExceptionManager {...mass} />
            </motion.div>
          )}
        </Box>
      )}
    </Dialog>
  )
}

function UpdateSingleMassForm({ mass, close, isShown, toggleShown }: UpdateMassProps) {
  const [updateMass] = useUpdateMassMutation()
  return (
    <Dialog
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      width={COLUMN * 6}
      title="Update Single Mass"
      isShown={isShown}
      hasFooter={false}
      onCloseComplete={() => toggleShown(false)}
      contentContainerProps={{ padding: 0 }}
    >
      {({ close }: { close: () => void }) => (
        <Box padding={majorScale(3)} position="relative" width="100%">
          <Formik<UpdateMassFormVariables>
            onSubmit={async (values, actions) => {
              try {
                await updateMass({ variables: formatUpdateMassVariables({ values, mass }) })
                actions.setSubmitting(false)
                close()
              } catch (error) {
                Sentry.captureException(error)
              }
            }}
            initialValues={{
              start: DateTime.fromISO(mass.start).toJSDate(),
              title: mass.title ?? undefined,
              note: mass.note ?? undefined,
              flag: mass.flag ?? undefined,
            }}
          >
            {({ values, setFieldValue, submitCount, errors }) => (
              <Form>
                <TextInputField
                  is={Field}
                  name="title"
                  label="Title (optional)"
                  isInvalid={submitCount > 0 && errors.title && errors.title.length > 0}
                  validationMessage={submitCount > 0 && errors.title && errors.title}
                />
                <DateTimePicker
                  name="start"
                  inputWidth="100%"
                  value={values.start}
                  disableDates={(dt) => DateTime.fromJSDate(dt).diffNow('days').days < -1}
                  onChange={(dt: Date) => setFieldValue('start', dt)}
                  useAmPm
                  showArrowButtons
                  position={PositionEnum.BOTTOM_LEFT}
                  label="Date/Time"
                />
                {(values?.byDayOfWeek === 'SA' || values.start.getDay() === 6) &&
                  values.start.getHours() > 15 && (
                    <Checkbox
                      label="Saturday Vigil?"
                      checked={values.flag === Flag.Sunday}
                      onChange={(e: FormEvent<HTMLInputElement>) =>
                        setFieldValue('flag', e.currentTarget.checked ? Flag.Sunday : Flag.Ferial)
                      }
                    />
                  )}
                <TextInputField
                  is={Field}
                  name="note"
                  label="Note (optional)"
                  isInvalid={submitCount > 0 && errors.note && errors.note.length > 0}
                  validationMessage={submitCount > 0 && errors.note && errors.note}
                />
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <DeleteMassButton mass={mass} close={close} />
                  <Button marginLeft="auto" type="button" onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    marginLeft={majorScale(1)}
                    type="submit"
                    iconBefore={SaveIcon}
                    intent="success"
                    appearance="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Dialog>
  )
}

function DeleteMassButton({ mass, close }: Partial<UpdateMassProps>) {
  const [deleteMass] = useDeleteMassMutation(createDeleteMassMutationOptions({ id: mass.id }))
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false)
  return (
    <>
      <Dialog
        isShown={confirmDeleteIsOpen}
        onCancel={() => setConfirmDeleteIsOpen(false)}
        title={`Delete ${mass.title || 'Mass'}?`}
        confirmLabel="Delete"
        intent="danger"
        onConfirm={() => {
          deleteMass()
          setConfirmDeleteIsOpen(false)
          close()
        }}
      >
        Are you sure you want to delete this mass? This cannot be undone.
      </Dialog>
      <Button
        type="button"
        intent="danger"
        appearance="primary"
        iconBefore={TrashIcon}
        onClick={() => setConfirmDeleteIsOpen(true)}
      >
        Delete
      </Button>
    </>
  )
}

function createDeleteMassMutationOptions(
  variables: DeleteMassMutationVariables,
): MutationHookOptions<DeleteMassMutation, DeleteMassMutationVariables> {
  return {
    variables,
    update: (cache) => {
      const data = cache.readQuery<MassesQuery>({ query: MassesDocument })
      if (data) {
        try {
          cache.writeQuery<MassesQuery>({
            query: MassesDocument,
            data: {
              masses: data.masses.filter((mass) => mass.id !== variables.id),
            },
          })
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    },
  }
}
