import Box from '@parishconnect/box'
import {
  CalendarIcon,
  FileTextIcon,
  HelpCircleIcon,
  HomeIcon,
  LayoutIcon,
  majorScale,
  MenuIcon,
  MessageSquareIcon,
  PlusCircleIcon,
  SidebarIcon,
  Strong,
  ThemeContext,
  UsersIcon,
} from '@parishconnect/react-ui'
import { motion, Variants, Variant } from 'framer-motion'
import React, { useContext, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { NavSubMenu } from './NavSubMenu'

type NavButtonProps = {
  exact?: boolean
  param?: string
  to: string
  icon: any
  text: string
  mobile?: boolean
  open: boolean
  i: number
}

const NavButton = ({
  exact = false,
  to,
  param = '',
  icon: Icon,
  text,
  open = false,
  mobile,
  i,
  ...rest
}: NavButtonProps) => {
  return (
    <NavLink exact={exact} to={to + param} className="fv white">
      <Box display="inline-flex" alignItems="center" marginY={majorScale(1)} {...rest}>
        <Icon title={text} size={24} overflow="visible" />
        <Box variants={textMotion} is={motion.div}>
          <Strong className="menu-text" size={500} marginLeft={majorScale(2)} color="white">
            {text}
          </Strong>
        </Box>
      </Box>
    </NavLink>
  )
}

export type NavMenuProps = {
  open?: boolean
  firstAbout?: string
  firstSacrament?: string
  title?: string
  root?: string
  subMenu?: {
    id: string
    title: string
    to: string
  }[]
}

export const MENU_ITEMS = ({
  firstSacrament,
  firstAbout,
}: Pick<NavMenuProps, 'firstAbout' | 'firstSacrament'>) => [
  {
    exact: true,
    to: '/',
    icon: HomeIcon,
    text: 'Home',
  },
  {
    to: `/about/${firstAbout ?? ''}`,
    icon: HelpCircleIcon,
    text: 'About',
  },
  {
    to: `/sacraments/${firstSacrament ?? ''}`,
    icon: PlusCircleIcon,
    text: 'Sacraments',
  },
  {
    to: '/groups',
    icon: UsersIcon,
    text: 'Groups',
  },
  {
    to: '/forms',
    icon: SidebarIcon,
    text: 'Forms',
  },
  {
    to: '/posts',
    icon: FileTextIcon,
    text: 'Posts',
    mobile: false,
  },
  {
    to: '/events',
    icon: CalendarIcon,
    text: 'Events',
  },
]

const NavMenu = ({ open, subMenu, title, root, firstSacrament, firstAbout }: NavMenuProps) => {
  const theme = useContext(ThemeContext)

  return (
    <Box display="flex" position="relative" marginY={majorScale(3)}>
      <Box
        is={motion.nav}
        aria-label="site"
        variants={{
          init: {
            width: 56,
            height: '100%',
            paddingLeft: majorScale(2),
            paddingRight: majorScale(2),
            transition: {
              paddingRight: { duration: 0.015 },
              paddingLeft: { duration: 0.015 },
              width: { duration: 0.0125 },
              height: { duration: 0.125 },
            },
          },
          hover: {
            width: '100%',
            height: subMenu?.length >= 7 ? subMenu.length * 5.5 * 8 : 'auto',
            paddingLeft: majorScale(3),
            paddingRight: majorScale(3),
            transition: {
              when: 'beforeChildren',
              staggerChildren: 0.05,
              paddingRight: { duration: 0.015, delay: 0.15 },
              paddingLeft: { duration: 0.015, delay: 0.15 },
              width: { duration: 0.005 },
            },
          },
        }}
        initial={open ? 'hover' : 'init'}
        whileHover="hover"
        paddingX={majorScale(2)}
        paddingY={majorScale(4)}
        width="100%"
        borderRadius={28}
        transition="225ms"
        background={[null, theme.palette[theme.themeColor].dark]}
        display="inline-flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        zIndex={2}
        css={{
          a: {
            display: 'inline',
            marginRight: 'auto',
            pointerEvents: 'none',
            position: 'relative',

            svg: {
              pointerEvents: 'all',
            },
          },
          'a svg, a strong': {
            color: 'white',
            transition: '225ms',
          },
          'a:before': {
            content: '""',
            transition: '125ms',
            opacity: 0,
            position: 'absolute',
            width: 3,
            left: -majorScale(2),
            top: '50%',
            transform: 'translateY(-50%)',
            height: 30,
            backgroundColor: 'white',
            borderRadius: 1000,
          },
          'a.active:before, a:hover:before': {
            opacity: 1,
          },
          'a:not(.active):hover:before': {
            backgroundColor: 'rgb(255 255 255 / 0.6)',
          },
        }}
      >
        {MENU_ITEMS({ firstAbout, firstSacrament }).map((item, i) => (
          <NavButton key={item?.text} i={i} open={open} {...item} />
        ))}
      </Box>
      {subMenu && <NavSubMenu root={root} title={title} items={subMenu} />}
    </Box>
  )
}

const textMotion: Variants = {
  init: {
    opacity: 0,
    y: 10,
    transition: { y: { type: 'spring', damping: 5, stiffness: 300 } },
  },
  hover: {
    opacity: 1,
    y: 0,
    pointerEvents: 'all',
    transitionEnd: { display: 'block', width: 'initial' },
    transition: { y: { type: 'spring', damping: 75, stiffness: 100 } },
  },
}

export default NavMenu
