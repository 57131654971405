import Box from '@parishconnect/box'
import {
  Button,
  Card,
  Heading,
  majorScale,
  Paragraph,
  PlusIcon,
  SidebarIcon,
  UserPlusIcon,
} from '@parishconnect/react-ui'
import { DateTime } from 'luxon'
import React, { ComponentProps, useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, Link as RLink } from 'react-router-dom'
import { Footer, Nav, PageContainer } from '../components'
import { EmptyState } from '../components/shared/EmptyState'
import { Form, Status, useFormsQuery } from '../graphql/generated/graphql-hooks'
import { AbilityContext, Can } from '../utils'
import { COLUMN } from '../utils/constants'

function FormCard(form: Form) {
  const ability = useContext(AbilityContext)
  const closedDateTime = DateTime.fromISO(form.closedAt)
  let closingString = 'Open'

  if (form.status === Status.Closed) {
    closingString = 'Closed'
  }

  if (closedDateTime < DateTime.local()) {
    closingString = `Closed ${closedDateTime.toRelative()}`
  } else if (closedDateTime > DateTime.local()) {
    closingString = `Closes ${closedDateTime.toRelative()}`
  }

  let openCardProps = {
    hoverElevation: 2,
    cursor: 'pointer',
    is: Link,
    to: `/form/${form.id}`,
  } as ComponentProps<typeof Card>

  if (form.status === Status.Closed && ability.cannot('update', 'Form')) {
    openCardProps = {}
  }

  if (ability.can('update', 'Form')) {
    openCardProps.to = `/form/${form.id}/submissions`
  }

  return (
    <Card appearance="solid" padding={majorScale(2)} minHeight={majorScale(20)} {...openCardProps}>
      <Heading size={600}>{form.title}</Heading>
      <Heading size={200} color="theme">
        {closingString}
      </Heading>
    </Card>
  )
}

const Forms = () => {
  const limit = 10
  const { loading, error, data } = useFormsQuery({
    variables: {
      limit,
    },
  })

  const [hasMore, setHasMore] = useState(data?.forms?.length >= limit)

  if (loading) return null

  if (error || !data?.forms?.length) {
    return (
      <PageContainer>
        <Box display="flex">
          <Box width={[0, COLUMN * 6]}>
            <Nav menuOpen showMasses />
            <Footer />
          </Box>
          <Box marginLeft={[0, COLUMN]} width={['100%', COLUMN * 15]}>
            <EmptyState icon={SidebarIcon} title="No Forms">
              <Can I="create" a="form">
                <Paragraph size={500}>
                  When you create forms they will appear here.
                  <br />
                  <Button
                    marginTop={majorScale(1)}
                    appearance="primary"
                    iconBefore={PlusIcon}
                    is={RLink}
                    to="/form/new"
                  >
                    Create Form
                  </Button>
                </Paragraph>
              </Can>
            </EmptyState>
          </Box>
        </Box>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Helmet>
        <title>Forms</title>
      </Helmet>
      <Box display="flex">
        <Box width={[0, COLUMN * 6]}>
          <Nav menuOpen showMasses />
          <Footer />
        </Box>
        <Box
          marginLeft={[0, null, majorScale(2), COLUMN]}
          width={['100%', COLUMN * 15]}
          id="main-content"
          is="main"
        >
          <Box
            display="flex"
            width={['100%', majorScale(89)]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading
              marginTop={[majorScale(1), majorScale(3)]}
              marginBottom={majorScale(3)}
              size={800}
              is="h1"
            >
              Forms
            </Heading>
            <Can I="create" a="form">
              <Button appearance="primary" iconBefore={UserPlusIcon} is={RLink} to="/group/new">
                Create Form
              </Button>
            </Can>
          </Box>
          <Box
            display="grid"
            gridAutoFlow="row"
            width="100%"
            gap={`${majorScale(2)}px ${majorScale(5)}px`}
            gridTemplateColumns={[
              '1fr',
              `repeat(auto-fit, minmax(${majorScale(40)}px, ${majorScale(42)}px))`,
            ]}
          >
            {data?.forms.map((form) => (
              <FormCard key={form.id} {...form} />
            ))}
          </Box>
          {/* {hasMore && (
            <Button
              isLoading={loading}
              onClick={() =>
                fetchMore({
                  variables: {
                    limit,
                    cursor: data?.groups[data?.groups.length - 1].id,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                      return previousResult
                    }

                    const groups = fetchMoreResult?.groups ?? []

                    if (groups.length < limit) {
                      setHasMore(false)
                    }

                    return {
                      ...previousResult,
                      groups: [...previousResult.groups, ...fetchMoreResult.groups],
                    }
                  },
                })
              }
              height={36}
              marginBottom={majorScale(3)}
            >
              Load More
            </Button>
          )} */}
        </Box>
      </Box>
    </PageContainer>
  )
}

export default Forms
