import Box from '@parishconnect/box'
import {
  Button,
  Card,
  Heading,
  SegmentedControl,
  majorScale,
  Pane,
  SelectField,
  SendIcon,
  Textarea,
  FormField,
  DateTimePicker,
  DatePicker,
} from '@parishconnect/react-ui'
import { EDITOR_CLASS_SELECTOR, EMPTY_PARAGRAPH_NODE } from '@remirror/core'
import * as Sentry from '@sentry/browser'
import JSONparse from 'easy-json-parse'
import { Form, Formik } from 'formik'
import { capitalize, pick } from 'lodash-es'
import React, { FormEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, RouteComponentProps } from 'react-router'
import { array, date, number, object, string } from 'yup'
import { Editor } from '../components'
import { FeaturedImageUpload } from '../components/Image/FeaturedImageUpload'
import { Nav } from '../components/Nav'
import { PageContainer } from '../components/shared'
import {
  Post,
  PostsDocument,
  PostsQuery,
  PostTypes,
  Privacy,
  Status,
  useAddPostMutation,
  useAppQuery,
  useEditPostMutation,
  useGroupsQuery,
  usePostLazyQuery,
} from '../graphql/generated/graphql-hooks'
import { Can } from '../utils/abilityContext'
import { COLUMN } from '../utils/constants'
import { getLocalValue, removeLocalValue, setLocalValue } from '../utils/localStorage'
import { captureException } from '@sentry/browser'
import { DateTime } from 'luxon'
import { AnimatePresence, motion } from 'framer-motion'

const postSchema = object().shape({
  title: string().required(),
  image: string(),
  imagePosition: object().shape({
    y: number().default(0.5),
  }),
  ast: string()
    .notOneOf([JSON.stringify(EMPTY_PARAGRAPH_NODE)], 'Post must not be empty')
    .required(),
  lectionaryDate: date().nullable(),
  plain: string().required('Post must not be empty'),
  privacy: string().oneOf(Object.values(Privacy)).required().default(Privacy.Public),
  status: string().oneOf(Object.values(Status)).required().default(Status.Published),
  tags: array(string()),
  type: string().oneOf(Object.values(PostTypes)).required().default(PostTypes.Blog),
  group: string()
    .matches(/[0-9a-fA-F]{24}/gi)
    .nullable(),
})

type PostEditorProps = RouteComponentProps<{ id?: string }> & {}

function getLectionaryDate(post: Post) {
  if (post.lectionaryDate) {
    return DateTime.fromISO(post.lectionaryDate).toJSDate()
  }
  if (post.createdAt) {
    return DateTime.fromISO(post.createdAt).toJSDate()
  }
  return undefined
}

function disabledLectionaryDates(date: Date): boolean {
  if (
    date < DateTime.fromObject({ year: 2017, month: 5, day: 1 }).toJSDate() ||
    date > DateTime.fromObject({ year: 2021, month: 5, day: 31 }).toJSDate()
  ) {
    return true
  }

  return false
}

export default function PostEditor({
  history,
  match: {
    params: { id },
  },
  location: { state },
}: PostEditorProps) {
  const { data: groupsData } = useGroupsQuery()
  const { data: parishData, loading } = useAppQuery()
  const [mode, setMode] = useState<'edit' | 'create'>(!!id ? 'edit' : 'create')
  const [addPost] = useAddPostMutation()
  const [editPost] = useEditPostMutation()
  const [loadPost, { data, called, error, loading: postLoading }] = usePostLazyQuery({
    variables: { id },
  })

  const localValues = [
    'post-title',
    'post-ast',
    'post-plain',
    'post-image',
    'post-privacy',
    'post-status',
    'post-type',
  ]

  useEffect(() => {
    if (mode === 'edit' && !called) {
      loadPost()
    }
  }, [mode])

  if (!loading && !parishData?.user) {
    return <Redirect to="/login" />
  }

  // Lets Editor initialContent get set properly
  if (mode === 'edit' && (postLoading || !data)) {
    return null
  }

  return (
    <PageContainer>
      <Pane display="flex">
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
        </Box>
        <Box width="100%" marginLeft={[0, null, COLUMN / 2]}>
          <Formik
            onSubmit={async ({ group, image, imagePosition, ...rest }, actions) => {
              try {
                switch (mode) {
                  case 'create':
                    await addPost({
                      variables: {
                        post: {
                          image: image || undefined,
                          imagePosition,
                          group: group || undefined,
                          ...rest,
                        },
                      },
                      update(cache, { data: { addPost }, errors }) {
                        removeLocalValue(localValues)

                        try {
                          const data = cache.readQuery<PostsQuery>({ query: PostsDocument })
                          if (data?.posts) {
                            cache.writeQuery<PostsQuery>({
                              query: PostsDocument,
                              data: {
                                ...data,
                                posts: [addPost].concat(data.posts),
                              },
                            })
                          }
                        } catch (error) {
                          console.log(error)

                          Sentry.captureException(error)
                        }

                        history.replace(`/post/${addPost.id}`)
                      },
                    })
                    break
                  case 'edit':
                    await editPost({
                      variables: {
                        id,
                        post: {
                          ...rest,
                          image: image || undefined,
                          imagePosition: imagePosition?.y
                            ? { x: imagePosition.x, y: imagePosition.y }
                            : undefined,
                        },
                      },
                      update(_, { data: { editPost } }) {
                        removeLocalValue(localValues)
                        history.replace(`/post/${editPost.id}`)
                      },
                    })
                }
              } catch (error) {
                if (__DEV__) console.error(error)
                captureException(error)
              }
              actions.setSubmitting(false)
            }}
            validationSchema={postSchema}
            initialValues={{
              createdAt: data?.post?.createdAt || DateTime.local().toISO(),
              title: data?.post?.title || (mode !== 'edit' && getLocalValue('post-title')) || '',
              ast: data?.post?.ast || (mode !== 'edit' && getLocalValue('post-ast')),
              plain: data?.post?.plain || (mode !== 'edit' && getLocalValue('post-plain')) || '',
              lectionaryDate: data?.post && getLectionaryDate((data.post as unknown) as Post),
              image:
                data?.post?.image?.id ||
                (mode !== 'edit' && getLocalValue('post-image')) ||
                undefined,
              imagePosition:
                (pick(data?.post?.imagePosition, ['x', 'y']).x &&
                  pick(data?.post?.imagePosition, ['x', 'y'])) ||
                (mode !== 'edit' &&
                  JSONparse(getLocalValue('post-image-position'), {
                    initialValue: { x: 0.5, y: 0.5 },
                  }))[1],
              privacy:
                Privacy.Public || (mode !== 'edit' && (getLocalValue('post-privacy') as Privacy)),
              status:
                (mode !== 'edit' && (getLocalValue('post-status') as Status)) || Status.Published,
              type:
                data?.post?.type ||
                (mode !== 'edit' && (getLocalValue('post-type') as PostTypes)) ||
                PostTypes.Blog,
              tags: [],
              group: data?.post?.group?.id || state?.group,
            }}
          >
            {({ values, setFieldValue, submitForm, errors }) => {
              return (
                <Form>
                  <Helmet>
                    <title>{`${mode === 'edit' ? 'Editing - ' : ''} ${
                      values?.title || 'New Post'
                    }`}</title>
                  </Helmet>
                  <FeaturedImageUpload
                    aspectRatio={3}
                    initialFile={values.image}
                    position={values.imagePosition}
                    allowReposition
                    onUpload={({ id }) => {
                      setFieldValue('image', id)
                      if (mode !== 'edit') {
                        setLocalValue('post-image', id)
                      }
                    }}
                    onPositionChange={({ x, y }) => setFieldValue('imagePosition', { x, y })}
                    onRemove={() => {
                      setFieldValue('image', undefined)
                      removeLocalValue('post-image')
                      setFieldValue('imagePosition', undefined)
                      removeLocalValue('post-image-position')
                    }}
                  />
                  <Box
                    display="inline-flex"
                    width="100%"
                    alignItems="flex-start"
                    flexDirection={['column', null, null, 'row']}
                  >
                    <Box
                      marginX={[0, null, null, majorScale(2)]}
                      maxWidth={majorScale(90)}
                      width="100%"
                      css={{ [EDITOR_CLASS_SELECTOR]: { minHeight: [150, 350] } }}
                      paddingTop="env(safe-area-inset-top)"
                    >
                      <Editor
                        placeholder="Write something..."
                        toolbar
                        initialContent={JSONparse(values?.ast)[1]}
                        onChange={(state) => {
                          setFieldValue('ast', JSON.stringify(state.getObjectNode()))
                          setFieldValue('plain', state.getText())
                          if (mode !== 'edit') {
                            setLocalValue('post-ast', JSON.stringify(state.getObjectNode()))
                            setLocalValue('post-plain', JSON.stringify(state.getText()))
                          }
                        }}
                        contentComponents={
                          <Card
                            background="tint1"
                            marginTop={majorScale(3)}
                            padding={majorScale(1)}
                          >
                            <Textarea
                              autoFocus
                              appearance="editor-title"
                              component={Heading}
                              size={900}
                              maxLength="120"
                              maxWidth={900}
                              value={values.title}
                              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                                setFieldValue('title', e.currentTarget.value)
                                mode !== 'edit' &&
                                  setLocalValue('post-title', e.currentTarget.value)
                              }}
                              rows={1}
                              overflow="hidden"
                              autoresize
                              placeholder="Give this post a title..."
                            />
                          </Card>
                        }
                      />
                      {__DEV__ && <pre>{JSON.stringify(values, null, 3)}</pre>}
                    </Box>
                    <Card
                      position={['static', null, null, 'sticky']}
                      top={[null, null, null, majorScale(7)]}
                      elevation={1}
                      padding={majorScale(2)}
                      marginTop={majorScale(1)}
                      marginRight={[0, null, null, majorScale(2)]}
                      marginLeft={[0, null, null, 'auto']}
                      width={['100%', 'auto']}
                    >
                      <SelectField
                        marginBottom={majorScale(2)}
                        name="type"
                        label="Type"
                        inputWidth="100%"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue('type', e.currentTarget.value)

                          if (e.currentTarget.value === PostTypes.Homily) {
                            setFieldValue(
                              'lectionaryDate',
                              (data?.post?.createdAt &&
                                DateTime.fromISO(data.post.createdAt).toJSDate()) ||
                                DateTime.local().startOf('day').toJSDate(),
                            )
                          } else {
                            setFieldValue('lectionaryDate', null)
                          }

                          if (mode !== 'edit') {
                            setLocalValue('post-type', e.currentTarget.value)
                          }
                        }}
                        value={values.type}
                      >
                        <Can I="create" this={{ __typename: 'Post', parish: parishData?.parish }}>
                          {!values.group && (
                            <option value={PostTypes.Homily}>{capitalize(PostTypes.Homily)}</option>
                          )}
                        </Can>
                        {Object.values(PostTypes)
                          .filter((type) => type !== PostTypes.Homily)
                          .map((type) => (
                            <option key={type} value={type} id={type}>
                              {capitalize(type)}
                            </option>
                          ))}
                      </SelectField>

                      <AnimatePresence>
                        {values.type === PostTypes.Homily && (
                          <motion.div
                            key="lectionaryDate"
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            style={{ overflow: 'hidden' }}
                          >
                            <FormField
                              paddingBottom={majorScale(2)}
                              name="lectionaryDate"
                              label="Lectionary Date"
                            >
                              <DatePicker
                                shouldShowYearButtons={false}
                                width="100%"
                                value={values?.lectionaryDate}
                                disableDates={disabledLectionaryDates}
                                onChange={(date: Date) =>
                                  setFieldValue(
                                    'lectionaryDate',
                                    DateTime.fromJSDate(date).startOf('day').toJSDate(),
                                  )
                                }
                              />
                            </FormField>
                          </motion.div>
                        )}
                      </AnimatePresence>

                      {mode === 'create' && (
                        <SelectField
                          marginBottom={majorScale(2)}
                          name="group"
                          label="Group"
                          inputWidth="100%"
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            setFieldValue('group', e.currentTarget.value)
                          }
                          value={values.group}
                        >
                          <Can I="create" this={{ __typename: 'Post', parish: parishData?.parish }}>
                            <option value="">Parish</option>
                          </Can>
                          {Object.values(groupsData?.groups ?? []).map((group) => (
                            <Can
                              key={group.id}
                              I="create"
                              this={{ __typename: 'Post', group, parish: parishData?.parish }}
                            >
                              <option key={group?.id} value={group?.id} id={group?.id}>
                                {group?.name}
                              </option>
                            </Can>
                          ))}
                        </SelectField>
                      )}
                      <FormField marginBottom={majorScale(2)} name="privacy" label="Privacy">
                        <SegmentedControl
                          width="calc(100% - 20px)"
                          marginLeft="auto"
                          marginRight="auto"
                          marginBottom={majorScale(2)}
                          onChange={(value: string) => {
                            setFieldValue('privacy', value)
                            mode !== 'edit' && setLocalValue('post-privacy', value)
                          }}
                          value={values.privacy}
                          options={[
                            {
                              label: 'Public',
                              value: Privacy.Public,
                            },
                            {
                              label: 'Private',
                              value: Privacy.Private,
                            },
                          ]}
                        />
                      </FormField>

                      <FormField marginBottom={majorScale(2)} name="createdAt" label="Publish Date">
                        <DatePicker
                          value={
                            DateTime.fromISO(values.createdAt).toJSDate() ??
                            DateTime.local().toJSDate()
                          }
                          onChange={(value) => {
                            setFieldValue('createdAt', value)
                          }}
                        />
                      </FormField>

                      <Box display="flex" paddingTop={majorScale(3)}>
                        <Button
                          type="button"
                          marginRight={majorScale(1)}
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" appearance="primary" iconBefore={SendIcon}>
                          Publish
                        </Button>
                      </Box>
                    </Card>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </Pane>
    </PageContainer>
  )
}
