import Box from '@parishconnect/box'
import {
  Button,
  Card,
  DateTimePicker,
  Link,
  majorScale,
  Pane,
  PositionEnum,
  SelectField,
  SendIcon,
  TextInputField,
} from '@parishconnect/react-ui'
import { EDITOR_CLASS_SELECTOR } from '@remirror/core'
import { RemirrorStateListenerParams } from '@remirror/react'
import * as Sentry from '@sentry/browser'
import JSONparse from 'easy-json-parse'
import { Field, Form, Formik } from 'formik'
import { capitalize, debounce, pick } from 'lodash-es'
import { DateTime, Duration } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'
import * as yup from 'yup'
import { Editor } from '../components'
import { FeaturedImageUpload } from '../components/Image/FeaturedImageUpload'
import { Nav } from '../components/Nav'
import { PageContainer } from '../components/shared'
import {
  EventsDocument,
  EventsQuery,
  Privacy,
  useAddEventMutation,
  useAppQuery,
  useEditEventMutation,
  useEventLazyQuery,
  useGroupsQuery,
} from '../graphql/generated/graphql-hooks'
import { Can } from '../utils/abilityContext'
import { COLUMN } from '../utils/constants'
import { getLocalValue, removeLocalValue, setLocalValue } from '../utils/localStorage'

const eventFormSchema = yup.object().shape({
  title: yup.string().required().max(64),
  description: yup.string(),
  image: yup.string().nullable(),
  excerpt: yup.string(),
  dtstart: yup.date().required(),
  dtend: yup.date().nullable(),
})

export default function CreateEvent({
  history,
  match: {
    params: { id },
  },
  location: { state },
}: RouteComponentProps<{ id: string }>) {
  const { data: groupsData } = useGroupsQuery()
  const { data: parishData, loading } = useAppQuery()
  const [mode, setMode] = useState<'edit' | 'create'>(!!id ? 'edit' : 'create')
  const [hasEndDate, setHasEndDate] = useState(false)

  const [addEvent] = useAddEventMutation()
  const [editEvent] = useEditEventMutation()
  const [loadEvent, { data, called, error, loading: eventLoading }] = useEventLazyQuery({
    variables: { id },
  })

  const localValues = [
    'event-title',
    'event-image',
    'event-image-position',
    'event-description',
    'event-excerpt',
    'event-image-position',
    'event-privacy',
    'event',
  ]

  useEffect(() => {
    if (mode === 'edit' && !called) {
      loadEvent()
    }
  }, [mode])

  if (error) {
    Sentry.captureException(error)
  }

  if (!loading && !parishData?.user) {
    return <Redirect to="/login" />
  }

  // Lets Editor initialContent get set properly
  if (mode === 'edit' && (eventLoading || !data)) {
    return null
  }

  return (
    <PageContainer>
      <Pane display={['block', null, 'flex']}>
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
        </Box>
        <Box
          width={['100%', null, COLUMN * 17.5]}
          marginLeft={[0, null, COLUMN / 2]}
          marginBottom={[COLUMN * 2, null, 0]}
        >
          <Formik
            onSubmit={async (event, actions) => {
              try {
                switch (mode) {
                  case 'create':
                    await addEvent({
                      variables: {
                        event,
                      },
                      update(cache, { data: { addEvent } }) {
                        try {
                          const data = cache.readQuery<EventsQuery>({ query: EventsDocument })
                          if (data?.events) {
                            cache.writeQuery<EventsQuery>({
                              query: EventsDocument,
                              data: {
                                ...data,
                                events: [addEvent].concat(data.events),
                              },
                            })
                          }
                        } catch (error) {
                          Sentry.captureException(error)
                        }

                        history.push(`/event/${addEvent.id}`)
                      },
                    })
                    break
                  case 'edit':
                    delete event.group
                    await editEvent({
                      variables: {
                        id,
                        event,
                      },
                      update(_, { data: { editEvent } }) {
                        history.push(`/event/${editEvent.id}`)
                      },
                    })
                }
              } catch (error) {
                Sentry.captureException(error)
                actions.setErrors(error)
              }

              removeLocalValue(localValues)
            }}
            validationSchema={eventFormSchema}
            initialValues={{
              title: data?.event?.title || getLocalValue('event-title') || '',
              dtstart: data?.event?.dtstart
                ? DateTime.fromISO(data?.event?.dtstart).toJSDate()
                : DateTime.local().plus({ hour: 1 }).startOf('hour').startOf('minute').toJSDate(),
              dtend:
                data?.event?.dtstart && data?.event?.duration
                  ? DateTime.fromISO(data?.event?.dtstart)
                      .plus(Duration.fromISO(data?.event?.duration))
                      .toJSDate()
                  : undefined,
              description: data?.event?.description || getLocalValue('event-description'),
              excerpt: data?.event?.excerpt || getLocalValue('event-excerpt') || '',
              image: data?.event?.image?.id || getLocalValue('event-image'),
              imagePosition:
                pick(data?.event?.imagePosition, ['x', 'y']) ||
                (mode !== 'edit' &&
                  JSONparse(getLocalValue('event-image-position'), {
                    initialValue: { x: 0.5, y: 0.5 },
                  }))[1],
              privacy:
                Privacy.Public || (mode !== 'edit' && (getLocalValue('event-privacy') as Privacy)),
              group: data?.event?.group?.id || state?.group,
            }}
          >
            {({
              values,
              isSubmitting,
              submitCount,
              errors,
              setFieldValue,
              handleSubmit,
              ...props
            }) => (
              <Form>
                <Helmet>
                  <title>{values.title || 'Create a new Event'}</title>
                </Helmet>
                <FeaturedImageUpload
                  initialFile={values.image}
                  position={values.imagePosition}
                  onPositionChange={({ x, y }) => setFieldValue('imagePosition', { x, y })}
                  width={majorScale(62)}
                  height={majorScale(28)}
                  allowReposition
                  onUpload={({ id }) => {
                    setFieldValue('image', id)
                    if (mode !== 'edit') {
                      setLocalValue('event-image', id)
                    }
                  }}
                  onRemove={() => {
                    setFieldValue('image', undefined)
                    removeLocalValue('event-image')
                    setFieldValue('imagePosition', undefined)
                    removeLocalValue('event-image-position')
                  }}
                />
                <Box
                  display={['block', null, 'flex']}
                  width="100%"
                  marginX={[majorScale(1), null, majorScale(3)]}
                  marginTop={majorScale(4)}
                >
                  <Box maxWidth={COLUMN * 8}>
                    <TextInputField
                      disabled={isSubmitting}
                      name="title"
                      is={Field}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        setFieldValue('title', e.currentTarget.value)
                        setLocalValue('event-title', e.currentTarget.value)
                      }}
                      label="Event Title"
                      maxLength={64}
                      placeholder="Add a short, clear name"
                      isRequired
                      type="text"
                      hint={`${values.title.length >= 20 ? values.title.length + '/64' : ''} ${
                        values.title.length > 30
                          ? 'Long event names may not fully appear in some places (ex: mobile devices)'
                          : ''
                      }`}
                      isInvalid={submitCount > 0 && errors.title && errors.title.length > 0}
                      validationMessage={submitCount > 0 && errors.title && errors.title}
                    />
                    <Editor
                      autoFocus={false}
                      label="Description"
                      appearance="minimal"
                      toolbar={false}
                      borderRadius={6}
                      hint="**bold** _italic_ > quote"
                      initialContent={JSONparse(values.description)[1]}
                      styles={{
                        [EDITOR_CLASS_SELECTOR]: {
                          padding: `6px 10px`,
                          fontSize: 12,
                          minHeight: `40px!important`,
                          margin: '0px !important',
                          borderRadius: 6,
                          color: '#454545',
                          p: {
                            margin: 0,
                            marginTop: `0px !important`,
                            fontSize: '12px !important',
                            '::before': {
                              fontStyle: 'normal !important',
                              color: '#8A8A8A !important',
                            },
                          },
                        },
                      }}
                      onChange={debounce((state: RemirrorStateListenerParams) => {
                        setFieldValue('description', JSON.stringify(state.getObjectNode()))
                        setLocalValue('event-description', JSON.stringify(state.getObjectNode()))
                        setFieldValue('excerpt', state.getText())
                        setLocalValue('event-excerpt', state.getText())
                      }, 350)}
                      placeholder="Tell people what your event is about. (Basic Formatting Supported)"
                      isInvalid={
                        submitCount > 0 && errors.description && errors.description.length > 0
                      }
                      validationMessage={
                        submitCount > 0 && errors.description && errors.description
                      }
                    />
                    <Box display={!hasEndDate && 'flex'} alignItems="center">
                      <DateTimePicker
                        label={hasEndDate ? 'Start Date/Time' : 'Date/Time'}
                        position={PositionEnum.TOP_LEFT}
                        useAmPm
                        showArrowButtons
                        shouldShowYearButtons={false}
                        value={values.dtstart}
                        onChange={(datetime: Date) => setFieldValue('dtstart', datetime)}
                      />
                      {hasEndDate ? (
                        <Box display="flex" alignItems="center">
                          <DateTimePicker
                            label="End Date/Time"
                            position={PositionEnum.TOP_LEFT}
                            useAmPm
                            showArrowButtons
                            shouldShowYearButtons={false}
                            value={values.dtend}
                            onChange={(datetime: Date) => {
                              setFieldValue('dtend', datetime)
                              setLocalValue('dtend', JSON.stringify(datetime))
                            }}
                          />
                          <Link
                            marginLeft={majorScale(4)}
                            cursor="pointer"
                            onClick={() => {
                              setHasEndDate(false)
                              setFieldValue('dtend', undefined)
                              removeLocalValue('event-dtend')
                            }}
                          >
                            Remove
                          </Link>
                        </Box>
                      ) : (
                        <Link
                          marginLeft={majorScale(4)}
                          cursor="pointer"
                          onClick={() => {
                            if (!values.dtend) {
                              const dtend = DateTime.fromJSDate(values.dtstart)
                                .plus({ hour: 1 })
                                .toJSDate()
                              setFieldValue('dtend', dtend)
                              setLocalValue('event-dtend', JSON.stringify(dtend))
                            }
                            setHasEndDate(true)
                          }}
                        >
                          + Add End Time
                        </Link>
                      )}
                    </Box>
                  </Box>
                  <Card
                    elevation={1}
                    padding={majorScale(2)}
                    marginLeft={[0, null, majorScale(6)]}
                    width={['100%', null, 'initial']}
                    marginTop={[majorScale(3), null, 0]}
                    maxWidth={COLUMN * 8}
                  >
                    <SelectField
                      marginBottom={majorScale(2)}
                      name="group"
                      disabled={mode === 'edit'}
                      label="Publish to"
                      inputWidth="100%"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue('group', e.currentTarget.value)
                        setLocalValue('event-group', e.currentTarget.value)
                      }}
                      value={values.group}
                      css={{
                        select: { cursor: mode === 'edit' ? 'not-allowed' : 'pointer' },
                      }}
                    >
                      <Can I="create" this={{ __typename: 'Event', parish: parishData?.parish }}>
                        <option value="">Parish</option>
                      </Can>
                      {Object.values(groupsData?.groups ?? []).map((group) => (
                        <Can
                          key={group?.id}
                          I="create"
                          this={{ __typename: 'Event', group, parish: parishData?.parish }}
                        >
                          <option key={group?.id} value={group?.id} id={group?.id}>
                            {group?.name}
                          </option>
                        </Can>
                      ))}
                    </SelectField>
                    <SelectField
                      marginBottom={majorScale(2)}
                      name="privacy"
                      label="Privacy"
                      inputWidth="100%"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue('privacy', e.currentTarget.value)
                        setLocalValue('event-privacy', e.currentTarget.value)
                      }}
                      value={values.privacy}
                    >
                      {Object.values(Privacy)
                        .filter((p) => p === Privacy.Public || p === Privacy.Private)
                        .map((privacy) => (
                          <option key={privacy} value={privacy} id={privacy}>
                            {capitalize(privacy)}
                          </option>
                        ))}
                    </SelectField>
                    <Button
                      type="button"
                      marginRight={majorScale(1)}
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>

                    <Button type="submit" appearance="primary" iconBefore={SendIcon}>
                      Publish
                    </Button>
                  </Card>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Pane>
    </PageContainer>
  )
}
