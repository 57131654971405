import Box from '@parishconnect/box'
import { Image, majorScale, Badge, minorScale } from '@parishconnect/react-ui'
import React from 'react'
import ProgressiveImage from 'react-progressive-image'
import { PostModuleFragmentFragment } from '../../graphql/generated/graphql-hooks'

type PostModuleImageProps = PostModuleFragmentFragment['image'] & {
  type?: string
}

export const PostModuleImage = ({ src, srcset, lqip, title, type }: PostModuleImageProps) => {
  const width = majorScale(10)
  const height = majorScale(14)

  return (
    <Box
      width={width}
      height={height}
      position="relative"
      css={{
        '&:before': {
          content: '""',
          backgroundImage: `url(${lqip})`,
          width: width * 0.85,
          height: height,
          left: width * 0.075,
          top: 4,
          position: 'absolute',
          filter: 'blur(10px)',
          opacity: 0.75,
          zIndex: 1,
        },
      }}
    >
      <ProgressiveImage src={src} placeholder={lqip} srcSetData={{ srcSet: srcset, sizes: null }}>
        {(src: string, loading: boolean, srcSetData: any) => (
          <Image
            role="presentation"
            alt={title}
            position="relative"
            width={width}
            zIndex={2}
            height={height}
            borderRadius={6}
            src={src}
            srcSet={srcSetData.srcSet}
          />
        )}
      </ProgressiveImage>
    </Box>
  )
}
