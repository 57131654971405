import { ApolloClient, useApolloClient } from '@apollo/client'
import Box from '@parishconnect/box'
import {
  Card,
  CheckIcon,
  defaultTheme,
  Heading,
  IconButton,
  majorScale,
  Menu,
  minorScale,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextInput,
  TextTableCell,
  TextTableHeaderCell,
  ThemeContext,
  toaster,
} from '@parishconnect/react-ui'
import { captureException } from '@sentry/browser'
import { Form, Formik } from 'formik'
import produce from 'immer'
import { capitalize } from 'lodash-es'
import React, { ChangeEvent, useContext, useState } from 'react'
import {
  Level,
  ParishQuery,
  RoleDomain,
  SearchUsersDocument,
  SearchUsersQuery,
  useEditUserRoleMutation,
  UserQuery,
  UsersDocument,
  UsersQuery,
  useUserQuery,
  useUsersQuery,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils'
import { UserSearch } from './UserSearch'

// MARK: - Main Component
export function AdminSelector({}: ParishQuery['parish']) {
  const { data, loading, variables } = useUsersQuery({
    variables: {
      filters: { role: { domain: RoleDomain.Parish, levels: [Level.Admin, Level.Moderator] } },
    },
  })
  const {
    data: { parish, user: self },
  } = useUserQuery()
  const [selectedRow, setSelectedRow] = useState<string>(null)

  if (loading || !data) return null

  return (
    <Card
      appearance="solid"
      padding={majorScale(2)}
      marginTop={majorScale(3)}
      border="muted"
      maxWidth={COLUMN * 10}
    >
      <Heading color="theme" marginBottom={majorScale(2)} size={500}>
        Parish Admins
      </Heading>
      <Text>
        Parish Admins are allowed to edit information about the Parish. This includes mass times,
        sacraments and general information. This does not include billing information.
      </Text>
      {data?.users && (
        <Table width="100%" paddingTop={majorScale(2)}>
          <TableHead borderRadius={4}>
            <TextTableHeaderCell flexBasis="50%" textAlign="start">
              Name
            </TextTableHeaderCell>
            <TextTableHeaderCell flexBasis="50%" textAlign="end">
              Level
            </TextTableHeaderCell>
          </TableHead>
          <TableBody>
            {data.users.map((user) => (
              <AdminSelectorRow
                {...user}
                usersQueryVariables={variables}
                parish={parish}
                self={self}
                key={user.id}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            ))}
          </TableBody>
        </Table>
      )}
      <MemberPromotionRow parish={parish} usersQueryVariables={variables} />
    </Card>
  )
}

// MARK: Selector Row
export type SelectorRowProps = UsersQuery['users'][0] & {
  selectedRow?: string
  self: UserQuery['user']
  parish: UserQuery['parish']
  usersQueryVariables: any
  setSelectedRow: (id: string) => void
}

function AdminSelectorRow({
  setSelectedRow,
  selectedRow,
  self,
  parish,
  usersQueryVariables,
  ...user
}: SelectorRowProps) {
  const [editUserRole] = useEditUserRoleMutation()
  const isSelected = selectedRow === user.id
  const isSelf = user.id === self.id
  const parishRole = user.roles.find((role) => role.id === parish.id)

  if (parishRole.level === Level.Member) return null

  return (
    <TableRow
      isSelectable={!isSelf && parishRole.owner !== true}
      onSelect={() => setSelectedRow(user.id)}
      isSelected={isSelected}
      key={user.id}
      level={JSON.stringify(user.roles)}
      elevation={isSelected && 2}
      borderRadius={4}
      css={{
        transition: '225ms',
        ...(isSelected && {
          backgroundColor: 'white !important',
        }),
      }}
    >
      <TextTableCell flexBasis="50%" textAlign="start">
        {user.fullName}
        {isSelf ? ' (You)' : ''}
      </TextTableCell>
      {isSelected ? (
        <Formik<{ level: Level }>
          initialValues={{ level: parishRole.level }}
          onSubmit={async ({ level }, actions) => {
            try {
              await editUserRole({
                variables: { id: parishRole.id, user: user.id, role: { level } },
                update: (cache, { data: { editUserRole } }) => {
                  toaster.success(`Success`, {
                    description: `${user.fullName}'s role has been changed`,
                  })

                  const currentAdmins: UsersQuery = cache.readQuery({
                    query: UsersDocument,
                    variables: usersQueryVariables,
                  })

                  cache.writeQuery({
                    query: UsersDocument,
                    variables: usersQueryVariables,
                    data: produce(currentAdmins, (draft) => {
                      for (const user of draft.users) {
                        if (user.id === editUserRole.id) {
                          user.roles = editUserRole.roles
                        }
                      }
                      return draft
                    }),
                  })
                },
              })

              setSelectedRow(null)
            } catch (error) {
              console.log(error)

              captureException(error)
              toaster.danger('Oops. Something went wrong', {
                description: "You can't change that user's role",
                id: user.id,
              })
            }
            actions.setSubmitting(false)
          }}
        >
          {({ values, setFieldValue, dirty, submitForm }) => (
            <TableCell flexBasis="50%">
              <Select
                value={values.level}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setFieldValue('level', e.currentTarget.value)
                }
              >
                {Object.values(Level).map((level) => (
                  <option key={level} value={level}>
                    {capitalize(level)}
                  </option>
                ))}
              </Select>
              <IconButton
                marginLeft={minorScale(1)}
                icon={CheckIcon}
                onClick={submitForm}
                type="submit"
                appearance="primary"
                disabled={!dirty}
              />
            </TableCell>
          )}
        </Formik>
      ) : (
        <TextTableCell flexBasis="50%" textAlign="end" css={{ textTransform: 'capitalize' }}>
          {parishRole.owner ? 'Owner' : capitalize(parishRole.level)}
        </TextTableCell>
      )}
    </TableRow>
  )
}

// MARK: MemberPromotionRow

function MemberPromotionRow({
  usersQueryVariables,
  parish,
}: {
  parish: UserQuery['parish']
  usersQueryVariables: any
}) {
  const [editUserRole] = useEditUserRoleMutation()

  return (
    <Formik
      initialValues={{ id: '', fullName: '', level: Level.Member }}
      onSubmit={async ({ level, id, fullName }, actions) => {
        try {
          await editUserRole({
            variables: { id: parish.id, user: id, role: { level } },
            update: (cache, { data: { editUserRole } }) => {
              toaster.success(`Success`, {
                description: `${fullName}'s role has been changed`,
              })

              const currentAdmins: UsersQuery = cache.readQuery({
                query: UsersDocument,
                variables: usersQueryVariables,
              })
              cache.writeQuery({
                query: UsersDocument,
                variables: usersQueryVariables,
                data: produce(currentAdmins, (draft) => {
                  draft.users.push(editUserRole)
                }),
              })
            },
          })
        } catch (error) {
          captureException(error)
          toaster.danger('Oops. Something went wrong', {
            description: "You can't change that user's role",
            id,
          })
        }
        actions.resetForm()
        actions.setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, submitForm, initialValues }) => (
        <Box is={Form} paddingY={majorScale(1)}>
          <TableRow key="new" borderRadius={4}>
            <TableCell flexBasis="50%" textAlign="start">
              <UserSearch
                value={{ fullName: values.fullName, id: values.id }}
                setValue={setFieldValue}
                variables={{
                  filters: { role: { levels: [Level.Member], domain: RoleDomain.Parish } },
                }}
              />
            </TableCell>
            <TableCell flexBasis="50%" textAlign="end" css={{ textTransform: 'capitalize' }}>
              <Select
                disabled={!values.id}
                value={values.level}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setFieldValue('level', e.currentTarget.value)
                }
              >
                {Object.values(Level).map((level) => (
                  <option key={level} value={level}>
                    {capitalize(level)}
                  </option>
                ))}
              </Select>
              <IconButton
                marginLeft={minorScale(1)}
                icon={CheckIcon}
                onClick={submitForm}
                appearance="primary"
                disabled={!values.id || values.level === initialValues.level}
              />
            </TableCell>
          </TableRow>
        </Box>
      )}
    </Formik>
  )
}
