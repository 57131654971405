import Box from '@parishconnect/box'
import {
  Button,
  Heading,
  KeyIcon,
  majorScale,
  SaveIcon,
  TextInputField,
} from '@parishconnect/react-ui'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { useChangePasswordMutation } from '../../graphql/generated/graphql-hooks'
import { UserPaneWrapper } from './UserPaneWrapper'

const PasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Required'),
  newPassword: yup
    .string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Required'),
  reNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Required'),
})

export default function Password() {
  const [changePassword] = useChangePasswordMutation()
  return (
    <UserPaneWrapper>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          reNewPassword: '',
        }}
        validationSchema={PasswordSchema}
        onSubmit={async (values, actions) => {
          const { currentPassword, newPassword } = values
          await changePassword({
            variables: { currentPassword, newPassword },
          })
          actions.resetForm()
          actions.setSubmitting(false)
        }}
      >
        {({ values, submitForm, dirty, isSubmitting, errors, submitCount }) => (
          <Form>
            <Box display="flex" justifyContent="space-between">
              <Heading marginBottom={majorScale(4)} size={700} color="theme">
                Password
              </Heading>
              <Button
                type="submit"
                onClick={submitForm}
                appearance="primary"
                disabled={!dirty}
                iconBefore={SaveIcon}
                loading={isSubmitting}
              >
                Save
              </Button>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns={['1fr', `repeat(auto-fit, minmax(${majorScale(30)}px, 1fr))`]}
              gridGap={majorScale(4)}
            >
              <TextInputField
                is={Field}
                value={values.currentPassword || ''}
                disabled={isSubmitting}
                autocomplete="current-password"
                name="currentPassword"
                label="Current Password"
                type="password"
                spellCheck={false}
                isInvalid={
                  submitCount > 0 && errors.currentPassword && errors.currentPassword.length > 0
                }
                validationMessage={
                  submitCount > 0 && errors.currentPassword && errors.currentPassword
                }
              />
              <TextInputField
                is={Field}
                value={values.newPassword || ''}
                disabled={isSubmitting}
                gridRow={[null, '2']}
                autocomplete="new-password"
                name="newPassword"
                label="New Password"
                type="password"
                spellCheck={false}
                isInvalid={submitCount > 0 && errors.newPassword && errors.newPassword.length > 0}
                validationMessage={submitCount > 0 && errors.newPassword && errors.newPassword}
              />
              <TextInputField
                is={Field}
                value={values.reNewPassword || ''}
                disabled={isSubmitting}
                gridRow={[null, '2']}
                autocomplete="new-password"
                name="reNewPassword"
                label="Confirm New Password"
                type="password"
                spellCheck={false}
                isInvalid={
                  submitCount > 0 && errors.reNewPassword && errors.reNewPassword.length > 0
                }
                validationMessage={submitCount > 0 && errors.reNewPassword && errors.reNewPassword}
              />
            </Box>
            <Button
              appearance="primary"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
              iconBefore={KeyIcon}
            >
              Save Password
            </Button>
          </Form>
        )}
      </Formik>
    </UserPaneWrapper>
  )
}
