import React, { useContext } from 'react'
import { Image, majorScale, BackButton, ThemeContext, Card } from '@parishconnect/react-ui'
import Box from '@parishconnect/box'
import useRouter from 'use-react-router'
import ProgressiveImage from 'react-progressive-image'
import { EventQuery } from '../../graphql/generated/graphql-hooks'

export const SingleEventImage = (image: EventQuery['event']['image']) => {
  const { lqip, src, srcset, title = '' } = image
  const { history } = useRouter()
  const theme = useContext(ThemeContext)
  const height = [majorScale(20), null, majorScale(28)]

  return (
    <Box position="relative">
      {src ? (
        <ProgressiveImage src={src} placeholder={lqip}>
          {(src: string) => (
            <Image
              role="presentation"
              objectPosition="center"
              borderRadius={8}
              width="100%"
              maxWidth={majorScale(62)}
              height={height}
              backgroundColor="#ccc"
              src={src}
              srcSet={srcset}
              alt={title}
              transition="300ms"
              css={{ objectFit: 'cover' }}
            />
          )}
        </ProgressiveImage>
      ) : (
        <Card background={theme.palette[theme.themeColor].base} height={height} />
      )}
      <BackButton
        onClick={() => history.goBack()}
        height={34}
        position="absolute"
        top={[majorScale(1), majorScale(2)]}
        left={[majorScale(1), majorScale(2)]}
        border="none"
        background="rgba(0,0,0,0.7)"
        appearance="overlay"
        color="white"
        round
        zIndex={8}
      />
    </Box>
  )
}

export default SingleEventImage
