import Box, { BoxProps } from '@parishconnect/box'
import {
  Text,
  AlertTriangleIcon,
  Pane,
  Heading,
  majorScale,
} from '@parishconnect/react-ui'
import React, { ReactNode, ComponentType } from 'react'
import { IconProps } from '@parishconnect/react-ui/dist/icons/src/Icon'
import { IntentType } from '@parishconnect/react-ui/dist/constants'

export type ErrorProps = BoxProps & {
  icon?: ComponentType<IconProps>
  title?: string
  description?: string
  action?: ReactNode
  intent?: IntentType
}

export function Error({
  icon: Icon = AlertTriangleIcon,
  title = 'Something went wrong...',
  description,
  action: Action,
  intent = 'warning',
  ...rest
}: ErrorProps) {
  return (
    <Box
      width="100%"
      height="-webkit-fill-available"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      {...rest}
    >
      <Pane background="tint1" padding={64} borderRadius="100%">
        <Icon size={98} color={intent} />
      </Pane>
      <Heading size={700} marginTop={majorScale(3)}>
        {title}
      </Heading>
      {description && <Text marginTop={majorScale(1)}>{description}</Text>}
      {Action && Action}
    </Box>
  )
}
