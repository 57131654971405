import Box from '@parishconnect/box'
import { Button, ChevronRightIcon, Heading, majorScale } from '@parishconnect/react-ui'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GroupQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils/constants'
import { FeaturedPost } from '../Post'

export function SingleGroupFeaturedPosts({ posts = [] }: GroupQuery) {
  const height = majorScale(28)
  const width = majorScale(38)
  const { pathname } = useLocation()

  if (!posts.length) return null

  return posts.length >= 1 ? (
    <Box maxWidth={['100%', width * 2 + COLUMN / 2]} marginTop={majorScale(3)}>
      <Box display="flex" gridColumn="2 / -2">
        <Heading marginBottom={majorScale(2)} size={700}>
          Latest Posts
        </Heading>
        <Button
          is={Link}
          to={pathname + '/posts'}
          marginLeft={['auto', majorScale(2)]}
          iconAfter={ChevronRightIcon}
        >
          All Group Posts
        </Button>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={[`repeat(2, minmax(${majorScale(28)}px, 1fr))`]}
        gridTemplateRows={`${majorScale(30)}px`}
        gap={COLUMN / 2}
      >
        {posts.map((post) => (
          <FeaturedPost {...post} key={post.id} />
        ))}
      </Box>
    </Box>
  ) : null
}
