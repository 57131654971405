import Box from '@parishconnect/box'
import {
  FormField,
  majorScale,
  Menu,
  minorScale,
  Text,
  TextInput,
  ThemeContext,
} from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { ActionMeta } from 'react-select'
import Select from 'react-select/creatable'
import { useSeasonsQuery } from '../../graphql/generated/graphql-hooks'

type SeasonSuggestProps = {
  onChange: (season: { label: string; value: string }, action: ActionMeta<{}>) => void
  value: { label: string; value: string }
}

export function SeasonSuggest({ onChange, value = {} }: SeasonSuggestProps) {
  const { data, loading } = useSeasonsQuery()
  const theme = useContext(ThemeContext)
  const loadedSeasons = data?.seasons ?? []

  return (
    <FormField label="Season (optional)" marginBottom={majorScale(2)}>
      <Select
        defaultOptions={data?.seasons}
        options={[value].concat(
          loadedSeasons.map((season) => ({ value: season.id, label: season.name })),
        )}
        placeholder=""
        onChange={onChange}
        components={{
          Input: ({ ...props }) => (
            <TextInput
              size={300}
              {...props}
              placeholder={!value?.label && 'Add this mass to a seasonal schedule...'}
            />
          ),
          DropdownIndicator: null,
          ClearIndicator: null,
          Option: (props) =>
            props.children ? (
              <Menu.Item
                disabled={props.isDisabled}
                onSelect={() => props.selectOption(props.data)}
                {...props}
              />
            ) : (
              <Box />
            ),
          SingleValue: (props) => (
            <Text
              size={300}
              lineHeight="32px"
              marginLeft={minorScale(3)}
              css={theme.getTextInputCSS('default', theme.themeColor)}
              position="absolute"
              {...props}
            />
          ),
        }}
        styles={{
          control: () => ({
            position: 'relative',
            width: majorScale(35),
          }),
          container: () => ({ position: 'relative', width: majorScale(35) }),
          valueContainer: () => null,
          input: () => null,
        }}
      />
    </FormField>
  )
}
