import Box from '@parishconnect/box'
import { Heading, majorScale, Pane } from '@parishconnect/react-ui'
import React from 'react'
import { useParishQuery } from '../../graphql/generated/graphql-hooks'
import { COLUMN } from '../../utils'
import { AdminSelector } from './AdminSelector'
import { HeroSelector } from './HeroSelector'
import { LinksEditor } from './LinksEditor'
import { ThemeSelector } from './ThemeSelector'
import { StaffSelector } from './StaffSelector'

export function DashboardHome() {
  const { data, loading } = useParishQuery()

  if (loading) return null

  return (
    <Box display="flex" flexWrap={['wrap', null, null, 'initial']}>
      <Box maxWidth={COLUMN * 10}>
        <Heading marginTop={[majorScale(2), majorScale(2)]} marginBottom={majorScale(4)} size={800}>
          {`Manage ${data?.parish?.name ?? 'Home'}`}
        </Heading>
        <HeroSelector {...data?.parish} />
        <ThemeSelector {...data?.parish} />
        <LinksEditor {...data?.parish} />
      </Box>
      <Pane
        borderLeft
        borderLeftWidth={[0, null, null, 1]}
        marginLeft={[null, null, null, majorScale(3)]}
        paddingLeft={[null, null, null, majorScale(3)]}
        marginTop={[null, null, null, majorScale(8)]}
      >
        <AdminSelector {...data?.parish} />
        {/* <StaffSelector /> */}
      </Pane>
    </Box>
  )
}
