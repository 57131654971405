import Box from '@parishconnect/box'
import { Heading, majorScale, Text, ClockIcon } from '@parishconnect/react-ui'
import React, { useMemo } from 'react'
import { MassTable, SpecialMassTable } from '.'
import { SacramentsQuery, useMassesQuery } from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils/abilityContext'
import { COLUMN } from '../../utils/constants'
import { MassManagementDialog } from './MassManagementDialog'
import { getFerialMasses, getSpecialMasses, getSundayMasses, hydrateMasses } from './utils'
import { DateTime } from 'luxon'
import { isEmpty } from 'lodash-es'
import { EmptyState } from '../shared/EmptyState'

type MassesProps = {
  parish: SacramentsQuery['parish']
}

const Masses = ({ parish }: MassesProps) => {
  const { data, fetchMore, loading } = useMassesQuery()
  const masses = useMemo(() => hydrateMasses(data?.masses, true), [data])

  return (
    <Box marginBottom={majorScale(10)}>
      <Can I="update" this={{ __typename: 'Mass', parish }}>
        <MassManagementDialog fetchMore={fetchMore} data={data} loading={loading} />
      </Can>
      {isEmpty(masses) ? (
        <EmptyState icon={ClockIcon} title="No Masses" />
      ) : (
        <>
          <SpecialMassTable masses={getSpecialMasses(masses)} />
          <Box marginX={[majorScale(2), 0]}>
            <Heading size={700} marginBottom={majorScale(2)}>
              Regular Schedule
            </Heading>
            <Box
              display="grid"
              gridTemplateColumns={[
                '1fr',
                `repeat(2, minmax(${majorScale(36)}px, ${majorScale(39)}px))`,
              ]}
              gap={COLUMN}
            >
              <MassTable masses={getFerialMasses(masses)} heading="Daily" />
              <MassTable masses={getSundayMasses(masses)} heading="Sunday" />
            </Box>
            {masses?.[0]?.nextDate.zoneName !== DateTime.local().zoneName && (
              <Text color="muted" marginTop={majorScale(2)}>
                *Schedule is in {masses?.[0]?.nextDate.offsetNameLong}
              </Text>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default Masses
