import Box from '@parishconnect/box'
import React from 'react'
import { SacramentQuery, SacramentType } from '../../graphql/generated/graphql-hooks'
import { Masses } from '../Mass'
import { GenericSacramentBody } from './GenericSacramentBody'

const RenderSacramentBody = ({ sacrament, parish }: SacramentQuery) => {
  switch (sacrament && sacrament.type) {
    case SacramentType.Mass:
      return (
        <Box>
          <GenericSacramentBody sacrament={sacrament} parish={parish} />
          <Masses parish={parish} />
        </Box>
      )
    default:
      return <GenericSacramentBody sacrament={sacrament} parish={parish} />
  }
}

export default RenderSacramentBody
