import { SubRenderTreeProps, RenderTree } from '@remirror/react-renderer'
import React from 'react'

export function Doc({ node, ...props }: SubRenderTreeProps) {
  const content = node.content
  if (!content || !content.length) {
    return null
  }

  const children = content.map((child, ii) => {
    return <RenderTree json={child} {...(props as any)} key={ii} />
  })

  return <div {...(node.attrs || {})}>{children}</div>
}
