import Box from '@parishconnect/box/dist/box'
import {
  CombiningHandler,
  ConditionalHandler,
  DefaultHandler,
  DependentHandler,
  ExtractStorePlugin,
  PluginSimpleStack,
  ReferencingHandler,
  validateSchema,
  validators,
  WidgetRenderer,
  WidgetsBindingFactory,
} from '@ui-schema/ui-schema'
import React from 'react'
import { StringWidget } from './StringWidget'
// "global" ui-config
import { UIMetaProvider } from '@ui-schema/ui-schema/UIMeta'
// for data-stores / data-binding
import { storeUpdater } from '@ui-schema/ui-schema/storeUpdater'
import { createStore, UIStoreProvider } from '@ui-schema/ui-schema/UIStore'

// util for `PluginStack` rendering
import { injectPluginStack } from '@ui-schema/ui-schema/applyPluginStack'

// for validity checking
import { isInvalid, ValidityReporter } from '@ui-schema/ui-schema/ValidityReporter'
// for deep immutables
import { createOrderedMap } from '@ui-schema/ui-schema/Utils/createMap'
// for `t` keyword support / basic in-schema translation
import { relTranslator } from '@ui-schema/ui-schema/Translate/relT'
import { NumberWidget } from './NumberWidget'
import { Button, Card, Heading, HomeIcon, TextInputField } from '@parishconnect/react-ui'
import safeParse from 'easy-json-parse'
import { List, Map } from 'immutable'
import { SelectWidget } from './SelectWidget'
import { Formik } from 'formik'
import useRouter from 'use-react-router'
import { useSubmitFormMutation } from '../../graphql/generated/graphql-hooks'
import { Link } from 'react-router-dom'

export const widgets: WidgetsBindingFactory = {
  pluginStack: [
    ReferencingHandler,
    ExtractStorePlugin,
    CombiningHandler,
    DefaultHandler,
    DependentHandler,
    ConditionalHandler,
    PluginSimpleStack,
    ValidityReporter,
  ],
  GroupRenderer: ({ children }) => <Box>{children}</Box>,
  WidgetRenderer: WidgetRenderer,
  pluginSimpleStack: validators,
  types: {
    string: StringWidget,
    number: NumberWidget,
  },
  custom: {
    select: SelectWidget,
  },
}

export const RootContainer = injectPluginStack(Box)

export function FormViewer({ ast }: { ast: string }) {
  const { match } = useRouter<{ id: string }>()
  const { id } = match.params
  const [store, setStore] = React.useState(() => createStore(createOrderedMap({})))

  const schema = React.useMemo(() => createOrderedMap(ast ? JSON.parse(ast) : {}), [ast])

  const [success, setSuccess] = React.useState(false)

  const [submitForm, { loading }] = useSubmitFormMutation()

  const onChange = React.useCallback(
    (actions) => {
      setStore(storeUpdater(actions))
    },
    [setStore],
  )

  if (success) {
    return (
      <Card
        border
        css={{
          width: '100%',
          padding: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 15,
        }}
      >
        <Heading>Thank you for submitting!</Heading>
        <Button is={Link} to="/" iconBefore={HomeIcon} appearance="primary">
          Go Home
        </Button>
      </Card>
    )
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        try {
          await submitForm({
            variables: {
              id,
              data: JSON.stringify(store?.valuesToJS()),
            },
          })
        } catch (error) {}

        setSuccess(true)
      }}
    >
      {({ submitForm }) => (
        <Card
          border
          css={{
            width: '100%',
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <UIMetaProvider widgets={widgets} t={relTranslator}>
            <UIStoreProvider store={store} onChange={onChange} showValidity={true}>
              <RootContainer isRoot schema={schema} />
            </UIStoreProvider>
          </UIMetaProvider>

          <Box display="flex" marginLeft="auto">
            <Button
              disabled={!!isInvalid(store.getValidity())}
              isLoading={loading}
              onClick={submitForm}
              appearance="primary"
              intent="success"
            >
              Submit
            </Button>
          </Box>
        </Card>
      )}
    </Formik>
  )
}
