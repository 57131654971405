import Box from '@parishconnect/box'
import {
  Badge,
  Card,
  CheckIcon,
  Heading,
  majorScale,
  minorScale,
  Text,
  ThemeContext,
} from '@parishconnect/react-ui'
import color from 'color'
import { truncate } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useContext } from 'react'
import ProgressiveImage from 'react-progressive-image'
import { Link } from 'react-router-dom'
import { EventsQuery } from '../../graphql/generated/graphql-hooks'
import {
  cssLineClamp,
  getFullTimeString,
  getShortEventDateString,
  getStartTimeString,
} from '../../utils'

export const EventModule = ({
  image,
  title,
  duration,
  viewer,
  parish,
  group,
  dtstart,
  id,
  info = true,
}: EventsQuery['events'][0] & { info?: boolean }) => {
  const theme = useContext(ThemeContext)
  const height = majorScale(25)

  const date = DateTime.fromISO(dtstart)
  const beforeThisYear = date.year < DateTime.local().year

  return (
    <Box is={Link} to={`/event/${id}`} css={{ scrollSnapAlign: 'center' }}>
      <ProgressiveImage src={image?.src} placeholder={image?.lqip}>
        {(src: string) => (
          <Card
            backgroundImage={image && `url(${src})`}
            appearance={!image && 'gradient'}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            width="100%"
            height={height}
            position="relative"
            cursor="pointer"
            borderRadius={8}
            transition="150ms"
            hoverElevation={3}
            elevation={1}
            css={{
              '&::before': {
                content: '""',
                display: 'block',
                backgroundImage:
                  image &&
                  `linear-gradient(transparent, ${color(theme.palette[theme.themeColor].dark)
                    .darken(0.65)
                    .desaturate(0.65)})`,
                position: 'absolute',
                borderRadius: 8,
                bottom: 0,
                left: 0,
                width: '100%',
                height: '75%',
                zIndex: 1,
              },
            }}
          >
            <Box
              borderRadius={majorScale(1)}
              display="flex"
              flexDirection="column"
              position="absolute"
              top={majorScale(1)}
              left={majorScale(1)}
              color="white"
              padding={minorScale(3)}
              zIndex={3}
              backgroundColor="rgba(0,0,0,0.2)"
              css={{ backdropFilter: 'blur(20px) saturate(180%)' }}
            >
              <Heading opacity={1} size={700}>
                {date.toLocaleString({ day: 'numeric' })}
              </Heading>
              <Heading opacity={1} size={100}>
                {date.toLocaleString({ month: 'short' })}
              </Heading>
              {beforeThisYear && (
                <Heading opacity={1} size={100}>
                  {date.toLocaleString({ year: 'numeric' })}
                </Heading>
              )}
            </Box>
            <Box zIndex={3} padding={majorScale(2)} position="absolute" bottom={0} left={0}>
              <Heading color="white" size={600} css={cssLineClamp()}>
                {title}
              </Heading>
              <Box display="flex" alignItems="center" gap={majorScale(1)}>
                {dtstart && (
                  <Text whiteSpace="pre" color="white">
                    {getStartTimeString(dtstart)}
                  </Text>
                )}
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="white"
                  maxWidth={majorScale(25)}
                >
                  {group?.name || parish?.name}
                </Text>
              </Box>
            </Box>
            {viewer?.hasRSVP && (
              <Badge
                position="absolute"
                top={minorScale(2)}
                right={minorScale(2)}
                zIndex={3}
                color="green"
              >
                <Box display="flex" alignItems="center">
                  <CheckIcon size={10} marginRight={minorScale(1)} />
                  Going
                </Box>
              </Badge>
            )}
          </Card>
        )}
      </ProgressiveImage>
    </Box>
  )
}
