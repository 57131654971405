import Box from '@parishconnect/box'
import {
  Alert,
  Button,
  Card,
  FormField,
  Heading,
  majorScale,
  Paragraph,
  SegmentedControl,
  SelectField,
  SendIcon,
  Textarea,
  TextInputField,
} from '@parishconnect/react-ui'
import { EDITOR_CLASS_SELECTOR } from '@remirror/core'
import { RemirrorStateListenerParams } from '@remirror/react'
import * as Sentry from '@sentry/browser'
import parseJSON from 'easy-json-parse'
import { Formik } from 'formik'
import { capitalize, debounce } from 'lodash-es'
import React, { FormEvent } from 'react'
import { Helmet } from 'react-helmet-async'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { number, object, string } from 'yup'
import { Editor, FeaturedImageUpload, Nav, PageContainer } from '../components'
import {
  GroupInput,
  Privacy,
  useAddGroupMutation,
  useUserQuery,
  useParishQuery,
  Level,
} from '../graphql/generated/graphql-hooks'
import { COLUMN, getRoleForParish } from '../utils'

const groupValidationSchema = object().shape<GroupInput>({
  name: string().required().max(120),
  image: string(),
  coverPosition: object().shape({ y: number() }),
  privacy: string().oneOf(Object.values(Privacy)).required(),
  excerpt: string(),
  description: string(),
})

export default function NewGroup({ history }: RouteComponentProps) {
  const [addGroup, { loading }] = useAddGroupMutation()
  const { data: { user } = {}, loading: userLoading } = useUserQuery()
  const { data: { parish } = {} } = useParishQuery()

  if (!userLoading && !user) {
    return <Redirect to="/login" />
  }

  return (
    <PageContainer>
      <Box display={['block', 'flex']}>
        <Box width={[0, COLUMN * 6]}>
          <Nav menuOpen />
        </Box>
        <Box width={['100%', COLUMN * 17.5]} marginLeft={[0, COLUMN / 2]}>
          <Formik
            onSubmit={async (group, actions) => {
              try {
                await addGroup({
                  variables: { group },
                  update(_, { data: { addGroup }, errors }) {
                    history.push(`/group/${addGroup.id}`)
                  },
                })
                actions.setSubmitting(false)
              } catch (error) {
                Sentry.captureException(error)
                actions.setErrors(error)
              }
            }}
            initialValues={{
              name: '',
              excerpt: '',
              privacy: Privacy.Public,
              description: '',
              cover: undefined,
              coverPosition: { y: 0 },
            }}
            validationSchema={groupValidationSchema}
          >
            {({ values, setFieldValue, submitCount, errors, submitForm }) => (
              <>
                <Helmet>
                  <title>{`Creating ${values?.name || 'New Group'}`}</title>
                </Helmet>
                <FeaturedImageUpload
                  initialFile={values.cover}
                  initialPosition={values.coverPosition}
                  onUpload={({ id, y = 0.5 }) => {
                    setFieldValue('cover', id)
                    setFieldValue('coverPosition', { y })
                  }}
                  onPositionChange={(y) => setFieldValue('coverPosition', { y })}
                  onRemove={() => {
                    setFieldValue('cover', undefined)
                    setFieldValue('coverPosition', undefined)
                  }}
                />
                {getRoleForParish(user, parish)?.level !== Level.Admin && (
                  <Alert
                    title="This group requires approval"
                    marginTop={majorScale(2)}
                    appearance="card"
                  >
                    Your group will not be visible to the public until an administrator approves it.
                  </Alert>
                )}
                <Box
                  display="flex"
                  width="100%"
                  paddingX={majorScale(3)}
                  paddingTop={majorScale(4)}
                >
                  <Box maxWidth={COLUMN * 10} width="100%">
                    <TextInputField
                      label="Name"
                      marginTop={majorScale(2)}
                      autoFocus
                      maxWidth={900}
                      value={values.name}
                      onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                        setFieldValue('name', e.currentTarget.value)
                      }}
                      rows={1}
                      overflow="hidden"
                      placeholder="Give this group a name..."
                    />
                    <TextInputField
                      is={Editor}
                      marginTop={majorScale(3)}
                      label="Description"
                      appearance="minimal"
                      toolbar={false}
                      borderRadius={6}
                      hint="**bold** _italic_ > quote"
                      initialContent={parseJSON(values.description)}
                      styles={{
                        [EDITOR_CLASS_SELECTOR]: {
                          padding: `6px 10px`,
                          fontSize: 12,
                          minHeight: `40px!important`,
                          margin: '0px !important',
                          borderRadius: 6,
                          background: '#EDF0F2',
                          color: '#454545',
                          p: {
                            margin: 0,
                            marginTop: `0px !important`,
                            fontSize: '12px !important',
                            '::before': {
                              fontStyle: 'normal !important',
                              color: '#8A8A8A !important',
                            },
                          },
                        },
                      }}
                      onChange={debounce((state: RemirrorStateListenerParams) => {
                        setFieldValue('description', JSON.stringify(state.getObjectNode()))
                        setFieldValue('excerpt', state.getText())
                      }, 350)}
                      placeholder="Tell people what your group is about. (Basic Formatting Supported)"
                      isInvalid={
                        submitCount > 0 && errors.description && errors.description.length > 0
                      }
                      validationMessage={
                        submitCount > 0 && errors.description && errors.description
                      }
                    />
                  </Box>
                  <Card
                    elevation={1}
                    padding={majorScale(2)}
                    marginLeft="auto"
                    display="flex"
                    flexDirection="column"
                  >
                    <FormField
                      marginBottom={majorScale(2)}
                      name="privacy"
                      label="Privacy"
                      hint="Private groups are coming soon"
                    >
                      <SegmentedControl
                        width="calc(100% - 20px)"
                        marginLeft="auto"
                        marginRight="auto"
                        marginBottom={majorScale(2)}
                        onChange={(value: string) => {
                          setFieldValue('privacy', value)
                        }}
                        value={values.privacy}
                        options={[
                          {
                            label: 'Public',
                            value: Privacy.Public,
                          },
                          // {
                          //   label: 'Private',
                          //   value: Privacy.Private,
                          // },
                        ]}
                      />
                    </FormField>
                    <Box marginTop="auto">
                      <Button
                        type="button"
                        marginRight={majorScale(1)}
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      <Button
                        isLoading={loading}
                        type="submit"
                        appearance="primary"
                        iconBefore={SendIcon}
                        onClick={submitForm}
                      >
                        Publish
                      </Button>
                    </Box>
                  </Card>
                </Box>
              </>
            )}
          </Formik>

          <Box paddingX={majorScale(3)} paddingTop={majorScale(4)}>
            <Card width="100%" appearance="solid" padding={majorScale(3)} maxWidth={COLUMN * 10}>
              <Heading color="theme" size={100} paddingBottom={majorScale(1)}>
                Creating a Group or Ministry
              </Heading>
              <Paragraph>
                Every ministry at {parish?.name} has a place on the website. When you create your
                group or ministry here it will be sent for approval.
                <br />
                <br /> Once your group is approved, you can start posting announcements, events and
                more either for just your group or for the whole parish.
              </Paragraph>
            </Card>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  )
}
