import Box from '@parishconnect/box'
import { Card, Heading, majorScale, minorScale } from '@parishconnect/react-ui'
import React from 'react'
import { cssLineClamp } from '../../utils'

export function DonationModule() {
  return (
    <Card
      is="a"
      href="/donate"
      role="listitem"
      height="0"
      paddingBottom="150%"
      position="relative"
      backgroundSize="270%"
      backgroundPosition="center"
      css={{
        '::before': {
          content: '""',
          backgroundImage: 'url("https://parishconnect-bkt.s3.amazonaws.com/donate.png")',
          width: '100%',
          height: '100%',
          backgroundRepeat: 'repeat',
          position: 'absolute',
          backgroundSize: '50%',
          opacity: 0.2,
          transform: 'rotate(-30deg) scale(1.6)',
        },
        scrollSnapAlign: 'center',
        boxShadow:
          'inset 0 0 0 0px hsla(334, 79%, 50%, 0.35), 0 1px 6px -0.5px hsla(334, 79%, 50%, 0.5)',
        background:
          'linear-gradient(-25deg,hsla(334, 79%, 50%, 1),hsla(314, 79%, 65%, 1)), url("https://parishconnect-bkt.s3.amazonaws.com/donate.png")',
        '&:not([disabled]):not([data-disabled]):hover': {
          transform: 'translateY(-1px)',
          boxShadow: '0 3px 6px 0 hsla(334, 79%, 50%, 0.75) !important',
        },
        '&:not([disabled]):not([data-disabled]):active, &:not([disabled]):not([data-disabled]):focus': {
          boxShadow: '0 0 0 2px hsla(334, 79%, 50%, 0.5) !important',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        padding={majorScale(1)}
        position="absolute"
        height="100%"
        width="100%"
        zIndex={3}
      >
        <Box marginTop="auto" padding={minorScale(1)}>
          <Heading color="rgba(255, 255, 255, 0.7)" size={100}>
            Give Online
          </Heading>
          <Heading color="white" size={500} css={cssLineClamp()}>
            Donate
          </Heading>
        </Box>
      </Box>
    </Card>
  )
}
