import Box from '@parishconnect/box'
import {
  Card,
  CheckIcon,
  IconButton,
  majorScale,
  Portal,
  TextInput,
  UnlinkIcon,
  StackingContext,
  Stack,
} from '@parishconnect/react-ui'
import { DecorationSet, Decoration } from 'prosemirror-view'
import { getMarkAttrs, getMarkRange } from '@remirror/core'
import { bubblePositioner, useRemirrorContext, GetPositionParams } from '@remirror/react'
import React, {
  ChangeEventHandler,
  DOMAttributes,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
  FocusEvent,
} from 'react'
import { keyName } from 'w3c-keyname'
import { string } from 'yup'

function selectionIsOnlyLink(params: GetPositionParams<any>): boolean {
  return !!getMarkRange(params.newState.selection.$anchor, params.newState.schema.marks.link)
}

type BubbleMenuProps = {
  wrapperRef: MutableRefObject<HTMLDivElement>
  linkActivated: boolean
  deactivateLink: () => void
  activateLink: () => void
}

export function BubbleMenu({ linkActivated = false, deactivateLink, wrapperRef }: BubbleMenuProps) {
  const { getPositionerProps, actions, manager, state, helpers } = useRemirrorContext()

  const activatedLinkHref = useMemo<string | undefined>(() => {
    return getMarkAttrs(state.newState, manager.schema.marks.link).href
  }, [manager, state])

  const positionerProps = getPositionerProps({
    ...bubblePositioner,
    hasChanged: () => true,
    isActive: (params) => {
      const isActive =
        !params.view.dragging &&
        ((bubblePositioner.isActive(params) && linkActivated) ||
          (bubblePositioner.isActive(params) && !!activatedLinkHref && selectionIsOnlyLink(params)))

      return isActive
    },
    positionerId: 'bubbleMenu',
  })

  const { bottom, ref, left, isActive } = positionerProps

  const updateLink = (href: string) => actions.updateLink({ href })
  const removeLink = () => actions.removeLink()
  const canRemove = () => actions.removeLink.isActive()
  const selectAll = () => actions.selectLink()

  return (
    <Stack>
      {(zIndex: number) => (
        <Card
          elevation={3}
          innerRef={ref}
          bottom={bottom + majorScale(3)}
          left={left}
          position="absolute"
          zIndex={zIndex}
          appearance="white"
          padding={majorScale(1)}
          autoFocus={true}
        >
          {isActive && (
            <LinkInput
              {...{ deactivateLink, updateLink, removeLink, canRemove, selectAll }}
              defaultValue={activatedLinkHref}
            />
          )}
        </Card>
      )}
    </Stack>
  )
}

type LinkInputProps = Pick<BubbleMenuProps, 'deactivateLink'> & {
  defaultValue?: string
  updateLink(href: string): void
  removeLink(): void
  canRemove(): boolean
  selectAll: () => void
}

export function LinkInput({
  canRemove,
  removeLink,
  updateLink,
  selectAll,
  defaultValue,
  deactivateLink,
}: LinkInputProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [href, setHref] = useState(defaultValue)

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setHref(event.target.value)
  }

  const submitLink = () => {
    updateLink(href)
    deactivateLink()
  }

  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const key = keyName(event.nativeEvent)
    if (key === 'Escape') {
      event.preventDefault()
      deactivateLink()
    }

    if (key === 'Enter') {
      event.preventDefault()
      submitLink()
    }
  }

  const onClickRemoveLink: DOMAttributes<HTMLButtonElement>['onClick'] = (event) => {
    event.preventDefault()
    canRemove && removeLink()
    deactivateLink()
  }

  const handleClick = (event: MouseEvent) => {
    if (!wrapperRef.current || wrapperRef.current.contains(event.target as Node)) {
      return
    }
    deactivateLink()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => {
      document.removeEventListener('mousedown', handleClick, false)
    }
  })

  return (
    <Box innerRef={wrapperRef} display="flex">
      <TextInput
        defaultValue={defaultValue}
        placeholder="Enter URL..."
        onChange={onChange}
        onSubmit={submitLink}
        marginRight={majorScale(1)}
        onKeyPress={onKeyPress}
        onFocus={(e: FocusEvent<HTMLInputElement>) => {
          selectAll()
          e.preventDefault()
          e.currentTarget.select()
          e.currentTarget.focus()
        }}
      />
      <IconButton
        appearance="minimal"
        icon={UnlinkIcon}
        intent="danger"
        onClick={onClickRemoveLink}
        disabled={!defaultValue}
      />
      <IconButton
        appearance="minimal"
        disabled={!string().url().required().isValidSync(href)}
        intent="success"
        icon={CheckIcon}
        onClick={() => updateLink(href)}
      />
    </Box>
  )
}
