import { Card, Heading, majorScale, minorScale, Text, ThemeContext } from '@parishconnect/react-ui'
import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import { MassType, RRuleConfig } from '../../graphql/generated/graphql-hooks'
import { useToggle } from '../../utils'
import { UpdateMass } from './UpdateMass'
import { ComputedMass } from './utils'
import { DateTime } from 'luxon'

type MassCardType = {
  mass: ComputedMass
  preview?: boolean
}

function translateFrequency(frequency: RRuleConfig['frequency']) {
  switch (frequency) {
    case 'WEEKLY':
    default:
      return 'week'
  }
}

function getExceptionText(mass: ComputedMass) {
  const exceptionsCount = mass.exdates.length
  if (exceptionsCount > 0) {
    return ` with ${exceptionsCount} exception${exceptionsCount > 1 ? 's' : ''}`
  }
  return ''
}

function getSuperTitle(mass: ComputedMass) {
  if (!mass) return ''
  switch (mass.type) {
    case MassType.Recurring:
      return `Every ${translateFrequency(mass.rule.config.frequency)}${getExceptionText(mass)}`
    case MassType.Single:
      return (
        mass.title ||
        `Once on ${mass?.nextDate?.toLocaleString({
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}`
      )
    default:
      return ''
  }
}

export default function MassCard({ mass, preview }: MassCardType) {
  const MASS_FORMAT = { weekday: 'long', hour: 'numeric', minute: '2-digit' }
  const theme = useContext(ThemeContext)
  const [isShown, toggleShown] = useToggle(false)

  return (
    <>
      {!preview && <UpdateMass mass={mass} isShown={isShown} toggleShown={toggleShown} />}
      <Card
        is={motion.div}
        positionTransition
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        zIndex={500}
        position="relative"
        paddingX={majorScale(3)}
        paddingY={minorScale(3)}
        width="100%"
        maxWidth={majorScale(36)}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        hoverElevation={!preview && 1}
        elevation={0}
        cursor={preview ? null : 'pointer'}
        userSelect="none"
        appearance="solid"
        border={mass.type === MassType.Recurring}
        onClick={!preview && toggleShown}
        borderColor={theme.palette[theme.themeColor].light}
      >
        <Text>{getSuperTitle(mass)}</Text>
        <Heading size={600}>
          {mass.type === MassType.Recurring
            ? mass?.nextDate?.toLocaleString(MASS_FORMAT)
            : mass?.nextDate?.toLocaleString(DateTime.DATETIME_MED)}
        </Heading>
        <Text color="theme" height={majorScale(2)} size={300}>
          {mass.note}
        </Text>
      </Card>
    </>
  )
}
