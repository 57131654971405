export function cssLineClamp(lines = 2): any {
  if (lines === 1) {
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      width: '100%',
      whiteSpace: 'nowrap',
    }
  }
  return {
    display: '-webkit-box',
    WebkitLineClamp: `${lines}`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
  }
}
