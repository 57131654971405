import Box from '@parishconnect/box'
import { InfoIcon } from '@parishconnect/react-ui'
import { sortBy } from 'lodash-es'
import React, { useContext, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, Route, Switch } from 'react-router'
import useRouter from 'use-react-router'
import { Footer, Nav, NewAbout, PageContainer, SingleAbout } from '../components'
import { EmptyState } from '../components/shared/EmptyState'
import { About as AboutType, useAboutsQuery } from '../graphql/generated/graphql-hooks'
import { AbilityContext } from '../utils'
import { COLUMN } from '../utils/constants'

const createAboutSubmenu = (about: AboutType[]) =>
  about?.length >= 1
    ? sortBy(
        about?.map(({ id, title }) => ({ title, id, to: id })),
        'order',
      )
    : []

const About = () => {
  const { data, loading, error } = useAboutsQuery()
  const ability = useContext(AbilityContext)

  const aboutSubmenu = useMemo(() => createAboutSubmenu(data?.abouts), [data])

  const {
    match: { url },
    history,
  } = useRouter()

  if (loading) return null

  if (data?.abouts?.length <= 0) {
    if (ability.cannot('update', data?.parish)) {
      return (
        <PageContainer>
          <Box display="flex">
            <Box width={[0, null, COLUMN * 6]}>
              <Nav menuOpen showMasses />
              <Footer />
            </Box>
            <Box marginLeft={[0, COLUMN]} width={['100%', COLUMN * 15]}>
              <EmptyState icon={InfoIcon} title="No About Information" />
            </Box>
          </Box>
        </PageContainer>
      )
    }
  }

  const aboutLimitReached = data?.abouts.length >= 10

  return (
    <PageContainer>
      <Box display="flex">
        <Helmet>
          <title>About</title>
        </Helmet>
        <Box width={[0, null, COLUMN * 6]}>
          <Nav
            root="/about"
            showMasses
            title="About"
            subMenu={aboutSubmenu}
            menuOpen={data?.abouts?.length <= 0}
            newItem={
              !aboutLimitReached && {
                title: 'New About Section',
                onClick: () => history.push('/about/new'),
                isShown: ability.can('update', data?.parish),
              }
            }
          />
          <Footer />
        </Box>
        <Box width={['100%', null, COLUMN * 17.5]} marginLeft={[0, null, COLUMN / 2]}>
          <Switch>
            <Route path="/about" exact>
              <Redirect to={`/about/${aboutSubmenu?.[0]?.id}`} />
            </Route>
            <Route
              exact
              path="/about/new"
              component={(route: any) => {
                if (aboutLimitReached) {
                  return <Redirect from="/about/new" exact to={`${url}/${aboutSubmenu?.[0]?.id}`} />
                }

                return <NewAbout {...route} {...data} />
              }}
            />
            <Route
              path={`${url}/:id`}
              component={(route: any) => <SingleAbout {...route} {...data} />}
            />
          </Switch>
        </Box>
      </Box>
    </PageContainer>
  )
}

export default About
