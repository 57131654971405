import { FieldProps, getIn, Field } from 'formik'
import { TextInputField } from '@parishconnect/react-ui'
import React from 'react'

export default function FormikTextField({
  form,
  field,
  label,
  ...props
}: FieldProps & { label: string }) {
  const errorText = getIn(form.touched, field.name) && getIn(form.errors, field.name)

  return (
    <TextInputField
      is={Field}
      label={label}
      {...field}
      {...props}
      validationMessage={!!errorText && errorText}
    />
  )
}
