import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import {
  Button,
  Card,
  Heading,
  InlineAlert,
  Link,
  majorScale,
  Strong,
  Text,
  TextInputField,
  ThemeContext,
  SelectField,
} from '@parishconnect/react-ui'
import { Field, Form, Formik } from 'formik'
import React, { useContext, FormEvent } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, Redirect, RouteComponentProps } from 'react-router-dom'
import { string, object, ref } from 'yup'
import { PageContainer } from '../components'
import { useAddUserMutation, useUserQuery } from '../graphql/generated/graphql-hooks'
import { saintPlaceholder } from '../utils'
import { NAME_REGEX, prefixes } from '../utils/constants'

const signupFormSchema = object().shape({
  prefix: string().oneOf(prefixes).nullable().label('Prefix'),
  firstName: string()
    .max(50)
    .required()
    .trim()
    .matches(NAME_REGEX, '${path} cannot contain special characters'),
  lastName: string()
    .max(50)
    .required()
    .trim()
    .matches(NAME_REGEX, '${path} cannot contain special characters'),
  email: string().email().trim().required(),
  password: string().required().max(64).min(8),
  confirmPassword: string()
    .required()
    .oneOf([ref('password'), null], "Passwords don't match"),
})

export default function Signup({ history }: RouteComponentProps) {
  const [addUser] = useAddUserMutation()
  const { data } = useUserQuery()
  const { firstName, lastName } = saintPlaceholder()
  const theme = useContext(ThemeContext)

  return (
    <PageContainer>
      {data?.user && <Redirect to={'/user'} />}
      <Helmet>
        <title>Signup</title>
      </Helmet>
      <Box marginTop={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
        <Card
          appearance="white"
          elevation={1}
          padding={[majorScale(3), majorScale(6)]}
          width={majorScale(48)}
          maxWidth={`calc(100vw - ${majorScale(2)}px)`}
        >
          <Heading color="theme" size={600}>
            Join {data?.parish?.name} Parish
          </Heading>
          <Text>
            Create your <Strong>ParishConnect</Strong> account
          </Text>
          <Formik
            onSubmit={async ({ firstName, lastName, email, password }, actions) => {
              try {
                await addUser({
                  variables: {
                    user: {
                      firstName,
                      lastName,
                      email,
                      password,
                    },
                  },
                  update: (_, { data: { addUser } }) => {
                    actions.setSubmitting(false)
                    history.push('/signup-success', { ...addUser })
                  },
                })
              } catch (error) {
                Sentry.captureException(error)
                actions.setSubmitting(false)
                actions.setErrors(error)
              }
            }}
            validationSchema={signupFormSchema}
            initialValues={{
              prefix: '',
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              confirmPassword: '',
            }}
            render={({ errors, submitCount, isSubmitting, setFieldValue, values }) => (
              <Box is={Form} disabled={isSubmitting} marginTop={majorScale(2)}>
                {errors?.[0] && <InlineAlert intent="danger">{errors?.[0].message}</InlineAlert>}
                <SelectField
                  name="prefix"
                  label="Prefix (optional)"
                  value={values.prefix}
                  onChange={(e: FormEvent<HTMLSelectElement>) =>
                    setFieldValue('prefix', e.currentTarget.value)
                  }
                >
                  <option value="">Not Specified</option>
                  {prefixes.map((prefix) => (
                    <option key={prefix} value={prefix}>
                      {prefix}
                    </option>
                  ))}
                </SelectField>
                <TextInputField
                  is={Field}
                  name="firstName"
                  label="First Name"
                  type="text"
                  autoFocus
                  autocomplete="given-name"
                  isRequired
                  placeholder={firstName}
                  disabled={isSubmitting}
                  isInvalid={submitCount > 0 && errors.firstName && errors.firstName.length > 0}
                  validationMessage={submitCount > 0 && errors.firstName && errors.firstName}
                />
                <TextInputField
                  is={Field}
                  name="lastName"
                  label="Last Name"
                  type="text"
                  autocomplete="family-name"
                  isRequired
                  placeholder={lastName}
                  disabled={isSubmitting}
                  isInvalid={submitCount > 0 && errors.lastName && errors.lastName.length > 0}
                  validationMessage={submitCount > 0 && errors.lastName && errors.lastName}
                />
                <TextInputField
                  is={Field}
                  name="email"
                  label="Email"
                  type="email"
                  autocomplete="email"
                  isRequired
                  placeholder={`${firstName.toLowerCase()}.${lastName.toLowerCase()}@saint.va`}
                  disabled={isSubmitting}
                  isInvalid={submitCount > 0 && errors.email && errors.email.length > 0}
                  validationMessage={submitCount > 0 && errors.email && errors.email}
                />
                <TextInputField
                  is={Field}
                  name="password"
                  type="password"
                  label="Password"
                  autocomplete="new-password"
                  isRequired
                  placeholder="Enter a new password"
                  hint="Must be at least 8 characters"
                  disabled={isSubmitting}
                  isInvalid={submitCount > 0 && errors.password && errors.password.length > 0}
                  validationMessage={submitCount > 0 && errors.password && errors.password}
                />
                <TextInputField
                  is={Field}
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  autocomplete="new-password"
                  isRequired
                  placeholder="Confirm password"
                  disabled={isSubmitting}
                  isInvalid={
                    submitCount > 0 && errors.confirmPassword && errors.confirmPassword.length > 0
                  }
                  validationMessage={
                    submitCount > 0 && errors.confirmPassword && errors.confirmPassword
                  }
                />
                <Button type="submit" isLoading={isSubmitting} appearance="primary">
                  Signup
                </Button>
              </Box>
            )}
          />
        </Card>
        <Text marginTop={majorScale(2)}>
          Already have an account?{' '}
          <Link is={RouterLink} to="/login">
            Login
          </Link>
        </Text>
      </Box>
    </PageContainer>
  )
}
