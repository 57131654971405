import { Global } from '@emotion/core'
import Box from '@parishconnect/box'
import { Heading, majorScale, Spinner, ThemeContext } from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { BigAvatar, PageContainer, Password, Preferences, UserPreferencesMenu } from '../components'
import { MobileNav } from '../components/Nav/MobileNav'
import { Profile } from '../components/User/Profile'
import { useUserQuery } from '../graphql/generated/graphql-hooks'
import { Can } from '../utils/abilityContext'
import { COLUMN } from '../utils/constants'

export default function User({ match }: RouteComponentProps<{ id: string }>) {
  const userParamId = /[0-9a-fA-F]{24}/gi.test(match?.params?.id)
  const theme = useContext(ThemeContext)

  const { data, loading } = useUserQuery({
    variables: userParamId ? { id: match.params.id } : undefined,
  })

  if (loading) {
    return <Spinner />
  }

  return (
    <PageContainer>
      <Helmet>
        <title>{data?.user?.fullName}</title>
      </Helmet>
      <Global
        styles={{
          body: {
            background: theme.colors.background.tint2,
          },
        }}
      />
      <Box display={['block', 'flex']} marginTop={majorScale(3)} paddingX={[0, majorScale(8)]}>
        <Box width={['100%', majorScale(30)]}>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <BigAvatar {...data} />
            <Heading marginTop={majorScale(3)} size={600}>
              {data?.user?.fullName}
            </Heading>
            <Heading marginTop={majorScale(1)} color="theme">
              {data?.user?.primaryParish?.name}
            </Heading>
          </Box>
          <Can I="update" this={data?.user}>
            <UserPreferencesMenu user={data?.user} parish={data?.parish} />
          </Can>
        </Box>
        <Box width={['100%', COLUMN * 17.5]} marginLeft={[0, COLUMN]}>
          <Can I="update" this={data?.user}>
            <Switch>
              <Route path="/user/preferences" children={<Preferences user={data?.user} />} />
              <Route path="/user/password" component={Password} />
              <Route path="/user/:userId" children={<Profile {...data} />} />
            </Switch>
          </Can>
        </Box>
      </Box>
      <MobileNav showMasses={false} />
    </PageContainer>
  )
}
