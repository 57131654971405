import { Alert, majorScale } from '@parishconnect/react-ui'
import React from 'react'
import { GroupQuery, GroupStatus } from '../../graphql/generated/graphql-hooks'

export function GroupDisabledBanner({ status }: GroupQuery['group']) {
  if (status !== GroupStatus.Enabled) {
    return (
      <Alert
        title="This group is not enabled yet"
        intent="warning"
        marginRight={majorScale(5)}
        marginBottom={majorScale(2)}
      >
        A parish administrator must enable this group before anyone can join it.
      </Alert>
    )
  }

  return null
}
