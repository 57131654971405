import Box from '@parishconnect/box'
import { Heading, majorScale, Spinner } from '@parishconnect/react-ui'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { EventModule, Footer, Nav, PageContainer, PostModule } from '../components'
import { SearchResult, useSearchQuery } from '../graphql/generated/graphql-hooks'
import { COLUMN } from '../utils'
import { EmptyState } from '../components/shared/EmptyState'

export default function Search() {
  const query = new URLSearchParams(useLocation().search).get('s')
  const { data, loading } = useSearchQuery({ variables: { text: query } })

  return (
    <PageContainer>
      <Box display="flex">
        <Helmet>
          <title>Search</title>
        </Helmet>
        <Box width={[0, null, COLUMN * 6]}>
          <Nav showMasses title="Search" menuOpen />
          <Footer />
        </Box>
        <Box width={['100%', null, COLUMN * 17.5]} marginLeft={[0, null, COLUMN / 2]}>
          <Heading marginY={majorScale(3)} size={600}>
            Search results for "{query}"
          </Heading>
          {data?.search.length > 0 ? (
            <Box
              display="grid"
              gridAutoFlow="row"
              width="100%"
              gap={`${majorScale(3)}px ${majorScale(5)}px`}
              gridTemplateColumns={['1fr', `repeat(auto-fit, ${majorScale(38)}px)`]}
            >
              {data?.search.map((result) => (
                <ResultCard {...result} />
              ))}
            </Box>
          ) : loading ? (
            <Spinner />
          ) : (
            <EmptyState title="No Results" />
          )}
        </Box>
      </Box>
    </PageContainer>
  )
}

function ResultCard(item: Partial<SearchResult>) {
  return item.__typename === 'Event' ? <EventModule {...item} /> : <PostModule {...item} />
}
