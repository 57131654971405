import Box from '@parishconnect/box'
import JSONparse from 'easy-json-parse'
import {
  Button,
  Edit2Icon,
  Heading,
  majorScale,
  CornerUpLeftIcon,
  SaveIcon,
  toaster,
  TextInputField,
} from '@parishconnect/react-ui'
import React, { useState } from 'react'
import { GroupQuery, useEditGroupMutation } from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils'
import { COLUMN } from '../../utils/constants'
import { Renderer, Editor } from '../Editor'
import { EMPTY_PARAGRAPH_NODE } from '@remirror/core'
import { debounce } from 'lodash-es'

export function SingleGroupDescription(group: GroupQuery['group']) {
  const [editing, setEditing] = useState(false)
  const [newContent, setNewContent] = useState(null)
  const [newExcerpt, setNewExcerpt] = useState(null)
  const [editGroup, { loading }] = useEditGroupMutation()
  const width = majorScale(42)

  function toggleEditing() {
    if (editing) {
      setEditing(false)
      setNewContent(null)
    } else {
      setEditing(true)
    }
  }

  async function saveDescription() {
    await editGroup({
      variables: {
        id: group.id,
        group: {
          description: JSON.stringify(newContent),
          excerpt: newExcerpt,
        },
      },
    })
    toaster.success('Success!', { description: `"${group.name}" description saved.` })
    toggleEditing()
  }

  return (
    <Box maxWidth={width * 2 + COLUMN / 2} position="relative">
      <Heading size={600}>Description</Heading>
      {editing ? (
        <TextInputField
          is={Editor}
          hint="Formatting: **bold** _italic_ > quote"
          label=""
          initialContent={JSONparse(group?.description, { initialValue: EMPTY_PARAGRAPH_NODE })[1]}
          appearance="minimal"
          onChange={debounce((value: any) => {
            setNewContent(value.getJSON().doc)
            setNewExcerpt(value.getText())
          }, 350)}
          toolbar={false}
        />
      ) : (
        <Renderer document={group?.description} />
      )}
      <Can I="update" this={group}>
        <Box position={['relative', 'absolute']} right={0}>
          <Button
            intent={editing ? 'danger' : 'none'}
            onClick={toggleEditing}
            iconBefore={editing ? CornerUpLeftIcon : Edit2Icon}
          >
            {editing ? 'Cancel' : 'Edit Description'}
          </Button>
          {editing && (
            <Button
              marginLeft={majorScale(1)}
              intent="success"
              appearance="primary"
              iconBefore={SaveIcon}
              onClick={saveDescription}
            >
              Save
            </Button>
          )}
        </Box>
      </Can>
    </Box>
  )
}
