import { ApolloClient, ApolloLink, InMemoryCache, InMemoryCacheConfig } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import { createUploadLink } from 'apollo-upload-client'
import { Request, Response } from 'express'
import { extractFiles } from 'extract-files'
import 'isomorphic-fetch'
import { DateTime } from 'luxon'
import { DOMAIN_REGEX } from './constants'

declare global {
  interface Window {
    __APOLLO_STATE__: any
  }
}

const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Role: {
      keyFields: false,
    },
  },
}

export function getApolloURI(req: Request) {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:4000'
  }

  if (process.browser) {
    return `https://api.${DOMAIN_REGEX.exec(document.location.hostname)?.[0]}`
  } else {
    return `https://api.${DOMAIN_REGEX.exec(req.hostname)?.[0]}`
  }
}

export function getAbsoluteURI(req: Request) {
  return req ? `${req.protocol}://${req.get('Host')}/graphql` : '/graphql'
}

export default function (req?: Request, res?: Response) {
  const opts = {
    credentials: 'same-origin',
    uri: getAbsoluteURI(req),
    fetch: (url, init) => {
      return fetch(url, {
        ...init,
        headers: {
          ...init.headers,
          ...((process.browser
            ? {
                Domain: document.location.hostname,
                dpr: window.devicePixelRatio,
                w: window.innerWidth,
                timezone: DateTime.local().zoneName,
              }
            : {
                cookie: req.headers && req.headers.cookie,
                'set-cookie': req.headers && req.headers['set-cookie'],
                Domain: req.hostname,
              }) as any),
        },
      }).then((response) => {
        if (!process.browser) {
          let setCookies: string[] = []
          response.headers.forEach((value, key) => {
            if (key === 'set-cookie') {
              setCookies.push(value)
            }
          }),
            res.setHeader('set-cookie', setCookies)
        }
        return response
      })
    },
  }

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    assumeImmutableResults: true,
    queryDeduplication: true,
    name: 'web',
    link: ApolloLink.from([
      onError(({ graphQLErrors, response }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((error) => {
            if (__DEV__) {
              console.log(error)
            }
            Sentry.setContext('response', response)
            Sentry.captureException(error)
          })
        }
      }),
      ApolloLink.split(
        (operation) => extractFiles(operation).files.size > 0,
        createUploadLink(opts),
        new BatchHttpLink(opts),
      ),
    ]),
    cache: process.browser
      ? new InMemoryCache(cacheOptions).restore(window.__APOLLO_STATE__)
      : new InMemoryCache(cacheOptions),
  })
}
