import {
  GroupQuery,
  useUserQuery,
  useJoinGroupMutation,
  useGroupQuery,
  useUnJoinGroupMutation,
  GroupDocument,
} from '../../graphql/generated/graphql-hooks'
import { Button, majorScale, CheckIcon, UserPlusIcon, toaster } from '@parishconnect/react-ui'
import React from 'react'
import useRouter from 'use-react-router'

type JoinGroupButtonProps = {
  group: GroupQuery['group']
}

export default function JoinGroupButton({ group }: JoinGroupButtonProps) {
  const { data } = useUserQuery()
  const { history } = useRouter()
  const [joinGroup] = useJoinGroupMutation({
    variables: { group: group.id },
    refetchQueries: [{ query: GroupDocument, variables: { id: group.id } }],
  })
  const [unJoinGroup] = useUnJoinGroupMutation({
    variables: { group: group.id },
    refetchQueries: [{ query: GroupDocument, variables: { id: group.id } }],
  })
  const isMember = group?.viewer?.isMember

  //TODO Add admin check

  return (
    <Button
      height={34}
      position="absolute"
      bottom={majorScale(2)}
      right={majorScale(2)}
      zIndex={8}
      appearance={isMember ? 'primary' : 'overlay'}
      iconBefore={isMember ? CheckIcon : UserPlusIcon}
      onClick={() => {
        if (!data?.user) {
          history.push('/login')
        }

        if (group.owner.id === data.user?.id) {
          toaster.warning('A group owner cannot leave their own group', { id: 'group-owner' })
          return
        }

        if (isMember) {
          unJoinGroup()
        } else {
          joinGroup()
        }
      }}
    >
      {isMember ? 'Joined' : 'Join Group'}
    </Button>
  )
}
