import Box from '@parishconnect/box'
import { Button, EditIcon, Heading, majorScale } from '@parishconnect/react-ui'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Nav, PageContainer, PostModule, Footer } from '../components'
import { usePostsQuery, PostsQueryVariables } from '../graphql/generated/graphql-hooks'
import { Can } from '../utils/abilityContext'
import { COLUMN } from '../utils/constants'

type PostsProps = {
  variables: PostsQueryVariables
}

const Posts = ({ variables }: PostsProps) => {
  const limit = 10
  const { loading, error, data, fetchMore } = usePostsQuery({
    variables: {
      ...variables,
      limit,
    },
  })

  const posts = data?.posts ?? []
  const hasMore = posts.length >= limit

  function fetchMorePosts() {
    fetchMore({
      variables: {
        limit,
        cursor: data?.posts[data?.posts.length - 1].id,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        return {
          ...previousResult,
          posts: [...previousResult.posts, ...fetchMoreResult.posts],
        }
      },
    })
  }

  useEffect(() => {
    fetchMorePosts()
  }, [])

  if (error || !data) {
    return null
  }

  return (
    <PageContainer>
      <Helmet>
        <title>Posts</title>
      </Helmet>
      <Box display="flex">
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
          <Footer />
        </Box>
        <Box marginLeft={[0, COLUMN / 2]} width={['100%', COLUMN * 15]}>
          <Box
            display="flex"
            width={['100%', majorScale(81)]}
            alignItems="center"
            justifyContent="space-between"
            is="main"
            id="main-content"
          >
            <Heading marginY={majorScale(3)} size={900} is="h1">
              Posts
            </Heading>
            <Can I="create" this={{ __typename: 'Post', parish: data?.parish?.id }}>
              <Button appearance="primary" iconBefore={EditIcon} is={Link} to="/post/new">
                Create Post
              </Button>
            </Can>
          </Box>
          <Box
            display="grid"
            gridAutoFlow="row"
            width="100%"
            gap={`${majorScale(3)}px ${majorScale(5)}px`}
            gridTemplateColumns={['1fr', `repeat(auto-fit, ${majorScale(38)}px)`]}
          >
            {posts.map((post) => (
              <PostModule key={post.id} {...post} />
            ))}
            {hasMore && (
              <Box
                marginY={majorScale(3)}
                marginX="auto"
                gridColumn={['1/2', '1/3']}
                display="flex"
                justifyContent="center"
              >
                <Button isLoading={loading} onClick={fetchMorePosts} height={36}>
                  Load More
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </PageContainer>
  )
}

export default Posts
