import Box from '@parishconnect/box'
import {
  Avatar,
  Card,
  Heading,
  Image,
  majorScale,
  minorScale,
  Pane,
  StackingOrder,
  Text,
  ThemeContext,
} from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import ProgressiveImage from 'react-progressive-image'
import { Link } from 'react-router-dom'
import { GroupsQuery } from '../../graphql/generated/graphql-hooks'
import { cssLineClamp } from '../../utils'

export const GroupModule = (props: GroupsQuery['groups'][0]) => {
  const { id } = props
  return (
    <Card
      background="tint1"
      is={Link}
      to={`group/${id}`}
      hoverElevation={2}
      cursor="pointer"
      border="muted"
    >
      <GroupModuleCoverImage {...props} />
      <GroupModuleInfo {...props} />
    </Card>
  )
}

const GroupModuleInfo = ({ name, excerpt }: GroupsQuery['groups'][0]) => {
  return (
    <Pane background="tint1" paddingX={majorScale(3)} paddingY={majorScale(2)}>
      <Heading color="theme" marginBottom={minorScale(2)} size={600}>
        {name}
      </Heading>
      <Text
        css={{
          ...cssLineClamp(),
        }}
      >
        {excerpt}
      </Text>
    </Pane>
  )
}

const GroupModuleCoverImage = ({ cover, members, memberCount }: GroupsQuery['groups'][0]) => {
  const width = majorScale(42)
  const height = majorScale(21)
  const theme = useContext(ThemeContext)
  const { src, srcset, title, lqip } = cover || {}

  return (
    <Box width={['100%', width]} height={height} position="relative">
      <Box
        position="absolute"
        bottom={minorScale(3)}
        right={majorScale(2)}
        zIndex={StackingOrder.OVERLAY}
      >
        <Pane
          background="rgba(255,255,255,0.65)"
          css={{ backdropFilter: 'blur(20px) saturate(180%)' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={majorScale(20)}
        >
          {members.map(
            (member, i) =>
              i <= 3 && (
                <Avatar
                  zIndex={i}
                  marginRight={i !== members.length - 1 && -majorScale(1)}
                  boxShadow="0 0 0 2px white"
                  size={majorScale(4)}
                  key={member.id}
                  name={member.fullName}
                  src={member?.image?.src}
                />
              ),
          )}
          {memberCount >= 5 ? (
            <Text size={300} color="theme" paddingLeft={minorScale(1)} paddingRight={majorScale(1)}>
              +{memberCount - 4}
            </Text>
          ) : null}
        </Pane>
      </Box>
      <ProgressiveImage src={src} placeholder={lqip}>
        {(src: string) =>
          src ? (
            <Image
              role="presentation"
              alt={title}
              objectFit="cover"
              objectPosition="center"
              position="relative"
              width={['100%', width]}
              zIndex={2}
              height={height}
              borderRadius={6}
              src={src}
              srcSet={srcset}
            />
          ) : (
            <Card
              width={width}
              height={height}
              backgroundColor={theme.fills.subtle[theme.themeColor].backgroundColor}
              position="relative"
              zIndex={2}
            />
          )
        }
      </ProgressiveImage>
    </Box>
  )
}
