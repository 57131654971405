import Box from '@parishconnect/box'
import {
  Button,
  ExternalLinkIcon,
  MailIcon,
  majorScale,
  minorScale,
  PhoneIcon,
} from '@parishconnect/react-ui'
import React from 'react'
import { useParishQuery } from '../../graphql/generated/graphql-hooks'
import { DonateDialog } from './DonateDialog'

export function HomeBar() {
  const { data } = useParishQuery()

  const links = data?.parish?.links ?? []
  const gutter = majorScale(2)

  return (
    <Box
      width="100%"
      position="relative"
      display="grid"
      gridGap={`${gutter}px 0`}
      gridTemplateColumns={`${gutter}px 1fr ${gutter}px`}
      alignContent="start"
    >
      <Box
        display="flex"
        overflowX="scroll"
        gridColumn="1 / -1"
        paddingY={majorScale(1)}
        paddingX={minorScale(3)}
        css={{
          '> *': {
            scrollSnapAlign: 'start',
          },
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          overflowX: 'scroll',
          '@media(pointer: fine)': {
            scrollSnapType: 'x mandatory',
            scrollPadding: gutter,
          },
        }}
      >
        {data?.parish?.donationMethods?.length > 0 && <DonateDialog {...data.parish} />}
        {data?.parish?.phoneNumber && (
          <Button
            is="a"
            href={`tel:${data?.parish?.phoneNumber}`}
            iconBefore={PhoneIcon}
            intent="success"
            whiteSpace="pre"
            marginX={minorScale(1)}
          >
            {data?.parish?.phoneNumber}
          </Button>
        )}
        {data?.parish?.email && (
          <Button
            is="a"
            href={`mailto:${data?.parish?.email}`}
            iconBefore={MailIcon}
            whiteSpace="pre"
            marginX={minorScale(1)}
          >
            {data?.parish?.email}
          </Button>
        )}
        {links.map((link) => (
          <Button
            whiteSpace="pre"
            key={link.id}
            marginX={minorScale(1)}
            is="a"
            href={link.href}
            iconBefore={ExternalLinkIcon}
            target="_blank"
            rel="noopener"
          >
            {link.text}
          </Button>
        ))}
        <Box minWidth={majorScale(1)} height={1} />
      </Box>
    </Box>
  )
}
