import Box, { BoxProps } from '@parishconnect/box'
import { useMeasure } from 'react-use'
import {
  Button,
  Card,
  CheckIcon,
  CornerUpLeftIcon,
  IconButton,
  majorScale,
  MoveIcon,
  SaveIcon,
  Spinner,
  ThemeContext,
  toaster,
  TrashIcon,
} from '@parishconnect/react-ui'
import { motion, useAnimation } from 'framer-motion'
import { debounce, floor } from 'lodash-es'
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react'
import ImageEditor from 'react-avatar-editor'
import { DropzoneInputProps, useDropzone } from 'react-dropzone'
import {
  Position2D,
  useGetImageLazyQuery,
  useRevertTempImageMutation,
  useUploadTempImageMutation,
} from '../../graphql/generated/graphql-hooks'
import { ImagePicker } from '../shared'

export type FeaturedImageUploadProps = {
  width?: BoxProps['width']
  height?: BoxProps['height']
  disabled?: DropzoneInputProps['disabled']
  accept?: DropzoneInputProps['accept']
  allowReposition?: boolean
  onSubmit?: ({}: { position: Position2D; imageId: string }) => void
  initialFile?: string
  position?: Position2D
  onUpload: (file: { id: string; path?: string }) => void
  onRemove: (id: string) => void
  onPositionChange?: ({}: { x: number; y: number }) => void
  aspectRatio?: number
  round?: boolean
}

export function FeaturedImageUpload({
  width = majorScale(122),
  height = majorScale(38),
  aspectRatio = 3,
  round = false,
  accept = 'image/*',
  disabled = false,
  onSubmit,
  onRemove = () => {},
  onUpload = () => {},
  onPositionChange = () => {},
  allowReposition = false,
  initialFile,
  position = { x: 0.5, y: 0.5 },
}: FeaturedImageUploadProps) {
  const theme = useContext(ThemeContext)
  const controls = useAnimation()
  const imageRef = useRef<ImageEditor>(null)
  const [uploadImage, { loading: imageUploadLoading }] = useUploadTempImageMutation()
  const [getImage, { data }] = useGetImageLazyQuery()
  const [revertImage] = useRevertTempImageMutation()
  const [serverFile, setServerFile] = useState(initialFile)
  const [localFile, setLocalFile] = useState<File>()
  const [isTemporary, setIsTemporary] = useState(false)
  const [isImagePickerShown, showImagePicker] = useState(false)
  // const [containerRef, { height, width }] = useMeasure()
  const { acceptedFiles, getInputProps, getRootProps, open } = useDropzone({
    disabled,
    noDrag: !!localFile,
    accept,
    noClick: true,
    multiple: false,
    maxSize: 2000000,
    onDropRejected: (files, e) => {
      if (files?.[0]?.file.size >= 2000000) {
        toaster.danger('Image too large', {
          description: 'Please use an image smaller than 2MB',
        })
      } else if (!/image/gi.test(files?.[0]?.file.type)) {
        toaster.danger('Only images are accepted', {
          description: 'Please a PNG, JPEG or other image format.',
        })
      } else {
        toaster.danger('Something went wrong.', {
          description: "We're looking into this issue. Sorry for the inconvenience",
        })
      }
    },
    onDropAccepted: async (files) => {
      setLocalFile(files[0])
      await upload(files[0])
    },
  })

  useEffect(() => {
    if (data?.image?.url) {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', data?.image?.url)
      xhr.responseType = 'blob'

      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return
        }
        if (xhr.status >= 200 && xhr.status < 300) {
          const blob = xhr.response as Blob
          setLocalFile(new File([xhr.response], blob.type))
          setIsTemporary(false)
          onUpload({
            id: data?.image?.id,
          })
          return
        }
        toaster.warning('Error loading temporary image')
        setLocalFile(null)
        setServerFile(null)
      }
      xhr.send()
    }
  }, [data])

  useEffect(() => {
    if (!localFile && serverFile) {
      getImage({ variables: { id: serverFile } })
    }
  }, [serverFile])

  /**
   * Temporarily uploads image and forwards the id of the image
   */
  async function upload(file: File) {
    await uploadImage({
      variables: { image: file },
      update: (_, { data: { uploadImage } }) => {
        setServerFile(uploadImage?.id)
        onUpload({
          id: uploadImage?.id,
          path: uploadImage?.path,
        })
      },
    })
  }

  /**
   * Deletes a temporary image and removes preview
   */
  async function revert() {
    if (isTemporary !== false) {
      await revertImage({
        variables: { id: serverFile },
      })
    }
    controls.unmount()
    setLocalFile(undefined)
    setServerFile(undefined)
    onRemove(serverFile)
  }

  const ActionButton = round ? IconButton : Button

  return (
    <Card
      // innerRef={containerRef}
      width={['100%', null, width]}
      height={height}
      appearance="solid"
      border="muted"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...getRootProps()}
      userSelect="none"
      overflow="hidden"
      css={{
        borderRadius: round ? '99999px !important' : 0,
        '&:focus': {
          outline: 'none',
          boxShadow: `0 0 0 2px ${theme.palette[theme.themeColor].light}, 0 1px 1px 0 ${
            theme.palette[theme.themeColor].lightest
          }`,
        },
      }}
    >
      {localFile ? (
        <>
          <Box
            position="absolute"
            display="flex"
            top={round ? '70%' : 0}
            left={round ? '25%' : 0}
            zIndex={2}
            width={round ? width / 2 : width}
            height={height}
            padding={majorScale(1)}
            pointerEvents="none"
          >
            <ActionButton
              pointerEvents="all"
              type="button"
              icon={round ? (isTemporary ? CornerUpLeftIcon : TrashIcon) : null}
              iconBefore={!round ? (isTemporary ? CornerUpLeftIcon : TrashIcon) : null}
              appearance={round ? 'primary' : 'overlay'}
              intent={round ? 'danger' : 'none'}
              round
              onClick={(e: FormEvent<Button>) => {
                e.preventDefault()
                revert()
              }}
            >
              {round ? undefined : isTemporary ? 'Undo' : 'Remove'}
            </ActionButton>
            <ActionButton
              position={round ? 'absolute' : 'static'}
              display={round ? 'none' : undefined}
              is="div"
              alignSelf="flex-start"
              alignItems="center"
              justifyContent="center"
              marginLeft="auto"
              round
              isLoading={imageUploadLoading}
              icon={round ? CheckIcon : null}
              appearance="overlay"
            >
              {round ? undefined : imageUploadLoading ? 'Uploading' : 'Uploaded'}
            </ActionButton>
            {typeof onSubmit === 'function' && (
              <ActionButton
                position={round ? undefined : 'absolute'}
                bottom={majorScale(1)}
                right={majorScale(1)}
                pointerEvents="all"
                zIndex={2}
                marginLeft="auto"
                type="button"
                appearance="primary"
                intent="success"
                disabled={!localFile}
                iconBefore={round ? null : SaveIcon}
                icon={round ? SaveIcon : null}
                round={round}
                onClick={(e: FormEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  onSubmit({
                    imageId: serverFile,
                    position: { y: imageRef.current.state.image.y },
                  })
                }}
              >
                Save
              </ActionButton>
            )}
            {allowReposition && (
              <Card
                transition="225ms"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                top="50%"
                left="50%"
                position="absolute"
                transform="translate(-50%, -50%)"
                paddingX={majorScale(3)}
                paddingY={majorScale(2)}
                background="rgba(0,0,0,0.4)"
                color="white"
                css={{ backdropFilter: 'blur(20px) saturate(180%)' }}
              >
                <MoveIcon marginRight={majorScale(1)} /> {!round && 'Drag to Reposition'}
              </Card>
            )}
          </Box>
          <motion.div
            key="File_input_preview"
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', damping: 15, stiffness: 50 }}
          >
            <ImageEditor
              border={0}
              borderRadius={round ? 99999 : 0}
              width={width as number}
              height={height as number}
              image={acceptedFiles?.[0] ?? localFile}
              onImageReady={() => {
                imageRef.current.setState((prevState) => ({
                  ...prevState,
                  image: { ...prevState.image, ...position },
                }))
              }}
              ref={imageRef}
              style={!allowReposition && { cursor: 'default' }}
              onPositionChange={debounce(({ x, y }) => {
                if (y <= 0.11) y = 0
                if (y >= 0.89) y = 1
                onPositionChange({ y: floor(y, 2), x: floor(x, 2) })
              }, 150)}
            />
          </motion.div>
        </>
      ) : (
        <>
          <motion.div
            key="File_input_prompt"
            initial={{
              x: majorScale(5),
              opacity: 0,
            }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ type: 'spring', damping: 20, delay: 0.25 }}
            onClick={() => showImagePicker(true)}
          >
            <input {...getInputProps()} />
            Drag & Drop an image or
            <Button type="button" marginLeft={majorScale(1)} appearance="primary">
              Browse
            </Button>
          </motion.div>
          <ImagePicker
            browse={open}
            isShown={isImagePickerShown}
            onClose={() => showImagePicker(false)}
            onPick={({ id }) => {
              setServerFile(id)
              setIsTemporary(false)
            }}
          />
        </>
      )}
    </Card>
  )
}
