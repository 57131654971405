import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import {
  Alert,
  Avatar,
  Button,
  Card,
  Heading,
  majorScale,
  SaveIcon,
  SelectField,
  Spinner,
  Text,
  TextInputField,
  ThemeContext,
  Trash2Icon,
} from '@parishconnect/react-ui'
import { Field, Form, Formik } from 'formik'
import { capitalize } from 'lodash-es'
import React, { FormEvent, useContext, useState } from 'react'
import useRouter from 'use-react-router'
import * as yup from 'yup'
import {
  GroupQuery,
  GroupsDocument,
  GroupsQuery,
  Privacy,
  useDeleteGroupMutation,
  useEditGroupMutation,
  useGroupAdminsQuery,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN, AbilityContext } from '../../utils'
import { Redirect } from 'react-router-dom'

const groupSettingsValidationSchema = new yup.object().shape({
  name: yup.string().required(),
  privacy: yup.string().oneOf(Object.values(Privacy)).required().default(Privacy.Public),
})

export function GroupSettings({ group }: GroupQuery) {
  const [editGroup, { loading }] = useEditGroupMutation()
  const ability = useContext(AbilityContext)
  if (!group) return null

  if (ability.cannot('update', group)) {
    return <Redirect to={`/group/${group.id}`} />
  }

  return (
    <Box>
      <Heading marginBottom={majorScale(3)} size={800}>
        Group Settings
      </Heading>
      <Formik
        enableReinitialize
        validationSchema={groupSettingsValidationSchema}
        initialValues={{
          name: group?.name,
          privacy: group?.privacy,
        }}
        onSubmit={async (values, actions) => {
          try {
            await editGroup({ variables: { id: group.id, group: values } })
            actions.setSubmitting(false)
          } catch (error) {
            Sentry.captureException(error)
            actions.setErrors(error)
          }
        }}
      >
        {({ values, setFieldValue, errors, dirty }) => (
          <Form>
            <Box maxWidth={COLUMN * 8} width="100%" marginRight={[0, null, COLUMN / 2]}>
              {errors &&
                Object.entries(errors).map(([name, error]) => (
                  <Alert intent="danger">{`Error on ${name}: ${error}`}</Alert>
                ))}
              <TextInputField label="Name" is={Field} name="name" />
              <SelectField
                disabled
                marginBottom={majorScale(2)}
                name="privacy"
                label="Privacy"
                inputWidth="100%"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue('privacy', e.currentTarget.value)
                }}
                value={values.privacy}
                hint="Private Groups coming soon"
              >
                {Object.values(Privacy)
                  .filter((p) => p === Privacy.Public || p === Privacy.Private)
                  .map((privacy) => (
                    <option key={privacy} value={privacy} id={privacy}>
                      {capitalize(privacy)}
                    </option>
                  ))}
              </SelectField>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  disabled={!dirty}
                  isLoading={loading}
                  iconBefore={SaveIcon}
                  appearance="primary"
                  intent="success"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <Box maxWidth={COLUMN * 8} width="100%" marginRight={COLUMN / 2} marginBottom={COLUMN * 2}>
        <GroupAdminSelector {...group} />
        <GroupDangerZone {...group} />
      </Box>
    </Box>
  )
}

function GroupDangerZone({ id, name }: GroupQuery['group']) {
  const theme = useContext(ThemeContext)
  const [inputValue, setInputValue] = useState('')
  const [deleteGroup] = useDeleteGroupMutation()
  const { history } = useRouter()

  const inputMatches = name.toLowerCase() === inputValue.toLowerCase()

  return (
    <Card
      border
      borderColor={theme.palette.red.light}
      background="redTint"
      marginTop={majorScale(3)}
      paddingY={majorScale(2)}
      paddingX={majorScale(3)}
    >
      <Heading color={theme.palette.red.base}>Danger Zone</Heading>
      <Box display="flex" marginTop={majorScale(2)} alignItems="center">
        <TextInputField
          placeholder=""
          label={`Please type "${name}" to confirm.`}
          value={inputValue}
          onChange={(e: FormEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)}
          marginRight={majorScale(2)}
          hint="This action cannot be undone"
          width="100%"
        />
        <Button
          whiteSpace="pre"
          intent="danger"
          iconBefore={Trash2Icon}
          onClick={async () => {
            try {
              await deleteGroup({
                variables: { id },
                update: (cache, { data: { deleteGroup } }) => {
                  const data = cache.readQuery<GroupsQuery>({ query: GroupsDocument })
                  if (data?.groups.length > 0) {
                    cache.writeQuery<GroupsQuery>({
                      query: GroupsDocument,
                      data: {
                        ...data,
                        groups: data.groups.filter((group) => group.id !== deleteGroup),
                      },
                    })
                  }
                },
              })
              history.push('/groups')
            } catch (error) {
              Sentry.captureException(error)
            }
          }}
          appearance="primary"
          disabled={!inputMatches}
          marginTop={-majorScale(3)}
        >
          Delete Group
        </Button>
      </Box>
    </Card>
  )
}

function GroupAdminSelector({ id }: GroupQuery['group']) {
  const { data, loading } = useGroupAdminsQuery({ variables: { group: id } })

  if (loading) {
    return null
  }

  if (!data || data.users?.length <= 0) {
    return null
  }

  return (
    <Box>
      <Heading marginY={majorScale(3)} size={800}>
        Group Admins
      </Heading>
      <Box display="grid" gridGap={majorScale(1)}>
        {data.users?.map((admin) => (
          <Card
            border
            appearance="white"
            padding={majorScale(2)}
            key={admin.id}
            display="flex"
            alignItems="center"
          >
            <Avatar
              name={admin.firstName + ' ' + admin.lastName}
              src={admin.image?.src}
              marginRight={majorScale(1)}
            />
            <Text>{admin.fullName}</Text>
          </Card>
        ))}
      </Box>
    </Box>
  )
}
