import Box from '@parishconnect/box'
import { BackButton, Image, majorScale } from '@parishconnect/react-ui'
import React from 'react'
import ProgressiveImage from 'react-progressive-image'
import useRouter from 'use-react-router'
import { PostQuery } from '../../graphql/generated/graphql-hooks'

const SinglePostImage = ({ image, type }: PostQuery['post']) => {
  const { history } = useRouter()

  return (
    <Box
      position="relative"
      height={0}
      paddingBottom={image ? 'calc(100% / 3)' : majorScale(10)}
      borderRadius={8}
      width="100%"
      overflow="hidden"
    >
      {image ? (
        <ProgressiveImage
          srcSetData={{
            sizes: '1x,2x,3x',
            srcSet: image?.srcset,
          }}
          src={image?.src}
          placeholder={image?.lqip}
        >
          {(src: string, _, { srcSet }: { srcSet?: string } = {}) => (
            <Image
              width="100%"
              backgroundColor="#ccc"
              src={src}
              srcSet={srcSet}
              alt={image?.title || ''}
              transition="300ms"
            />
          )}
        </ProgressiveImage>
      ) : (
        <Box height={majorScale(8)} />
      )}
      <BackButton
        onClick={() => history.goBack()}
        height={34}
        position="absolute"
        top={majorScale(2)}
        left={majorScale(2)}
        appearance={image ? 'overlay' : 'default'}
        round
        zIndex={8}
      />
    </Box>
  )
}

export default SinglePostImage
