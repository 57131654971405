import Box from '@parishconnect/box'
import { Heading, Image, majorScale, Paragraph, Button, HomeIcon } from '@parishconnect/react-ui'
import React from 'react'
import manShrug from '../assets/man-shrug.svg'
import womanShrug from '../assets/woman-shrug.svg'
import { PageContainer } from '../components'
import { Link } from 'react-router-dom'

export default function FourOhFour() {
  const src = Math.random() > 0.5 ? manShrug : womanShrug
  return (
    <PageContainer>
      <Box
        display="flex"
        width="100%"
        height="100%"
        minHeight="-webkit-fill-available"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={src} alt="" maxWidth={majorScale(30)} />
        <Heading color="theme" size={900} paddingTop={majorScale(2)}>
          404
        </Heading>
        <Paragraph paddingY={majorScale(1)}>There's nothing here. That's no good.</Paragraph>
        <Button iconBefore={HomeIcon} is={Link} to="/">
          Go Home
        </Button>
      </Box>
    </PageContainer>
  )
}
