import Box from '@parishconnect/box'
import {
  Heading,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextTableCell,
  TextTableHeaderCell,
} from '@parishconnect/react-ui'
import { beautifyKey } from '@ui-schema/ui-schema'
import React from 'react'
import useRouter from 'use-react-router'
import { PageContainer } from '../components'
import { useFormSubmissionsQuery } from '../graphql/generated/graphql-hooks'

export function FormSubmissions() {
  const { match } = useRouter<{ id: string }>()
  const { id } = match.params
  const { data } = useFormSubmissionsQuery({ variables: { id } })

  const firstSubmission = data?.form?.submissions[0]?.data

  return (
    <PageContainer>
      <Box marginTop={24}>
        <Box marginY={24}>
          <Heading size={600}>Submissions for {data?.form?.title}</Heading>
        </Box>
        <Table>
          <TableHead>
            {firstSubmission &&
              Object.keys(JSON.parse(firstSubmission))
                .filter((key) => key !== '__typename' && key !== 'id')
                .map((key) => (
                  <TextTableHeaderCell key={key}>{beautifyKey(key)}</TextTableHeaderCell>
                ))}
          </TableHead>
          <TableBody>
            {data?.form?.submissions.map((submission) => {
              const data = JSON.parse(submission.data)
              return (
                <TableRow key={submission.id}>
                  {Object.keys(data)
                    .filter((key) => key !== '__typename' && key !== 'id')
                    .map((key) => (
                      <TextTableCell key={key}>{data[key]}</TextTableCell>
                    ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>
    </PageContainer>
  )
}
