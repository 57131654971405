import Box from '@parishconnect/box'
import { useSacramentQuery, SacramentsQuery } from '../../graphql/generated/graphql-hooks'
import { Card, Heading, majorScale, ThemeContext } from '@parishconnect/react-ui'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import ProgressiveImage from 'react-progressive-image'
import { match } from 'react-router'
import { COLUMN } from '../../utils/constants'
import RenderSacramentBody from './RenderSacramentBody'

type SingleSacramentProps = {
  match: match<{ id: string }>
}

export const SingleSacrament = ({ match, parish }: SacramentsQuery & SingleSacramentProps) => {
  const { data } = useSacramentQuery({
    variables: { id: match.params.id },
  })
  const theme = useContext(ThemeContext)

  return (
    <>
      <Box position="relative">
        <Helmet>
          <title>{data?.sacrament?.title ?? 'Sacrament'}</title>
        </Helmet>
        <Box
          is="section"
          position="relative"
          height={0}
          paddingBottom="calc(100% / 3)"
          borderRadius={8}
          width="100%"
          overflow="hidden"
        >
          {parish?.image?.src ? (
            <Card
              width="100%"
              position="absolute"
              height="100%"
              css={{ objectFit: 'cover' }}
              is="img"
              role="presentation"
              alt=""
              srcSet={parish?.image?.srcset}
              src={parish?.image?.src}
            />
          ) : (
            <Card
              appearance="solid"
              height={[majorScale(16), majorScale(26), null, majorScale(38)]}
              width="100%"
            />
          )}
        </Box>
        <Card
          is="header"
          aria-label={data?.sacrament?.title ?? 'Sacrament'}
          appearance="solid"
          position={['static', 'absolute']}
          paddingX={majorScale(3)}
          paddingY={majorScale(2)}
          marginTop={[majorScale(1), 0]}
          borderRadius={14}
          bottom={majorScale(2)}
          left={majorScale(2)}
        >
          <Heading color="theme" size={800} is="h1">
            {data?.sacrament?.title ?? 'Sacrament'}
          </Heading>
          <Heading color={theme.palette[theme.themeColor].text} size={100}>
            {parish?.name}
          </Heading>
        </Card>
      </Box>
      <Box marginTop={COLUMN / 2} marginX={[0, COLUMN / 2]} id="main-content" is="main">
        <RenderSacramentBody {...data} parish={parish} />
      </Box>
    </>
  )
}
