import Box from '@parishconnect/box'
import {
  Button,
  Checkbox,
  DateTimePicker,
  Dialog,
  Label,
  majorScale,
  PlusIcon,
  PositionEnum,
  SegmentedControl,
  TextInputField,
  TimePicker,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { Field, Form, Formik } from 'formik'
import { DateTime } from 'luxon'
import React, { FormEvent, Fragment, useState } from 'react'
import * as yup from 'yup'
import {
  AddMassMutationVariables,
  Flag,
  MassesDocument,
  MassesQuery,
  RDateInput,
  RRuleInput,
  useAddMassMutation,
} from '../../graphql/generated/graphql-hooks'
import { COLUMN, DateAdapter, Dates, Rule } from '../../utils'
import { SeasonSuggest } from './SeasonSuggest'
import { WeekdaySelector } from './WeekdaySelector'

type AddMassFormVariables = {
  frequency: 'once' | 'WEEKLY'
  start: Date
  byDayOfWeek?: DateAdapter.Weekday
  note?: string
  flag?: Flag
  title?: string
  season?: { value: string; label: string }
}

const newMassValidationSchema = yup.object().shape({
  frequency: yup.string().oneOf(['WEEKLY', 'once']),
  start: yup.date(),
  byDayOfWeek: yup.string().oneOf(Object.values(DateAdapter.WEEKDAYS)),
  title: yup.string().max(26),
  note: yup.string().max(26),
  season: yup.object().shape({ value: yup.string(), id: yup.string() }),
})

function formatAddMassVariables(values: AddMassFormVariables): AddMassMutationVariables['mass'] {
  const mutationVariables: AddMassMutationVariables['mass'] & { season?: string } = {
    start: values.start,
    flag: values.flag,
    note: values.note,
  }

  const timezone = DateTime.local().zoneName
  const dt = DateTime.fromJSDate(values.start).startOf('minute').setZone(timezone)

  if (values.frequency === 'WEEKLY') {
    try {
      mutationVariables.rule = new Rule({
        frequency: 'WEEKLY',
        byDayOfWeek: [values.byDayOfWeek],
        start: dt,
      }).toJSON() as RRuleInput
    } catch (error) {
      Sentry.captureException(error)
    }
  } else {
    try {
      mutationVariables.season = values.season?.value ?? values.season?.label
      mutationVariables.date = new Dates({
        timezone,
        dates: [dt],
      }).toJSON() as RDateInput
      mutationVariables.title = values.title
    } catch (error) {
      console.log(error)

      Sentry.captureException(error)
    }
  }

  return mutationVariables
}

export function NewMass() {
  const [isShown, setShown] = useState(false)
  const [addMass] = useAddMassMutation()

  return (
    <Fragment>
      <Button appearance="primary" iconBefore={PlusIcon} onClick={() => setShown(true)}>
        New Mass
      </Button>
      <Formik<AddMassFormVariables>
        validationSchema={newMassValidationSchema}
        initialValues={{
          frequency: 'WEEKLY',
          start: DateTime.local().plus({ hour: 1 }).startOf('hour').toJSDate(),
          byDayOfWeek: DateAdapter.WEEKDAYS[DateTime.local().weekday],
          flag: undefined,
          title: '',
          note: '',
        }}
        onSubmit={async (values, actions) => {
          try {
            await addMass({
              variables: { mass: formatAddMassVariables(values) },
              update: (cache, { data: { addMass } }) => {
                const massData = cache.readQuery<MassesQuery>({ query: MassesDocument })
                if (massData) {
                  cache.writeQuery<MassesQuery>({
                    query: MassesDocument,
                    data: {
                      masses: [...massData.masses, addMass],
                    },
                  })
                }
              },
            })
            actions.setSubmitting(false)
            setShown(false)
          } catch (error) {
            Sentry.captureException(error)
          }
        }}
      >
        {({
          resetForm,
          values,
          setFieldValue,
          setFieldTouched,
          submitCount,
          errors,
          submitForm,
        }) => (
          <>
            <Dialog
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEscapePress={false}
              width={COLUMN * 6}
              title="New Mass"
              confirmLabel="Save"
              onCancel={() => {
                setShown(false)
                resetForm()
              }}
              isShown={isShown}
              contentContainerProps={{
                marginX: 'auto',
                paddingY: majorScale(2),
              }}
              onConfirm={submitForm}
            >
              <Form>
                <Box>
                  <Label>Frequency</Label>
                  <SegmentedControl
                    marginLeft={majorScale(1)}
                    marginTop={majorScale(1)}
                    marginBottom={majorScale(4)}
                    maxWidth={COLUMN * 4}
                    value={values.frequency}
                    name="freq"
                    onChange={(v: string) => {
                      if (v === 'WEEKLY') {
                        setFieldValue('title', '')
                      }
                      setFieldValue('frequency', v)
                    }}
                    onBlur={() => setFieldTouched('frequency', true)}
                    options={[
                      {
                        label: 'Every Week',
                        value: 'WEEKLY',
                      },
                      { label: 'Once', value: 'once' },
                    ]}
                    label="Frequency"
                  />
                </Box>
                {values.frequency === 'once' ? (
                  <Fragment>
                    <TextInputField
                      is={Field}
                      name="title"
                      maxLength="26"
                      width={majorScale(35)}
                      label="Title (optional)"
                      isInvalid={submitCount > 0 && errors.title && errors.title.length > 0}
                      validationMessage={submitCount > 0 && errors.title && errors.title}
                    />
                    <DateTimePicker
                      name="start"
                      value={values.start}
                      disableDates={(dt) => DateTime.fromJSDate(dt).diffNow('days').days < -1}
                      onChange={(dt: Date) => setFieldValue('start', dt)}
                      useAmPm
                      shouldShowYearButtons={false}
                      shouldShowTodayButton={false}
                      showArrowButtons
                      position={PositionEnum.BOTTOM_LEFT}
                      label="Date/Time"
                    />
                    <SeasonSuggest
                      value={values.season}
                      onChange={(season) => setFieldValue('season', season)}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <WeekdaySelector
                      value={values.byDayOfWeek}
                      onChange={(v) => setFieldValue('byDayOfWeek', v)}
                      onBlur={() => setFieldTouched('byDayOfWeek', true)}
                    />
                    <TimePicker
                      value={values.start}
                      onChange={(v) => setFieldValue('start', v)}
                      onBlur={() => setFieldTouched('start', true)}
                      maxWidth={majorScale(35)}
                      width="100%"
                      useAmPm
                      showArrowButtons
                      label="Time"
                    />
                  </Fragment>
                )}
                {(values?.byDayOfWeek === 'SA' || values.start.getDay() === 6) &&
                  values.start.getHours() > 15 && (
                    <Checkbox
                      label="Saturday Vigil?"
                      checked={values.flag === Flag.Sunday}
                      onChange={(e: FormEvent<HTMLInputElement>) =>
                        setFieldValue('flag', e.currentTarget.checked ? Flag.Sunday : Flag.Ferial)
                      }
                    />
                  )}
                <TextInputField
                  is={Field}
                  name="note"
                  maxLength="26"
                  width={majorScale(35)}
                  label="Note (optional)"
                  isInvalid={submitCount > 0 && errors.note && errors.note.length > 0}
                  validationMessage={submitCount > 0 && errors.note && errors.note}
                />
              </Form>
            </Dialog>
          </>
        )}
      </Formik>
    </Fragment>
  )
}
