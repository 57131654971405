import Box from '@parishconnect/box'
import React, { useContext } from 'react'
import newspaper from '../assets/newspaper.svg'
import calendar from '../assets/calendar.svg'
import groups from '../assets/groups.svg'
import user from '../assets/user.svg'
import home from '../assets/home.svg'
import sacraments from '../assets/sacraments.svg'
import {
  Image,
  Pane,
  Heading,
  majorScale,
  Card,
  Text,
  Strong,
  ThemeContext,
} from '@parishconnect/react-ui'
import { COLUMN } from '../utils/constants'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { PageContainer, Nav, Footer } from '../components'

const menuItems = [
  {
    title: 'My Account',
    icon: user,
    to: '/user',
  },
  {
    title: 'Home',
    icon: home,
    to: '/',
  },
  {
    title: 'Posts',
    icon: newspaper,
    to: '/posts',
  },
  {
    title: 'Sacraments',
    icon: sacraments,
    to: '/sacraments',
  },
  {
    title: 'Events',
    icon: calendar,
    to: '/events',
  },
  {
    title: 'Groups',
    icon: groups,
    to: '/groups',
  },
]

export default function Menu() {
  const theme = useContext(ThemeContext)
  return (
    <PageContainer>
      <Helmet>
        <title>Menu</title>
      </Helmet>
      <Pane display="flex">
        <Box width={COLUMN * 6}>
          <Nav menuOpen showMasses />
          <Footer />
        </Box>
        <Box width={COLUMN * 17.5} marginLeft={COLUMN}>
          <Heading marginY={majorScale(3)} size={900} marginBottom={majorScale(4)}>
            Explore
          </Heading>
          <Box display="grid" gap={majorScale(1)}>
            {menuItems.map((item) => (
              <Card
                is={Link}
                to={item.to}
                maxWidth={majorScale(40)}
                paddingY={majorScale(1)}
                paddingX={majorScale(2)}
                key={item.title}
                transition="175ms"
                css={{
                  '&:hover': {
                    background: theme.palette[theme.themeColor].lightest,
                  },
                }}
                display="flex"
                alignItems="center"
              >
                <Image height={majorScale(4)} src={item.icon} marginRight={majorScale(2)} />
                <Strong size={500}>{item.title}</Strong>
              </Card>
            ))}
          </Box>
        </Box>
      </Pane>
    </PageContainer>
  )
}
