import Box from '@parishconnect/box'
import {
  Button,
  CalendarIcon,
  ChevronRightIcon,
  EditIcon,
  Heading,
  majorScale,
} from '@parishconnect/react-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import { Can } from '../../utils'
import { useFeaturedEventsQuery, useParishQuery } from '../../graphql/generated/graphql-hooks'
import { EventModule } from './EventModule'

export const FeaturedEvents = () => {
  const { data, loading, error } = useFeaturedEventsQuery()
  const { data: { parish } = {} } = useParishQuery()
  if (error || loading) {
    return null
  }

  const events = data?.events
  if (!events?.[0]) {
    return null
  }

  const width = majorScale(36)
  const gutter = majorScale(2)
  const mobileGutter = majorScale(1)

  return (
    <Box
      paddingTop={majorScale(5)}
      width="100%"
      position="relative"
      display="grid"
      gridGap={`${gutter}px 0`}
      gridTemplateColumns={`${gutter}px 1fr ${gutter}px`}
      alignContent="start"
    >
      <Box display="flex" gridColumn="2 / -2">
        <Heading size={700}>Upcoming Events</Heading>
        <Button
          is={Link}
          to="/events"
          marginLeft={['auto', majorScale(2)]}
          iconAfter={ChevronRightIcon}
        >
          All Events
        </Button>
        <Can I="create" this={{ __typename: 'Event', parish }}>
          <Button
            display={['none', 'inline-flex']}
            marginLeft="auto"
            is={Link}
            to="/event/new"
            appearance="primary"
            iconBefore={CalendarIcon}
          >
            Create Event
          </Button>
        </Can>
      </Box>
      <Box
        role="list"
        display="grid"
        overflowX="scroll"
        gridTemplateColumns={`8px repeat(${events.length}, ${width}px) 8px`}
        gridColumn="1 / -1"
        gridGap={majorScale(1)}
        paddingY={majorScale(1)}
        css={{
          '&:before, &:after': {
            content: '""',
          },
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          overflowX: 'scroll',
          '@media(pointer: fine)': {
            scrollSnapType: 'x mandatory',
            scrollPaddingLeft: gutter,
          },
        }}
      >
        {events.map((event, i) => (
          <EventModule key={event.id} {...event} />
        ))}
      </Box>
    </Box>
  )
}
