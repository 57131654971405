import Box from '@parishconnect/box'
import { majorScale, Pane } from '@parishconnect/react-ui'
import { EDITOR_CLASS_SELECTOR } from '@remirror/core'
import React from 'react'
import { Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import {
  Nav,
  PageContainer,
  Renderer,
  Seo,
  SinglePostActions,
  SinglePostImage,
  SinglePostInfo,
} from '../components'
import { PostReadings } from '../components/Post/PostReadings'
import { usePostQuery } from '../graphql/generated/graphql-hooks'
import { COLUMN } from '../utils/constants'

const Post = () => {
  const { match, location, history } = useRouter<{ id: string }>()
  const { id } = match.params

  const { loading, data } = usePostQuery({
    variables: { id },
  })

  if (!loading && !data?.post?.id) {
    return <Redirect to="/posts" />
  }

  if (loading || !data?.post) {
    return null
  }

  return (
    <PageContainer css={{ [EDITOR_CLASS_SELECTOR]: { minHeight: 350 } }}>
      <Seo
        title={data?.post?.title}
        key={data?.post?.id}
        description={data?.post?.excerpt}
        pathname={location?.pathname}
        image={data?.post?.image?.src}
        createdAt={data?.post?.createdAt}
        updatedAt={data?.post?.updatedAt}
        parish={data?.post?.parish}
        author={data?.post?.author?.fullName}
        type="article"
      />
      <Pane display="flex">
        <Box width={[0, null, COLUMN * 6]}>
          <Nav menuOpen showMasses />
        </Box>
        <Box width="100%" paddingX={[0, null, majorScale(3)]} is="main">
          <SinglePostImage {...data.post} />
          <SinglePostInfo {...data.post} />
          <SinglePostActions {...data.post} />
          <PostReadings {...data.post} />
          <Box
            id="main-content"
            width="100%"
            maxWidth={COLUMN * 14}
            paddingX={[0, null, majorScale(3)]}
            marginTop={[majorScale(3), null, COLUMN / 1.5]}
            marginBottom={[majorScale(8), null, COLUMN / 1.5]}
          >
            <Renderer document={data?.post?.ast} />
          </Box>
        </Box>
      </Pane>
    </PageContainer>
  )
}

export default Post
