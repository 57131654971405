import { majorScale } from '@parishconnect/react-ui'
import { Schedule } from './rSchedule'

export const COLUMN = majorScale(7)
export const MOBILE_COLUMN = majorScale(3)
export const COLUMNS = 24
export const NAME_REGEX = /^[a-zA-z\s,.'-]{0,50}$/
export const TWITTER_REGEX = /^[a-zA-Z0-9_]+$/
export const FACEBOOK_REGEX = /^[a-zA-Z0-9\.]+$/
export const YOUTUBE_REGEX = /^[a-zA-Z0-9\.\'_]+$/
export const USERNAME_REGEX = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9-_+.]{4,50}$/
export const DOMAIN_REGEX = /([A-Za-z_-]+\.\w+)$/

export const TWITTER_COLOR = '#1DA1F2'
export const FACEBOOK_COLOR = '#4267B2'
export const YOUTUBE_COLOR = '#FF0000'
export const INSTAGRAM_COLOR = '#E1306C'

export const RECURRENCE_DAYS = [
  { label: 'SUN', value: 'SU' },
  { label: 'MON', value: 'MO' },
  { label: 'TUE', value: 'TU' },
  { label: 'WED', value: 'WE' },
  { label: 'THU', value: 'TH' },
  { label: 'FRI', value: 'FR' },
  { label: 'SAT', value: 'SA' },
]

export const WeekdayDictionary = {
  SU: 'Sunday',
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
}

export const ALLOWED_CITIES = [
  'Ajax',
  'Aurora',
  'Barrie',
  'Brampton',
  'Caledon',
  'Collingwood',
  'East York',
  'Etobicoke',
  'Georgina',
  'King',
  'Markham',
  'Midland',
  'Mississauga',
  'New Tecumseth',
  'North York',
  'Orangeville',
  'Orillia',
  'Oshawa',
  'Penetanguishene',
  'Pickering',
  'Scarborough',
  'Stouffville',
  'Thornhill',
  'Toronto',
  'Uxbridge',
  'Vaughan',
  'Whitby',
  'York',
]

export const ALLOWED_PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK']

export const prefixes = [
  'Br.',
  'Dcn.',
  'Dr.',
  'Fr.',
  'Mr.',
  'Mrs.',
  'Ms.',
  'Msgr.',
  'Rev.',
  'Rt.Rev.',
  'Sr.',
]

export const emptySchedule: Schedule.JSON = {
  type: 'Schedule',
  rrules: [],
  exrules: [],
  rdates: { type: 'Dates', dates: [] },
  exdates: { type: 'Dates', dates: [] },
}
