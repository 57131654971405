import * as Sentry from '@sentry/browser'
import produce from 'immer'
import { sortBy } from 'lodash-es'
import { DateTime } from 'luxon'
import { ExceptionData, Flag, Mass, MassType, Season } from '../../graphql/generated/graphql-hooks'
import { add, Dates, OccurrenceGenerator, Rule, subtract, unique } from '../../utils'

export function getSundayMasses(masses: ComputedMass[]): ComputedMass[] {
  if (!masses) return []

  return sortBy(
    masses.filter(
      (mass) =>
        mass.type === MassType.Recurring &&
        (mass?.nextDate?.weekday === 7 || mass.flag === Flag.Sunday),
    ),
    (mass) => [mass?.nextDate.weekday, mass?.nextDate.toMillis()],
  )
}

export function getRelevantDateTimeParts(
  oldDate: DateTime,
  newDate: DateTime,
  { relative = false } = {},
): Intl.DateTimeFormatOptions {
  try {
    const diff = oldDate.diff(newDate, ['years', 'months', 'days', 'hours', 'minutes'])

    const value: Intl.DateTimeFormatOptions = {
      year: diff.years !== 0 ? 'numeric' : undefined,
      month: diff.months !== 0 ? 'long' : undefined,
      weekday: diff.days !== 0 && relative ? 'long' : undefined,
      day: diff.days !== 0 && !relative ? 'numeric' : undefined,
      hour: diff.minutes !== 0 || diff.hours !== 0 ? 'numeric' : undefined,
      minute: diff.minutes !== 0 ? '2-digit' : undefined,
    }

    return value
  } catch (error) {
    Sentry.captureException(error)
    return {}
  }
}

export function getRelevantPreposition(format: Intl.DateTimeFormatOptions) {
  let preposition = 'at'
  if (!format) return preposition
  if (format.year || format.month) preposition = 'in'
  if (format.day) preposition = 'on'
  return preposition
}

export function getFerialMasses(masses: ComputedMass[]): ComputedMass[] {
  if (!masses) return []

  return sortBy(
    masses.filter(
      (mass) =>
        mass.type === MassType.Recurring &&
        mass.nextDate?.weekday !== 7 &&
        mass.flag !== Flag.Sunday &&
        !mass.special,
    ),
    (mass) => [mass?.nextDate.weekday, mass?.nextDate.toMillis()],
  )
}

export type SpecialMassGroup = {
  [season: string]: Season & {
    days: {
      [millis: number]: ComputedMass[]
    }
  }
}

export function getSpecialMasses(masses: ComputedMass[]): SpecialMassGroup {
  if (!masses) return null

  const sortedMasses = sortBy(
    masses.filter(mass => mass.special),
    mass => [mass?.nextDate.weekday, mass?.nextDate.toMillis()],
  )

  const newMasses = produce<SpecialMassGroup>({}, draft => {
    for (const mass of sortedMasses) {
      const seasonName = mass.season?.name ?? 'Special Masses'
      const millis = mass.nextDate.startOf('day').toMillis()

      // Create a new season if it doesn't exist
      if (!draft[seasonName]) {
        draft[seasonName] = { days: [], ...mass.season }
      }

      // Create date key in millis if doesn't exist
      if (!draft[seasonName].days[millis]) {
        draft[seasonName].days[millis] = []
      }

      // Push mass into date key
      draft[seasonName].days[millis].push(mass as ComputedMass)
    }
  })

  return newMasses
}

export type ComputedMass = Mass &
  Partial<ExceptionData> & {
    newDate?: DateTime
    computedSchedule: OccurrenceGenerator
    nextDate: DateTime
    oldDate?: DateTime
    special: boolean
  }

export function hydrateMasses(masses: Mass[], exceptions = true): ComputedMass[] {
  const startOfToday = DateTime.local().startOf('day')
  return masses
    ?.map(mass => {
      if (!mass.rule && !mass.date) return undefined

      const computedSchedule = mass.rule
        ? Rule.fromJSON(mass.rule as Rule.JSON).pipe(
            subtract(
              ...(exceptions
                ? mass.exdates?.map(date => Dates.fromJSON(date.date as Dates.JSON)) ?? []
                : []),
            ),
            add(
              ...(exceptions
                ? mass.exdates?.map(date => Dates.fromJSON(date.date as Dates.JSON)) ?? []
                : []),
            ),
            unique(),
          )
        : Dates.fromJSON(mass.date! as Dates.JSON)

      if (!computedSchedule) return undefined

      const adapter = computedSchedule
        .occurrences({
          start: startOfToday,
          end: mass.rule ? startOfToday.plus({ week: 1 }) : undefined,
          take: 1,
        })
        .toArray()[0]

      if (!adapter) return null

      const massGenerator = adapter.generators.find(gen =>
        gen instanceof Dates ? gen : undefined,
      ) as Dates<ExceptionData> | undefined
      const data = massGenerator?.data

      return {
        ...mass,
        computedSchedule,
        nextDate: data?.newDate
          ? DateTime.fromISO(data?.newDate).setZone(adapter.date.zone)
          : adapter.date,
        oldDate: adapter.date,
        ...data,
        newDate: data?.newDate ? DateTime.fromISO(data.newDate) : undefined,
        special: mass.type === MassType.Single,
      }
    })
    .filter(Boolean) as ComputedMass[]
}
