import Box from '@parishconnect/box'
import { Card, majorScale, minorScale } from '@parishconnect/react-ui'
import { RemirrorContentType } from '@remirror/core'
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  DropCursorExtension,
  HardBreakExtension,
  HeadingExtension,
  HorizontalRuleExtension,
  ItalicExtension,
  ListItemExtension,
  OrderedListExtension,
  ParagraphExtension,
  PlaceholderExtension,
  SSRHelperExtension,
  TrailingNodeExtension,
  UnderlineExtension,
} from '@remirror/core-extensions'
import {
  ManagedRemirrorProvider,
  RemirrorEventListener,
  RemirrorExtension,
  RemirrorManager,
  useRemirrorContext,
} from '@remirror/react'
import React, { ReactChild, useRef } from 'react'
import {
  useUploadEditorImageMutation,
  useUploadTempImageMutation,
} from '../../graphql/generated/graphql-hooks'
import { useToggle } from '../../utils'
import { BubbleMenu } from './BubbleMenu'
import EditorStyles from './editorStyles'
import { EditorToolbar } from './EditorToolbar'
import { ImageExtension } from './extensions/ImageExtension'
import { LinkExtension } from './extensions/LinkExtension'

type EditorProps = {
  placeholder?: string
  onChange?: RemirrorEventListener
  contentComponents?: ReactChild
  initialContent?: RemirrorContentType
  toolbar?: boolean
  styles?: JSX.IntrinsicAttributes['css']
}

export function Editor({
  onChange,
  placeholder = 'Enter some text...',
  contentComponents,
  initialContent,
  toolbar = true,
  styles,
}: EditorProps) {
  const [linkMenuActive, toggleLinkMenuActive] = useToggle(false)
  const [uploadImage] = useUploadEditorImageMutation()

  return (
    <Box css={styles}>
      <EditorStyles />
      <RemirrorManager>
        <RemirrorExtension Constructor={ParagraphExtension} key={ParagraphExtension.name} />
        <RemirrorExtension Constructor={DropCursorExtension} key={DropCursorExtension.name} />
        <RemirrorExtension Constructor={SSRHelperExtension} key={SSRHelperExtension.name} />
        <RemirrorExtension
          Constructor={ImageExtension}
          uploadImage={uploadImage}
          key={ImageExtension.name}
        />
        <RemirrorExtension Constructor={BoldExtension} key={BoldExtension.name} />
        <RemirrorExtension Constructor={UnderlineExtension} key={UnderlineExtension.name} />
        <RemirrorExtension Constructor={ItalicExtension} key={ItalicExtension.name} />
        <RemirrorExtension Constructor={BlockquoteExtension} key={BlockquoteExtension.name} />
        <RemirrorExtension Constructor={HeadingExtension} key={HeadingExtension.name} />
        <RemirrorExtension Constructor={HardBreakExtension} key={HardBreakExtension.name} />
        <RemirrorExtension Constructor={TrailingNodeExtension} key={TrailingNodeExtension.name} />
        <RemirrorExtension Constructor={ListItemExtension} key={ListItemExtension.name} />
        <RemirrorExtension Constructor={OrderedListExtension} key={OrderedListExtension.name} />
        <RemirrorExtension Constructor={BulletListExtension} key={BulletListExtension.name} />
        <RemirrorExtension
          Constructor={HorizontalRuleExtension}
          key={HorizontalRuleExtension.name}
        />
        <RemirrorExtension
          Constructor={LinkExtension}
          priority={1}
          key={LinkExtension.name}
          activationHandler={() => toggleLinkMenuActive(true)}
        />
        <RemirrorExtension
          Constructor={PlaceholderExtension}
          placeholder={placeholder}
          key={PlaceholderExtension.name}
        />
        <ManagedRemirrorProvider autoFocus onChange={onChange} initialContent={initialContent}>
          <InnerEditor
            toolbar={toolbar}
            contentComponents={contentComponents}
            linkMenuActive={linkMenuActive}
            toggleLinkMenuActive={toggleLinkMenuActive}
          />
        </ManagedRemirrorProvider>
      </RemirrorManager>
    </Box>
  )
}

export type InnerEditorProps = {
  contentComponents: EditorProps['contentComponents']
  linkMenuActive: boolean
  toolbar: boolean
  toggleLinkMenuActive: (next: boolean) => void
}

function InnerEditor({
  contentComponents = null,
  toggleLinkMenuActive,
  linkMenuActive,
  toolbar,
}: InnerEditorProps) {
  const { getRootProps } = useRemirrorContext()
  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <Box>
      <BubbleMenu
        wrapperRef={wrapperRef}
        deactivateLink={() => toggleLinkMenuActive(false)}
        activateLink={() => toggleLinkMenuActive(true)}
        linkActivated={linkMenuActive}
      />
      {toolbar && (
        <EditorToolbar
          toggleLinkMenuActive={toggleLinkMenuActive}
          linkMenuActive={linkMenuActive}
        />
      )}
      {contentComponents}
      <Card
        background="tint1"
        paddingX={majorScale(1)}
        paddingY={minorScale(1)}
        marginTop={majorScale(1)}
        innerRef={wrapperRef}
      >
        <Box {...getRootProps()} />
      </Card>
    </Box>
  )
}
