import Box, { BoxProps } from '@parishconnect/box'
import React from 'react'
import { majorScale } from '@parishconnect/react-ui'
import { COLUMN, COLUMNS } from '../../utils/constants'

const PageContainer: React.FC<BoxProps> = (props) => (
  <Box
    marginX="auto"
    paddingY={[majorScale(8), majorScale(9)]}
    paddingX={[majorScale(1), majorScale(2)]}
    boxSizing="content-box"
    maxWidth={COLUMN * COLUMNS}
    marginTop="env(safe-area-inset-top)"
    minHeight="-webkit-fill-available"
    {...props}
  />
)

export default PageContainer
