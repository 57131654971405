import Box from '@parishconnect/box'
import {
  BackButton,
  Button,
  Card,
  EyeOffIcon,
  Heading,
  ImageIcon,
  LockIcon,
  majorScale,
  minorScale,
  Strong,
  UnlockIcon,
} from '@parishconnect/react-ui'
import { History } from 'history'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GroupQuery, Privacy, useEditGroupMutation } from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils'
import { FeaturedImageUpload } from '../Image/FeaturedImageUpload'
import JoinGroupButton from './JoinGroupButton'

const SingleGroupImage: React.FC<{ history: History<any> } & GroupQuery['group']> = (group) => {
  const { cover, coverPosition, name, privacy } = group
  const [editing, setEditing] = useState(false)
  const [updateGroup] = useEditGroupMutation()

  return editing ? (
    <FeaturedImageUpload
      initialFile={cover?.id}
      initialPosition={{ y: coverPosition.y }}
      onSubmit={async ({ imageId, position }) => {
        try {
          await updateGroup({
            variables: { group: { cover: imageId, coverPosition: position }, id: group.id },
          })
          setEditing(false)
        } catch (error) {}
      }}
      allowReposition={false}
      onRemove={async () => {
        try {
          await updateGroup({
            variables: { group: { cover: null, coverPosition: null }, id: group.id },
          })
        } catch (error) {}
      }}
    />
  ) : (
    <Box position="relative">
      <Box
        position="relative"
        height={0}
        paddingBottom="calc(100% / 3)"
        borderRadius={8}
        width="100%"
        overflow="hidden"
      >
        {group?.cover?.src ? (
          <Card
            role="presentation"
            position="absolute"
            width="100%"
            height="100%"
            css={{ objectFit: 'cover' }}
            is="img"
            role="presentation"
            alt=""
            srcSet={group?.cover?.srcset}
            src={group?.cover?.src}
          />
        ) : (
          <Card appearance="solid" css={{ aspectRatio: '99 / 33' }} width="100%" />
        )}
      </Box>
      <BackButton
        is={Link}
        to="/groups"
        height={34}
        position="absolute"
        top={majorScale(2)}
        left={majorScale(2)}
        appearance={cover ? 'overlay' : 'default'}
        round
        zIndex={8}
      />
      <Can I="update" this={group}>
        <Button
          onClick={() => setEditing(true)}
          iconBefore={ImageIcon}
          position="absolute"
          top={majorScale(2)}
          right={majorScale(2)}
          appearance={cover ? 'overlay' : 'default'}
          round
          zIndex={8}
        >
          Edit Image
        </Button>
      </Can>
      <Card
        bottom={majorScale(2)}
        left={majorScale(2)}
        position={['static', 'absolute']}
        marginTop={[majorScale(2), 0]}
        paddingX={majorScale(3)}
        paddingY={majorScale(2)}
        borderRadius={14}
        appearance="solid"
      >
        <Heading size={700} color="theme" is="h1">
          {name}
        </Heading>
        <PrivacyText privacy={privacy} type="Group" />
      </Card>
      <JoinGroupButton group={group} />
    </Box>
  )
}

export const PrivacyText = ({
  privacy,
  type = '',
}: {
  privacy: Privacy
  type?: 'Group' | 'Post' | 'Event' | ''
}) => {
  const PrivacyComponent = ({ text, icon: Icon }: { text: string; icon: any }) => (
    <Box display="flex" alignItems="center">
      <Icon size={minorScale(3)} />
      <Strong marginLeft={minorScale(1)}>{text}</Strong>
    </Box>
  )

  switch (privacy) {
    case Privacy.Public:
      return <PrivacyComponent text={`Public ${type}`} icon={UnlockIcon} />
    case Privacy.Private:
    case Privacy.Info:
      return <PrivacyComponent text={`Private ${type}`} icon={LockIcon} />
    case Privacy.Hidden:
      return <PrivacyComponent text={`Hidden ${type}`} icon={EyeOffIcon} />
    default:
      return null
  }
}

export default SingleGroupImage
