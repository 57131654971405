import { SelectField } from '@parishconnect/react-ui'
import { TransTitle, WidgetProps } from '@ui-schema/ui-schema'
import React from 'react'

export function SelectWidget({
  value,
  storeKeys,
  onChange,
  required,
  schema,
  errors,
  valid,
}: WidgetProps) {
  return (
    <SelectField
      label={<TransTitle schema={schema} storeKeys={storeKeys} />}
      type={'text'}
      required={required}
      value={value || ''}
      hint={schema.get('description')}
      onChange={(e) => {
        onChange({
          storeKeys,
          scopes: ['value'],
          type: 'set',
          data: {
            value: e.target.value,
          },
          schema,
          required,
        })
      }}
    >
      <option value="">Select an option</option>
      {schema.get('enum').map((item) => (
        <option key={item} value={item} children={item} />
      ))}
    </SelectField>
  )
}
