import Box from '@parishconnect/box'
import {
  AwardIcon,
  Button,
  CheckIcon,
  Dialog,
  Edit2Icon,
  LinkIcon,
  majorScale,
  Menu,
  PlusIcon,
  Popover,
  Position,
  ShareIcon,
  Text,
  Trash2Icon,
} from '@parishconnect/react-ui'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useClipboard } from 'use-clipboard-copy'
import useRouter from 'use-react-router'
import {
  Event,
  EventsDocument,
  EventsQuery,
  useAddRsvpMutation,
  useDeleteEventMutation,
  useDeleteRsvpMutation,
  usePinEventMutation,
  EventQuery,
} from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils/abilityContext'

export const SingleEventActions = (event: EventQuery['event']) => {
  return (
    <Box
      marginX={[majorScale(1), null, majorScale(3)]}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
    >
      <Box display="flex" width="100%">
        <ShareButton {...event} />
        <Can I="read" this={event}>
          <RSVPButton {...event} />
        </Can>
      </Box>
      <Box marginTop={majorScale(2)}>
        <Can I="update" this={event.parish}>
          <PinButton {...event} />
        </Can>
        <Can I="update" this={event}>
          <EditButton {...event} />
        </Can>
        <Can I="delete" this={event}>
          <DeleteButton {...event} />
        </Can>
      </Box>
    </Box>
  )
}

const RSVPButton = ({ id, viewer, rsvpCount }: EventQuery['event']) => {
  const { history } = useRouter()
  const [addRSVP] = useAddRsvpMutation({ variables: { id } })
  const [deleteRSVP] = useDeleteRsvpMutation({ variables: { id } })

  return (
    <Box display="flex" alignItems="center">
      <Button
        height={32}
        marginLeft={majorScale(1)}
        iconBefore={viewer?.hasRSVP ? CheckIcon : PlusIcon}
        round
        onClick={viewer ? (viewer?.hasRSVP ? deleteRSVP : addRSVP) : () => history.push('/login')}
        appearance={viewer?.hasRSVP ? 'primary' : 'default'}
      >
        {viewer?.hasRSVP ? "You're Going" : 'RSVP'}
      </Button>
      {rsvpCount > 0 && (
        <Text marginLeft={majorScale(1)} color="theme">
          {rsvpCount} going
        </Text>
      )}
    </Box>
  )
}

const ShareButton = ({ id }: EventQuery['event']) => {
  const clipboard = useClipboard({ copiedTimeout: 600 })

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Item
            onClick={() => clipboard.copy(typeof document !== 'undefined' && document.location)}
            icon={LinkIcon}
          >
            Copy Link
          </Menu.Item>
        </Menu>
      }
    >
      <Button is="a" height={32} round iconBefore={ShareIcon}>
        Share
      </Button>
    </Popover>
  )
}

const DeleteButton = ({ id, title }: EventQuery['event']) => {
  const { history } = useRouter()
  const [isOpen, setOpen] = useState(false)
  const [deleteEvent, { loading, data }] = useDeleteEventMutation({
    variables: { id },
  })

  useEffect(() => {
    if (data?.deleteEvent) {
      history.push('/events')
    }
  }, [data])

  return (
    <Fragment>
      <Dialog
        confirmLabel="Delete"
        isShown={isOpen}
        title={`Delete ${title}?`}
        intent="danger"
        onConfirm={() =>
          deleteEvent({
            update: cache => {
              const data = cache.readQuery<EventsQuery>({ query: EventsDocument })
              if (data?.events) {
                cache.writeQuery<EventsQuery>({
                  query: EventsDocument,
                  data: {
                    ...data,
                    events: data?.events?.filter(event => event.id !== id),
                  },
                })
              }
            },
          })
        }
        onCancel={() => setOpen(false)}
      >
        Are you sure you want to delete the event {title}?
      </Dialog>
      <Button
        isLoading={loading}
        onClick={() => setOpen(true)}
        height={32}
        marginLeft={majorScale(1)}
        iconBefore={Trash2Icon}
        intent="danger"
        round
      >
        Delete
      </Button>
    </Fragment>
  )
}

const EditButton = ({ id }: EventQuery['event']) => {
  return (
    <Button
      is={Link}
      to={`/event/${id}/edit`}
      height={32}
      marginLeft={majorScale(1)}
      iconBefore={Edit2Icon}
      round
    >
      Edit Event
    </Button>
  )
}

const PinButton = ({ id, pinned }: EventQuery['event']) => {
  const [pinEvent, { loading }] = usePinEventMutation({
    variables: { id, pinned: !pinned },
    refetchQueries: ['EventQuery', 'PinnedQuery', 'FeaturedEventsQuery'],
  })

  return (
    <Button
      height={32}
      appearance={pinned ? 'primary' : 'default'}
      iconBefore={AwardIcon}
      round
      isLoading={loading}
      onClick={pinEvent}
    >
      {pinned ? 'Remove Pin' : 'Pin'}
    </Button>
  )
}
