import { DateTime, DateTimeFormatOptions, Duration } from 'luxon'

export function getShortEventDateString(dtstart: string, duration?: string): string {
  const start = DateTime.fromISO(dtstart)
  const end = duration && start.plus(Duration.fromISO(duration))
  const showEnd = end && !start.hasSame(end, 'day')
  const dtstartFormat: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
  }
  const dtendFormat: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  if (!showEnd) {
    dtstartFormat.year = 'numeric'
  }

  return `${start.toLocaleString(dtstartFormat)}${
    showEnd ? ' - ' + end.toLocaleString(dtendFormat) : ''
  }`
}

export function getFullDateString(dtstart: string, duration?: string) {
  const start = DateTime.fromISO(dtstart)
  const end = duration && start.plus(Duration.fromISO(duration))
  const showEnd = end && !start.hasSame(end, 'day')
  const dateFormat: DateTimeFormatOptions = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  }

  return `${start.toLocaleString(dateFormat)}${
    showEnd ? ' - ' + end.toLocaleString(dateFormat) : ''
  }`
}

export function getStartTimeString(dtstart: string) {
  const start = DateTime.fromISO(dtstart)
  const timeFormat: DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
  }

  return start.toLocaleString(timeFormat)
}

export function getFullTimeString(dtstart: string, duration?: string) {
  const start = DateTime.fromISO(dtstart)
  const end = duration && start.plus(Duration.fromISO(duration))
  const timeFormat: DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
  }

  return `${start.toLocaleString(timeFormat)}${end ? ' - ' + end.toLocaleString(timeFormat) : ''}`
}
