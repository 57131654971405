import Box from '@parishconnect/box'
import { Button, ChevronRightIcon, Heading, majorScale, Card } from '@parishconnect/react-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import { COLUMN } from '../../utils/constants'
import { BulletinLink } from './BulletinLink'
import { useBulletinModuleQuery } from '../../graphql/generated/graphql-hooks'
import { Can } from '../../utils'
import { AddBulletin } from './AddBulletin'

export function CurrentBulletinLink() {
  const { data } = useBulletinModuleQuery()

  const link = data?.bulletin?.currentLink

  return link ? (
    <Box
      marginTop={COLUMN / 2}
      maxWidth={majorScale(38)}
      role="complementary"
      aria-labelledby="current-bulletin-header"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={majorScale(2)}
      >
        <Heading id="current-bulletin-header" size={600}>
          Current Bulletin
        </Heading>
        <Button
          marginRight="auto"
          marginLeft={majorScale(2)}
          appearance="minimal"
          is={Link}
          to="/bulletins"
          iconAfter={ChevronRightIcon}
        >
          All Bulletins
        </Button>
      </Box>
      <BulletinLink i={0} title="Sunday Bulletin" bulletin={link} />
    </Box>
  ) : (
    <Card
      maxWidth={majorScale(38)}
      appearance="solid"
      padding={majorScale(1)}
      marginTop={COLUMN / 2}
      display="flex"
      alignItems="center"
    >
      <Heading marginLeft={majorScale(1)} size={300} marginRight="auto">
        No Bulletins
      </Heading>
      <Can I="create" a="bulletin">
        <AddBulletin {...data?.bulletin} />
      </Can>
    </Card>
  )
}
