import Box from '@parishconnect/box'
import {
  Button,
  CalendarIcon,
  FileTextIcon,
  Heading,
  Image,
  majorScale,
  Menu,
  minorScale,
  Pane,
  Popover,
  Position,
  SearchIcon,
  StackingOrder,
  UsersIcon,
  Stack,
} from '@parishconnect/react-ui'
import * as Sentry from '@sentry/browser'
import { isEmpty } from 'lodash-es'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import logoSrc from '../../assets/white_logo.svg'
import { HeaderQuery, useUserQuery } from '../../graphql/generated/graphql-hooks'
import { AbilityContext } from '../../utils/abilityContext'
import { COLUMN, COLUMNS } from '../../utils/constants'
import { UserHeaderMenu, HeaderSearch } from './'

export function Header() {
  const { data, loading } = useUserQuery()

  return (
    <Stack value={StackingOrder.OVERLAY}>
      {(zIndex: number) => (
        <Pane
          is="header"
          aria-label="Main Header"
          appearance="gradient"
          position="fixed"
          zIndex={zIndex + 2}
          top={0}
          left={0}
          width="100vw"
          paddingLeft="env(safe-area-inset-left)"
          paddingRight="env(safe-area-inset-right)"
          paddingTop="env(safe-area-inset-top)"
        >
          <Box
            display="flex"
            alignItems="center"
            minHeight={majorScale(6)}
            height={['auto', majorScale(6)]}
            maxWidth={COLUMN * COLUMNS}
            paddingX={majorScale(2)}
            boxSizing="content-box"
            marginX="auto"
          >
            <Box display="flex" alignItems="center">
              <Image src={logoSrc} alt="ParishConnect Logo" width={minorScale(5)} />
              <Button
                is={Link}
                to="/"
                size={400}
                appearance="minimal"
                strong
                color="white!important"
                marginLeft={majorScale(1)}
                display={['none', null, 'initial']}
              >
                Home
              </Button>
              <CreateMenu {...data} />
            </Box>
            <Heading
              is={Link}
              to="/"
              position={['static', 'absolute']}
              left="50%"
              marginLeft={[8, 0]}
              transform={['none', 'translateX(-50%)']}
              color="white"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              maxWidth="50%"
              className="fv white"
            >
              {data?.parish?.name}
            </Heading>
            <Box marginLeft="auto" display="flex" alignItems="center">
              <HeaderSearch />
              {!!data?.user?.id ? (
                <UserHeaderMenu parish={data?.parish} user={data?.user} />
              ) : (
                <Button
                  is={Link}
                  to="/login"
                  appearance="minimal"
                  size={400}
                  strong
                  color="white!important"
                >
                  Login
                </Button>
              )}
            </Box>
          </Box>
        </Pane>
      )}
    </Stack>
  )
}

const CreateMenu = ({ parish }: HeaderQuery) => {
  const ability = useContext(AbilityContext)
  const items = [
    { type: 'Post', icon: FileTextIcon, to: '/post/new' },
    { type: 'Event', icon: CalendarIcon, to: '/event/new' },
    { type: 'Group', icon: UsersIcon, to: '/group/new' },
  ].filter((item) => {
    try {
      return ability.can('create', { __typename: item.type, parish })
    } catch (error) {
      Sentry.captureException(error)
      return false
    }
  })

  return !isEmpty(items) ? (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Group>
            {items.map((item) => (
              <Menu.Item key={item.type} is={Link} to={item.to} icon={item.icon}>
                {item.type}
              </Menu.Item>
            ))}
          </Menu.Group>
        </Menu>
      }
    >
      <Button
        display={['none', null, 'initial']}
        strong
        size={400}
        color="white!important"
        appearance="minimal"
      >
        Create
      </Button>
    </Popover>
  ) : null
}
