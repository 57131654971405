import Box from '@parishconnect/box'
import {
  Card,
  Heading,
  HeartFilledIcon,
  HeartIcon,
  majorScale,
  minorScale,
  Pane,
  Paragraph,
  Text,
} from '@parishconnect/react-ui'
import { isEmpty, truncate } from 'lodash-es'
import { DateTime } from 'luxon'
import React from 'react'
import { Link } from 'react-router-dom'
import { Post } from '../../graphql/generated/graphql-hooks'
import { cssLineClamp } from '../../utils'
import { PostModuleImage } from './PostModuleImage'

const PostModule = (post: Partial<Post>) => {
  const {
    image,
    title,
    type,
    plain,
    author,
    id,
    createdAt,
    group,
    likeCount,
    viewer,
    parish,
  } = post
  return (
    <Box is={Link} to={`/post/${id}`} css={{ scrollSnapAlign: 'center', width: 'fit-content' }}>
      <Card
        display="inline-block"
        width={['100%', majorScale(38)]}
        height={majorScale(25)}
        background="white"
        hoverElevation={2}
        cursor="pointer"
        border="muted"
        padding={majorScale(2)}
        identifier={parish && parish.theme}
      >
        <Box display="flex">
          {!isEmpty(image) && <PostModuleImage type={type} {...image} />}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            width="100%"
            marginY={majorScale(1)}
            marginLeft={!isEmpty(image) ? majorScale(2) : majorScale(1)}
          >
            <Box display="flex" width="100%" justifyContent="space-between">
              <Heading size={600} maxHeight={48} maxWidth={majorScale(22)} overflow="hidden">
                <Box width={majorScale(22)}>{truncate(title, { length: 38 })}</Box>
              </Heading>
            </Box>
            <Paragraph
              lineHeight={`${majorScale(2)}px`}
              marginTop={minorScale(1)}
              size={300}
              height={majorScale(title.length > 16 ? 6 : 8)}
              css={{
                ...cssLineClamp(3),
              }}
            >
              {plain}
            </Paragraph>
          </Box>
        </Box>
        <PostModuleInfo {...post} />
      </Card>
    </Box>
  )
}

const PostModuleInfo = ({ author, createdAt, parish, group, likeCount, viewer }: Partial<Post>) => (
  <Pane borderTop="muted" position="static" marginTop={majorScale(1)} paddingTop={majorScale(1)}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {author && (
          <Heading lineHeight="12px" size={300}>
            {author.fullName}
          </Heading>
        )}
        <Text size={300}>{DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_MED)}</Text>
        <Text whiteSpace="nowrap" size={300}>{` • ${group?.name || parish?.name}`}</Text>
      </Box>
      <Box display="flex" alignItems="center">
        {likeCount > 0 && <Text marginRight={minorScale(1)}>{likeCount}</Text>}
        {viewer && viewer.likesPost ? (
          <HeartFilledIcon color={'danger'} marginRight={majorScale(1)} />
        ) : (
          <HeartIcon marginRight={majorScale(1)} />
        )}
      </Box>
    </Box>
  </Pane>
)

export default PostModule
