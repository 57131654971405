import { Alert, StackingOrder, Text, majorScale } from '@parishconnect/react-ui'
import React from 'react'

export const NoScriptMessage = ({ domain }: { domain: string }) => (
         <Alert
           appearance="card"
           title="Please Enable JavaScript"
           intent="warning"
           is="noscript"
           position="fixed"
           bottom={majorScale(3)}
           right={majorScale(3)}
           zIndex={StackingOrder.TOASTER}
           role="alert"
           aria-atomic="true"
         >
           <Text>
             Parts of <Text color="theme">{domain}</Text> may not work properly without JavaScript
             enabled.
           </Text>
         </Alert>
       )
