import Box from '@parishconnect/box'
import { Button, Label, majorScale, Small } from '@parishconnect/react-ui'
import React from 'react'
import { RECURRENCE_DAYS } from '../../utils'

export type WeekdaySelectorProps = {
  onChange: (value: string) => void
  onBlur: (e: InputEvent) => void
  value: string
}

export function WeekdaySelector({ onChange, onBlur, value }: WeekdaySelectorProps) {
  return (
    <Box marginBottom={majorScale(3)}>
      <Label>Day</Label>
      <Box marginTop={majorScale(1)} display="flex">
        {RECURRENCE_DAYS.map(r => (
          <Button
            key={r.value}
            onBlur={onBlur}
            type="button"
            display="flex"
            justifyContent="center"
            round
            onClick={() => onChange(r.value)}
            height={majorScale(4)}
            width={majorScale(4)}
            padding={0}
            appearance={value === r.value ? 'primary' : 'minimal'}
          >
            <Small>{r.label}</Small>
          </Button>
        ))}
      </Box>
    </Box>
  )
}
