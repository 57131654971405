import Box from '@parishconnect/box'
import {
  Button,
  Card,
  ChevronRightIcon,
  Dialog,
  Heading,
  HeartIcon,
  majorScale,
  minorScale,
  Text,
  ThemeContext,
} from '@parishconnect/react-ui'
import React, { useContext, useState } from 'react'
import { Parish } from '../../graphql/generated/graphql-hooks'
import { DonationMethodDictionary } from '../Dashboard/DonationsManager'

export function DonateDialog(parish: Partial<Parish>) {
  const [isShown, setShown] = useState(false)
  const theme = useContext(ThemeContext)
  return (
    <>
      <Button
        marginX={minorScale(1)}
        appearance="primary"
        iconBefore={HeartIcon}
        css={{
          scrollSnapAlign: 'center',
          boxShadow:
            'inset 0 0 0 0px hsla(334, 79%, 50%, 0.35), 0 1px 6px -0.5px hsla(334, 79%, 50%, 0.35)',
          background: 'linear-gradient(-25deg,hsla(334, 79%, 50%, 1),hsla(314, 79%, 65%, 1))',
          '&:not([disabled]):not([data-disabled]):hover': {
            boxShadow: '0 0 0 2px hsla(334, 79%, 50%, 0.35) !important',
          },
          '&:not([disabled]):not([data-disabled]):active, &:not([disabled]):not([data-disabled]):focus': {
            boxShadow: '0 0 0 2px hsla(334, 79%, 50%, 0.5) !important',
            background: 'linear-gradient(-25deg,hsla(334, 79%, 45%, 1),hsla(314, 79%, 60%, 1))',
          },
        }}
        onClick={() => setShown(true)}
        color="white"
      >
        Donate
      </Button>
      <Dialog
        title={`Donate to ${parish.name}`}
        isShown={isShown}
        hasFooter={false}
        onCloseComplete={() => setShown(false)}
      >
        <Box paddingY={majorScale(2)} display="flex" flexDirection="column" gap={majorScale(1)}>
          {parish?.donationMethods?.map((donationMethod) => (
            <a
              key={donationMethod.id}
              rel="noopener noreferrer nofollow"
              target="_blank"
              href={donationMethod.url}
            >
              <Card
                appearance="solid"
                padding={majorScale(2)}
                border="muted"
                elevation={1}
                hoverElevation={2}
                display="flex"
                alignItems="center"
              >
                <div>
                  <Heading color="theme">
                    {donationMethod?.title || DonationMethodDictionary[donationMethod.method].name}
                  </Heading>
                  <Text color="muted">{donationMethod.note}</Text>
                </div>
                <ChevronRightIcon
                  size={majorScale(3)}
                  color={theme.getThemeColor(theme)}
                  marginLeft="auto"
                />
              </Card>
            </a>
          ))}
        </Box>
      </Dialog>
    </>
  )
}
