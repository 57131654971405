import Box from '@parishconnect/box'
import { Image, majorScale } from '@parishconnect/react-ui'
import React from 'react'
import christmas from '../../assets/christmas.svg'

const iconMap = {
  christmas,
}

export function SpecialMassTableIcon({ seasonName }: { seasonName: string }) {
  const icon = iconMap[seasonName.toLowerCase()]

  if (!icon) return null

  return (
    <Image
      height="50%"
      src={icon}
      position="absolute"
      top="-25px"
      left="-25px"
      opacity="0.05"
      mixBlendMode="overlay"
    />
  )
}
