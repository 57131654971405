import Box from '@parishconnect/box'
import {
  Heading,
  majorScale,
  minorScale,
  ThemeContext,
  Button,
  PlusIcon,
} from '@parishconnect/react-ui'
import color from 'color'
import React, { Fragment, useContext } from 'react'
import { NavLink } from 'react-router-dom'

type NavSubMenuProps = {
  title: string
  root: string
  items: {
    id: string
    title: string
    to: string
  }[]
}

export const NavSubMenu = ({ items, title, root }: NavSubMenuProps) => {
  const theme = useContext(ThemeContext)

  if (!items || items.length <= 0) return null

  return (
    <Fragment>
      <Box width={0} height={items.length * 5.5 * 8} position="relative" />
      <Box
        is="nav"
        aria-labelledby="submenu-heading"
        position="absolute"
        display="flex"
        flexDirection="column"
        top={majorScale(2)}
        left={majorScale(9)}
        width={`calc(100% - ${majorScale(10)}px)`}
      >
        <Heading
          id="submenu-heading"
          size={200}
          textTransform="uppercase"
          marginBottom={majorScale(1)}
          marginLeft={majorScale(2)}
          color={color(theme.getThemeColor(theme)).saturate(10).rotate(-25).lighten(0.7).hex()}
        >
          {title}
        </Heading>
        {items.map(({ title, to, id }, i, arr) => (
          <Heading
            marginBottom={i !== arr.length - 1 && minorScale(1)}
            paddingY={majorScale(1)}
            paddingX={majorScale(2)}
            is={NavLink}
            to={`${root}${to ? `/${to}` : ''}`}
            key={id}
            exact={!to}
            color="white"
            borderRadius={14}
            width={majorScale(27)}
            transition="background 225ms"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            css={{
              '&.active': {
                background: theme.palette[theme.themeColor].dark,
              },
              '&:hover': {
                background: color(theme.palette[theme.themeColor].dark).darken(0.05).hex(),
              },
            }}
          >
            {title}
          </Heading>
        ))}
      </Box>
    </Fragment>
  )
}
