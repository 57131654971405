import Box from '@parishconnect/box'
import * as Sentry from '@sentry/browser'
import {
  Button,
  Card,
  Heading,
  majorScale,
  PlusSquareIcon,
  TextInputField,
  ThemeContext,
  Text,
  ExternalLinkIcon,
  IconButton,
  TrashIcon,
} from '@parishconnect/react-ui'
import { Field, Form, Formik } from 'formik'
import React, { useContext } from 'react'
import * as yup from 'yup'
import {
  ParishQuery,
  useAddParishLinkMutation,
  useDeleteParishLinkMutation,
} from '../../graphql/generated/graphql-hooks'
import { truncate } from 'lodash-es'

const linkValidationSchema = new yup.object().shape({
  href: yup.string().url().required(),
  text: yup.string().required().max(22),
})

export function LinksEditor({ links, id }: ParishQuery['parish']) {
  const theme = useContext(ThemeContext)
  const [addLink] = useAddParishLinkMutation()

  return (
    <Box>
      <Heading marginTop={majorScale(3)} size={500}>
        Links {`${links.length}/4`}
      </Heading>
      <Text color="muted">
        Links appear on the home page below the hero image. Use these links for important websites
        outside of your parish site.
      </Text>
      <Box display="grid" gridAutoFlow="row" marginTop={majorScale(2)} gridGap={majorScale(1)}>
        {links.map((link) => (
          <LinkCard key={link.id} link={link} parishId={id} />
        ))}
      </Box>
      {links.length <= 3 && (
        <Card appearance="solid" padding={majorScale(2)} marginTop={majorScale(3)} border="muted">
          <Heading color="theme" marginBottom={majorScale(2)} size={500}>
            Add A Link
          </Heading>
          <Formik
            onSubmit={async (link, actions) => {
              try {
                await addLink({ variables: { link } })
                actions.setSubmitting(false)
              } catch (error) {
                Sentry.captureException(error)
                actions.setErrors(error)
              }
            }}
            validationSchema={linkValidationSchema}
            initialValues={{ href: '', text: '' }}
          >
            <Form>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" width="100%">
                  <TextInputField
                    is={Field}
                    flexBasis="50%"
                    name="text"
                    label="Text"
                    maxLength="22"
                    placeholder="Link text..."
                    css={{ backgroundColor: theme.palette[theme.themeColor].light }}
                  />
                  <TextInputField
                    flexBasis="50%"
                    marginLeft={majorScale(1)}
                    is={Field}
                    name="href"
                    label="URL"
                    placeholder="example.com/link"
                    css={{ backgroundColor: theme.palette[theme.themeColor].light }}
                  />
                </Box>
                <Button
                  type="Submit"
                  whiteSpace="nowrap"
                  iconBefore={PlusSquareIcon}
                  intent="success"
                  appearance="primary"
                  marginLeft={majorScale(1)}
                >
                  Add Link
                </Button>
              </Box>
            </Form>
          </Formik>
        </Card>
      )}
    </Box>
  )
}

function LinkCard({
  parishId,
  link,
}: {
  link: ParishQuery['parish']['links'][0]
  parishId: string
}) {
  const theme = useContext(ThemeContext)
  const [deleteParishLink, { loading }] = useDeleteParishLinkMutation({
    variables: { parishId, id: link.id },
  })
  return (
    <Card
      appearance="solid"
      key={link.id}
      padding={majorScale(2)}
      display="flex"
      alignItems="center"
      maxWidth="100%"
    >
      <ExternalLinkIcon color={theme.getThemeColor(theme)} marginRight={majorScale(1)} />
      <Text whiteSpace="nowrap" color="theme" marginRight={majorScale(2)}>
        {link.text}
      </Text>
      <Text
        color="muted"
        whiteSpace="nowrap"
        maxWidth={majorScale(40)}
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {link.href}
      </Text>
      <IconButton
        onClick={deleteParishLink}
        isLoading={loading}
        intent="danger"
        icon={TrashIcon}
        appearance="primary"
        marginLeft="auto"
      />
    </Card>
  )
}
