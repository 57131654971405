import Box from '@parishconnect/box'
import {
  Button,
  Card,
  Heading,
  HeartIcon,
  Link,
  majorScale,
  Text,
  ThemeContext,
} from '@parishconnect/react-ui'
import { ButtonProps } from '@parishconnect/react-ui/dist/buttons/src/Button'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Footer, Hero, Nav, Renderer } from '../components'
import { Donation_Methods, useParishQuery } from '../graphql/generated/graphql-hooks'
import { COLUMN, COLUMNS } from '../utils/constants'
import donatePost from './donatePost.json'

const GUTTER = majorScale(4)

export default function Donate() {
  const { data } = useParishQuery()
  const theme = useContext(ThemeContext)
  return (
    <Box
      marginX="auto"
      paddingY={[majorScale(8), majorScale(9)]}
      maxWidth={COLUMN * COLUMNS + GUTTER}
      marginTop="env(safe-area-inset-top)"
      minHeight="-webkit-fill-available"
    >
      <Helmet title="Home" />
      <Box
        display="flex"
        flexWrap={['wrap', null, 'initial']}
        flexDirection={['column-reverse', null, 'row']}
        width="100%"
      >
        <Box maxWidth={['100%', null, COLUMN * 6 + majorScale(4)]} paddingX={majorScale(2)}>
          <Nav menuOpen showMasses />
          <Footer />
        </Box>
        <Box width="100%" is="main">
          <Box paddingX={majorScale(2)}>
            <Hero />
          </Box>
          <Box
            width="100%"
            paddingX={[majorScale(3), null, majorScale(6)]}
            paddingTop={majorScale(4)}
            id="main-content"
          >
            <Heading size={900}>Donate to {data?.parish?.name || ''}</Heading>
            <Box
              display="flex"
              gap={majorScale(6)}
              flexWrap={['wrap', null, 'nowrap']}
              paddingY={majorScale(2)}
            >
              <Box maxWidth="75ch" width="100%">
                <Renderer document={JSON.stringify(donatePost)} />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                maxWidth={[500, null, 275]}
                minWidth={75}
                css={{ gap: 16 }}
              >
                <Card display="block" overflow="hidden" appearance="solid">
                  <Box padding={majorScale(2)}>
                    <Button
                      marginBottom={majorScale(2)}
                      appearance="primary"
                      is="a"
                      href="https://parishconnect.imgix.net/5b7d9148513b5759ab01f68f/Pre-Authorized_Giving_Form.pdf?expires=2384040132&s=2e8cc7b41188a1ab3dc9355817d10fa6"
                    >
                      Download Giving Form
                    </Button>
                    <Heading
                      size={100}
                      color={theme.palette[theme.themeColor].text}
                      marginBottom={majorScale(1)}
                    >
                      Pre-Authorized Giving
                    </Heading>

                    <Text>
                      Click, complete, and email to{' '}
                      <Link href="mailto:nativity@myparish.org">nativity@myparish.org</Link> or
                      print and drop off the completed form into the mail slot by the parish office.
                      <br />
                      <br />
                      Thank you for your donation!
                    </Text>
                  </Box>
                </Card>

                <Card width="100%" appearance="solid" padding={majorScale(2)}>
                  <DonateButton
                    href={
                      data?.parish?.donationMethods?.find(
                        (donationMethod) => donationMethod.method === Donation_Methods.Toronto,
                      )?.url
                    }
                  >
                    Donate Online
                  </DonateButton>
                  <Heading
                    size={100}
                    color={theme.palette[theme.themeColor].text}
                    paddingY={majorScale(1)}
                    marginTop={majorScale(1)}
                  >
                    Online Giving
                  </Heading>
                  <Text>
                    Click the button above, complete, Please select <b>monthly gift.</b> and submit
                    online.
                    <br />
                    <br />
                    <Text>Thank you for your donation!</Text>
                  </Text>
                </Card>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const DonateButton = (props: ButtonProps) => (
  <Button
    key="donate"
    appearance="primary"
    iconBefore={HeartIcon}
    css={{
      boxShadow:
        'inset 0 0 0 0px hsla(334, 79%, 50%, 0.35), 0 1px 6px -0.5px hsla(334, 79%, 50%, 0.35)',
      background: 'linear-gradient(-25deg,hsla(334, 79%, 50%, 1),hsla(314, 79%, 65%, 1))',
      '&:not([disabled]):not([data-disabled]):hover': {
        boxShadow: '0 0 0 2px hsla(334, 79%, 50%, 0.35) !important',
      },
      '&:not([disabled]):not([data-disabled]):active, &:not([disabled]):not([data-disabled]):focus': {
        boxShadow: '0 0 0 2px hsla(334, 79%, 50%, 0.5) !important',
        background: 'linear-gradient(-25deg,hsla(334, 79%, 45%, 1),hsla(314, 79%, 60%, 1))',
      },
    }}
    is="a"
    color="white"
    {...props}
  />
)