import { ObjectMark, ObjectNode } from '@remirror/core'
import React from 'react'
import { isString } from 'util'
import { MARK_MAP } from '../Renderer'

export type TextHandlerProps = {
  node: ObjectNode
  markMap: typeof MARK_MAP
  skipUnknownMarks?: boolean
}

export const normalizeMark = (mark: ObjectMark | string) =>
  isString(mark) ? { type: mark, attrs: {} } : { attrs: {}, ...mark }

export const TextHandler: React.FC<TextHandlerProps> = ({ node, ...props }) => {
  if (!node?.text) {
    return null
  }

  const textElement = <>{node.text}</>

  if (!node.marks) {
    return textElement
  }

  const fn = (child: JSX.Element, mark: ObjectMark | string) => {
    const normalized = normalizeMark(mark)
    const MarkHandler = props.markMap[normalized.type]

    if (!MarkHandler) {
      if (!props.skipUnknownMarks) {
        console.warn(
          !props.skipUnknownMarks,
          'No handler for mark type `' + normalized.type + '` registered',
        )
      }

      return child
    }

    return <MarkHandler {...normalized.attrs}>{child}</MarkHandler>
  }

  // Use assigned mark handlers
  return node.marks.reduce<JSX.Element>(fn, textElement)
}
