import { escape } from 'lodash-es'

export function getTwitterShareURL({
  url,
  text,
  hashtagsArray,
}: {
  url: string
  text?: string
  hashtagsArray?: string[]
}) {
  url = '?url=' + escape(url)
  text = text ? '&text=' + escape(text) : ''
  const hashtags = hashtagsArray?.length > 0 ? '&hashtags=' + hashtagsArray.join(',') : ''

  return `http://twitter.com/share${url}${text}${hashtags}`
}

export function getFacebookShareURL(url: string) {
  url = escape(url)
  return `http://www.facebook.com/sharer.php?u=${url}`
}
